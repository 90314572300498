import {useHasAccess} from "contexts/UserAccessContext";
import {Permissions, Roles} from "contexts/UserAccessContext/const";

const usePermissions = () => {
  const canEnableDisableActiveAndVerify= useHasAccess([])
  const canEditUsers = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.EDIT_USERS]
    }
  ])

  return {
    canEnableDisableActiveAndVerify,
    canEditUsers
  }
}

export default usePermissions

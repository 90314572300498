import axios from "axios";
import paths from "routes/paths";


class AuthService {
  public login(isAuthenticated: boolean) {
    if (!isAuthenticated) {
      this.logout();
    }

    this.isAuthenticated = isAuthenticated;
    const params = new URLSearchParams(window.location.search);
    const from = params.get("from") || "/";
    window.location.replace(from || "/");
  }

  public logout() {
    axios.defaults.headers.common.Authorization = null;
    this.isAuthenticated = false;

    const from = encodeURIComponent(window.location.pathname);
    window.location.replace(`${paths.auth.login  }?from=${from}`);
  }

  // eslint-disable-next-line class-methods-use-this
  private set isAuthenticated(status: boolean) {
    localStorage.setItem("isAuthenticated", status.toString());
  }

  // eslint-disable-next-line class-methods-use-this
  public get isAuthenticated(): boolean {
    const value = localStorage.getItem("isAuthenticated");
    return value ? value === 'true' : false;
  }
}

const authService = new AuthService();

export default authService;

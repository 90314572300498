import { useQuery } from "@tanstack/react-query";
import ApiClient from "apiClient";
import {CustomerQueryKeys} from "shared/queryKeys";

type Params = Parameters<typeof ApiClient.customers.getCustomerOverview>[0]

export const useCustomerOverview = ({email}: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: [CustomerQueryKeys.OVERVIEW, {email}],
    queryFn: () => ApiClient.customers.getCustomerOverview({email}),
    enabled: !!email,
  });

  return {
    data,
    isLoading,
  };
};
import {Box, Skeleton, Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";

const SkeletonStatsItem = () => {
  const theme = useTheme()

  return(
    <Box sx={{
      borderRadius: '8px',
      width: '100%',
      padding: '8px 12px',
      backgroundColor: theme.palette.themeColors.grey['20']
    }}>
      <Stack gap='12px' padding='4px 0px'>
        <Typography>
          <Skeleton width={140} height={16} variant='rounded'/>
        </Typography>
        <Typography>
          <Skeleton width={123} height={20} variant='rounded'/>
        </Typography>
      </Stack>
    </Box>
  )
}

export default SkeletonStatsItem;
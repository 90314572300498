import usePagination from "shared/hooks/usePagination";
import {
  Box,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
} from "@mui/material";
import {AppCard, AppTableHead} from "shared/components/mui";
import LoaderBackdrop from "shared/components/LoaderBackdrop";
import useSorting from "shared/hooks/useSorting";
import useDataFilters from "apps/merchantDirectory/pages/list/useDataFilters";
import {useCallback, useMemo, useState} from "react";
import {useDeleteMerchantDirectoryContacts} from "apps/merchantDirectory/pages/list/useDataMutations";
import DialogConfirm from "shared/components/DialogConfirm";
import useDialogState from "shared/hooks/useDialogState";
import TableToolbar from "shared/components/TableToolbar";
import ContactRow from "apps/merchantDirectory/pages/list/ContactRow";
import CreateMerchantDirectoryDialog from "apps/merchantDirectory/pages/create";
import usePermissions from "apps/merchantDirectory/pages/list/permissions/usePermissions";
import PermissionError from "shared/PermissionError";

const TABLE_HEAD = [
  {id: 'title', label: 'NAME', sortable: true},
  {id: 'email', label: 'INFO', sortable: true},
  {id: 'contact_type', label: 'STATUS', sortable: true},
  {id: 'created_at', label: 'CREATED', sortable: true},
]

const MerchantDirectory = () => {
  const {currentPage, perPage, pagination} = usePagination();

  const [selected, setSelected] = useState<string[]>([]);

  const createMerchantDirectoryDialog = useDialogState();
  const confirmDialog = useDialogState();

  const {
    orderDirection,
    orderBy,
    orderByField,
    handleOrderBy,
  } = useSorting({defaultOrderBy: 'created_at'});

  const {data, isLoading, isFetching, filters} = useDataFilters({currentPage, perPage, orderBy: orderByField});

  const {deleteContacts} = useDeleteMerchantDirectoryContacts({dialogStateHandler: confirmDialog});

  const {canAccessMerchantDirectory, canManageMerchantDirectory} = usePermissions();

  const onSubmit = () => {
    deleteContacts({ids: selected})
    setSelected([])
  }

  const handleSelectAllClick = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = (data?.items?.map((el) => el._id) || []).filter(Boolean);
      setSelected(newSelected.filter((id): id is string => id !== undefined));
      return;
    }
    setSelected([]);
  }, [data]);

  const handleClick = useCallback((event: React.MouseEvent<unknown>, id: string) => {
    const newSelected: string[] = selected.includes(id)
      ? selected.filter(item => item !== id)
      : [...selected, id];
    setSelected(newSelected);
  }, [selected]);

  const isSelected = useMemo(() => (id: string) => selected.includes(id), [selected]);

  if (isLoading) return <LinearProgress/>

  if (!canAccessMerchantDirectory){
    return <PermissionError />
  }

  return (
    <AppCard
      title='Merchant Directory'
      action={
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          {canManageMerchantDirectory && (
            <Box mr={2}>
              <Button variant="outlined" color="primary" onClick={createMerchantDirectoryDialog.openDialog}>
                Create
              </Button>
            </Box>
          )}
          {filters}
        </Box>
      }
    >
      <TableContainer sx={{overflow: 'unset'}}>
        <TableToolbar numSelected={selected.length} dialogStateHandler={confirmDialog} perms={canManageMerchantDirectory}/>
        <Table size='small' sx={{minWidth: 800, bgcolor: '#fff', position: 'relative'}}>
          <AppTableHead
            headLabel={TABLE_HEAD}
            order={orderDirection}
            orderBy={orderBy}
            onSort={handleOrderBy}
            numSelected={selected.length}
            onSelectAllRows={handleSelectAllClick}
            rowCount={data?.items?.length || 0}
          />

          <TableBody sx={{position: 'relative'}}>
            {isLoading && 'Loading...'}
            <LoaderBackdrop open={!isLoading && isFetching}/>
            {data && data.items?.map((el => (
              <ContactRow
                isItemSelected={isSelected(el._id || '')}
                handleOnClick={(event: React.MouseEvent<unknown>) => handleClick(event, el._id || '')}
                key={el._id}
                _id={el._id}
                name={el.name}
                title={el.title}
                email={el.email}
                phone={el.phone}
                messaging_appname={el.messaging_appname}
                messaging_username={el.messaging_username}
                contact_type={el.contact_type}
                created_by_username={el.created_by_username}
                created_at={el.created_at}
                additional_notes={el.additional_notes}
              />
            )))}
          </TableBody>

          <TableFooter>
            <TableCell colSpan={9} align='right' sx={{py: 1, border: 'none'}}>
              {pagination({total: data?.total, pages: data?.pages})}
            </TableCell>
          </TableFooter>
        </Table>
      </TableContainer>

      <DialogConfirm
        dialogStateHandler={confirmDialog}
        handleConfirm={onSubmit}
        title="Are you sure to delete selected contact? You won't be able to revert this!"
      />

      <CreateMerchantDirectoryDialog dialogStateHandler={createMerchantDirectoryDialog} />
    </AppCard>
  )
};

export default MerchantDirectory;

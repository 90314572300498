import {Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import {startCase, toLower} from "lodash";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useFormContext} from "react-hook-form";
import SwitchFormField from "shared/components/formFields/SwitchFormField";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledAccordion = styled(Accordion)(({theme}) => ({
  border: 'none',
  '&.Mui-expanded': {
    margin: 0,
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledAccordionSummary = styled(AccordionSummary)(({theme}) => ({
  padding: `${theme.spacing(1)} 0`,
  minHeight: 0,
  '& .MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': {
      minHeight: 0,
      margin: 0,
    },
  },
  '&.Mui-expanded': {
    minHeight: 0,
    margin: 0,
  },
}));

interface FieldsListProps {
  title: string,
  fields: readonly string[]
}

const FieldsList = ({title, fields}: FieldsListProps) => {
  const theme = useTheme();
  const {watch} = useFormContext();

  const allFields = watch('fields');

  const getFieldsChecked = (items: readonly string[]) => allFields
    ? Object.entries(allFields)
      .filter(([key]) => items.includes(key))
      .filter(([, value]) => Boolean(value))
      .map(([key]) => key)
    : []

  const checkedFieldsCount = getFieldsChecked(fields).length

  return (
    <StyledAccordion>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1-content"
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="B4">
            {startCase(toLower(title))}
          </Typography>
          <Typography variant="B6" sx={{color: theme.palette.primary.main}}>
            {checkedFieldsCount > 0 && `(${checkedFieldsCount})`}
          </Typography>
        </Stack>
      </StyledAccordionSummary>
      <AccordionDetails
        sx={{
          padding: '3px 0px 13px 20px',
        }}
      >
        <Stack>
          {fields.map((field: string) =>
            (
              <Stack key={field} direction="row" justifyContent="space-between" mb={1}>
                <Typography
                  variant="B4"
                  sx={{
                    color: theme.palette.themeColors.grey[110],
                    fontWeight: 400,
                  }}
                >
                  {startCase(toLower(field))}
                </Typography>
                <Box
                  sx={{
                    '& .MuiFormControlLabel-root': {
                      margin: 0,
                    },
                  }}
                >
                  <SwitchFormField name={`fields.${field}`}/>
                </Box>

              </Stack>
            )
          )}
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  )
};

export default FieldsList;

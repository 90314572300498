import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


interface FileListSelectorProps {
  fieldsList: string[];
  visibleGroups: string[];
  handleAddVisibleGroup: (field: string) => void;
}

const FileListSelector = ({fieldsList, visibleGroups, handleAddVisibleGroup}: FileListSelectorProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (field: string) => () => {
    handleAddVisibleGroup(field);
    handleClose();
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Add other documents
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {fieldsList.map((field) => {
          if (!visibleGroups.includes(field)) {
            return <MenuItem onClick={handleMenuItemClick(field)} key={field}>{field}</MenuItem>
          }
          return null;
        })}
      </Menu>
    </div>
  );
}

export default FileListSelector;

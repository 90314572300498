import {Grid} from "@mui/material";
import PersonalInfo from "apps/account/pages/settings/PersonalInfo";
import {useUserSettings} from "apps/account/pages/settings/useDataQueries";
import ContactInfo from "apps/account/pages/settings/ContacInfo";
import ChangePassword from "apps/account/pages/settings/ChangePassword";
import TwoFa from "apps/account/pages/settings/TwoFa";
import AuthApp from "apps/account/pages/settings/AuthApp";

const AccountSettings = () => {
  const {data} = useUserSettings()

  if (!data){
    return null
  }

  return (
    <Grid container spacing={3} sx={{mt: 0, pt: 0}}>
      <Grid item lg={6} xs={12}>
        <PersonalInfo firstName={data.first_name} lastName={data.last_name} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <ContactInfo email={data.email_address} phone={data.phone} emailVerified={data.email_verified} phoneVerified={data.phone_verified} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <ChangePassword lastPasswordCreatedAt={data.last_password_created_at} passwordUpdatedRequiredAt={data.password_updated_required_at} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TwoFa twoFaEnabled={data.two_fa_enabled} preferredMethod={data.preferred_2fa} />
        <AuthApp authenticator_app_enabled={data.authenticator_app_enabled} />
      </Grid>
    </Grid>
  )
}

export default AccountSettings
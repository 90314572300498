import {keepPreviousData, useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AccountQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {IApiError} from "shared/types";
import {DialogStateHandler} from "shared/hooks/useDialogState";

interface UseUpdateUserSettingsAttributes {
  successText: string
  dialogHandler?: DialogStateHandler
}

export const useUserSettings = () => {
  const {data, isLoading,  isFetching} = useQuery({
    queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS],
    queryFn: () => ApiClient.account.getAccountSettings({}),
    placeholderData: keepPreviousData,
  })

  return {
    data,
    isLoading,
    isFetching
  }
}

type Params = Parameters<typeof ApiClient.account.patchAccountSettings>[0]

export const useUpdateUserSettings = ({successText, dialogHandler}: UseUpdateUserSettingsAttributes) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.account.patchAccountSettings(params),
    onSuccess: async () => {
      dialogHandler?.openDialog()
      enqueueSnackbar(successText, {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS]})
    },
    onError: (error: IApiError) => {
      const {email} = error.body.message
      const {phone} = error.body.message
      if (phone) enqueueSnackbar(phone[0], {variant: 'error'})
      if (email) enqueueSnackbar(email[0], {variant: 'error'})
    }
  })

  return {
    mutate,
    isPending,
  }
}
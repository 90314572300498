import { CustomCellRendererProps } from "@ag-grid-community/react";
import Bank from "shared/components/DS/Bank";

const BankRenderer = ({value}: CustomCellRendererProps) => {
  if (!value) return null;

  return <Bank bankName={value} />;
}

export default BankRenderer;

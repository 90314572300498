import React from 'react'
import { styled, alpha } from '@mui/material/styles'
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  MenuProps,
} from '@mui/material'

interface AppMenuProps extends MenuProps {
  children?: React.ReactNode
  name: string
  width?: number
  menu?: {
    icon?: React.ReactNode
    label: string
    onClick?: () => void
    divider?: boolean
    disabled?: boolean
  }[]
}

interface MuiMenuProps extends MenuProps {
  width?: number
}

const MuiMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))<MuiMenuProps>(({ theme, width }) => ({
  '& .MuiPaper-root': {
    fontSize: 12,
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: width || 100,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

const AppMenu: React.FC<AppMenuProps> = ({ name, menu, children, ...props }) => (
  <MuiMenu
    id={`${name}-menu`}
    MenuListProps={{
      'aria-labelledby': `${name  }-button`,
    }}
    {...props}
  >
    {children || (
      <MenuList>
        {menu?.map((item) => (
          <>
            {item.divider && <Divider />}
            <MenuItem onClick={item.onClick} disabled={item.disabled}>
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          </>
        ))}
      </MenuList>
    )}
  </MuiMenu>
)

export default AppMenu

import {Button, Chip, TableCell, TableRow, Typography} from "@mui/material";
import {KYCRequestSchema} from "generatedTypes";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";
import useDialogState from "shared/hooks/useDialogState";
import KYCDetailDialog from "apps/KYCRequests/pages/list/KYCDetailDialog";

function KYCRequestRow({
  _id,
  email,
  site_name,
  sid,
  status,
  verified_at,
  verified_by
}: KYCRequestSchema) {
  const detailsDialog = useDialogState();

  return (
    <>
      <TableRow key={_id}>
        <TableCell>
          <Typography variant='body2'>{email}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2'>
            {site_name} - ({sid})
          </Typography>
        </TableCell>
        <TableCell>
          <Chip label={status} color={status === 'verified' ? 'success' : 'warning'} />
        </TableCell>
        <TableCell>
          <Typography variant='body2'>
            {verified_at && convertIsoToCustomFormat(verified_at)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2'>{verified_by}</Typography>
        </TableCell>
        <TableCell>
          <Button variant='contained' size='small' onClick={detailsDialog.openDialog}>Details</Button>
        </TableCell>
      </TableRow>

      <KYCDetailDialog dialogStateHandler={detailsDialog} email={email} kycId={_id} />
    </>
  )
}

export default KYCRequestRow;

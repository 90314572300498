import palette from "theme/palette";
import {bindTrigger, PopupState} from "material-ui-popup-state/hooks";
import {Box, IconButton, Stack} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";

const BlackDot = styled(Box)(() => ({
  width: '3px',
  height: '3px',
  borderRadius: '50%',
}));

interface TableIconButtonProps {
  popupState: PopupState;
}

const TableIconButton = ({popupState}: TableIconButtonProps) => {
  const theme = useTheme();

  return(
    <IconButton
      aria-haspopup="true"
      sx={{
        padding: '8px',

        border: popupState.isOpen ? `0.5px solid ${palette.themeColors.purple['20']}`: 'none',
        outline: popupState.isOpen ? '3px solid rgba(129, 69, 181, 0.10)' : 'none',
        backgroundColor: popupState.isOpen ? theme.palette.themeColors.grey['10'] : 'transparent',

        '.dot': {
          backgroundColor: popupState.isOpen ? theme.palette.themeColors.grey['120'] : theme.palette.themeColors.grey['90'],
        },

        '&:hover': {
          backgroundColor: theme.palette.themeColors.grey['30'],

          '.dot': {
            backgroundColor: theme.palette.themeColors.grey['110'],
          }
        },

      }}
      {...bindTrigger(popupState)}
    >
      <Stack
        direction="row"
        sx={{width: '16px', height: '16px', gap: '3px'}}
        alignItems="center"
      >
        <BlackDot className='dot'/>
        <BlackDot className='dot'/>
        <BlackDot className='dot'/>
      </Stack>
    </IconButton>
  )
}

export default TableIconButton;

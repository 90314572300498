import {useQuery} from "@tanstack/react-query";
import {MerchantQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

export const useMerchantDataQuery = () => {
  const {data, isLoading} = useQuery({
    queryKey: [MerchantQueryKeys.LIST],
    queryFn: () => ApiClient.merchants.getMerchant({perPage: -1}),
  })

  return {
    data,
    isLoading
  };
};

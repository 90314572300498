import {AxiosError} from "axios";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import AuthFormsContainer from "apps/auth/components/AuthFormsWrapper";
import {Alert, Box, CircularProgress, Typography} from "@mui/material";
import {useMutation} from "@tanstack/react-query";
import {autoLoginMutation} from "apps/auth/pages/autoLogin/mutations";
import authService from "routes/authService";


function AutoLoginView() {
  const [errorMessage, setErrorMessage] = useState('');
  const {verificationToken, checksum} = useParams();

  const {mutate, isPending} = useMutation({
    mutationFn: autoLoginMutation,
    onMutate: () => {
    },
    onSuccess: (data) => {
      const isAuthenticated = data.data.is_authenticated;
      if (isAuthenticated) {
        authService.login(isAuthenticated)
      }
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      const message = error.response?.data?.message
      if (typeof message === 'string') {
        setErrorMessage(message)
      }

      if (typeof message === 'object' && message !== null) {
        const messages = Object.keys(message).map(key => message[key])
        setErrorMessage(messages.join('\n'))
      }
    },
  })

  useEffect(() => {
    mutate({verification_token: verificationToken, checksum})
  }, [])

  return (
    <AuthFormsContainer>
      <Box sx={{textAlign: "center", my: 3}}>
        {isPending && (
          <>
            <CircularProgress/>
            <Typography>Signin in...</Typography>
          </>
        )}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Box>
    </AuthFormsContainer>
  )

}

export default AutoLoginView;

import {Link as RouterLink} from "react-router-dom";
import {Link} from "@mui/material";
import UiPaths from "routes/uiPaths";

interface TransactionIDProps {
  value: string;
}

const stripTransactionID = (id: string, frontLength: number = 6, endLength: number = 7): string => {
  if (id.length <= frontLength + endLength) {
    return id;
  }
  return `${id.slice(0, frontLength)} ... ${id.slice(-endLength)}`;
};

const TransactionID = ({value}: TransactionIDProps) => {
  const displayValue = stripTransactionID(value);

  return (
    <Link component={RouterLink} to={UiPaths.transactionPaths.detail.reverse({transactionId: value})}>
      {displayValue}
    </Link>
  )
}

export default TransactionID;
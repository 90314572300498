/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationSchema } from '../models/NotificationSchema';
import type { NotificationsPaginatedSchema } from '../models/NotificationsPaginatedSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NotificationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get dashboard notifications
     * @returns NotificationsPaginatedSchema Success
     * @throws ApiError
     */
    public getNotificationsList({
        page,
        perPage,
        search,
        orderBy,
        xFields,
    }: {
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * Filter by text
         */
        search?: string,
        /**
         * Order by
         */
        orderBy?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<NotificationsPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/notifications/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
                'search': search,
                'order_by': orderBy,
            },
        });
    }
    /**
     * Get dashboard notification details
     * @returns NotificationSchema Success
     * @throws ApiError
     */
    public getNotificationDetail({
        notificationId,
        xFields,
    }: {
        notificationId: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<NotificationSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/notifications/{notification_id}/',
            path: {
                'notification_id': notificationId,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
}

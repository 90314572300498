import {AppTableHead} from "shared/components/mui";
import {
  Backdrop,
  Box,
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
} from "@mui/material";
import usePagination from "shared/hooks/usePagination";
import useSorting from "shared/hooks/useSorting";
import useDataFilters from "apps/dashboardRequests/pages/list/useDataFilters";
import DashboardRequestsRow from "apps/dashboardRequests/pages/list/DashboardRequestsRow";
import usePermissions from "apps/dashboardRequests/pages/permissions/usePermissions";
import PermissionError from "shared/PermissionError";

const TABLE_HEAD = [
  {id: 'sid_name', label: 'Site Name', sortable: true},
  {id: 'txn', label: 'TXID', sortable: true},
  {id: 'action', label: 'Action', sortable: true},
  {id: 'amount', label: 'Amount', sortable: true},
  {id: 'reason', label: 'Reason', sortable: true},
  {id: 'status', label: 'Status', sortable: true},
  {id: 'comment', label: 'Comment', sortable: true},
  {id: 'created_by', label: 'Created By', sortable: true},
  {id: 'created_at', label: 'Created At', sortable: true},
  {id: 'updated_by', label: 'Updated By', sortable: true},
  {id: 'updated_at', label: 'Updated At', sortable: true},
  {id: 'actions', label: ''},
]

const DashboardRequests = () => {
  const {currentPage, perPage, pagination} = usePagination();

  const {
    orderDirection,
    orderBy,
    orderByField,
    handleOrderBy,
  } = useSorting({defaultOrderBy: 'created_at'});

  const {data, isLoading, isFetching, filters} = useDataFilters({currentPage, perPage, orderBy: orderByField});

  const {canViewDashboardRequests} = usePermissions();

  if (isLoading) return <LinearProgress/>

  if (!canViewDashboardRequests){
    return <PermissionError />
  }

  return (
    <>
      <Box mb={4} maxWidth={600}>
        {filters}
      </Box>

      <Box>
        <TableContainer>
          <Table size='small' sx={{minWidth: 800, bgcolor: '#fff', position: 'relative'}}>
            <AppTableHead
              headLabel={TABLE_HEAD}
              order={orderDirection}
              orderBy={orderBy}
              onSort={handleOrderBy}
            />

            <TableBody sx={{position: 'relative'}}>
              {isLoading && 'Loading...'}
              <Backdrop open={!isLoading && isFetching} sx={{zIndex: 99, position: 'absolute'}}>
                <CircularProgress color="inherit" sx={{zIndex: 100}}/>
              </Backdrop>

              {data?.items?.map((item) => (
                <DashboardRequestsRow
                  sid_name={item.sid_name}
                  sid={item.sid}
                  txn={item.txn}
                  action={item.action}
                  amount={item.amount}
                  currency_code={item.currency_code}
                  reason={item.reason}
                  status={item.status}
                  comment={item.comment}
                  created_by={item.created_by}
                  created_at={item.created_at}
                  updated_by={item.updated_by}
                  updated_at={item.updated_at}
                />
              ))}
            </TableBody>

            <TableFooter>
              <TableCell colSpan={9} align='right' sx={{py: 1, border: 'none'}}>
                {pagination({total: data?.total, pages: data?.pages})}
              </TableCell>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default DashboardRequests;

import {Box, Chip} from "@mui/material";
import {styled} from "@mui/material/styles";
import {TransactionDetailModel} from "generatedTypes";

interface MarkedByProps {
  values: TransactionDetailModel['marked_by'];
}

const StyledBox = styled(Box)({
  display: 'flex',
  gap: '4px'
});

const MarkedBy = ({values}: MarkedByProps) =>
  values && values.length > 0 ? (
    <StyledBox>
      {values.includes('api') ? (<Chip label='API' variant='seaBadge'/>) : <Chip label='API' variant='contained'/>}
      {values.includes('email') ? <Chip label='Email' variant='tealBadge'/>: <Chip label='Email' variant='contained'/>}
      {values.includes('file') ? <Chip label='File' variant='pinkBadge'/> : <Chip label='File' variant='contained'/>}
    </StyledBox>
  ) : (
    <StyledBox>
      <Chip label='API' variant='outlined'/>
      <Chip label='Email' variant='outlined'/>
      <Chip label='File' variant='outlined'/>
    </StyledBox>
  );

export default MarkedBy;

import {useEffect, useState} from 'react';
import {Box, IconButton, Tooltip} from "@mui/material";
import useBookmarkMutations
  from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/actions/useBookmarkMutations";
import useCurrentUser from "shared/hooks/useCurrentUser";
import {ReactComponent as BookmarkIcon} from "assets/iconsDS/bookmark.svg";
import {ReactComponent as BookmarkedIcon} from "assets/iconsDS/Bookmarked.svg";
import {useParams} from "react-router-dom";


const Bookmark = () => {
  const {user} = useCurrentUser();
  const {transactionId} = useParams()

  const [isBookmarked, setIsBookmarked] = useState(false);
  const {handleCreateBookmark, handleDeleteBookmark} = useBookmarkMutations();

  const createBookmark = async () => {
    await handleCreateBookmark(transactionId || '');
  };

  const deleteBookmark = async () => {
    await handleDeleteBookmark(transactionId || '');
  };

  useEffect(() => {
    if (user?.bookmarked_transactions) {
      setIsBookmarked(user.bookmarked_transactions.includes(transactionId as string));
    }
  }, [transactionId, user]);

  return (
    <Box>
      {
        isBookmarked ?
          <Tooltip title="Delete bookmark" arrow placement="top">
            <IconButton color='primary' onClick={deleteBookmark} sx={{height: '32px', width: '32px'}}>
              <BookmarkedIcon/>
            </IconButton>
          </ Tooltip>
          :
          <Tooltip title="Create bookmark" arrow placement="top">
            <IconButton onClick={createBookmark} sx={{height: '32px', width: '32px'}}>
              <BookmarkIcon fontSize='small'/>
            </IconButton>
          </Tooltip>
      }
    </Box>
  );
};

export default Bookmark;

import TransactionsTable from "apps/transactions/pages/TransactionsList/partials/TransactionsTable";
import {commonBreadcrumbs} from "shared/breadcrumbConfig";
import useBreadcrumb from "shared/hooks/useBreadcrumb";


const TransactionsListView = () => {
  useBreadcrumb([commonBreadcrumbs.transactionsList]);

  return (
    <TransactionsTable/>
  )
}

export default TransactionsListView;

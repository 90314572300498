import {AsYouType, parsePhoneNumberFromString} from 'libphonenumber-js';

export const fNumber = (number: number) => {
  // if (number < 1000) return number
  if (number >= 1000 && number < 1_000_000)
    return `${(number / 1000).toFixed(1)}K`
  if (number >= 1_000_000 && number < 1_000_000_000)
    return `${(number / 1_000_000).toFixed(1)}M`
  if (number >= 1_000_000_000 && number < 1_000_000_000_000)
    return `${(number / 1_000_000_000).toFixed(1)}B`
  if (number >= 1_000_000_000_000) return `${(number / 1_000_000_000_000).toFixed(1)}T`

  return number
}

export const fCurrency = (number?: number | null) => {
  const validatedNumber = number === null || number === undefined ? 0 : number;

  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
  }).format(validatedNumber);
};

export const fCurrencyInteger = (number?: number) => {
  const validatedNumber = number === undefined ? 0 : number;

  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(validatedNumber).replace(',', '.');
};


export const fText = (text: string, len: number) => {
  if (text.length > len) return `${text.slice(0, len)}...`
  return text
}

export const fData = (num: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let number = num
  let unit = 0
  while (number >= 1024) {
    number /= 1024
    unit += 1
  }
  return `${number.toFixed(1)} ${units[unit]}`
}

export const fAvatar = (inputName: any) => {
  const name = String(inputName)
  const splitName = name.split(' ')
  if (splitName.length > 1) {
    return `${splitName[0][0]}${splitName[1][0]}`
  }
  return `${splitName[0][0]}`
}

export const fStringArray = (markedBy: string[]): string => {
  const arr: string = markedBy.map(label => `'${label}'`).join(', ');
  return `[${arr}]`;
};

export function getYearFromDate(dateString?: string): number {
  const validatedNumber = dateString === undefined ? '' : dateString;

  const date = new Date(validatedNumber);
  return date.getFullYear();
}

export function formatDifferenceDate(years?: number, months?: number, days?: number): string {
  const validatedYears = years === undefined ? 0 : years;
  const validatedMonths = months === undefined ? 0 : months;
  const validatedDays = days === undefined ? 0 : days;


  let result = '';
  if (validatedYears > 0) {
    result += `${validatedYears}y`;
  }
  if (validatedMonths > 0) {
    result += `${validatedMonths}m`;
  } else if (validatedDays > 0) {
    result += `${validatedDays}d`;
  }

  return result
}

export function formatNumber(inputNumber: string): string {
  const numberString: string = inputNumber;
  if (numberString.length >= 12) {
    const firstPart: string = numberString.slice(0, 3);
    const middlePart: string = numberString.slice(9, 14);
    const lastPart: string = numberString.slice(-1);
    return `${firstPart}-${middlePart}-${lastPart}`;
  }
  return numberString
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);
  if (!phoneNumberObject) return phoneNumber;

  const countryCode = phoneNumberObject.countryCallingCode;
  const nationalNumber = new AsYouType().input(phoneNumberObject.nationalNumber);

  return `${countryCode} (${nationalNumber.slice(0, 3)}) ${nationalNumber.slice(3, 6)}-${nationalNumber.slice(6)}`;
};

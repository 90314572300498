import {useQuery} from "@tanstack/react-query";
import {StatisticsQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";
import {useFormContext, useWatch} from "react-hook-form";
import {isoFormattedDate} from "shared/utils/dateFormats";


export const useStatCardsDataQuery = () => {
  const {control} = useFormContext();
  const mids = useWatch({control, name: 'mids'});
  const rids = useWatch({control, name: 'rids'});
  const sids = useWatch({control, name: 'sids'});
  const startDate = useWatch({control, name: 'startDate'});
  const endDate = useWatch({control, name: 'endDate'});

  const filters = {mids, rids, sids, startDate: isoFormattedDate(startDate), endDate: isoFormattedDate(endDate)}

  const {data, isLoading} = useQuery({
    queryKey: [StatisticsQueryKeys.STATISTICS_BREAKDOWN, filters],
    queryFn: () => ApiClient.dashboard.getStatisticsBreakdown(filters),
  })

  return {
    data,
    isLoading,
  }
}

import {Alert, Box, Grid, Link, TextField} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useMutation} from "@tanstack/react-query";
import AuthFormsContainer from "apps/auth/components/AuthFormsWrapper";
import paths from "routes/paths";
import {useState} from "react";
import {forgotPasswordMutation} from "apps/auth/pages/forgotPassword/mutations";
import {AxiosError} from "axios/index";


const schema = yup.object().shape({
  email: yup.string().email('Please enter a valid email').required('This field is required.'),
});

type FormData = yup.InferType<typeof schema>;

function ForgotPasswordView() {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const {register, handleSubmit, formState: {errors}} = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const {mutate, isPending} = useMutation({
    mutationFn: forgotPasswordMutation,
    onMutate: () => {
      setErrorMessage('')
      setSuccessMessage('')
    },
    onSuccess: (data) => {
      if (data.data.success) {
        setSuccessMessage(data.data.message)
      } else {
        setErrorMessage(data.data.message)
      }
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      const message = error.response?.data?.message
      if (typeof message === 'string') {
        setErrorMessage(message)
      }

      if (typeof message === 'object' && message !== null) {
        const messages = Object.keys(message).map(key => message[key])
        setErrorMessage(messages.join('\n'))
      }
    }
  })

  const onSubmit = (data: FormData) => {
    mutate(data.email)
  }

  return (
    <AuthFormsContainer subtitle="enter your email address to continue.">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 1, width: '100%'}}>
        <TextField
          margin="normal"
          size="small"
          required
          fullWidth
          id="email"
          placeholder="Email Address"
          autoComplete="email"
          autoFocus
          error={!!errors?.email}
          helperText={errors?.email?.message}
          {...register("email")}
        />

        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

        <LoadingButton
          fullWidth
          loading={isPending}
          variant="contained"
          loadingIndicator="Submitting…"
          type="submit"
          sx={{mt: 3, mb: 2}}
        >
          Submit
        </LoadingButton>

        <Grid container>
          <Grid item xs>
            <Link href={paths.auth.login} variant="body2">
              Login
            </Link>
          </Grid>
          {/* <Grid item> */}
          {/*  <Link href={paths.auth.register} variant="body2"> */}
          {/*      Don&apos;t have an account? Sign Up */}
          {/*  </Link> */}
          {/* </Grid> */}
        </Grid>
      </Box>
    </AuthFormsContainer>
  );
}

export default ForgotPasswordView;

import { Components } from '@mui/material/styles';

import typography from "theme/typography";
import palette from "theme/palette";

const MuiLink: Components['MuiLink'] = {
  styleOverrides: {
    root: {
      ...typography.B4,
      color: palette.primary.main,
      cursor: 'pointer',
      textDecoration: 'none',

      '&:hover': {
        color: palette.themeColors.purple['120']
      }
    }
  }
}

export default MuiLink;
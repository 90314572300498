import {Components} from "@mui/material/styles";
import palette from "theme/palette";

const MuiList: Components['MuiList'] = {
  styleOverrides: {
    root: {
      padding: '2px 4px',
      borderRadius: '8px',
      backgroundColor: palette.themeColors.grey["10"],
      border: `1px solid ${palette.themeColors.grey["20"]}`,
      boxShadow: '0px 10px 20px -4px #21201D26',
    },
  }
}

export default MuiList;

import RoutePath from "routes/utils";

const baseBase = '/transactions';

export default {
  list: new RoutePath(`${baseBase}/`),

  detail: new RoutePath(`${baseBase}/:transactionId/`),
  detailNotesTab: new RoutePath(`${baseBase}/:transactionId/notes/`),
  detailCartTab: new RoutePath(`${baseBase}/:transactionId/cart/`),
  detailNotificationsTab: new RoutePath(`${baseBase}/:transactionId/notifications/`),
  detailTimelineTab: new RoutePath(`${baseBase}/:transactionId/timeline/`),
  detailEmailsTab: new RoutePath(`${baseBase}/:transactionId/emails/`),
  detailBillingTab: new RoutePath(`${baseBase}/:transactionId/billing/`),
  detailFinancialTab: new RoutePath(`${baseBase}/:transactionId/financial/`),
  detailPostbacksTab: new RoutePath(`${baseBase}/:transactionId/postbacks/`),
  detailDevelopersTab: new RoutePath(`${baseBase}/:transactionId/developers/`),
}

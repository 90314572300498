import {useHasAccess} from "contexts/UserAccessContext";
import {Permissions, Roles} from "contexts/UserAccessContext/const";

const usePermissions = () => {
  const canViewDashboardRequests = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [
        Permissions.REFUND_TRANSACTION || Permissions.CANCEL_TRANSACTION ||
        Permissions.DECLINE_TRANSACTION || Permissions.REQUEST_REFUND_TRANSACTION
        || Permissions.REQUEST_DECLINE_TRANSACTION || Permissions.REQUEST_CANCEL_TRANSACTION
        || Permissions.UPDATE_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST
      ]
    }
  ])

  return {
    canViewDashboardRequests,
  }
}

export default usePermissions

import React from 'react'
import { styled } from '@mui/material/styles'
import { Badge, BadgeProps } from '@mui/material'

interface AppBadgeProps extends BadgeProps {
  top?: number
}

const MuiBadge = styled((props: AppBadgeProps) => (
  <Badge max={9} color='warning' {...props} />
))(({ theme, top }) => ({
  '& .MuiBadge-badge': {
    top,
    width: 20,
    height: 20,
    fontSize: 10,
    fontWeight: 'bold',
    border: `2px solid ${theme.palette.background.paper}`,
    color: theme.palette.common.white,
    padding: '0 5px',
    boxShadow: theme.shadows[1],

    '&.MuiBadge-colorWarning': {
      backgroundColor: '#EC6764',
    },
  },
}))

const AppBadge: React.FC<AppBadgeProps> = ({ children, ...props }) => <MuiBadge {...props}>{children}</MuiBadge>

export default AppBadge

import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {IApiError} from "shared/types";
import {useState} from "react";
import {ActionCenterQueryKeys} from "shared/queryKeys";

type Params = Parameters<typeof ApiClient.actionCenter.uploadKyc>[0]

type SetError = (
  name: string,
  error: any,
) => void;

function setFormErrors(error: IApiError, setError: SetError) {
  error.body.errors.forEach((err: any) => {
    Object.keys(err).forEach((key: string) => {
      setError(key, {type: 'api_response', message: err[key].message});
    });
  });
}

interface UploadDocumentsArgs {
  setError: SetError
  reset: () => void,
}

export const useUploadDocuments = ({setError, reset}: UploadDocumentsArgs) => {
  const [apiErrors, setApiErrors] = useState<any>()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient();

  const {mutate, isPending, isSuccess} = useMutation({
    mutationFn: (params: Params) => ApiClient.actionCenter.uploadKyc(params),
    onError: (error: IApiError) => {
      if (error.body.errors) {
        setFormErrors(error, setError)
      }
      if (error.body.detail.api_errors) {
        setApiErrors(error.body.detail.api_errors)
      }
      if (error.body.detail.permissions) {
        enqueueSnackbar(error.body.detail.permissions, {variant: 'error'})
      }
    },
    onSuccess: async () => {
      reset();
      enqueueSnackbar('Documents have been uploaded successfully', {variant: 'success'})
      await queryClient.invalidateQueries({
        queryKey: [ActionCenterQueryKeys.LIST]
      });
    }
  });

  return {
    mutate,
    isPending,
    isSuccess,
    apiErrors,
  };
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingEventsPaginated } from '../models/BillingEventsPaginated';
import type { BlacklistSchema } from '../models/BlacklistSchema';
import type { Bookmark } from '../models/Bookmark';
import type { CreateBlacklistSchema } from '../models/CreateBlacklistSchema';
import type { CreateChargebackResponse } from '../models/CreateChargebackResponse';
import type { DevelopersSchema } from '../models/DevelopersSchema';
import type { NotesPaginatedSchema } from '../models/NotesPaginatedSchema';
import type { PatchTransactionSchema } from '../models/PatchTransactionSchema';
import type { RawEmailSchema } from '../models/RawEmailSchema';
import type { RecoverTransactionSchema } from '../models/RecoverTransactionSchema';
import type { ResendPostbackSchema } from '../models/ResendPostbackSchema';
import type { ResendTransactionNotificationSchema } from '../models/ResendTransactionNotificationSchema';
import type { TimelineSchema } from '../models/TimelineSchema';
import type { TransactionNotificationPaginatedLogs } from '../models/TransactionNotificationPaginatedLogs';
import type { TransactionNotificationsEmailSchema } from '../models/TransactionNotificationsEmailSchema';
import type { TransactionNotificationsSmsSchema } from '../models/TransactionNotificationsSmsSchema';
import type { TransactionPostbackDetailPostbackSchema } from '../models/TransactionPostbackDetailPostbackSchema';
import type { TransactionPostbackDetailWebhookSchema } from '../models/TransactionPostbackDetailWebhookSchema';
import type { TransactionPostbackInProcessPaginatedSchema } from '../models/TransactionPostbackInProcessPaginatedSchema';
import type { TransactionPostbackProcessPaginatedSchema } from '../models/TransactionPostbackProcessPaginatedSchema';
import type { TransactionRequest } from '../models/TransactionRequest';
import type { ViewText } from '../models/ViewText';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TransactionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get transactions list for autocomplete
     * @returns any Success
     * @throws ApiError
     */
    public getTransactionsAutocomplete({
        search,
    }: {
        /**
         * Search TXN, Description or CA Number.
         */
        search?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/autocomplete',
            query: {
                'search': search,
            },
        });
    }
    /**
     * Get transaction billing events
     * Roles: ADMIN, CUSTOMER_SERVICE.
     * Permissions: VIEW_BILLING_EVENTS.
     * @returns BillingEventsPaginated Success
     * @throws ApiError
     */
    public getBillingEventsTxn({
        txn,
        page,
        perPage,
        xFields,
    }: {
        txn: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<BillingEventsPaginated> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/billing-events/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Get blacklist details of a transaction
     * Roles: ADMIN, CUSTOMER_SERVICE.
     * Permissions: ADD_TO_BLACKLIST.
     *
     * Parameters:
     * txn: Transaction ID (in path, required)
     * @returns BlacklistSchema Success
     * @throws ApiError
     */
    public getActionBlackList({
        txn,
        xFields,
    }: {
        txn: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<Array<BlacklistSchema>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/blacklist/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Add user to the blacklist
     * Roles: ADMIN, CUSTOMER_SERVICE.
     * Permissions: ADD_TO_BLACKLIST.
     * @returns CreateBlacklistSchema Success
     * @throws ApiError
     */
    public postActionBlackList({
        txn,
        requestBody,
        xFields,
    }: {
        txn: string,
        requestBody: {
            email?: boolean;
            phone?: boolean;
            name?: boolean;
            ip_address?: boolean;
            reason?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CreateBlacklistSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/blacklist/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add bookmark to transaction
     * @returns Bookmark Success
     * @throws ApiError
     */
    public postTransactionBookmarkView({
        txn,
        xFields,
    }: {
        txn: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<Bookmark> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/bookmark/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Remove bookmark from transaction
     * @returns Bookmark Success
     * @throws ApiError
     */
    public patchTransactionBookmarkView({
        txn,
        xFields,
    }: {
        txn: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<Bookmark> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transactions/{txn}/bookmark/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Get cart details of a transaction
     * @returns TransactionRequest Success
     * @throws ApiError
     */
    public getCart({
        txn,
        xFields,
    }: {
        txn: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<TransactionRequest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/cart/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Create chargeback
     * Roles: ADMIN, CUSTOMER_SERVICE.
     * Permissions: CHARGEBACK.
     * @returns CreateChargebackResponse Success
     * @throws ApiError
     */
    public postTransactionCreateChargeBack({
        txn,
        requestBody,
        xFields,
    }: {
        txn: string,
        requestBody: {
            blacklisted?: boolean;
            all_merchants?: boolean;
            all_methods?: boolean;
            voluntarily_refund?: boolean;
            chargeback_return?: boolean;
            select_reason?: string;
            amount?: number;
            reason?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CreateChargebackResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/chargeback/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get panel of a transaction developers
     * @returns DevelopersSchema Success
     * @throws ApiError
     */
    public getTimelineDialog({
        txn,
        panel,
        xFields,
    }: {
        txn: string,
        panel: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<DevelopersSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/developers/{panel}/',
            path: {
                'txn': txn,
                'panel': panel,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Get transaction emails
     * Roles: ADMIN, CUSTOMER_SERVICE.
     * Permissions: VIEW_EMAIL_DATA.
     * @returns NotesPaginatedSchema Success
     * @throws ApiError
     */
    public getTransactionEmails({
        txn,
        page,
        perPage,
        xFields = 'items{_id,to_email,amount,sender_ip,reply_to_email,name,status,saved_at},*',
    }: {
        txn: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<NotesPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/emails/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Get transaction email details
     * Roles: ADMIN, CUSTOMER_SERVICE.
     * Permissions: VIEW_EMAIL_DATA.
     * @returns RawEmailSchema Success
     * @throws ApiError
     */
    public getTransactionEmail({
        txn,
        emailId,
        xFields,
    }: {
        txn: string,
        emailId: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<RawEmailSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/emails/{email_id}/',
            path: {
                'txn': txn,
                'email_id': emailId,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Get transaction financial details
     * Roles: ADMIN, CUSTOMER_SERVICE.
     * Permissions: VIEW_FINANCIAL_DETAILS.
     * @returns BillingEventsPaginated Success
     * @throws ApiError
     */
    public getFinancialDetails({
        txn,
        page,
        perPage,
        xFields,
    }: {
        txn: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<Array<BillingEventsPaginated>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/financial-details/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Get notifications for a transaction
     * @returns TransactionNotificationPaginatedLogs Success
     * @throws ApiError
     */
    public getTransactionNotifications({
        txn,
        page,
        perPage,
        xFields,
    }: {
        txn: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<TransactionNotificationPaginatedLogs> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/notifications/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Resend notification for a transaction
     * @returns ResendTransactionNotificationSchema Success
     * @throws ApiError
     */
    public postResendTransactionNotifications({
        txn,
        requestBody,
        xFields,
    }: {
        txn: string,
        requestBody: {
            notification_id?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ResendTransactionNotificationSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/notifications/resend/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get email notifications for a transaction
     * @returns TransactionNotificationsEmailSchema email response
     * @returns TransactionNotificationsSmsSchema sms response
     * @throws ApiError
     */
    public getTransactionNotificationsDetails({
        txn,
        notificationId,
    }: {
        txn: string,
        notificationId: string,
    }): CancelablePromise<TransactionNotificationsEmailSchema | TransactionNotificationsSmsSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/notifications/{notification_id}/',
            path: {
                'txn': txn,
                'notification_id': notificationId,
            },
        });
    }
    /**
     * Patch transaction details
     * Roles: SUPER_ADMIN
     * @returns PatchTransactionSchema Success
     * @throws ApiError
     */
    public postPatchTransaction({
        txn,
        requestBody,
        xFields,
    }: {
        txn: string,
        requestBody: {
            email?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<PatchTransactionSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/patch-details/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get postback process of a transaction
     * @returns TransactionPostbackProcessPaginatedSchema Success
     * @throws ApiError
     */
    public getTransactionPostbackProcess({
        txn,
        page,
        perPage,
        postbackType,
        xFields,
    }: {
        txn: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * Postback type
         */
        postbackType?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<TransactionPostbackProcessPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/postback-process/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
                'postback_type': postbackType,
            },
        });
    }
    /**
     * Get postback in process
     * @returns TransactionPostbackInProcessPaginatedSchema Success
     * @throws ApiError
     */
    public getTransactionPostbackInProcess({
        txn,
        page,
        perPage,
        xFields,
    }: {
        txn: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<TransactionPostbackInProcessPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/postback-process/in-process/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Resend postback process of a transaction
     * @returns ResendPostbackSchema Success
     * @throws ApiError
     */
    public postTransactionPostbackResend({
        txn,
        postbackId,
        xFields,
    }: {
        txn: string,
        postbackId: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ResendPostbackSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/postback-process/resend/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'postback_id': postbackId,
            },
        });
    }
    /**
     * Get details of a postback process
     * @returns TransactionPostbackDetailPostbackSchema Success
     * @throws ApiError
     */
    public getTransactionPostbackProcessPostbackDetail({
        txn,
        postbackId,
        xFields,
    }: {
        txn: string,
        postbackId: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<TransactionPostbackDetailPostbackSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/postback-process/{postback_id}/postback/',
            path: {
                'txn': txn,
                'postback_id': postbackId,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Get details of a postback process
     * @returns TransactionPostbackDetailWebhookSchema Success
     * @throws ApiError
     */
    public getTransactionPostbackProcessWebhookDetail({
        txn,
        postbackId,
        xFields,
    }: {
        txn: string,
        postbackId: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<TransactionPostbackDetailWebhookSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/postback-process/{postback_id}/webhook/',
            path: {
                'txn': txn,
                'postback_id': postbackId,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Recover transaction
     * Roles: ADMIN, CUSTOMER_SERVICE.
     * Permissions: RECOVER_WORKER.
     * @returns RecoverTransactionSchema Success
     * @throws ApiError
     */
    public postRecoverTransaction({
        txn,
        requestBody,
        xFields,
    }: {
        txn: string,
        requestBody: {
            process_timestamp_refresh?: boolean;
            process?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<RecoverTransactionSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/recover/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get timeline of a transaction
     * Roles: ADMIN, CUSTOMER_SERVICE.
     * @returns TimelineSchema Success
     * @throws ApiError
     */
    public getTimeline({
        txn,
        xFields,
    }: {
        txn: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<TimelineSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/timeline/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Get timeline dialog of a transaction
     * Roles: ADMIN, CUSTOMER_SERVICE.
     * @returns any Success
     * @throws ApiError
     */
    public getTimelineDialog1({
        txn,
        dialog,
    }: {
        txn: string,
        dialog: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/timeline/{dialog}/',
            path: {
                'txn': txn,
                'dialog': dialog,
            },
        });
    }
    /**
     * Get text view of a transaction
     * Roles: ADMIN, CUSTOMER_SERVICE.
     * @returns ViewText Success
     * @throws ApiError
     */
    public getActionViewText({
        txn,
        xFields,
    }: {
        txn: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ViewText> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/view-text/',
            path: {
                'txn': txn,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
}

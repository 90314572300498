import {DialogStateHandler} from "shared/hooks/useDialogState";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, Typography,
} from "@mui/material";
import CloseIconButton from "shared/CloseButton";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import TextFormField from "shared/components/formFields/TextFormField";

const schema = yup.object().shape({
  storeId: yup.number().required('store is required'),
  storeToken: yup.string().required('store token is required'),
})

type FormData = yup.InferType<typeof schema>;

interface PluginDialogProps {
  dialogStateHandler: DialogStateHandler
}

const PluginDialog = ({dialogStateHandler}: PluginDialogProps) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  return (
    <Dialog open={dialogStateHandler.isOpen} onClose={dialogStateHandler.closeDialog} fullWidth>
      <FormProvider {...methods}>
        <DialogTitle id="modal-dialog-title">
          Moneris Plugin
        </DialogTitle>
        <CloseIconButton onClick={dialogStateHandler.closeDialog}/>
        <DialogContent>
          <Grid container>
            <Grid item lg={3} md={3}>
              <Box mt={1}>
                <img src="https://www.scantranx.com/images/moneris-logo-small.png" alt="" width="120px" height="120px" />
              </Box>
            </Grid>
            <Grid item lg={8} md={8}>
              <Typography variant='h6'>
                Moneris
              </Typography>
              <Typography variant='body2'>
                With the Moneris Payper plugin, you can accept and process credit card/debit card payments through your website, easily and securely.
                No additional payment processing solution is needed with this extension.
                Your customer will remain on your website throughout the payment process, making it a seamless customer experience.
                And, it&apos;s hosted by Moneris and handled by Payper which means less risk for you as you don&apos;t have to handle any sensitive card data.
              </Typography>
            </Grid>
          </Grid>
          <Box mt={2}>
            <TextFormField name='storeId' label='Store ID' />
          </Box>
          <Box mt={1}>
            <TextFormField name='storeToken' label='Store Token' />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={!methods.formState.isDirty || !methods.formState.isValid}
          >
            Enable
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}

export default PluginDialog;
import {styled} from "@mui/material/styles";
import {MaterialDesignContent} from "notistack";

export const StyledSnackbar = styled(MaterialDesignContent)(() => ({
  '#notistack-snackbar': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: 0,
  },

  '&.notistack-MuiContent': {
    borderRadius: '4px',
    padding: '8px 16px',
    color: '#fff',
    fontSize: '14px',
    fontWeight: '400',
    boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.20)"
  },

  '&.notistack-MuiContent div:nth-child(2)': {
    paddingLeft: '24px',
  },

  '&.notistack-MuiContent-success': {
    backgroundColor: '#258750',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#D83A52',
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#0073EA',
  },
  '&.notistack-MuiContent-default': {
    backgroundColor: '#323338',
  }
}));
import {Alert, Box, Grid, Link, TextField} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {AxiosError} from "axios";
import debounce from "lodash/debounce";

import {useNavigate, useParams} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useMutation} from "@tanstack/react-query";
import AuthFormsContainer from "apps/auth/components/AuthFormsWrapper";
import paths from "routes/paths";
import passwordValidator from "apps/auth/validators/passwordValidator";
import {updatePasswordMutation} from "apps/auth/pages/updatePassword/mutations";
import {useState} from "react";


const schema = yup.object().shape({
  ...passwordValidator,
});

type FormData = yup.InferType<typeof schema>;

function UpdatePasswordView() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')
  const [successMessage, setSuccessMessage] = useState<string | undefined>('')
  const {verificationToken, checksum} = useParams();
  const navigate = useNavigate();
  const {register, handleSubmit, formState: {errors}} = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const delayedNavigate = debounce(() => {
    navigate(paths.auth.login);
  }, 3000);

  const {mutate, isPending} = useMutation({
    mutationFn: updatePasswordMutation,
    onMutate: () => {
      setErrorMessage('')
      setSuccessMessage('')
    },
    onSuccess: (data) => {
      if (data.data.success) {
        delayedNavigate()
        setSuccessMessage(data.data.message)
      }
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      const message = error.response?.data?.message
      if (typeof message === 'string') {
        setErrorMessage(message)
      }

      if (typeof message === 'object' && message !== null) {
        const messages = Object.keys(message).map(key => message[key])
        setErrorMessage(messages.join('\n'))
      }
    },
  })

  const onSubmit = (data: FormData) => {
    mutate({
      password: data.password,
      confirm_password: data.confirm_password,
      verification_token: verificationToken,
      checksum
    })
  }

  return (
    <AuthFormsContainer subtitle="enter your new password and confirm to continue.">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 1, width: '100%'}}>
        <TextField
          margin="normal"
          size="small"
          required
          fullWidth
          type="password"
          placeholder="Password"
          id="password"
          autoComplete="current-password"
          error={!!errors?.password}
          helperText={errors?.password?.message}
          {...register('password')}
        />
        <TextField
          margin="normal"
          size="small"
          required
          fullWidth
          type="password"
          placeholder="Confirm Password"
          id="confirm_password"
          autoComplete="current-password"
          error={!!errors?.confirm_password}
          helperText={errors?.confirm_password?.message}
          {...register('confirm_password')}
        />

        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}

        <LoadingButton
          fullWidth
          loading={isPending}
          variant="contained"
          loadingIndicator="Submitting…"
          type="submit"
          sx={{mt: 3, mb: 2}}
        >
          Submit
        </LoadingButton>
        <Grid container>
          <Grid item xs>
            <Link href={paths.auth.login} variant="body2">
              Login
            </Link>
          </Grid>
          {/* <Grid item> */}
          {/*  <Link href={paths.auth.register} variant="body2"> */}
          {/*      Don&apos;t have an account? Sign Up */}
          {/*  </Link> */}
          {/* </Grid> */}
        </Grid>
      </Box>
    </AuthFormsContainer>
  );
}

export default UpdatePasswordView;

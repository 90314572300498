import {ISidebarMenuItem} from "layouts/MainLayout/partials/Sidebar/menuItems";
import {ListItemButton, ListItemText} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {useTheme} from "@mui/material/styles";


interface SidebarMenuItemProps extends ISidebarMenuItem {
  onClick?: () => void;
}

const SidebarSubMenuItem = ({name, link, onClick}: SidebarMenuItemProps) => {
  const theme = useTheme();

  const location = useLocation();
  const isActive = location.pathname === link;

  return (
    <ListItemButton
      component={Link}
      onClick={onClick}
      to={link}
      sx={{
        minHeight: 48,
        justifyContent: "initial",
        px: 2.5,
        borderRadius: '8px',
        '&:hover': {
          background: '#F0EFED',
        },
        ...(isActive && {
          background: theme.palette.themeColors.grey["30"],
          color: theme.palette.themeColors.grey["120"],
          boxShadow: '0px -1px 0px 0px #FFFFFFCC inset, 0px 1px 0px 0px #33250114 inset;',
        }),
      }}
    >
      <ListItemText primary={name} sx={{opacity: 1}}/>
    </ListItemButton>
  );
}

export default SidebarSubMenuItem;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserCreateSchema } from '../models/UserCreateSchema';
import type { UserDetailsSchema } from '../models/UserDetailsSchema';
import type { UsersListPaginatedSchema } from '../models/UsersListPaginatedSchema';
import type { UserVerificationSchema } from '../models/UserVerificationSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get users list
     * Roles: merchant
     * Permissions: MANAGE_USERS
     * @returns UsersListPaginatedSchema Success
     * @throws ApiError
     */
    public getUsersList({
        page,
        perPage,
        search,
        xFields,
    }: {
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * First name, last name, or email address.
         */
        search?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UsersListPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
                'search': search,
            },
        });
    }
    /**
     * Create user
     * Roles: merchant
     * Permissions: MANAGE_USERS
     * @returns UserCreateSchema Success
     * @throws ApiError
     */
    public postUsersList({
        formData,
        xFields,
    }: {
        formData: {
            email: string;
            first_name: string;
            last_name: string;
            phone?: string;
            role: string;
            send_password_via_email: boolean;
            password?: string;
            confirm_password?: string;
            mid?: string;
            rid?: string;
            sid?: string;
            permissions?: Array<string>;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UserCreateSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/',
            headers: {
                'X-Fields': xFields,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
        });
    }
    /**
     * User email verification
     * @returns UserVerificationSchema Success
     * @throws ApiError
     */
    public postUserEmailVerification({
        verificationCode,
        userId,
        email,
        xFields,
    }: {
        verificationCode: string,
        userId: string,
        email: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UserVerificationSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/email-verification/{verification_code}/{user_id}/{email}/',
            path: {
                'verification_code': verificationCode,
                'user_id': userId,
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Get user details
     * Roles: Merchant
     * Permissions: EDIT_USERS
     * @returns UserDetailsSchema Success
     * @throws ApiError
     */
    public getUserDetails({
        userId,
        xFields,
    }: {
        userId: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UserDetailsSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/{user_id}/',
            path: {
                'user_id': userId,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Edit user email
     * Roles: Merchant
     * Permissions: EDIT_USERS
     * @returns UserDetailsSchema Success
     * @throws ApiError
     */
    public postEditUserEmail({
        userId,
        requestBody,
        xFields,
    }: {
        userId: string,
        requestBody: {
            email?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UserDetailsSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/{user_id}/edit/email/',
            path: {
                'user_id': userId,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Edit user password
     * Roles: Merchant
     * Permissions: EDIT_USERS
     * @returns UserDetailsSchema Success
     * @throws ApiError
     */
    public postEditUserPassword({
        userId,
        requestBody,
        xFields,
    }: {
        userId: string,
        requestBody: {
            password?: string;
            confirm_password?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UserDetailsSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/{user_id}/edit/password/',
            path: {
                'user_id': userId,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Edit user permissions
     * Roles: Merchant
     * Permissions: EDIT_USERS
     * @returns UserDetailsSchema Success
     * @throws ApiError
     */
    public postEditUserPermissions({
        userId,
        formData,
        xFields,
    }: {
        userId: string,
        formData: {
            permissions: Array<string>;
            role: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UserDetailsSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/{user_id}/edit/permissions/',
            path: {
                'user_id': userId,
            },
            headers: {
                'X-Fields': xFields,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
        });
    }
    /**
     * Edit user phone
     * Roles: Merchant
     * Permissions: EDIT_USERS
     * @returns UserDetailsSchema Success
     * @throws ApiError
     */
    public postEditUserPhone({
        userId,
        requestBody,
        xFields,
    }: {
        userId: string,
        requestBody: {
            phone?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UserDetailsSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/{user_id}/edit/phone/',
            path: {
                'user_id': userId,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Edit user profile
     * Roles: Merchant
     * Permissions: EDIT_USERS
     * @returns UserDetailsSchema Success
     * @throws ApiError
     */
    public postEditUserProfile({
        userId,
        requestBody,
        xFields,
    }: {
        userId: string,
        requestBody: {
            first_name?: string;
            last_name?: string;
            is_active?: boolean;
            is_2fa_enabled?: boolean;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UserDetailsSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/{user_id}/edit/profile/',
            path: {
                'user_id': userId,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Verify user
     * Roles: Merchant
     * Permissions: EDIT_USERS
     *
     * action: send-verification-link, manually, with-code
     * verification_type: email, phone
     * @returns UserVerificationSchema Success
     * @throws ApiError
     */
    public postVerifyUser({
        userId,
        action,
        verificationType,
        value,
        xFields,
    }: {
        userId: string,
        action: string,
        verificationType: string,
        value: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UserVerificationSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/{user_id}/verify/',
            path: {
                'user_id': userId,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'action': action,
                'verification_type': verificationType,
                'value': value,
            },
        });
    }
}

import {Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import uiPaths from "routes/uiPaths";
import {formatNumber} from "shared/utils/formats";

interface TransactionLinkProps {
  txn: string
}

const TransactionLink = ({txn}: TransactionLinkProps) => (
  <Link
    color='inherit'
    underline='hover'
    to={uiPaths.transactionPaths.detailNotesTab.reverse({transactionId: String(txn)})}
    component={RouterLink}
  >
    #{formatNumber(txn)}
  </Link>
)

export default TransactionLink;
import { useState } from 'react';

export interface DialogStateHandler {
  isOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}
const useDialogState = (): DialogStateHandler => {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  return { isOpen, openDialog, closeDialog };
};

export default useDialogState;

import React from 'react';
import {Box, Typography} from "@mui/material";

interface CustomerAverageOrderValueProps {
  children: React.ReactNode;
}

const CustomerAverageOrderValue = ({children}: CustomerAverageOrderValueProps) => (
  <Box
    sx={{
      display: 'flex',
      height: '20px',
      padding: '0px 4px',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: '1 0 0',
      borderRadius: '4px',
      background: '#E6F1FE',
      marginTop: '10px',
    }}
  >
    <Typography sx={{
      color: '#0A3D77',
      fontFamily: 'Inter',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '-0.165px',
    }}>
          AVERAGE ORDER VALUE
    </Typography>
    <Typography sx={{
      color: '#0A3D77',
      fontFamily: 'Inter',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '-0.165px',
    }}>
      {children}
    </Typography>
  </Box>
);

export default CustomerAverageOrderValue;

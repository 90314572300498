import {Box, Typography} from "@mui/material";

interface SubmissionInfoProps {
  emailCard?: string;
  email?: string;
  documents?: any;
  customerDetails?: any;
  whiteListStatus?: boolean;
}

const SubmissionInfo = ({emailCard, email, documents, customerDetails, whiteListStatus}: SubmissionInfoProps) => (
  <Box>
    <h3 className="mb-2">You have submitted information successfully.</h3>
    <Box mb={2}>
      <span className="me-25">Requires the documents for </span>
      <b>{emailCard || email}</b>
    </Box>
    {documents ? (
      <Box mb={2}>
        <Typography variant="h6">Documents</Typography>
        {Object.entries(documents).map(([key, value]) => (
          value
            ? (
              <>
                <strong>{key}</strong>
                <ul>
                  {/* @ts-ignore */}
                  {value.map((el: { name: any; }) => <li key={el.name}>{el.name}</li>)}
                </ul>
              </>
            ) : null
        ))}
      </Box>
    ) : null}
    {customerDetails ? (
      <Box mb={2}>
        <Typography variant="h6">Customer Details</Typography>
        <ul>
          {Object.entries(customerDetails).map(([key, value]) => (
            <Box key={key} component='li'>
              <>
                <strong>{key}</strong>: {value}
              </>
            </Box>
          ))}
        </ul>
      </Box>
    ) : null}

    <Box mb={2}>
      <Typography variant="h6">Customer Whitelist</Typography>
      {whiteListStatus ? 'True' : 'False'}
    </Box>

    <Box mb={2}>
      Your information has been forwarded to the concerned department. You may be contacted if more information is required.
    </Box>

    <Box>
      <Typography variant="h6" className="text-start">KYC Status</Typography>
      <Typography variant="body1" className="text-start">Review Pending</Typography>
    </Box>
  </Box>
);

export default SubmissionInfo;

import React from 'react';
import {Box, Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import LoadingButton from "@mui/lab/LoadingButton";

interface DialogInfoProps {
  dialogStateHandler: DialogStateHandler
  dialogTitle: string
  children?: React.ReactNode
  closeBtnText?: string
  submitBtnText?: string
  reset?: () => void
  maxWidth?: Breakpoint
  onClose?: () => void
  DialogActionsComponent?: React.ReactNode
  onSubmit?: (data: any) => void
  isPending?: boolean,
}

const DialogGeneral = ({
  dialogStateHandler,
  dialogTitle,
  children,
  closeBtnText = 'Close',
  reset,
  maxWidth = 'md',
  submitBtnText,
  onClose,
  DialogActionsComponent,
  onSubmit,
  isPending = false,
}: DialogInfoProps) => (
  <Dialog
    open={dialogStateHandler.isOpen}
    onClose={dialogStateHandler.closeDialog}
    fullWidth
    maxWidth={maxWidth}
  >
    <form onSubmit={onSubmit}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Box pt={2}>
          {children}
        </Box>
      </DialogContent>
      <DialogActions>
        {DialogActionsComponent || (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                dialogStateHandler.closeDialog();
                if (reset) {
                  reset();
                }
                if (onClose) {
                  onClose();
                }
              }}
            >
              {closeBtnText}
            </Button>

            {reset && (
              <Button
                variant="contained"
                color="secondary"
                onClick={reset}
              >
                Reset
              </Button>
            )}

            {submitBtnText && (
              <LoadingButton
                color="primary"
                loading={isPending}
                variant="contained"
                loadingIndicator="Submiting…"
                type="submit"
              >
                {submitBtnText}
              </LoadingButton>
            )}
          </>
        )}
      </DialogActions>
    </form>
  </Dialog>
);

export default DialogGeneral;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VirtualTerminalCreateResponse } from '../models/VirtualTerminalCreateResponse';
import type { VirtualTerminalEnabledPaymentMethodsSchema } from '../models/VirtualTerminalEnabledPaymentMethodsSchema';
import type { VirtualTerminalSitePaginatedSchema } from '../models/VirtualTerminalSitePaginatedSchema';
import type { VirtualTerminalVirtualTerminalDataSchema } from '../models/VirtualTerminalVirtualTerminalDataSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class VirtualTerminalService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Creates a transaction from virtual terminal
     * Roles: Merchant
     * Permissions: USE_VIRTUAL_TERMINAL
     * @returns VirtualTerminalCreateResponse Success
     * @throws ApiError
     */
    public postVirtualTerminalCreateTransaction({
        requestBody,
        xFields,
    }: {
        requestBody: VirtualTerminalVirtualTerminalDataSchema,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<VirtualTerminalCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/virtual-terminal/create-transaction/',
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get virtual terminal enabled payment methods for current user
     * :param site_id: site id from the list of sites allowed for the current user
     * :param transaction_type: payment or payout
     * @returns VirtualTerminalEnabledPaymentMethodsSchema Success
     * @throws ApiError
     */
    public getVirtualTerminalEnabledPaymentMethods({
        siteId,
        transactionType,
        xFields,
    }: {
        siteId: number,
        transactionType: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<VirtualTerminalEnabledPaymentMethodsSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/virtual-terminal/enabled-methods/{site_id}/{transaction_type}/',
            path: {
                'site_id': siteId,
                'transaction_type': transactionType,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Get virtual terminal sites for current user
     * @returns VirtualTerminalSitePaginatedSchema Success
     * @throws ApiError
     */
    public getVirtualTerminalSites({
        page,
        perPage,
        xFields,
    }: {
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<VirtualTerminalSitePaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/virtual-terminal/sites/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
}

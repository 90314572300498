import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AccountQueryKeys} from "shared/queryKeys";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import {useErrorMessage} from "shared/hooks/useErrorMessage";
import {IApiError} from "shared/types";

type VerifyEmailParams = Parameters<typeof ApiClient.account.postAccountSettingsVerifyEmail>[0]

export const useVerifyUserEmail = (dialogHandler: DialogStateHandler) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const {errorMessage, handleSetErrorMessage} = useErrorMessage()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: VerifyEmailParams) => ApiClient.account.postAccountSettingsVerifyEmail(params),
    onMutate: () => handleSetErrorMessage(),
    onSuccess: async () => {
      enqueueSnackbar('Email verified', {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS]})
      dialogHandler.closeDialog()
    },
    onError: async (error: IApiError) => {
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS]})
      handleSetErrorMessage(error)
    }
  })

  return {
    mutate,
    isPending,
    errorMessage
  }
}

type VerifyPhoneParams = Parameters<typeof ApiClient.account.postAccountSettingsVerifyPhone>[0]

export const useVerifyUserPhone = (dialogHandler: DialogStateHandler) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const {errorMessage, handleSetErrorMessage} = useErrorMessage()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: VerifyPhoneParams) => ApiClient.account.postAccountSettingsVerifyPhone(params),
    onMutate: () => handleSetErrorMessage(),
    onSuccess: async () => {
      enqueueSnackbar('Phone verified', {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS]})
      dialogHandler.closeDialog()
    },
    onError: async (error: IApiError) => {
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS]})
      handleSetErrorMessage(error)
    }
  })

  return {
    mutate,
    isPending,
    errorMessage
  }
}
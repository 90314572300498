const ICONS = {
  open: 'ic_open',
  dashboard: 'ic_dashboard',
  activity: 'ic_activity',
  repeat: 'ic_repeat',
  groups: 'ic_groups',
  notes: 'ic_notes',
  notebook: 'ic_notebook',
  email: 'ic_email',
  briefcase: 'ic_briefcase',
  cpu: 'ic_cpu',
  layers: 'ic_layers',
  database: 'ic_database',
  doubleArrowLeft: 'ic_doubleArrowLeft',
  block: 'ic_block',
  users: 'ic_users',
  user: 'ic_user',
  log: 'ic_log',
  accounting: 'ic_accounting',
  usb: 'ic_usb',
  api: 'ic_api',
  bag: 'ic_bag',
  notification: 'ic_notification',
  timeline: 'ic_timeline',
  bill: 'ic_bill',
  card: 'ic_card',
  wifi: 'ic_wifi',
  code: 'ic_code',
  transaction: 'ic_transaction',
  arrowSwitch: 'ic_arrowSwitch',
  credit: 'ic_credit',
  credit2: 'ic_credit2',
  bank: 'ic_bank',
  blacklist: 'ic_blacklist',
  chevronRight: 'ic_chevronRight',
  performUp: 'ic_performUp',
  performDown: 'ic_performDown',
  arrowUp: 'ic_arrowUp',
  arrowDown: 'ic_arrowDown',
  checked: 'ic_checked',
  pending: 'ic_pending',
  rejected: 'ic_rejected',
  send: 'ic_send',
  receive: 'ic_receive',
  copy: 'ic_copy',
  copied: 'ic_copied',
  gear: 'ic_gear',
  export: 'ic_export',
  lock: 'ic_lock',
  info: 'ic_info',
  logout: 'ic_logout',
  communication: 'ic_communication',
  search: 'ic_search',
  eye: 'ic_eye',
  fund: 'ic_fund',
  fundKey: 'ic_fundKey',
  refresh: 'ic_refresh',
  plus: 'ic_plus',
  layout: 'ic_layout',
  browser: 'ic_browser',
  emailOpen: 'ic_emailOpen',
  task: 'ic_task',
  close: 'ic_close',
  upload: 'ic_upload',
  computer: 'ic_computer',
  terminal: 'ic_terminal',
  payment: 'ic_payment',
  payout: 'ic_payout',
}

export default ICONS

import {
  addHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  format,
  intlFormatDistance,
  isValid,
  parseISO,
  subMonths,
  subYears,
} from 'date-fns';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

/**
 * Calculates the difference between the current date and a given date in years, months, and days.
 *
 * @param date - The given date. Should be in ISO format or a Date object
 * @returns An object containing the difference in years, months, and days.
 */
export function calculateDifferenceFromNow(date: Date | string): { years: number, months: number, days: number } {
  const now = new Date();

  const validatedDate = typeof date === 'string' ? parseISO(date) : date;

  const years = differenceInYears(now, validatedDate);
  const months = differenceInMonths(subYears(now, years), validatedDate);
  const days = differenceInDays(subMonths(subYears(now, years), months), validatedDate);

  return {years, months, days};
}

/**
 * Converts a date from ISO format to a custom format.
 *
 * @param isoDate - The date in ISO format.
 * @param dateFormat - The desired date format.
 * @returns The date in the desired format.
 */
export const convertIsoToCustomFormat = (isoDate: string, dateFormat: string = "dd MMM yyyy hh:mm:ss a "): string => {
  const parsedDate = parseISO(isoDate);
  const match = isoDate.match(/([+-])(\d{2}):(\d{2})$/);
  const timezoneOffsetHours = match ? (Number.parseInt(match[2], 10) * (match[1] === '-' ? -1 : 1)) : 0;

  const zonedDate = utcToZonedTime(parsedDate, 'UTC');
  const adjustedDate = addHours(zonedDate, timezoneOffsetHours);

  return format(adjustedDate, dateFormat);
}

/**
 * Calculates the difference between the current date and a given date in years, months, and days.
 * Only includes time units (years, months, days) that have a value greater than 0 in the returned string.
 *
 * @returns A string representing the difference between the current date and the createdAt date.
 */
export const getDateDiff = (date: Date | string) => {
  const {years, months, days} = calculateDifferenceFromNow(date);
  let result = '';

  if (years > 0) {
    result += `${years}y, `;
  }
  if (months > 0) {
    result += `${months}m, `;
  }
  if (days > 0) {
    result += `${days}d, `;
  }

  // Remove the trailing comma and space
  return result.slice(0, -2);
}


interface GetFormattedDisanceArgs {
  fromDate: Date | string;
  toDate: Date | string;
  convertFromDateToUtc?: boolean;
  convertToDateToUtc?: boolean;
}

export const getFormattedDistance = ({fromDate, toDate, convertFromDateToUtc, convertToDateToUtc}: GetFormattedDisanceArgs) => {
  const cleanedFromDate = typeof fromDate === 'string' ? parseISO(fromDate) : fromDate;
  const cleanedToDate = typeof toDate === 'string' ? parseISO(toDate) : toDate;


  const zonedFromDate = convertFromDateToUtc ? utcToZonedTime(cleanedFromDate, 'UTC') : cleanedFromDate;
  const zonedToDate = convertToDateToUtc ? utcToZonedTime(cleanedToDate, 'UTC') : cleanedToDate;

  return intlFormatDistance(zonedFromDate, zonedToDate)
}

export const isoFormattedDate = (date: Date | string) => {
  const cleanedDate = typeof date === 'string' ? parseISO(date) : date;
  if (isValid(cleanedDate)) {
    return format(cleanedDate, 'yyyy-MM-dd');
  }
  return '';
}

export const calculateTimeRange = (start: Date): string => {
  const now = new Date();
  const timeDiff = now.getTime() - start.getTime();
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  if (daysDiff > 365) {
    const years = Math.floor(daysDiff / 365);
    return `${years} year${years > 1 ? 's' : ''}`;
  }
  if (daysDiff >= 30) {
    const months = Math.floor(daysDiff / 30);
    return `${months} month${months > 1 ? 's' : ''}`;
  }
  const days = Math.floor(daysDiff);
  return `${days} day${days > 1 ? 's' : ''}`;
}

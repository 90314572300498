import React from 'react'
// @mui
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------
interface SvgColorProps extends BoxProps {
  src: string
  color?: string
}

const SvgColor = React.forwardRef(
  ({ src, sx, color, ...other }: SvgColorProps, ref) => (
    <Box
      component='span'
      className='svg-color'
      ref={ref}
      sx={{
        width: 24,
        height: 24,
        display: 'inline-block',
        bgcolor: color || 'currentColor',
        mask: `url(${src}) no-repeat center / contain`,
        WebkitMask: `url(${src}) no-repeat center / contain`,
        ...sx,
      }}
      {...other}
    />
  )
)

export default SvgColor

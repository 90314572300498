/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardDayBreakdown } from '../models/DashboardDayBreakdown';
import type { DashboardSiteBreakdownList } from '../models/DashboardSiteBreakdownList';
import type { DashboardTransactionsAggregatedTotalStats } from '../models/DashboardTransactionsAggregatedTotalStats';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DashboardService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns DashboardTransactionsAggregatedTotalStats Success
     * @throws ApiError
     */
    public getStatisticsBreakdown({
        mids,
        rids,
        sids,
        startDate,
        endDate,
        xFields,
    }: {
        /**
         * Filter by sids.
         */
        mids?: Array<string>,
        /**
         * Filter by sids.
         */
        rids?: Array<string>,
        /**
         * Filter by sids.
         */
        sids?: Array<string>,
        /**
         * Start date.
         */
        startDate?: string,
        /**
         * End date.
         */
        endDate?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<DashboardTransactionsAggregatedTotalStats> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/dashboard/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'mids': mids,
                'rids': rids,
                'sids': sids,
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }
    /**
     * @returns DashboardDayBreakdown Success
     * @throws ApiError
     */
    public getDayBreakdown({
        mids,
        rids,
        sids,
        startDate,
        endDate,
        xFields,
    }: {
        /**
         * Filter by sids.
         */
        mids?: Array<string>,
        /**
         * Filter by sids.
         */
        rids?: Array<string>,
        /**
         * Filter by sids.
         */
        sids?: Array<string>,
        /**
         * Start date.
         */
        startDate?: string,
        /**
         * End date.
         */
        endDate?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<Array<DashboardDayBreakdown>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/dashboard/day-breakdown/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'mids': mids,
                'rids': rids,
                'sids': sids,
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }
    /**
     * @returns DashboardSiteBreakdownList Success
     * @throws ApiError
     */
    public getSiteBreakdown({
        mids,
        rids,
        sids,
        startDate,
        endDate,
        sortBy,
        xFields,
    }: {
        /**
         * Filter by sids.
         */
        mids?: Array<string>,
        /**
         * Filter by sids.
         */
        rids?: Array<string>,
        /**
         * Filter by sids.
         */
        sids?: Array<string>,
        /**
         * Start date.
         */
        startDate?: string,
        /**
         * End date.
         */
        endDate?: string,
        /**
         * Sort by amount or count.
         */
        sortBy?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<DashboardSiteBreakdownList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/dashboard/site-breakdown/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'mids': mids,
                'rids': rids,
                'sids': sids,
                'start_date': startDate,
                'end_date': endDate,
                'sort_by': sortBy,
            },
        });
    }
}

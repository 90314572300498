import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {ReactComponent as ArrowBackIcon} from "assets/iconsDS/breadcrumbs-arow-left.svg";
import {ReactComponent as ArrowForwardIcon} from "assets/iconsDS/breadcrumbs-arow-right.svg";

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {useTheme} from "@mui/material/styles";

const NavigationArrows: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [history, setHistory] = useState<string[]>([location.pathname]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (location.pathname !== history[currentIndex]) {
      setHistory((prev) => {
        const newHistory = [...prev];
        if (currentIndex < newHistory.length - 1) {
          newHistory.length = currentIndex + 1; // truncate history if navigating forward
        }
        newHistory.push(location.pathname);
        return newHistory;
      });
      setCurrentIndex((prev) => prev + 1);
    }
  }, [location]);

  const handleBack = () => {
    if (currentIndex > 0) {
      navigate(-1); // Navigate to the previous page
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const handleForward = () => {
    if (currentIndex < history.length - 1) {
      navigate(1); // Navigate to the next page
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const isBackDisabled = currentIndex === 0;
  const isForwardDisabled = currentIndex === history.length - 1;

  return (
    <Stack direction="row" alignItems="center">
      <IconButton
        onClick={handleBack}
        aria-label="back"
        disabled={isBackDisabled}
        sx={{
          width: '30px',
          height: '30px',
        }}
      >
        <ArrowBackIcon
          style={{
            fill: isBackDisabled
              ? theme.palette.themeColors.grey['60']
              : theme.palette.themeColors.grey['120'],
          }}
        />
      </IconButton>
      <IconButton
        onClick={handleForward}
        aria-label="forward"
        disabled={isForwardDisabled}
        sx={{
          width: '30px',
          height: '30px',
        }}
      >
        <ArrowForwardIcon
          style={{
            fill: isForwardDisabled
              ? theme.palette.themeColors.grey['60']
              : theme.palette.themeColors.grey['120'],
          }}
        />
      </IconButton>
    </Stack>
  );
};

export default NavigationArrows;

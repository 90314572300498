import {AppTableHead} from "shared/components/mui";
import {
  Backdrop,
  Box,
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
} from "@mui/material";
import usePagination from "shared/hooks/usePagination";
import PermissionError from "shared/PermissionError";
import usePermissions from "apps/actionCenter/pages/list/permissions/usePermissions";
import useDataFilters from "apps/actionCenter/pages/list/useDataFilters";
import useSorting from "shared/hooks/useSorting";
import ActionCenterRow from "apps/actionCenter/pages/list/partials/ActionCenterRow";

const TABLE_HEAD = [
  {id: 'email', label: 'EMAIL', sortable: true},
  {id: 'site', label: 'SITE', sortable: true},
  {id: 'action_type', label: 'ACTION TYPE', sortable: true},
  {id: 'comment', label: 'COMMENT', sortable: true},
  {id: 'status', label: 'STATUS', sortable: true},
  {id: 'status_timestamp', label: 'UPDATED AT', sortable: true},
  {id: 'actions', label: ''},
]

const ActionCenter = () => {
  const {currentPage, perPage, pagination} = usePagination();

  const {
    orderDirection,
    orderBy,
    orderByField,
    handleOrderBy,
  } = useSorting({defaultOrderBy: 'status_timestamp'});

  const {data, isLoading, isFetching, filters} = useDataFilters({currentPage, perPage, orderBy: orderByField});

  const {canViewActionCenter} = usePermissions();

  if (!canViewActionCenter){
    return <PermissionError />
  }

  if (isLoading) return <LinearProgress/>

  return (
    <>
      <Box mb={4} maxWidth={600}>
        {filters}
      </Box>

      <Box>
        <TableContainer>
          <Table size='small' sx={{minWidth: 800, bgcolor: '#fff', position: 'relative'}}>
            <AppTableHead
              headLabel={TABLE_HEAD}
              order={orderDirection}
              orderBy={orderBy}
              onSort={handleOrderBy}
            />

            <TableBody sx={{position: 'relative'}}>
              {isLoading && 'Loading...'}
              <Backdrop open={!isLoading && isFetching} sx={{zIndex: 99, position: 'absolute'}}>
                <CircularProgress color="inherit" sx={{zIndex: 100}}/>
              </Backdrop>

              {data?.items?.map((item) => (
                <ActionCenterRow
                  _id={item._id}
                  email={item.email}
                  site_name={item.site_name}
                  sid={item.sid}
                  status={item.status}
                  action_type={item.action_type}
                  comment={item.comment}
                  status_timestamp={item.status_timestamp}
                />
              ))}
            </TableBody>

            <TableFooter>
              <TableCell colSpan={9} align='right' sx={{py: 1, border: 'none'}}>
                {pagination({total: data?.total, pages: data?.pages})}
              </TableCell>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default ActionCenter;

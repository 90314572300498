import SidAutocompleteFormField from "shared/components/formDataFields/SidAutocompleteFormField";
import {FormProvider, useForm} from "react-hook-form";
import FileFormField from "shared/components/formFields/FileFormField";
import useProcessBatchUpload from "apps/batchUpload/pages/index/useDataMutations";
import {Box, Button, Link, Stack} from "@mui/material";


import * as Yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import ErrorsList from "apps/batchUpload/pages/index/ErrorsList";
import SuccessImportList from "apps/batchUpload/pages/index/SuccessList";

const formSchema = Yup.object().shape({
  sid: Yup.string(),
  // .required('SID is required'),
  batch_file: Yup.mixed()
  // .required('A file is required')
  // .test(
  //   "fileSize",
  //   "File too large",
  //   value => value && value instanceof File && value.size <= 1_048_576 // 1MB
  // )
  // .test(
  //   "fileFormat",
  //   "Unsupported Format",
  //   value => value && value instanceof File && ['application/pdf', 'application/msword'].includes(value.type) // replace with your supported formats
  // )
});

const BatchUpload = () => {
  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      sid: '',
      batch_file: null,
    }
  });

  const {mutate, isPending, apiErrors, apiSuccess} = useProcessBatchUpload({
    // @ts-ignore
    setError: methods.setError,
    reset: () => methods.reset(methods.formState.defaultValues),
  });

  const onSubmit = (data: any) => {
    mutate({
      formData: data,
    });
  }

  return (
    <FormProvider {...methods}>

      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h1>Batch Upload</h1>
        <Stack spacing={2} direction="row">
          <Link href="/assets/samples/batch-upload/batch_upload.csv" download>Download Sample CSV</Link>
          <Link href="/assets/samples/batch-upload/batch_upload.xlsx" download>Download Sample XLSX</Link>
        </Stack>
        <Box my={2}>
          <SidAutocompleteFormField name='sid'/>
        </Box>
        <Box my={2}>
          <FileFormField
            name='batch_file'
            label='Batch File'
            accept={{
              'text/csv': ['.csv'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            }}
          />
        </Box>

        <Button type='submit' disabled={isPending}>Submit</Button>

        {apiErrors && (
          <ErrorsList
            comment={apiErrors?.comment}
            items={apiErrors?.items}
          />
        )}
        {apiSuccess && (
          <SuccessImportList
            comment={apiSuccess?.comment}
            report={apiSuccess?.report}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default BatchUpload;

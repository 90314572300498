import {Avatar, Box, Typography} from "@mui/material";
import getImageURL from "apps/transactions/pages/TransactionDetails/urls";
import {fAvatar} from "shared/utils/formats";

interface SitePreviewProps {
  siteName: string;
  sid: number;
}

const Site = ({siteName, sid}: SitePreviewProps) => (
  <Box sx={{display: 'inline-flex', alignItems: 'center', gap: '6px'}}>
    <Avatar
      sx={{width: 16, height: 16, fontSize: 8, fontWeight: 600, borderRadius: '3px'}}
      src={getImageURL(sid)}
      variant='rounded'
    >
      {fAvatar(siteName)}
    </Avatar>
    <Typography
      variant='B4'
    >
      {siteName}
    </Typography>
  </Box>
)

export default Site;

import {useFormFilters} from "apps/dashboard/pages/Dashboard/useFormFilters";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {StatisticsQueryKeys} from "shared/queryKeys";

export const useChartPaymentsDataQueries = () => {
  const filters = useFormFilters()
  const {data, isLoading} = useQuery({
    queryKey: [StatisticsQueryKeys.DATE_BREAKDOWN_PAYMENTS, filters],
    queryFn: () => ApiClient.dashboard.getDayBreakdown({...filters}),
  });

  return{
    data,
    isLoading
  }
}

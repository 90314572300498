import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {NotificationsQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

type Params = Parameters<typeof ApiClient.notifications.getNotificationsList>[0]

export const useNotificationsList = ({
  page,
  perPage,
  orderBy,
  ...filters
}: Params) => {
  const {data, refetch, isLoading, isFetching} = useQuery({
    queryKey: [NotificationsQueryKeys.LIST, {page, perPage, orderBy, filters: {filters}}],
    queryFn: () => ApiClient.notifications.getNotificationsList({page, perPage, orderBy, ...filters}),
    placeholderData: keepPreviousData,
    staleTime: 0,
    gcTime: 0,
  })

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  }
}
import { Components, Theme } from '@mui/material/styles';

const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    flexContainer: ({ theme }) => ({
      gap: theme.spacing(3),
    }),
    indicator: {
      height: '1px',
    }
  },
};

export default MuiTabs;

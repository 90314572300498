import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {Controller, useFormContext} from "react-hook-form";
import {useDropzone} from "react-dropzone";
import {Box, FormHelperText} from "@mui/material";
import {useCallback} from "react";
import {truncate} from "lodash";


function truncateCenter(word: string, maxLength: number) {
  if (word.length <= maxLength) {
    return word;
  }

  const sideLength = Math.floor(maxLength / 2);
  const start = truncate(word.slice(0, sideLength), { 'length': sideLength });
  const end = truncate(word.slice(-sideLength), { 'length': sideLength });

  return `${start}......${end}`;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface FileFormFieldProps {
  name: string;
  label: string;
  multiple?: boolean;
  accept?: {[key: string]: string[]};
  supportedFiles?: string;
  maxSize?: number;
}

const FileFormField = ({name, label, multiple = false, accept, supportedFiles, maxSize = 52_428_800}: FileFormFieldProps) => {
  const {control, setValue, watch} = useFormContext();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = multiple ? acceptedFiles : acceptedFiles[0];
    setValue(name, file);
  }, [])

  const {getRootProps, getInputProps, open} = useDropzone({
    onDrop,
    multiple,
    accept,
    maxSize,
    noClick: true,
    noKeyboard: true,
  });

  const watchedFiles = watch(name);
  const filesArray = Array.isArray(watchedFiles) ? watchedFiles : [watchedFiles];
  const files = filesArray?.filter(Boolean).map(file => (
    <li key={file.path}>{truncateCenter(file.path, 40)} - {file.size} bytes</li>
  ));

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({field, fieldState}) => (
          <Box {...getRootProps({className: 'dropzone'})}>
            <Button
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              onClick={open}
            >
              {label}
              <VisuallyHiddenInput
                type="file"
                {...getInputProps({
                  onChange: field.onChange,
                  onBlur: field.onBlur,
                })}
              />
            </Button>
            {fieldState.invalid && <FormHelperText style={{color: '#EC6764'}}>{fieldState.error?.message}</FormHelperText>}
          </Box>
        )}
      />

      {supportedFiles && `Supported files: ${supportedFiles}`}

      <ul>
        {files}
      </ul>
    </Box>
  );
}

export default FileFormField;

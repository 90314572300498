import {useEffect} from "react";
import useFilters from "apps/actionCenter/pages/list/useFilters";
import {useActionCenter} from "apps/actionCenter/pages/list/useDataQueries";

type DataFiltersParams = {
  currentPage: number;
  perPage: number;
  orderBy: string;
}

const useDataFilters = ({currentPage, perPage, orderBy}: DataFiltersParams) => {
  const {filterData, filters, submitCount} = useFilters()

  const {data, refetch, isLoading, isFetching} = useActionCenter({
    page: currentPage,
    perPage,
    orderBy,
    status: filterData.status,
    actionType: filterData.action_type,
    comment: filterData.comment,
    statusTimestamp: filterData.status_timestamp,
    email: filterData.email,
    sids: filterData.sids,
  });

  useEffect(() => {
    void refetch();
  }, [submitCount]);

  return {
    data,
    isLoading,
    isFetching,
    filters,
  }
}

export default useDataFilters;

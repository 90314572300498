import React from 'react';
import {FormControl, InputAdornment, InputBase, InputLabel} from "@mui/material";
import {NumericFormat, NumericFormatProps} from "react-number-format";
import { SxProps, Theme } from '@mui/system';

type DSTextFieldProps = {
  name?: string,
  label?: string,
  placeholder?: string,
  type?: "text" | "email" | "number" | "password",
  maxLength?: number,
  multiline?: boolean,
  minRows?: number,
  isReadOnly?: boolean,
  startIcon?: React.ReactNode,
  endIcon?: React.ReactNode,
  variant?: 'filled' | 'outlined' | 'standard',
  value?: string | number,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  sx?: SxProps<Theme>,
  autoFocus?: boolean,
};

interface CustomProps {
  onChange: (event: { target: { name?: string; value: string } }) => void;
  name?: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const {onChange, ...other} = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        decimalScale={2}
        fixedDecimalScale
      />
    );
  },
);

const getInputProps = (type: string) => {
  if (type === 'number') {
    return {
      inputComponent: NumericFormatCustom as any,
      type: 'text',
    };
  }
  return {
    maxLength: 255,
    type,
  };
};

const DSTextField: React.FC<DSTextFieldProps> = ({
  name,
  label,
  placeholder,
  type = 'text',
  maxLength = 255,
  multiline = false,
  minRows = 3,
  isReadOnly = false,
  startIcon,
  endIcon,
  variant = 'outlined',
  value,
  onChange,
  autoFocus = false,
  sx
}) => {
  const inputProps = getInputProps(type);
  const uniqueId = React.useId();

  return (
    <FormControl variant={variant} fullWidth sx={sx}>
      {label && (
        <InputLabel shrink htmlFor={`${name ?? uniqueId}-field`}>
          {label}
        </InputLabel>
      )}
      <InputBase
        id={`${name ?? uniqueId}-field`}
        inputProps={{maxLength}}
        placeholder={placeholder}
        multiline={multiline}
        minRows={minRows}
        disabled={isReadOnly}
        startAdornment={startIcon ? <InputAdornment position="start">{startIcon}</InputAdornment> : null}
        endAdornment={endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        {...inputProps}
      />
    </FormControl>
  );
};

export default DSTextField;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Action } from '../models/Action';
import type { CancelResponseModel } from '../models/CancelResponseModel';
import type { CancelTransactionData } from '../models/CancelTransactionData';
import type { CreateUpdateNoteSchema } from '../models/CreateUpdateNoteSchema';
import type { DashboardAPISchema } from '../models/DashboardAPISchema';
import type { DeclineResponseModel } from '../models/DeclineResponseModel';
import type { DeclineTransactionData } from '../models/DeclineTransactionData';
import type { LinkedTransactionsModel } from '../models/LinkedTransactionsModel';
import type { MerchantLimitsModel } from '../models/MerchantLimitsModel';
import type { NotesSchema } from '../models/NotesSchema';
import type { RefundResponseModel } from '../models/RefundResponseModel';
import type { RefundTransactionData } from '../models/RefundTransactionData';
import type { SearchRequest } from '../models/SearchRequest';
import type { SearchResponse } from '../models/SearchResponse';
import type { TransactionDetailModel } from '../models/TransactionDetailModel';
import type { TransactionItems } from '../models/TransactionItems';
import type { TransactionsListPaginatedModel } from '../models/TransactionsListPaginatedModel';
import type { UpdateInstructionsResponseModel } from '../models/UpdateInstructionsResponseModel';
import type { UserModel } from '../models/UserModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TransactionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Transactions
     * # Get Transactions Endpoint
     *
     * This endpoint is used to retrieve a list of transactions.
     *
     * ## Transactions Retrieval
     *
     * The transactions data is retrieved from the database and it includes information such as method,
     * method name, status, amount, transaction id, email, first name, last name, site name, interac_ca, status timestamp, IP address, merchant name, mid, sid, rid, retailer name, type, currency code, udfs, phone, address, city, zip code, state, country, status, and type.
     *
     * The transactions data is then returned to the client for further processing.
     *
     * ## X Fields
     *
     * The `x_fields` parameter can be used to specify which fields should be included in the response.
     * Field name can be taken from the `Successful Response` example.
     * The value of `x_fields` should be a comma-separated list of field names.
     *
     * ## Validation
     *
     * The transactions data is validated before being processed. The validation checks include:
     * - Checking if the user role has access to the specified transactions.
     * - Checking if the requested fields are valid and exist in the transactions data.
     *
     * ## Response
     *
     * The response from this endpoint is a paginated list of transactions. If successful, a list of transactions is returned.
     *
     * ## Permissions
     *
     * Users must have one of the following roles:
     * - ADMIN
     * - CUSTOMER_SERVICE
     * - MERCHANT
     * - RETAILER
     * - MANAGER
     * - SITE
     *
     * Users must also have the following permission:
     * - VIEW_TRANSACTIONS
     * @returns TransactionsListPaginatedModel Successful Response
     * @throws ApiError
     */
    public getTransactionsList({
        fields,
        page,
        perPage,
        search,
        method,
        status,
        transactionType,
        sid,
        address,
        emailAddress,
        ipAddress,
        name,
        phone,
        dateFrom,
        dateTo,
        amount,
        ridSid,
        placeId,
        orderBy,
        isBookmarked,
    }: {
        fields?: (Array<string> | null),
        page?: (number | null),
        perPage?: (number | null),
        search?: (string | null),
        method?: Array<string>,
        status?: Array<string>,
        transactionType?: Array<string>,
        sid?: (Array<number> | null),
        address?: (string | null),
        emailAddress?: (string | null),
        ipAddress?: (string | null),
        name?: (string | null),
        phone?: (string | null),
        dateFrom?: (string | null),
        dateTo?: (string | null),
        amount?: (number | null),
        ridSid?: (number | null),
        placeId?: (string | null),
        orderBy?: (string | null),
        isBookmarked?: (boolean | null),
    }): CancelablePromise<TransactionsListPaginatedModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/',
            query: {
                'fields': fields,
                'page': page,
                'per_page': perPage,
                'search': search,
                'method': method,
                'status': status,
                'transaction_type': transactionType,
                'sid': sid,
                'address': address,
                'email_address': emailAddress,
                'ip_address': ipAddress,
                'name': name,
                'phone': phone,
                'date_from': dateFrom,
                'date_to': dateTo,
                'amount': amount,
                'rid_sid': ridSid,
                'placeId': placeId,
                'order_by': orderBy,
                'is_bookmarked': isBookmarked,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Documents
     * @returns SearchResponse Successful Response
     * @throws ApiError
     */
    public searchDocumentsGrouped({
        requestBody,
    }: {
        requestBody: SearchRequest,
    }): CancelablePromise<SearchResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Transaction Details
     * @returns TransactionDetailModel Successful Response
     * @throws ApiError
     */
    public getTransactionDetails({
        txn,
    }: {
        txn: string,
    }): CancelablePromise<TransactionDetailModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/',
            path: {
                'txn': txn,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Delete Transaction Bookmark
     * @returns UserModel Successful Response
     * @throws ApiError
     */
    public createDeleteTransactionBookmark({
        txn,
        action,
    }: {
        txn: string,
        action: Action,
    }): CancelablePromise<UserModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/{action}/bookmark/',
            path: {
                'txn': txn,
                'action': action,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Merchant Limits
     * @returns MerchantLimitsModel Successful Response
     * @throws ApiError
     */
    public getMerchantLimits({
        txn,
    }: {
        txn: string,
    }): CancelablePromise<MerchantLimitsModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/merchant-limits/',
            path: {
                'txn': txn,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Transaction Items
     * @returns TransactionItems Successful Response
     * @throws ApiError
     */
    public getTransactionItems({
        txn,
    }: {
        txn: string,
    }): CancelablePromise<TransactionItems> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/items/',
            path: {
                'txn': txn,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Linked Transactions
     * @returns LinkedTransactionsModel Successful Response
     * @throws ApiError
     */
    public getLinkedTransactions({
        txn,
    }: {
        txn: string,
    }): CancelablePromise<LinkedTransactionsModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/linked/',
            path: {
                'txn': txn,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Note Transaction
     * @returns DashboardAPISchema Successful Response
     * @throws ApiError
     */
    public createNoteTransaction({
        txn,
        requestBody,
    }: {
        txn: string,
        requestBody: CreateUpdateNoteSchema,
    }): CancelablePromise<DashboardAPISchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/notes/',
            path: {
                'txn': txn,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Notes Transaction
     * @returns NotesSchema Successful Response
     * @throws ApiError
     */
    public getNotesTransaction({
        txn,
    }: {
        txn: string,
    }): CancelablePromise<NotesSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/notes/',
            path: {
                'txn': txn,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Note Transaction
     * @returns CreateUpdateNoteSchema Successful Response
     * @throws ApiError
     */
    public updateNoteTransaction({
        txn,
        noteId,
        requestBody,
    }: {
        txn: string,
        noteId: string,
        requestBody: CreateUpdateNoteSchema,
    }): CancelablePromise<CreateUpdateNoteSchema> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transactions/{txn}/notes/{note_id}/',
            path: {
                'txn': txn,
                'note_id': noteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Note Transaction
     * @returns DashboardAPISchema Successful Response
     * @throws ApiError
     */
    public deleteNoteTransaction({
        txn,
        noteId,
    }: {
        txn: string,
        noteId: string,
    }): CancelablePromise<DashboardAPISchema> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/transactions/{txn}/notes/{note_id}/',
            path: {
                'txn': txn,
                'note_id': noteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Api Lookup
     * @returns any Successful Response
     * @throws ApiError
     */
    public getApiLookup({
        txn,
    }: {
        txn: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transactions/{txn}/api-lookup/',
            path: {
                'txn': txn,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Refund Transaction
     * @returns RefundResponseModel Successful Response
     * @throws ApiError
     */
    public refundTransaction({
        txn,
        requestBody,
    }: {
        txn: string,
        requestBody: RefundTransactionData,
    }): CancelablePromise<RefundResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/refund/',
            path: {
                'txn': txn,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Cancel Transaction
     * @returns CancelResponseModel Successful Response
     * @throws ApiError
     */
    public cancelTransaction({
        txn,
        requestBody,
    }: {
        txn: string,
        requestBody: CancelTransactionData,
    }): CancelablePromise<CancelResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/cancel/',
            path: {
                'txn': txn,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Decline Transaction
     * @returns DeclineResponseModel Successful Response
     * @throws ApiError
     */
    public declineTransaction({
        txn,
        requestBody,
    }: {
        txn: string,
        requestBody: DeclineTransactionData,
    }): CancelablePromise<DeclineResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/decline/',
            path: {
                'txn': txn,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Resend Instructions
     * @returns UpdateInstructionsResponseModel Successful Response
     * @throws ApiError
     */
    public resendPayoutInstructions({
        txn,
    }: {
        txn: string,
    }): CancelablePromise<UpdateInstructionsResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transactions/{txn}/resend-instructions/',
            path: {
                'txn': txn,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}

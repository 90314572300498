import {Box, Button, Stack} from "@mui/material";
import {
  useColumnTemplates
} from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ColumnsToolBar/useDataQueries";
import TemplateItem
  from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ColumnsToolBar/TemplateItem";
import {ReactComponent as LupeIcon} from "assets/iconsDS/text-field-loupe.svg";
import {useState} from "react";
import DSTextField from "shared/components/DS/DSTextField";
import useColumnsState
  from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ColumnsToolBar/useColumnsState";
import {ExportTemplate} from "generatedTypes";

const validateInput = (input: string) => {
  const unsafeCharacters = /[#$%&*<>]/;
  return !unsafeCharacters.test(input);
};

interface TemplatesListProps {
  gridRef: any;
}

const TemplatesList = ({gridRef}: TemplatesListProps) => {
  const {data, isLoading} = useColumnTemplates();
  const [filterText, setFilterText] = useState('');

  const [selectedTemplate, setSelectedTemplate] = useState<ExportTemplate | null>(null);

  const {restoreState} = useColumnsState({gridRef});

  const filteredItems = data?.filter((item) =>
    item.name?.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleTemplateClick = (selectedItem: ExportTemplate) => {
    setSelectedTemplate(selectedItem);
  }

  const handleApply = () => {
    if (selectedTemplate) {
      restoreState(selectedTemplate.aggrid_columns);
    }
  }

  if (!data && !isLoading) {
    return <Box>No templates</Box>
  }

  return (
    <Box>
      <Box
        sx={{
          marginBottom: '11px',
        }}
      >
        <DSTextField
          placeholder="Search for Template"
          variant="outlined"
          value={filterText}
          startIcon={<LupeIcon/>}
          onChange={({target: {value}}) => {
            if (validateInput(value)) {
              setFilterText(value);
            }
          }}
        />
      </Box>

      {filteredItems?.map((item) => (
        item.aggrid_columns && (
          <TemplateItem
            key={item._id}
            item={item}
            selectedTemplate={selectedTemplate}
            onClick={handleTemplateClick}
          />
        )
      ))}

      <Stack
        direction="row"
        sx={{
          gap: '12px'
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          disabled
          // onClick={saveState}
        >
          Save Template
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleApply}
          disabled={!selectedTemplate}
        >
          Apply
        </Button>
      </Stack>
    </Box>
  );
};

export default TemplatesList;

import {Grid} from "@mui/material";
import RidMidSidSelect from "apps/dashboard/pages/Dashboard/partials/Filters/RidMidSidSelect";
import DatePickerFormField from "shared/components/formFields/DatePickerFormField";

const Filters = () => (
  <Grid container spacing={2}>
    <Grid item md={6}>
      <RidMidSidSelect />
    </Grid>
    <Grid item md={6}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <DatePickerFormField name='startDate' label='From' />
        </Grid>
        <Grid item md={6}>
          <DatePickerFormField name='endDate' label='To' />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default Filters;

import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {ActionCenterQueryKeys} from "shared/queryKeys";

type Params = Parameters<typeof ApiClient.actionCenter.putActionCenterList>[0]

const useCompleteAction = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient();


  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.actionCenter.putActionCenterList(params),
    onSuccess: async (data) => {
      enqueueSnackbar('Action center completed', {variant: 'success'})

      await queryClient.invalidateQueries({queryKey: [ActionCenterQueryKeys.DETAILS, data.email, data.sid]});
      await queryClient.invalidateQueries({queryKey: [ActionCenterQueryKeys.LIST]})
    },
  })

  return {
    mutate,
    isPending,
  }
}

export default useCompleteAction;

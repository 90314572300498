import React from 'react'
import {
  Box,
  BoxProps,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

interface AppSelectProps extends BoxProps {
  name: string
  items: string[]
  select: string
  setSelect: (value: string) => void
  fullWidth?: boolean
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const MuiFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[400],
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
  },
}))

const AppSelect: React.FC<AppSelectProps> = ({
  name,
  items,
  select,
  setSelect,
  fullWidth,
  ...props
}) => (
  <Box>
    <MuiFormControl
      size='small'
      fullWidth={fullWidth}
      sx={{ width: fullWidth ? null : 200 }}
    >
      <InputLabel id={name.replace(' ', '-')}>{name}</InputLabel>
      <Select
        labelId={`${name.replace(' ', '-')  }-label`}
        id={name.replace(' ', '-')}
        value={select}
        // @ts-ignore
        onChange={(event: any) => setSelect(event.target.value as string)}
        input={<OutlinedInput label={name} />}
        MenuProps={MenuProps}
        IconComponent={KeyboardArrowDown}
        sx={{ px: 1 }}
        {...props}
      >
        {items.map((item) => (
          <MenuItem dense key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </MuiFormControl>
  </Box>
)

export default AppSelect

import {useEffect, useState} from "react";
import {socket} from "socket";
import {SocketServerRecentTransactions} from "generatedTypes";

const useRecentTransactionsSocketData = () => {
  const [recentData, setRecentData] = useState<SocketServerRecentTransactions[]>([]);

  useEffect(() => {
    const onDataEvent = (data: SocketServerRecentTransactions) => {
      setRecentData(previous => [data, ...previous]);
    }

    socket.on('data', onDataEvent);

    return () => {
      socket.off('data', onDataEvent);
    };
  }, [recentData]);

  return {
    data: recentData,
  }
}

export default useRecentTransactionsSocketData

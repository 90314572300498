import { useQuery } from "@tanstack/react-query";
import ApiClient from "apiClient";



export const useTimeZoneOptions = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["timezones"],
    queryFn: () => ApiClient.account.getTimezone({}),
  });

  return {
    data,
    isLoading,
  };
};

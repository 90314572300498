import {Grid} from "@mui/material";
import {memo} from "react";
import TextFormField from "shared/components/formFields/TextFormField";
import PhoneFormField from "shared/components/formFields/PhoneFormField";
import DateTimePickerFormField from "shared/components/formFields/DateTimePickerFormField";
import AddressAutocompleteFormField from "shared/components/formFields/AddressAutocompleteFormField";
import RSSelectFormField from "shared/components/formFields/RSSelectFormField";

export const methodsList = [
  {value: "credit_card", label: "Credit Card"},
  {value: "credit_send", label: "Credit Send"},
  {value: "digital_cheque", label: "Digital Cheque"},
  {value: "direct_deposit", label: "Direct Deposit"},
  {value: "etransfer_request_money", label: "e-Transfer® Request Money"},
  {value: "etransfer_send_money", label: "e-Transfer® Send Money"},
  {value: "etransfer_standard", label: "e-Transfer® Standard"},
  {value: "interac_online", label: "Interac® Online"},
  {value: "online_banking", label: "Online Banking"},
  {value: "reward_card", label: "Reward Card"}
];

export const statusList = [
  {value: "approved", label: "Approved"},
  {value: "batched", label: "Batched"},
  {value: "cancelled", label: "Cancelled"},
  {value: "claimed", label: "Claimed"},
  {value: "confirming", label: "Confirming"},
  {value: "declined", label: "Declined"},
  {value: "failed", label: "Failed"},
  {value: "pending", label: "Pending"},
  {value: "expired", label: "Expired"},
  {value: "pg_error", label: "PG Error"},
  {value: "pl_error", label: "PL Error"},
  {value: "process_expired", label: "Process Expired"},
  {value: "returned", label: "Returned"},
  {value: "refused", label: "Refused"},
  {value: "sent", label: "Sent"},
  {value: "waiting_authorization", label: "Waiting Authorization"}
];

export const transactionTypesList = [
  {value: "capture", label: "Capture"},
  {value: "chargeback", label: "Chargeback"},
  {value: "payment", label: "Payment"},
  {value: "payout", label: "Payout"},
  {value: "preauth", label: "PreAuth"},
  {value: "refund", label: "Refund"},
  {value: "void", label: "Void"}
];


// Address

const FilterComponent = memo(() => (
  <>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextFormField name="search" placeholder="Search TXN, Network ID, CA Number or UDF."/>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <RSSelectFormField isMulti name="method" label="Method" options={methodsList}/>
      </Grid>
      <Grid item xs={12}>
        <RSSelectFormField isMulti name="transaction_status" label="Status" options={statusList}/>
      </Grid>
      <Grid item xs={12}>
        <RSSelectFormField isMulti name="type" label="Type" options={transactionTypesList}/>
      </Grid>
      <Grid item xs={12}>
        <TextFormField name="ip_address" placeholder="IP Address"/>
      </Grid>
      <Grid item xs={12}>
        <TextFormField name="name" placeholder="Name"/>
      </Grid>
      <Grid item xs={12}>
        <TextFormField name="amount" placeholder="Amount"/>
      </Grid>
      <Grid item xs={12}>
        <TextFormField name="email" placeholder="Email" type="email"/>
      </Grid>
      <Grid item xs={12}>
        <TextFormField name="rid_sid" placeholder="RID/SID"/>
      </Grid>
      <Grid item xs={12}>
        <AddressAutocompleteFormField name='place_id' />
      </Grid>
      <Grid item xs={12}>
        <DateTimePickerFormField
          name="date_start"
          label="Date From"
        />
      </Grid>
      <Grid item xs={12}>
        <DateTimePickerFormField
          name="date_end"
          label="Date To"
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneFormField name="phone" placeholder="Phone"/>
      </Grid>
    </Grid>
  </>
));

export default FilterComponent;

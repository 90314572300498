import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form"
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box} from "@mui/material";
import FilterButtonsComponent from "apps/transactions/pages/TransactionsList/useFilters/FilterButtonsComponent";
import FilterComponent from "apps/actionCenter/pages/list/useFilters/FilterComponent";
import useQueryString from "shared/hooks/useQueryString";

const schema = yup.object().shape({
  email: yup.string().email(),
  sids: yup.array(),
  action_type: yup.string(),
  status: yup.string(),
  comment: yup.string().max(255),
  status_timestamp: yup.date().nullable(),
})

type FormData = yup.InferType<typeof schema>;

const defaultValues = {
  email: '',
  sids: [],
  action_type: '',
  status: '',
  comment: '',
  status_timestamp: null,
}

const useFilters = () => {
  const {setQueryString, getDefaultValues} = useQueryString()
  const [formData, setFormData] = useState<FormData>(defaultValues);

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: getDefaultValues(defaultValues),
  });

  const {formState} = methods;

  const onSubmit = (data: FormData) => {
    setFormData(data);
    setQueryString({
      page: 1,
      ...data,
      status_timestamp: data.status_timestamp?.toISOString(),
    });
  }

  const filters = (
    <FormProvider {...methods}>
      <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
        <FilterComponent/>
        <FilterButtonsComponent onSubmit={onSubmit}/>
      </Box>
    </FormProvider>
  )

  const filterData = {
    ...formData,
    status_timestamp: formData.status_timestamp?.toISOString(),
  }

  return {
    filterData,
    filters,
    submitCount: formState.submitCount,
    isValid: formState.isValid,
  };
}

export default useFilters;

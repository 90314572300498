import React, {useEffect, useRef} from 'react';
import {Box, Button, Checkbox, FormHelperText, LinearProgress, Paper, Popper, Stack, Typography} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {
  useTransactionsFields
} from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ExportToolBar/useDataQueries";
import {
  groupFieldsByGroup
} from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ExportToolBar/utils";
import FieldsList from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ExportToolBar/FieldsList";
import {ReactComponent as UploadIcon} from "assets/iconsDS/upload.svg";
import {FormProvider, useForm} from "react-hook-form";
import ToolBarHead from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ExportToolBar/ToolBarHead";
import ToggleButtonsFormField from "shared/components/formFields/ToggleButtonsFormField";
import HelpToolTip from "shared/components/DS/HelpToolTip";
import SelectOption
  from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ExportToolBar/SelectOption";
import {
  useTransactionExport
} from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ExportToolBar/useDataMutations";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {bindPopper, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import useDialogState from "shared/hooks/useDialogState";
import FileDownloadDialog
  from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ExportToolBar/FileDownloadDialog";
import type {TransactionListItemModel} from "generatedTypes";
import {isEmpty} from "lodash";

interface ColumnsToolBarProps {
  gridRef: React.MutableRefObject<any>;
  customerEmail?: string;
}

const schema = yup.object().shape({
  exportFormat: yup.string()
    .oneOf(['csv', 'xlsx'], 'Invalid format')
    .required('Format is required'),
  exportOption: yup.string()
    .oneOf(['all', 'selected'], 'Invalid format')
    .required('Format is required'),
  fields: yup.object().test({
    name: 'at least one true', // name of the test
    message: 'At least one field must be true', // error message
    test: value => Object.values(value).includes(true) // test function
  }).nullable(),
});

type FormData = yup.InferType<typeof schema>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExportToolBar = ({gridRef, customerEmail}: ColumnsToolBarProps) => {
  const theme = useTheme();
  const popupState = usePopupState({variant: 'popper', popupId: 'exportPopper'});
  const fileLoadingDialog = useDialogState();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      exportOption: 'all',
      exportFormat: 'csv',
      fields: {},
    }
  });

  // const handleSetValues = (field: string, value: boolean) => {
  //   methods.setValue(`fields.${field}`, value);
  // }
  //
  // const handleToggleAll = () => {
  //   fields.forEach(field => handleSetValues(field, !allChecked));
  // };

  const {
    exportTransactions,
    downloadLink,
    isPending,
    isSuccess,
    isFileLoading,
    mutationReset,
    // errorMessage,
    exportStatusError,
  } = useTransactionExport()

  const {data, isLoading} = useTransactionsFields({enabled: popupState.isOpen})
  const fields = groupFieldsByGroup(data || [])

  const onSubmit = (formData: FormData) => {
    const selectedRows = gridRef.current!.api?.getSelectedRows()
    let selectedTxns = [];
    if (formData.exportOption === 'selected') {
      selectedTxns = selectedRows?.map((row: TransactionListItemModel) => row.txn) || [];
    }

    exportTransactions({
      fileFormat: formData.exportFormat,
      fields: Object.entries(formData.fields || []).filter(([, value]) => value === true).map(([key]) => key),
      selectedTxns,
      email: customerEmail || ''
    })
    return formData
  }

  const allFields = methods.watch('fields');
  const numberOfChecked = isEmpty(allFields) ? 0 : Object.values(allFields).filter(Boolean).length;
  const areAllFieldsChecked = isEmpty(allFields) ? false : Object.values(allFields).every(Boolean);

  const handleToggleAll = () => {
    if (!isEmpty(allFields)) {
      // @ts-ignore
      Object.keys(allFields).forEach(field => methods.setValue(`fields.${field}`, !areAllFieldsChecked));
    }
  };

  const exportOptionWatch = methods.watch('exportOption');

  useEffect(() => {
    if (isSuccess) {
      fileLoadingDialog.openDialog()
    }
    return () => mutationReset()
  }, [isSuccess])

  useEffect(() => {
    const exportOption = methods.getValues('exportOption');
    const handleRowSelected = () => {
      if (exportOption === 'selected') {
        if (gridRef.current!.api?.getSelectedRows().length === 0) {
          methods.setError(
            'root.exportOption',
            {
              type: 'manual',
              message: 'At least one transaction must be selected'
            });
        } else {
          methods.clearErrors('root.exportOption');
        }
      }
    };

    if (exportOption === 'selected') {
      handleRowSelected();
    } else {
      methods.clearErrors('root.exportOption');
    }

    if (gridRef.current) {
      gridRef.current!.api?.addEventListener('rowSelected', handleRowSelected);
    }
    return () => {
      if (gridRef.current) {
        gridRef.current!.api?.removeEventListener('rowSelected', handleRowSelected);
      }
    };
  }, [gridRef.current?.api, exportOptionWatch]);

  return (
    <>
      <Box>
        <Button
          ref={buttonRef}
          color="primary"
          variant="contained"
          size='medium'
          startIcon={<UploadIcon/>}
          {...bindTrigger(popupState)}
        >
          Export
        </Button>
      </Box>
      <Popper
        {...bindPopper(popupState)}
        placement="bottom-start"
        sx={{
          zIndex: 2
        }}
      >
        <Paper
          sx={{
            width: 440,
            padding: '4px',
          }}
        >
          <ToolBarHead
            title="Export"
            onClose={popupState.close}
          />
          {isLoading && <Box mb={1}><LinearProgress/></Box>}

          <FormProvider {...methods}>
            <Box
              component="form"
              onSubmit={methods.handleSubmit(onSubmit)}
              sx={{
                padding: '12px',
              }}
            >
              <SelectOption gridRef={gridRef}/>

              <Stack mb={3}>
                <Typography
                  variant='B4'
                  sx={{
                    color: theme.palette.themeColors.grey[110],
                  }}
                >
                  Export Format
                </Typography>
                <ToggleButtonsFormField
                  name="exportFormat"
                  options={[
                    {label: 'CSV', value: 'csv'},
                    {label: 'XLSX', value: 'xlsx'},
                  ]}
                />
              </Stack>

              <Stack mb={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    marginBottom: '6px',
                  }}
                >
                  <Typography variant="B1">
                    Fields
                  </Typography>
                  <Checkbox
                    onClick={(event) => {
                      event.stopPropagation();
                      handleToggleAll();
                    }}
                    checked={areAllFieldsChecked}
                    indeterminate={
                      !areAllFieldsChecked && numberOfChecked !== 0
                    }
                    // disabled={allFields.length === 0}
                    inputProps={{
                      'aria-label': 'all items selected',
                    }}
                  />
                </Stack>

                {/* <Typography */}
                {/*  variant="B4" */}
                {/*  sx={{ */}
                {/*    color: theme.palette.themeColors.grey[110], */}
                {/*    fontWeight: 400, */}
                {/*  }}> */}
                {/*  Fields available on current table view */}
                {/* </Typography> */}
              </Stack>


              {Object.entries(fields).map(([group, groupFields]) => {
                const fieldsList = groupFields.map((field) => (field.field))
                return (
                  <FieldsList key={group} title={group} fields={fieldsList}/>
                )
              })}

              <Stack direction="row" mb={1}>
                <Typography variant="B4" mr='6px' color={theme.palette.themeColors.grey['110']}>
                  Save as Template
                </Typography>
                <HelpToolTip title="Save as Template"/>
              </Stack>

              {methods.formState.errors.fields && (
                <FormHelperText
                  style={{color: theme.palette.error.main}}>{methods.formState.errors.fields.root?.message}</FormHelperText>
              )}
              {methods.formState.errors.root?.exportOption && (
                <FormHelperText
                  style={{color: theme.palette.error.main}}>{methods.formState.errors.root?.exportOption.message}</FormHelperText>
              )}

              <Stack direction="row" spacing={1} sx={{borderTop: '0.5px solid #E2E0DD', padding: '12px 12px 0px 12px'}}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={popupState.close}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isPending || !methods.formState.isValid}
                  fullWidth
                >
                  Export
                </Button>
              </Stack>

            </Box>
          </FormProvider>
        </Paper>
      </Popper>

      <FileDownloadDialog
        isFileLoading={isFileLoading}
        exportStatusError={exportStatusError}
        downloadLink={downloadLink}
        dialogStateHandler={fileLoadingDialog}
      />
    </>
  );
}

export default ExportToolBar;

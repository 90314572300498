import {useEffect, useState} from 'react';
import {DragDropContext, Droppable} from '@hello-pangea/dnd';
import {Box, Button, Stack, Typography,} from '@mui/material';
import {ColDef} from "@ag-grid-community/core";
import ColumnsList
  from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ColumnsToolBar/ColumnsList";
import useColumnsState
  from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ColumnsToolBar/useColumnsState";
import {useTheme} from "@mui/material/styles";
import HelpToolTip from "shared/components/DS/HelpToolTip";
import {ReactComponent as LupeIcon} from "assets/iconsDS/text-field-loupe.svg";
import DSTextField from "shared/components/DS/DSTextField";

interface ColumnsToolBarProps {
  gridRef: any;
}

const validateInput = (input: string) => {
  const unsafeCharacters = /[#$%&*<>]/;
  return !unsafeCharacters.test(input);
};

// @ts-ignore
const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ColumnsListTab = ({gridRef}: ColumnsToolBarProps) => {
  const theme = useTheme();

  const [items, setItems] = useState<ColDef[]>([]);
  const [filterText, setFilterText] = useState('');

  const {saveState, templateName, setTemplateName} = useColumnsState({gridRef});

  // @ts-ignore
  const onDragEnd = (result) => {
    if (!result.destination || !items || result.destination.index === result.source.index) {
      return;
    }
    const movedItem = items[result.source.index];
    gridRef.current.api.moveColumn(movedItem.field, result.destination.index);
    const cols = reorder(items, result.source.index, result.destination.index);
    setItems(cols);
  }

  useEffect(() => {
    if (gridRef.current?.api && items.length === 0) {
      const defs = gridRef.current.api.getColumnDefs();
      setItems(defs);
    }
  }, [gridRef.current?.api]);

  const filteredItems = items.filter((item) =>
    item.headerName?.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <>
      <DSTextField
        placeholder="Search for column"
        variant="outlined"
        value={filterText}
        startIcon={<LupeIcon/>}
        onChange={({target: {value}}) => {
          if (validateInput(value)) {
            setFilterText(value);
          }
        }}
      />
      <Box
        sx={{
          maxHeight: '400px',
          overflow: 'auto',
          marginTop: 1.5,
          marginBottom: 3,
          padding: 2,
          borderRadius: 1,
          border: `1px solid ${theme.palette.themeColors.grey[40]}`
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="columns-list">
            {provided => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                <ColumnsList columns={filteredItems} gridRef={gridRef}/>
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      <Stack direction="row" mb={1} spacing={1}>
        <Typography variant="B4" color={theme.palette.themeColors.grey['110']}>
          Save as Template
        </Typography>
        <HelpToolTip title="Save as Template"/>
      </Stack>

      <DSTextField
        placeholder="ex. Template Name"
        variant="outlined"
        value={templateName}
        onChange={setTemplateName}
        sx={{
          mb: 1.5
        }}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={saveState}
      >
        Save Template
      </Button>
    </>
  );
}

export default ColumnsListTab;

import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Typography} from "@mui/material";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCreateNote} from "apps/transactions/pages/TransactionDetails/partials/tabs/Notes/useDataMutations";
import {useParams} from "react-router-dom";
import TextFormField from "shared/components/formFields/TextFormField";
import {useTransactionNotes} from "apps/transactions/pages/TransactionDetails/partials/tabs/Notes/useDataQueries";
import NoteDetails from "apps/transactions/pages/TransactionDetails/partials/tabs/Notes/NoteDetails";
import {useTheme} from "@mui/material/styles";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {ReactComponent as ExpandMoreIcon} from "assets/iconsDS/ExpandMoreIcon.svg";
import {ReactComponent as ExpandLessIcon} from "assets/iconsDS/ExpandLessIcon.svg";
import LoadingButton from "@mui/lab/LoadingButton";

const schema = yup.object().shape({
  note: yup.string()
    .required('Field is required')
    .min(1, 'Note cannot be empty')
    .max(500, 'Note cannot be longer than 500 characters')
    .matches(/^[\w !#$&'()*+,.:@-]*$/, 'Character(s) invalid or not allowed.'),
});

type FormData = yup.InferType<typeof schema>;

const defaultValues: FormData = {
  note: ''
};

interface NotesProps {
  setCount: any;
}

const Notes = forwardRef(({setCount}: NotesProps, ref) => {
  const {transactionId} = useParams()

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => setIsExpanded(!isExpanded);

  const theme = useTheme();

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  })

  const {data} = useTransactionNotes()

  const {mutate, isPending} = useCreateNote({reset: methods.reset})

  const onSubmit = (formData: FormData) => {
    mutate({
      txn: transactionId || '',
      requestBody: {
        note: formData.note
      }
    })
  }

  const handleOnCancel = () => {
    methods.reset(methods.formState.defaultValues);
  }

  const innerRef = useRef<HTMLDivElement | null>(null);

  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      if (innerRef.current) {
        innerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
        const rect = innerRef.current?.getBoundingClientRect();
        if (rect) {
          window.scrollBy({ top: rect.top - 70, behavior: 'smooth' });
        }
      }
    },
  }), []);

  useEffect(() => {
    if (data?.items) {
      setCount(data.items.length);
    }
  }, [data, setCount]);

  return (
    <Box sx={{width: "100%", marginTop: "2rem"}} ref={innerRef}>
      <Typography variant='h4' fontWeight={600}>
        Notes
      </Typography>
      <Typography variant='B4' color={theme.palette.themeColors.grey['110']} fontWeight={400}>
        Add a new note
      </Typography>

      <Box sx={{margin: '20px 0px'}}>
        <FormProvider {...methods}>
          <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
            <TextFormField minRows={4.7} variant='filled' name='note' placeholder='What’s on the agenda...' multiline/>
            <Box sx={{marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '12px'}}>
              <Button variant='outlined' color='secondary' size='large' onClick={handleOnCancel}>Cancel</Button>
              <LoadingButton
                loading={isPending}
                loadingIndicator="Saving…"
                variant="contained"
                color='primary'
                size='large'
                type="submit"
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </FormProvider>
      </Box>

      {data?.items?.length !== 0 && (
        <Accordion expanded={isExpanded} onChange={toggleAccordion}>
          <AccordionSummary
            expandIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
              <Typography variant='h5' color={theme.palette.themeColors.grey['90']}>
                Previous notes
              </Typography>
              <Chip variant='secondaryBadge' label={data?.items?.length}/>
            </Box>
          </AccordionSummary>
          {data?.items?.map((item) => (
            <AccordionDetails sx={{borderTop: '1px solid', borderColor: theme.palette.themeColors.grey['40'], padding: '16px'}}>
              <NoteDetails item={item} key={item._id} />
            </AccordionDetails>
          ))}
        </Accordion>
      )}

    </Box>
  )
})

export default Notes;
import {FormControlLabel, Switch} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";

type SwitchFieldsProps = {
  name: string,
  label?: string
}

const SwitchFormField = ({name, label = ''}: SwitchFieldsProps) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <FormControlLabel
          value="top"
          control={
            <Switch
              {...field}
              checked={Boolean(field.value)}
              id={`${name}-field`}
              size="small"
              color="primary"
              inputProps={{'aria-label': 'controlled'}}
            />
          }
          label={label}
          labelPlacement="top"
        />
      )}
    />
  );
};

export default SwitchFormField;

import {DialogStateHandler} from "shared/hooks/useDialogState";
import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIconButton from "shared/CloseButton";

interface UniversalDialogProps {
  dialogStateHandler: DialogStateHandler;
  title: string;
  children?: React.ReactNode
  onSubmit?: (data: any) => void
  onCancel?: () => void
  onSubmitClick?: () => void
  actionsComponent?: React.ReactNode
  cancelButtonText?: string
  submitButtonText?: string
  isPending?: boolean,
  isSubmitDisabled?: boolean,
  submitColor?: 'primary' | 'error',
  dialogSX?: object,
}

const UniversalDialog = ({
  dialogStateHandler,
  title,
  children,
  onSubmit,
  onCancel,
  onSubmitClick,
  actionsComponent,
  cancelButtonText = 'Cancel',
  submitButtonText = 'Submit',
  isPending = false,
  isSubmitDisabled = false,
  submitColor = 'primary',
  dialogSX = {},
}: UniversalDialogProps) => {
  const content = (
    <>
      <DialogTitle>{title}</DialogTitle>
      <CloseIconButton onClick={dialogStateHandler.closeDialog}/>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        {actionsComponent || (
          <>
            {cancelButtonText && (
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                onClick={onCancel || dialogStateHandler.closeDialog}
                fullWidth
              >
                {cancelButtonText}
              </Button>
            )}

            {submitButtonText && (
              <LoadingButton
                loading={isPending}
                variant="contained"
                color={submitColor}
                loadingIndicator="Submiting…"
                type="submit"
                size="medium"
                fullWidth
                onClick={onSubmitClick}
                disabled={isSubmitDisabled}
              >
                {submitButtonText}
              </LoadingButton>
            )}
          </>
        )}
      </DialogActions>
    </>
  )
  return (
    <Dialog
      open={dialogStateHandler.isOpen}
      onClose={dialogStateHandler.closeDialog}
      sx={dialogSX}
      fullWidth
    >
      {onSubmit ? (
        <form onSubmit={onSubmit}>
          {content}
        </form>
      ) : (
        content
      )}
    </Dialog>

  )
}

export default UniversalDialog;

import {useParams} from "react-router-dom";
import {useEffect} from "react";
import AuthFormsContainer from "apps/auth/components/AuthFormsWrapper";
import {Alert, Box, CircularProgress, Typography} from "@mui/material";
import {useEmailVerification} from "apps/users/pages/edit/partials/VerifyEmail/useMutations";

function VerifyUserEmail() {
  const {verificationCode, userId, email} = useParams();

  const {mutate, isPending} = useEmailVerification()

  useEffect(() => {
    if (verificationCode && userId && email){
      mutate({verificationCode, userId, email})
    }
  }, [verificationCode, userId, email])

  if (!verificationCode || !userId || !email) {
    return (
      <AuthFormsContainer>
        <Box sx={{textAlign: "center", my: 3}}>
          <Alert severity="error">Invalid verification link</Alert>
        </Box>
      </AuthFormsContainer>
    )
  }

  return (
    <AuthFormsContainer>
      <Box sx={{textAlign: "center", my: 3}}>
        {isPending && (
          <>
            <CircularProgress/>
            <Typography>User verification...</Typography>
          </>
        )}
      </Box>
    </AuthFormsContainer>
  )

}

export default VerifyUserEmail;

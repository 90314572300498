import { CustomCellRendererProps } from "@ag-grid-community/react";
import {Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

import RoutePath from "routes/utils";
import palette from "theme/palette";

interface RouteRendererArgs {
  uiPath: RoutePath;
  pathParamName: string;
}

const RouteRenderer = ({uiPath, pathParamName}: RouteRendererArgs) => (params: CustomCellRendererProps) => {
  if (params.value === null) {
    return null;
  }

  return (
    <Link
      component={RouterLink}
      to={uiPath.reverse({[pathParamName]: params.value})}
      sx={{
        color: palette.primary.main,
      }}
    >
      {params.value}
    </Link>
  );
}

export default RouteRenderer;

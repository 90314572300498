import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {useFieldArray, useFormContext} from "react-hook-form";
import FileFormField from "shared/components/formFields/FileFormField";

interface DocumentFieldArrayProps {
  arrayName: string;
  fieldName: string;
  sectionName: string;
  groupsCount: number;
  handleRemoveVisibleGroup: (field: string) => void;
}

const DocumentFieldArray = ({
  arrayName,
  fieldName,
  sectionName,
  groupsCount,
  handleRemoveVisibleGroup
}: DocumentFieldArrayProps) => {
  const methods = useFormContext();
  const {fields, append, remove} = useFieldArray({
    control: methods.control,
    name: `fields.${arrayName}`,
  });

  return (
    <Box>

      <Stack
        direction={{xs: 'column', sm: 'row'}}
        spacing={{xs: 1, sm: 2, md: 4}}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">{sectionName}</Typography>
        <Box>
          <Button onClick={() => append({[fieldName]: null})}>Add more</Button>
          {groupsCount > 1 && (
            <Button onClick={() => handleRemoveVisibleGroup(fieldName)}>Remove Group</Button>
          )}
        </Box>
      </Stack>
      <Grid container spacing={2}>
        {fields.map((item, index) => (
          <Grid item key={item.id}>
            <FileFormField
              name={`fields.${arrayName}.${index}.${fieldName}`}
              label="Add File"
              accept={{
                'image/*': ['.jpg', '.jpeg', '.png'],
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'video/mp4': ['.mp4'],
              }}
              supportedFiles="JPG (JPEG), PNG, PDF, DOC, DOCX, MP4"
            />
            {index > 0 && (
              <Button color="warning" type="button" onClick={() => remove(index)}>Delete</Button>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DocumentFieldArray;

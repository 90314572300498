import {Components, Theme} from "@mui/material/styles";

const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
  styleOverrides: {
    root: ({theme}) => ({
      '& path, & rect': {
        stroke: theme.palette.themeColors.grey['110'],
      }
    }),
  }
}

export default MuiListItemIcon;

import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/monokai-sublime.css';

hljs.registerLanguage('json', json);

const HLJson = ({data}: {data: object | undefined}) => {
  const jsonData = JSON.stringify(data, null, 2);

  const highlightedCode = jsonData ? hljs.highlight(jsonData, { language: 'json' }) : "";

  if (!highlightedCode) return null;

  return (
    <pre>
      {/* eslint-disable-next-line react/no-danger */}
      <code className="hljs" dangerouslySetInnerHTML={{ __html: highlightedCode.value }} />
    </pre>
  );
};

export default HLJson;

import {DialogStateHandler} from "shared/hooks/useDialogState";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import CloseIconButton from "shared/CloseButton";
import TextFormField from "shared/components/formFields/TextFormField";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import SelectFormField from "shared/components/formFields/SelectFormField";
import PhoneFormField from "shared/components/formFields/PhoneFormField";
import LoadingButton from "@mui/lab/LoadingButton";
import {emailValidator, phoneValidator} from "shared/schemas";
import MidAutocompleteFormField from "shared/components/formDataFields/MidAutocompleteFormField";
import useMerchantDirectoryContacts from "apps/merchantDirectory/pages/create/useDataMutations";

interface CreateMerchantDirectoryDialogProps {
  dialogStateHandler: DialogStateHandler
}

const schema = yup.object().shape({
  mid: yup.string().required('This field is required.'),
  contactType: yup.string().required('This field is required.'),
  name: yup.string().matches(/^[\d A-Za-z-]*$/, 'Name can\'t contain special symbols').max(100).required('This field is required.'),
  title: yup.string().matches(/^[\d A-Za-z-]*$/, 'Title can\'t contain special symbols').max(100).required('This field is required.'),
  email: emailValidator,
  phone: phoneValidator,
  username: yup.string().max(45).test(
    'username',
    'Username is required when App Name is provided.',
    function usernameTest(value) {
      const { appName } = this.parent;
      return appName ? !!value : true;
    }
  ),
  appName: yup.string().max(45).test(
    'appName',
    'App Name is required when Username is provided.',
    function appNameTest(value) {
      const { username } = this.parent;
      return username ? !!value : true;
    }
  ),
  additionalNotes: yup.string().matches(/^[\w !(),.?-]*$/ , 'Additional notes can\'t contain special symbols').max(255),
})

type FormData = yup.InferType<typeof schema>;

const contactTypes = [
  {
    value: 'primary',
    label: 'Primary',
  },
  {
    value: 'secondary',
    label: 'Secondary',
  },
  {value: 'accounting_team',
    label: 'Accounting team',
  },
  {
    value: 'authorized_signatory',
    label: 'Authorized signatory',
  },
  {
    value: 'compliance_officer',
    label: 'Compliance officer',
  },
  {
    value: 'customer_support',
    label: 'Customer support',
  },
  {
    value: 'customer_support_department_head',
    label: 'Customer support department head',
  },
  {
    value: 'director',
    label: 'Director',
  },
  {
    value: 'escalations',
    label: 'Escalations',
  },
  {
    value: 'finance_department_head',
    label: 'Finance department head',
    'bullet': 'success'
  },
  {
    value: 'integrations_team',
    label: 'Integrations team',
    'bullet': 'error'
  },
  {
    value: 'legal_contact',
    label: 'Legal contact',
  },
  {
    value: 'management',
    label: 'Management',
  },
  {
    value: 'officer',
    label: 'Officer',
  },
  {
    value: 'payments_department_head',
    label: 'Payments department head',
  },
  {
    value: 'payments_transactions_team',
    label: 'Payments / transactions team',
  },
  {
    value: 'risk_fraud',
    label: 'Risk / fraud',
  },
  {
    value: 'settlements_billing_treasury',
    label: 'Settlements / billing / treasury',
  },
  {
    value: 'technical_department_head',
    label: 'Technical department head',
  },
  {
    value: 'technical_support',
    label: 'Technical support',
  },
  {
    value: 'other',
    label: 'Other',
  }
]

const CreateMerchantDirectoryDialog = ({dialogStateHandler}: CreateMerchantDirectoryDialogProps) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      mid: '',
      contactType: '',
      name: '',
      title: '',
      email: '',
      phone: '',
      username: '',
      appName: '',
      additionalNotes: '',
    }
  });

  const {mutate, isPending, phoneErrorMessage, emailErrorMessage} = useMerchantDirectoryContacts({dialogHandler: dialogStateHandler, reset: methods.reset})

  const handleReset = () => {
    methods.reset(methods.formState.defaultValues);
  }

  const onSubmit = (data: FormData) => {
    mutate({requestBody: {
      mid: data.mid,
      contact_type: data.contactType,
      name: data.name,
      title: data.title,
      email: data.email,
      phone: data.phone,
      username: data.username,
      app_name: data.appName,
      additional_notes: data.additionalNotes,
    }})
  }

  const handleOnClose = () => {
    dialogStateHandler.closeDialog();
    methods.reset(methods.formState.defaultValues);
  }

  return (
    <Dialog open={dialogStateHandler.isOpen} onClose={dialogStateHandler.closeDialog} fullWidth maxWidth='md'>
      <FormProvider {...methods}>
        <Box component='form' sx={{width: '100%'}} onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="modal-dialog-title">
            Create new contact
          </DialogTitle>
          <CloseIconButton onClick={handleOnClose}/>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <MidAutocompleteFormField name="mid"/>
              </Grid>
              <Grid item md={6}>
                <SelectFormField name="contactType" label="Type" options={contactTypes}/>
              </Grid>
              <Grid item md={8}>
                <TextFormField name="name" placeholder="Name"/>
              </Grid>
              <Grid item md={4}>
                <TextFormField name="title" placeholder="Title"/>
              </Grid>
              <Grid item md={6}>
                <TextFormField name="email" placeholder="Email"/>
              </Grid>
              <Grid item md={6}>
                <PhoneFormField name="phone" placeholder="Phone"/>
              </Grid>
            </Grid>

            <Divider sx={{mt: 2, mb: 2}}>Messaging</Divider>

            <Grid container spacing={2}>
              <Grid item md={12}>
                <Box display='flex'>
                  <Box width='100%'>
                    <TextFormField name="username" placeholder="Username"/>
                  </Box>
                  <Box ml={2} width='100%'>
                    <TextFormField name="appName" placeholder="App name"/>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={12}>
                <TextFormField multiline name="additionalNotes" placeholder="Additional Notes"/>
              </Grid>
            </Grid>
            {phoneErrorMessage && <Alert sx={{mt: 1}} severity="error">{phoneErrorMessage}</Alert>}
            {emailErrorMessage && <Alert sx={{mt: 1}} severity="error">{emailErrorMessage}</Alert>}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleReset}>Reset</Button>
            <LoadingButton
              loading={isPending}
              variant="contained"
              loadingIndicator="Submitting…"
              type="submit"
              disabled={!methods.formState.isDirty || !methods.formState.isValid}
              sx={{width: 130}}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default CreateMerchantDirectoryDialog;

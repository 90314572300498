import {ISidebarMenuItem} from "layouts/MainLayout/partials/Sidebar/menuItems";
import {ListItemButton, ListItemIcon, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {styled, useTheme} from "@mui/material/styles";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow={false} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: '2px 8px',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '4px',
    background: theme.palette.common.white,
    boxShadow: '0px 3px 1px 0px rgba(28, 41, 59, 0.02), 0px 3px 10px 0px rgba(7, 10, 18, 0.08)',
    color: theme.palette.themeColors.grey['100'],
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: 500,
  },
  [`& .MuiTooltip-tooltipPlacementRight`]: {
    marginLeft: '4px !important',
  },
}));

interface SidebarMenuItemProps extends ISidebarMenuItem {
  onClick?: () => void;
}

const SidebarMenuItem = ({icon, name, link, onClick}: SidebarMenuItemProps) => {
  const theme = useTheme();

  const location = useLocation();

  const getBasePath = () => {
    const url = new URL(link, window.location.origin);
    return url.pathname.split('/')[1];
  };

  const linkBasePath = getBasePath();
  const locationBasePath = location.pathname.split('/')[1];

  // const isActive = location.pathname === link;
  const isActive = linkBasePath === locationBasePath;

  return (
    <StyledTooltip
      title={name}
      placement="right"
    >
      <ListItemButton
        component={Link}
        onClick={onClick}
        to={link}
        sx={{
          height: '40px',
          width: '40px',
          justifyContent: "center",
          borderRadius: '8px',
          p: 1,
          '&:hover': {
            background: theme.palette.themeColors.grey["30"],

            '& .MuiListItemIcon-root': {
              color: theme.palette.themeColors.grey["110"],
            }
          },
          ...(isActive && {
            background: theme.palette.themeColors.grey["30"],
            color: theme.palette.themeColors.grey["120"],
            boxShadow: '0px -1px 0px 0px #FFFFFFCC inset, 0px 1px 0px 0px #33250114 inset;',

            '& .MuiListItemIcon-root': {
              color: theme.palette.themeColors.grey["120"],
            }
          }),
        }}
      >
        <ListItemIcon sx={{minWidth: 0, justifyContent: "center"}}>
          {icon}
        </ListItemIcon>
      </ListItemButton>
    </StyledTooltip>
  );
}

export default SidebarMenuItem;

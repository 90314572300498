/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SidPluginDetailsSchema } from '../models/SidPluginDetailsSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PluginsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get sid plugin details
     * Roles: MERCHANT
     * Permissions: CAN_VIEW_PLUGIN_PAGE
     * @returns SidPluginDetailsSchema Success
     * @throws ApiError
     */
    public getPluginSidDetails({
        sid,
        xFields,
    }: {
        sid: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<SidPluginDetailsSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/plugins/{sid}/',
            path: {
                'sid': sid,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
}

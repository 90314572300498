import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {KycRequestQueryKeys} from "shared/queryKeys";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import {useErrorMessage} from "shared/hooks/useErrorMessage";
import {IApiError} from "shared/types";
import {useState} from "react";

type Params = Parameters<typeof ApiClient.kycRequests.postCreateKycRequests>[0]

export const useCreateKYC = (dialogHandler: DialogStateHandler, reset: () => void) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const {errorMessage, handleSetErrorMessage} = useErrorMessage()
  
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState<string | undefined>('');


  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.kycRequests.postCreateKycRequests(params),
    onMutate: () => handleSetErrorMessage(),
    onSuccess: async () => {
      enqueueSnackbar('KYC submitted', {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [KycRequestQueryKeys.LIST]})
      dialogHandler.closeDialog()
      reset()
    },
    onError: async (error: IApiError) => {
      await queryClient.invalidateQueries({queryKey: [KycRequestQueryKeys.LIST]})
      handleSetErrorMessage(error)
      const {zip_code} = error.body.message
      if (zip_code && zip_code.length > 0) {
        setPostalCodeErrorMessage(zip_code[0])
      }
    }
  })

  return {
    mutate,
    isPending,
    errorMessage,
    postalCodeErrorMessage
  }
}
import {useEffect, useState} from "react";
import {ColDef} from "@ag-grid-community/core";
import {Roles} from "contexts/UserAccessContext/const";
import useCurrentUser from "shared/hooks/useCurrentUser";
import PaymentTypeRenderer from "shared/components/AGGridRenderers/TypeRenderer";
import StatusOfTransactionRenderer from "shared/components/AGGridRenderers/StatusOfTransactionRenderer";
import MethodRenderer from "shared/components/AGGridRenderers/MethodRenderer";
import SiteRenderer from "shared/components/AGGridRenderers/SiteRenderer";
import DateRenderer from "shared/components/AGGridRenderers/DateRenderer";
import TransactionIDRenderer from "shared/components/AGGridRenderers/TransactionIDRenderer";
import AmountRenderer from "shared/components/AGGridRenderers/AmountRenderer";
import RouteRenderer from "shared/components/AGGridRenderers/RouteRenderer";
import uiPaths from "routes/uiPaths";
import PhoneRenderer from "shared/components/AGGridRenderers/PhoneRenderer";
import {
  methodsList,
  statusList,
  transactionTypesList
} from "apps/transactions/pages/TransactionsList/useFilters/FilterComponent";

const types = transactionTypesList.map(({value}) => value);
const methods = methodsList.map(({value}) => value);
const statuses = statusList.map(({value}) => value);

const useColumnsConfig = () => {
  const {user} = useCurrentUser();

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    const baseColumns: ColDef[] = [
      {
        headerName: 'Type',
        field: 'type',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: types
        },
        sortable: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        cellRenderer: PaymentTypeRenderer,
        minWidth: 130,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Status',
        field: 'transaction_status',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: statuses
        },
        sortable: true,
        cellRenderer: StatusOfTransactionRenderer,
        minWidth: 170,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Amount',
        field: 'amount',
        filter: 'agNumberColumnFilter',
        sortable: true,
        cellRenderer: AmountRenderer,
        minWidth: 150,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Method',
        field: 'method',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: methods
        },
        sortable: true,
        cellRenderer: MethodRenderer,
        minWidth: 130,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Transaction ID',
        field: 'txn',
        filter: 'agTextColumnFilter',
        sortable: true,
        minWidth: 160,
        cellRenderer: TransactionIDRenderer,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Email',
        field: 'email',
        filter: 'agTextColumnFilter',
        sortable: true,
        cellRenderer: RouteRenderer({uiPath: uiPaths.customerPaths.detail, pathParamName: 'customerEmail'}),
        minWidth: 250,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Site',
        field: 'site_name',
        filter: 'agTextColumnFilter',
        sortable: true,
        cellRenderer: SiteRenderer,
        minWidth: 180,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Date',
        field: 'status_timestamp',
        filter: 'agTextColumnFilter',
        sortable: true,
        cellRenderer: DateRenderer,
        minWidth: 185,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Merchant Reference (UDF)',
        field: 'udfs',
        hide: true,
        filter: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Merchant Name',
        field: 'merchant_name',
        filter: 'agTextColumnFilter',
        sortable: true,
        hide: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Currency',
        field: 'currency_code',
        filter: 'agTextColumnFilter',
        sortable: true,
        hide: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'First Name',
        field: 'first_name',
        filter: true,
        sortable: true,
        hide: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Last Name',
        field: 'last_name',
        filter: true,
        sortable: true,
        hide: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Phone',
        field: 'phone',
        filter: 'agTextColumnFilter',
        sortable: true,
        hide: true,
        cellRenderer: PhoneRenderer,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Street Address',
        field: 'address',
        filter: 'agTextColumnFilter',
        sortable: true,
        hide: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Postal/ZIP Code',
        field: 'zip_code',
        filter: 'agTextColumnFilter',
        sortable: true,
        hide: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'City',
        field: 'city',
        filter: 'agTextColumnFilter',
        sortable: true,
        hide: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Province/State',
        field: 'state',
        filter: 'agTextColumnFilter',
        sortable: true,
        hide: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Country',
        field: 'country',
        filter: 'agTextColumnFilter',
        sortable: true,
        hide: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'IP Address',
        field: 'ip_address',
        filter: 'agTextColumnFilter',
        sortable: true,
        hide: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      }
    ];

    const roleColumns = (role: Roles): ColDef[] => {
      switch (role) {
      case Roles.SUPER_ADMIN:
      case Roles.ADMIN:
      case Roles.MANAGER: {
        return [
          {headerName: 'MID', field: 'mid', hide: true,},
          {headerName: 'RID', field: 'rid', hide: true,},
          {headerName: 'SID', field: 'sid', hide: true,}
        ]
      }
      case Roles.MERCHANT: {
        return [
          {headerName: 'RID', field: 'rid', hide: true,},
          {headerName: 'SID', field: 'sid', hide: true,}
        ]
      }
      case Roles.RETAILER: {
        return [
          {headerName: 'SID', field: 'sid', hide: true,}
        ]
      }
      default: {
        return [];
      }
      }
    };

    setColumnDefs([...baseColumns, ...roleColumns(user?.role as Roles)]);
  }, [user?.role]);

  return {
    columnDefs,
  }
}

export default useColumnsConfig;

import {styled, useTheme} from "@mui/material/styles";
import {Box, IconButton, Stack} from "@mui/material";
import {bindTrigger, PopupState} from "material-ui-popup-state/hooks";

const BlackDot = styled(Box)(() => ({
  width: '3px',
  height: '3px',
  borderRadius: '50%',
}));
interface DetailsIconButtonProps {
  popupState: PopupState;
}

const DetailsIconButton = ({popupState}: DetailsIconButtonProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme()

  return (
    <IconButton
      aria-haspopup="true"
      sx={{
        padding: '12px',
        border: popupState.isOpen ? `1px solid ${theme.palette.themeColors.purple[20]}` : `1px solid ${theme.palette.themeColors.grey[50]}`,
        backgroundColor: popupState.isOpen ? '#fff' : '#fff',

        '&:hover': {
          backgroundColor: '#fff',
          outline: popupState.isOpen ? `3px solid rgba(129, 69, 181, 0.10)` : `3px solid ${theme.palette.themeColors.grey[30]}`,

          '.dot': {
            backgroundColor: theme.palette.themeColors.grey['110'],
          }
        },

        '&:active': {
          backgroundColor: theme.palette.themeColors.grey[30],
          borderColor: theme.palette.themeColors.grey[60],

          '&:hover': {
            outline: 'none'
          },

          '.dot': {
            backgroundColor: theme.palette.themeColors.grey[120],
          }
        },

        '.dot': {
          backgroundColor: popupState.isOpen ? theme.palette.themeColors.grey['120'] : theme.palette.themeColors.grey['90'],
        },
      }}
      {...bindTrigger(popupState)}
    >
      <Stack
        direction="row"
        sx={{width: '16px', height: '16px', gap: '3px'}}
        alignItems="center"
      >
        <BlackDot className='dot'/>
        <BlackDot className='dot'/>
        <BlackDot className='dot'/>
      </Stack>
    </IconButton>
  )
}

export default DetailsIconButton;
export enum AccountQueryKeys {
  CURRENT_USER = 'CURRENT_USER',
  ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
  TWO_FA_METHODS = 'TWO_FA_METHODS',
}

export enum TransactionQueryKeys {
  LIST = 'TRANSACTION_LIST',
  DETAILS = 'TRANSACTION_DETAILS',
  VIRTUAL_TERMINAL_LIST = 'VIRTUAL_TERMINAL_LIST',
  ITEMS = 'TRANSACTION_ITEMS',
  RELATED_TRANSACTIONS = 'RELATED_TRANSACTIONS',
  NOTES = 'TRANSACTION_NOTES',
  TIMELINE = 'TRANSACTION_TIMELINE',
  API_LOOKUP = 'TRANSACTION_API_LOOKUP',
}

export enum UserQueryKeys {
  LIST = 'USER_LIST',
  DETAILS = 'USER_DETAILS',
}

export enum CustomerQueryKeys {
  LIST = 'CUSTOMER_LIST',
  RECENT_ORDERS = 'CUSTOMER_RECENT_ORDERS',
  INFORMATION = 'CUSTOMER_INFORMATION',
  ACCOUNTS = 'CUSTOMER_ACCOUNTS',
  BLACKLIST = 'CUSTOMER_BLACKLIST',
  MERCHANTS = 'CUSTOMER_MERCHANTS',
  RETAILERS = 'CUSTOMER_RETAILERS',
  SITES = 'CUSTOMER_SITES',
  TOP_MERCHANTS = 'CUSTOMER_TOP_MERCHANTS',
  DETAILS = 'CUSTOMER_DETAILS',
  CHART = 'CUSTOMER_CHART',
  NOTES = 'CUSTOMER_NOTES',
  ALIASES = 'CUSTOMER_ALIASES',
  NAME_MATCHING_ALIASES = 'CUSTOMER_NAME_MATCHING_ALIASES',
  OVERVIEW = 'CUSTOMER_OVERVIEW',
  TRANSACTIONS = 'CUSTOMER_TRANSACTIONS',
  FINANCIAL_DETAILS = 'CUSTOMER_FINANCIAL_DETAILS',
  FINANCIAL_INSTITUTION_SUMMARY = 'FINANCIAL_INSTITUTION_SUMMARY',
  RELATED_CUSTOMERS = 'RELATED_CUSTOMERS',
}

export enum VirtualTerminalQueryKeys {
  SITES = 'VIRTUAL_TERMINAL_SITES',
  PAYMENT_METHODS = 'VIRTUAL_TERMINAL_PAYMENT_METHODS',
}

export enum RecentEventsKeys {
  EVENTS = 'RECENT_EVENTS',
}

export enum KycRequestQueryKeys {
  LIST = 'KYC_REQUEST_LIST',
  DETAILS = 'KYC_REQUEST_DETAILS',
}

export enum StatisticsQueryKeys {
  STATISTICS_BREAKDOWN = 'STATISTICS_BREAKDOWN',
  SITE_BREAKDOWN = 'SITE_BREAKDOWN',
  DATE_BREAKDOWN_PAYMENTS = 'DATE_BREAKDOWN_PAYMENTS',
}

export enum ActionCenterQueryKeys {
  LIST = 'ACTION_CENTER_LIST',
  DETAILS = 'ACTION_CENTER_DETAILS',
}

export enum AutocompleteQueryKeys {
  SID = 'SID_AUTOCOMPLETE',
  MID = 'MID_AUTOCOMPLETE',
  RID = 'RID_AUTOCOMPLETE',
}

export enum ConfigsQueryKeys {
  CHARGEBACK_REASONS = 'CHARGEBACK_REASONS',
  REFUND_REASONS = 'REFUND_REASONS',
  USER_ROLES = 'USER_ROLES',
  USER_PERMISSIONS = 'USER_PERMISSIONS',
}

export enum NotificationsQueryKeys {
  LIST = 'NOTIFICATIONS_LIST',
  DETAILS = 'NOTIFICATIONS_DETAILS',
}
export enum PluginQueryKeys {
  LIST = 'PLUGIN_LIST',
  DETAILS = 'PLUGIN_DETAILS',
}

export enum MerchantQueryKeys {
  LIST = 'MERCHANT_LIST',
  DIRECTORY_CONTACTS = 'MERCHANT_DIRECTORY_CONTACTS',
  LIMITS_TXN = 'MERCHANT_LIMITS_TXN',
}

export enum DashboardQueryKeys {
  LIST = 'DASHBOARD_LIST',
}

export enum UserLimitsQueryKeys {
  LIST = 'USER_LIMITS_LIST',
  DETAILS = 'USER_LIMITS_DETAILS',
}

export enum ExportQueryKeys {
  TRANSACTIONS_FIELDS = 'TRANSACTIONS_FIELDS',
  AGGRID_COLUMNS = 'AGGRID_COLUMNS',
}

import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {UserLimitsQueryKeys} from "shared/queryKeys";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import {IApiError} from "shared/types";

type Params = Parameters<typeof ApiClient.userLimits.createUserLimitOrBlacklist>[0]

interface createUserLimitOrBlacklistApiUserLimitsPostAttrs {
  dialogHandler: DialogStateHandler;
  reset: () => void;
  handleSetPhoneValidationErrorMessage: (message: string) => void;
}

const useCreateUserLimitOrBlacklist = ({
  dialogHandler,
  reset,
  handleSetPhoneValidationErrorMessage
}: createUserLimitOrBlacklistApiUserLimitsPostAttrs) => {
  const {enqueueSnackbar} = useSnackbar()

  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.userLimits.createUserLimitOrBlacklist(params),
    onSuccess: async () => {
      handleSetPhoneValidationErrorMessage('')
      await queryClient.invalidateQueries({queryKey: [UserLimitsQueryKeys.LIST]})
      dialogHandler.closeDialog()
      enqueueSnackbar('User limit/blacklisted entry created successfully.', {variant: 'success'})
      reset()
    },
    onError: (error: IApiError) => {
      const {errors} = error.body
      if (errors && errors.length > 0 && errors[0].phone) {
        handleSetPhoneValidationErrorMessage('Invalid phone number')
      }
    }
  })

  return {
    mutate,
    isPending,
  }
}

export default useCreateUserLimitOrBlacklist;

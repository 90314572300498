import {useTheme} from "@mui/material/styles";
import {Box, Stack, Typography} from "@mui/material";
import {fCurrency} from "shared/utils";
import {CustomerOverview, FirstTransaction, LatestInfo, TransactionBreakdown, TransactionType} from "generatedTypes";
import ContactInfo from "apps/customers/pages/CustomerDetails/partials/CustomerInfo/CustomerContactInfo";
import {calculateTimeRange} from "shared/utils/dateFormats";
import {AnimatedBox} from "shared/styled/AnimatedBox";
import {WrapperBox} from "shared/styled/WrapperBox";
import SkeletonCustomerInfo from "apps/customers/pages/CustomerDetails/partials/CustomerInfo/SkeletonCustomerInfo";

const getApprovedBreakdown = (breakdown: TransactionBreakdown[], status: string) =>
  breakdown.find(item => item.status === status)?.amount || 0;

interface CustomerInfoProps {
  paymentBreakdown: TransactionType['breakdown']
  billingAddress: LatestInfo['billing_address']
  address: LatestInfo['address']
  phoneNumber: LatestInfo['phone']
  ipAddress: LatestInfo['ip_address']
  ipAddresses: CustomerOverview['ip_addresses']
  startDate: FirstTransaction['start']
  isLoading: boolean
}

const CustomerInfo = (
  {
    paymentBreakdown, billingAddress, address, phoneNumber, ipAddress, ipAddresses, startDate, isLoading
  }: CustomerInfoProps) => {
  const theme = useTheme()

  if (isLoading) return <SkeletonCustomerInfo/>

  return (
    <WrapperBox>
      <Stack
        direction="row"
        className="parentBox"
        spacing={1}
        sx={{
          height: '16px',
          alignItems: 'flex-end',
        }}
      >
        <AnimatedBox sx={{backgroundColor: '#EBFAF1'}}>
          <Stack className="text" direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#085E2D'}}>
              TOTAL APPROVED
            </Typography>
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#085E2D'}}>
              {fCurrency(getApprovedBreakdown(paymentBreakdown || [], 'approved'))}
            </Typography>
          </Stack>
        </AnimatedBox>
        <AnimatedBox sx={{backgroundColor: '#FFF3DB'}}>
          <Stack className="text" direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#5C3C00'}}>
              PENDING
            </Typography>
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#5C3C00'}}>
              {fCurrency(getApprovedBreakdown(paymentBreakdown || [], 'pending'))}
            </Typography>
          </Stack>
        </AnimatedBox>
        <AnimatedBox sx={{backgroundColor: '#FFE6EA'}}>
          <Stack className="text" direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#710A06'}}>
              DECLINED
            </Typography>
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#710A06'}}>
              {fCurrency(getApprovedBreakdown(paymentBreakdown|| [], 'declined'))}
            </Typography>
          </Stack>
        </AnimatedBox>
      </Stack>

      <Stack gap='8px'>
        <Box marginTop='14px'>
          <ContactInfo
            billingAddress={billingAddress}
            address={address}
            phoneNumber={phoneNumber}
            ipAddress={ipAddress}
            ipAddresses={ipAddresses}
          />
        </Box>

        <Stack direction='row' justifyContent='end'>
          <Typography variant="B7" sx={{textTransform: 'uppercase', color: theme.palette.themeColors.grey['90']}}>
            {calculateTimeRange(new Date(startDate))}
          </Typography>
        </Stack>
      </Stack>
    </WrapperBox>
  )
}

export default CustomerInfo;
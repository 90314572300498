import React from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  styled,
} from '@mui/material'
import { Check } from '@mui/icons-material'

interface AppChooseProps extends ButtonGroupProps {
  separate?: boolean
  items: {
    title: string
    icon?: React.ReactNode
  }[]
  select: string
  setSelect: (value: string) => void
}

interface ButtonProps extends ButtonGroupProps {
  separate?: boolean
}

const StyledButtonGroup = styled((props: ButtonProps) => (
  <ButtonGroup variant='outlined' {...props} />
))(({ theme, separate }) => ({
  '.MuiButton-root': {
    fontWeight: 600,
    textTransform: 'capitalize',
  },

  ...(separate && {
    '& .MuiButton-root': {
      position: 'relative',
      borderRadius: '8px !important',
      borderRightColor: 'inherit !important',

      '& + .MuiButton-root': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
}))

const StyledBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translate(calc(50% - 3px), -50%)',
  width: 15,
  height: 15,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  zIndex: 9,
}))

const AppChoose: React.FC<AppChooseProps> = ({
  separate = false,
  items,
  select,
  setSelect,
  ...props
}) => (
  <StyledButtonGroup separate={separate} {...props}>
    {items.map((item) => (
      <Button
        key={item.title}
        onClick={() => setSelect(item?.title)}
        sx={{
          color:
              select.toLocaleLowerCase() === item?.title?.toLocaleLowerCase()
                ? 'primary.main'
                : 'text.secondary',
          bgcolor:
              select.toLocaleLowerCase() === item?.title?.toLocaleLowerCase()
                ? 'primary.lighter'
                : 'background.paper',
        }}
        startIcon={item?.icon}
      >
        {separate &&
            select.toLocaleLowerCase() === item?.title?.toLocaleLowerCase() && (
          <StyledBadge>
            <Check sx={{ fontSize: 12 }} color='inherit' />
          </StyledBadge>
        )}
        {item?.title}
      </Button>
    ))}
  </StyledButtonGroup>
)

export default AppChoose

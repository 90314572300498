import {Divider, List, ListItem, ListItemText} from "@mui/material";
import {UserDetailsSchema} from "generatedTypes";
import {AppCard} from "shared/components/mui";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";

interface AccountInfoProps {
  data: UserDetailsSchema
}

const AccountInfo = ({data}: AccountInfoProps) => (
  <AppCard
    bodyStyle={{p: 0, m: 0}}
  >
    <List>
      {data.role && (
        <>
          <ListItem>
            <ListItemText
              primary={`Account type: ${data.role.toUpperCase()}`}
            />
          </ListItem>
          <Divider/>
        </>
      )}

      {data.is_active && (
        <>
          <ListItem sx={{ justifyContent: 'start' }}>
            <ListItemText
              primary={`Status: ${data.is_active ? 'Active' : 'Inactive'}`}
            />
          </ListItem>
          <Divider/>
        </>
      )}

      {data.two_fa_enabled && (
        <>
          <ListItem>
            <ListItemText
              primary={`2FA authentication: ${data.two_fa_enabled ? 'Enabled' : 'Disabled'}`}
            />
          </ListItem>
          <Divider/>
        </>
      )}

      {data.timezone && (
        <>
          <ListItem>
            <ListItemText
              primary={`Timezone: ${data.timezone}`}
            />
          </ListItem>
          <Divider/>
        </>
      )}

      {data.created_at && (
        <>
          <ListItem>
            <ListItemText
              primary={`Account Created: ${data.created_at && convertIsoToCustomFormat(data.created_at)}`}
            />
          </ListItem>
          <Divider/>
        </>
      )}

      {data.updated_at && (
        <>
          <ListItem>
            <ListItemText
              primary={`Account Updated: ${data.updated_at && convertIsoToCustomFormat(data.updated_at)}`}
            />
          </ListItem>
          <Divider/>
        </>
      )}

      {data.last_request_timestamp && (
        <>
          <ListItem>
            <ListItemText
              primary={`Last Online: ${data.last_request_timestamp && convertIsoToCustomFormat(data.last_request_timestamp)}`}
            />
          </ListItem>
          <Divider/>
        </>
      )}

      {data.session_timeout && (
        <>
          <ListItem>
            <ListItemText
              primary={`Session Timeout: ${data.session_timeout && convertIsoToCustomFormat(data.session_timeout)}`}
            />
          </ListItem>
          <Divider/>
        </>
      )}

      {data.last_password_created_at && (
        <>
          <ListItem>
            <ListItemText
              primary={`Last Password Updated: ${data.last_password_created_at && convertIsoToCustomFormat(data.last_password_created_at)}`}
            />
          </ListItem>
          <Divider/>
        </>
      )}

    </List>
  </AppCard>
)

export default AccountInfo;
import {useSearchParams} from 'react-router-dom';
import queryString from 'query-string';
import {isEmpty} from "lodash";
// eslint-disable-next-line @typescript-eslint/no-unused-vars

type Params = {
  [key: string]: string | number | string[] | undefined | null;
};

type UseQueryStringReturn = {
  urlParams: Params;
  setQueryString: (params: Params) => void;
  getDefaultValues: (defaultValues: object) => object;
};

const useQueryString = (): UseQueryStringReturn => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.toString();

  const setQueryString = (newParams: Params) => {
    const params = {
      ...Object.fromEntries(new URLSearchParams(window.location.search)),
      ...newParams,
    }
    const cleanedParams = Object.entries(params).reduce((acc: Params, [k, v]) => {
      if (!isEmpty(v) || typeof v === 'number') {
        acc[k] = v;
      }
      return acc;
    }, {});
    setSearchParams(new URLSearchParams(cleanedParams as Record<string, string>));
  };

  const urlParams = queryString.parse(search, {arrayFormat: 'comma'}) as Params;

  const getDefaultValues = (defaultValues: { [key: string]: any }) => {
    const updatedValues = {...defaultValues};
    for (const key in updatedValues) {
      if ( urlParams[key] &&  urlParams[key] !== '') {
        updatedValues[key] = urlParams[key] ;
      }
    }
    return updatedValues;
  }

  return {
    urlParams,
    setQueryString,
    getDefaultValues,
  };
};

export default useQueryString;

import {UserLimitsModel} from "generatedTypes";
import {Box, Button, Chip, Link, TableCell, TableRow, Typography} from "@mui/material";
import {fCurrency} from "shared/utils";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";
import VerifiedChip from "apps/userLimits/pages/list/UserLimitRow/VerifiedChip";
import {truncate} from "lodash";
import useDialogState from "shared/hooks/useDialogState";
import DialogConfirm from "shared/components/DialogConfirm";
import useDeleteUserLimitOrBlacklist from "apps/userLimits/pages/list/UserLimitRow/useDataMutations";
import usePermissions from "apps/userLimits/pages/permissions/usePermissions";
import {Link as RouterLink} from "react-router-dom";
import UiPaths from "routes/uiPaths";
import validator from 'validator';
import UpdateUserLimitOrBlacklistDialog from "apps/userLimits/pages/update";

interface UserLimitRowProps {
  data: UserLimitsModel;
}

const UserLimitRow = ({data}: UserLimitRowProps) => {
  const confirmDialog = useDialogState();
  const updateDialog = useDialogState();

  const {canCreateUpdateDeleteUserLimit} = usePermissions();

  const {mutate} = useDeleteUserLimitOrBlacklist({dialogStateHandler: confirmDialog});

  const handleConfirm = () => {
    mutate({id: data._id})
  }

  return (
    <>
      <TableRow key={data._id}>
        <TableCell>
          {validator.isEmail(data.contact) ? (
            <Link component={RouterLink}
              to={UiPaths.customerPaths.detail.reverse({customerEmail: data.contact})}>{truncate(`${data.contact}`, {
                length: 30,
                omission: '...'
              })}</Link>
          ) : (
            <Typography variant='body2'>{truncate(`${data.contact}`, {length: 30, omission: '...'})}</Typography>
          )}
        </TableCell>
        <TableCell>{data.sid}</TableCell>
        <TableCell>{fCurrency(data.payment_limits?.["24h_limit"]) || fCurrency(0)}</TableCell>
        <TableCell>{fCurrency(data.payment_limits?.["7d_limit"]) || fCurrency(0)}</TableCell>
        <TableCell>{fCurrency(data.payment_limits?.["30d_limit"]) || fCurrency(0)}</TableCell>
        <TableCell>
          {fCurrency(data.payment_limits?.transaction_limit) || fCurrency(0)}
        </TableCell>
        <TableCell>
          <VerifiedChip verified={data.payment_limits?.verified}/>
        </TableCell>

        <TableCell>{fCurrency(data.payout_limits?.["24h_limit"]) || fCurrency(0)}</TableCell>
        <TableCell>{fCurrency(data.payout_limits?.["7d_limit"]) || fCurrency(0)}</TableCell>
        <TableCell>{fCurrency(data.payout_limits?.["30d_limit"]) || fCurrency(0)}</TableCell>
        <TableCell>
          {fCurrency(data.payout_limits?.transaction_limit) || fCurrency(0)}
        </TableCell>
        <TableCell>
          <VerifiedChip verified={data.payout_limits?.verified}/>
        </TableCell>

        <TableCell>
          <Chip label={data.blacklisted ? 'True' : 'False'} color={data.blacklisted ? 'success' : 'error'}/>
        </TableCell>
        <TableCell>
          <Typography variant='body2'>{data.expiry && convertIsoToCustomFormat(data.expiry)}</Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant='body2'>{data.blacklist_status_updated && convertIsoToCustomFormat(data.blacklist_status_updated)}</Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant='body2'>{data?.limits_created && convertIsoToCustomFormat(data.limits_created)}</Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant='body2'>{data?.limits_updated && convertIsoToCustomFormat(data.limits_updated)}</Typography>
        </TableCell>
        <TableCell>
          {canCreateUpdateDeleteUserLimit && (
            <Box display='flex'>
              <Button variant="contained" onClick={confirmDialog.openDialog} sx={{marginRight: 1}}>Delete</Button>
              <Button variant="contained" onClick={updateDialog.openDialog}>Update</Button>
            </Box>
          )}
        </TableCell>

      </TableRow>

      <DialogConfirm
        dialogStateHandler={confirmDialog}
        handleConfirm={handleConfirm}
        title='Are you sure you want to remove this user from the blacklist?'
      />

      <UpdateUserLimitOrBlacklistDialog
        dialogStateHandler={updateDialog}
        data={data}
      />

    </>
  )
}

export default UserLimitRow;

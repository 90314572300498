import {useEffect, useRef, useState} from "react";
import {ColDef} from "@ag-grid-community/core";
import MethodRenderer from "shared/components/AGGridRenderers/MethodRenderer";
import {AgGridReact} from "@ag-grid-community/react";
import {FinancialDetail} from "generatedTypes";
import RouteRenderer from "shared/components/AGGridRenderers/RouteRenderer";
import uiPaths from "routes/uiPaths";
import BankRenderer from "shared/components/AGGridRenderers/BankRenderer";

const useColumnsConfig = () => {
  const gridRef = useRef<AgGridReact<FinancialDetail>>(null);

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    const baseColumns: ColDef[] = [
      {
        headerName: 'Institute name',
        field: 'fi_name',
        sortable: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        cellRenderer: BankRenderer,
        minWidth: 130,
      },

      {
        headerName: 'Name',
        field: 'fi_username',
        sortable: true,
        minWidth: 130,
      },
      {
        headerName: 'Method',
        field: 'method',
        sortable: true,
        cellRenderer: MethodRenderer,
        minWidth: 100,
      },
      {
        headerName: 'Email',
        field: 'fi_email',
        sortable: true,
        minWidth: 150,
        cellRenderer: RouteRenderer({uiPath: uiPaths.customerPaths.detail, pathParamName: 'customerEmail'}),
      },
    ];

    setColumnDefs([...baseColumns]);
  }, []);

  return {
    columnDefs,
    gridRef,
  }
}

export default useColumnsConfig;

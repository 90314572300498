import {Box, Button, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material'

import {bindMenu, bindTrigger, usePopupState,} from 'material-ui-popup-state/hooks'
// import useBookmarkMutations
//   from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/actions/useBookmarkMutations";
import useDialogState, {DialogStateHandler} from "shared/hooks/useDialogState";
// import AddNoteDialog from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/actions/AddNoteDialog";
// import APILookupDialog from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/partials/ActionApiLookup";
// import ViewTextDialog from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/actions/ViewTextDialog";
// import BlacklistDialog from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/actions/BlacklistDialog";
// import RecoverDialog from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/actions/RecoverDialog";
// import CreateChargebackDialog
//   from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/actions/CreateChargebackDialog";
// import RefundDialog from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/actions/RefundDialog";

// import {ReactComponent as NoteIcon} from "assets/icons/ic_communication.svg";
// import {ReactComponent as APILookupIcon} from "assets/icons/ic_search.svg";
// import {ReactComponent as ViewTextIcon} from "assets/icons/ic_eye.svg";
// import {ReactComponent as RecoverIcon} from "assets/icons/ic_refresh.svg";
// import {ReactComponent as BlacklistIcon} from "assets/icons/ic_blacklist.svg";
// import {ReactComponent as RefundIcon} from "assets/icons/ic_fund.svg";
// import {ReactComponent as ChargebackIcon} from "assets/icons/ic_gear.svg";
import {ReactComponent as ApiLookupIcon} from "assets/iconsDS/transaction-actions/api-lookup.svg";
import {ReactComponent as UserLimitsIcon} from "assets/iconsDS/transaction-actions/user-limits.svg";
import {ReactComponent as RefundTransactionIcon} from "assets/iconsDS/transaction-actions/refund-transaction.svg";
import {ReactComponent as RequestRefundIcon} from "assets/iconsDS/transaction-actions/request-refund.svg";
import {
  ReactComponent as ResendPayoutInstructionsIcon
} from "assets/iconsDS/transaction-actions/resend-payout-instructions.svg";
import {ReactComponent as BankDeclineIcon} from "assets/iconsDS/transaction-actions/bank-decline.svg";
import {ReactComponent as CancelTransactionIcon} from "assets/iconsDS/transaction-actions/cancel-transaction.svg";

import {ReactComponent as ChevronDownIcon} from "assets/iconsDS/chevron-down.svg";
import {ReactComponent as ChevronUpIcon} from "assets/iconsDS/chevron-up.svg";

import usePermissions from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/usePermissions";
// import PatchDetailsDialog
//   from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/actions/PatchDetailsDialog";
// import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import {CancelActionEnum, DeclineActionEnum, TransactionDetailModel} from "generatedTypes";
import ActionApiLookupDialog
  from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/partials/ActionApiLookup";
import ActionRefundDialog
  from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/partials/ActionRefundDialog";
import ActionCancelDialog
  from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/partials/ActionCancelDialog";
import ActionDeclineDialog
  from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/partials/ActionDeclineDialog";
import ActionUpdateUserLimitsDialog
  from "shared/features/ActionUpdateUserLimits";
import ActionResendInstructionsDialog
  from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/partials/ActionResendInstructionsDialog";
import {useTheme} from "@mui/material/styles";
import Bookmark from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/Bookmark";
import typography from "theme/typography";

// import useCurrentUser from "shared/hooks/useCurrentUser";


interface TranActionProps {
  transactionStatus: TransactionDetailModel['transaction_status'],
  transactionAction: TransactionDetailModel['action'],
  transactionType: TransactionDetailModel['type'],
  transactionAmount: TransactionDetailModel['amount']
  refundableBalance: TransactionDetailModel['refundable_balance'],
  isTransactionProcessed: TransactionDetailModel['processed'],
  email: TransactionDetailModel['email'],
  sid: TransactionDetailModel['sid'],
}

const TransactionActions = ({
  transactionType,
  transactionStatus,
  transactionAction,
  transactionAmount,
  refundableBalance,
  // isTransactionProcessed
  email,
  sid
}: TranActionProps) => {
  const popupState = usePopupState({variant: 'popover', popupId: 'transactionActions'})

  // const addNoteDialog = useDialogState();
  const apiLookupDialog = useDialogState();
  // const viewTextDialog = useDialogState();
  // const blacklistDialog = useDialogState();
  // const recoverDialog = useDialogState();
  // const createChargebackDialog = useDialogState();
  const refundDialog = useDialogState();
  const requestRefundDialog = useDialogState();
  const cancelDialog = useDialogState();
  const declineDialog = useDialogState();
  const resendInstructionsDialog = useDialogState();
  // const patchDetailsDialog = useDialogState();

  const updateUserLimitsDialog = useDialogState();

  const {
    // canAddToBlacklist,
    // canCreateChargeback,
    // canCreateNote,
    canCreateRefund,
    // canRecoverTransaction,
    canViewAPILookup,
    canCancelTransaction,
    canDeclineTransaction,
    // canViewText,
    // canPatchTransaction,
  } = usePermissions();

  // let isChargebackAllowed = false;
  // if (transactionAction) {
  //   isChargebackAllowed = transactionStatus === 'approved' && ['payment', 'capture'].includes(transactionAction);
  // }

  let isRefundAllowed = false;
  if (transactionAmount && refundableBalance && transactionType) {
    isRefundAllowed = transactionStatus === 'approved' && ['payment', 'capture'].includes(transactionType) && refundableBalance > 0;
  }


  let isCancelAllowed = false;
  if (transactionStatus) {
    isCancelAllowed =  ['pending', 'confirming', 'sent'].includes(transactionStatus) || transactionStatus === 'approved' && transactionAction === 'request';
  }

  let isDeclineAllowed = false;
  if (transactionStatus) {
    isDeclineAllowed = transactionStatus === 'pending' || transactionStatus === 'approved' && transactionAction === 'request';
  }

  const handleDialogMenuClick = (dialogStateHandler: DialogStateHandler) => () => {
    dialogStateHandler.openDialog();
    popupState.close();
  }

  const theme = useTheme()

  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
      <Bookmark/>
      <Button
        aria-haspopup="true"
        variant="text"
        endIcon={popupState.isOpen ? <ChevronUpIcon/> : <ChevronDownIcon width='16px' height='16px'/>}
        {...bindTrigger(popupState)}
        size='small'
        sx={{
          ...typography.B5,
          padding: '6px 8px',

          backgroundColor: popupState.isOpen ? theme.palette.themeColors.purple['30'] : '#fff',
          color: popupState.isOpen ? theme.palette.themeColors.purple['120'] : theme.palette.primary.main,
        }}
      >
        Actions
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* {canCreateNote && ( */}
        {/*  <MenuItem onClick={handleDialogMenuClick(addNoteDialog)}> */}
        {/*    <ListItemIcon> */}
        {/*      <NoteIcon width={20}/> */}
        {/*    </ListItemIcon> */}
        {/*    <ListItemText>Add Note</ListItemText> */}
        {/*  </MenuItem> */}
        {/* )} */}

        {canViewAPILookup && (
          <MenuItem onClick={handleDialogMenuClick(apiLookupDialog)}>
            <ListItemIcon>
              <ApiLookupIcon/>
            </ListItemIcon>
            <ListItemText>API Lookup</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={handleDialogMenuClick(updateUserLimitsDialog)}>
          <ListItemIcon>
            <UserLimitsIcon/>
          </ListItemIcon>
          <ListItemText>Update User Limits</ListItemText>
        </MenuItem>

        {(isRefundAllowed && canCreateRefund) && (
          <MenuItem
            sx={{
              '&:hover': {
                'svg path': {
                  fill: theme.palette.themeColors.grey['120'],
                  stroke: theme.palette.themeColors.grey['120']
                }
              }
            }}
            onClick={handleDialogMenuClick(refundDialog)}
          >
            <ListItemIcon>
              <RefundTransactionIcon/>
            </ListItemIcon>
            <ListItemText>Refund transaction</ListItemText>
          </MenuItem>
        )}

        {(isRefundAllowed && canCreateRefund) && (
          <MenuItem onClick={handleDialogMenuClick(requestRefundDialog)}>
            <ListItemIcon>
              <RequestRefundIcon/>
            </ListItemIcon>
            <ListItemText>Request Refund</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={handleDialogMenuClick(resendInstructionsDialog)}>
          <ListItemIcon>
            <ResendPayoutInstructionsIcon/>
          </ListItemIcon>
          <ListItemText>Resend payout instructions</ListItemText>
        </MenuItem>


        {(isDeclineAllowed && canDeclineTransaction) && (
          <MenuItem onClick={handleDialogMenuClick(declineDialog)}>
            <ListItemIcon>
              <BankDeclineIcon/>
            </ListItemIcon>
            <ListItemText color={theme.palette.themeColors.red['30']}>Bank Decline</ListItemText>
          </MenuItem>
        )}

        {(isCancelAllowed && canCancelTransaction) && (
          <MenuItem onClick={handleDialogMenuClick(cancelDialog)}>
            <ListItemIcon>
              <CancelTransactionIcon/>
            </ListItemIcon>
            <ListItemText color={theme.palette.themeColors.red['30']}>Cancel Transaction</ListItemText>
          </MenuItem>
        )}

        {/* {canViewText && ( */}
        {/*  <MenuItem onClick={handleDialogMenuClick(viewTextDialog)}> */}
        {/*    <ListItemIcon> */}
        {/*      <ViewTextIcon width={20}/> */}
        {/*    </ListItemIcon> */}
        {/*    <ListItemText>View Text</ListItemText> */}
        {/*  </MenuItem> */}
        {/* )} */}

        {/* {canAddToBlacklist && ( */}
        {/*  <MenuItem onClick={handleDialogMenuClick(blacklistDialog)}> */}
        {/*    <ListItemIcon> */}
        {/*      <BlacklistIcon width={20}/> */}
        {/*    </ListItemIcon> */}
        {/*    <ListItemText>Blacklist</ListItemText> */}
        {/*  </MenuItem> */}
        {/* )} */}

        {/* {canRecoverTransaction && ( */}
        {/*  <MenuItem onClick={handleDialogMenuClick(recoverDialog)}> */}
        {/*    <ListItemIcon> */}
        {/*      <RecoverIcon width={20}/> */}
        {/*    </ListItemIcon> */}
        {/*    <ListItemText>Recover transaction</ListItemText> */}
        {/*  </MenuItem> */}
        {/* )} */}

        {/* {(isChargebackAllowed && canCreateChargeback) && ( */}
        {/*  <MenuItem onClick={handleDialogMenuClick(createChargebackDialog)}> */}
        {/*    <ListItemIcon> */}
        {/*      <ChargebackIcon width={20}/> */}
        {/*    </ListItemIcon> */}
        {/*    <ListItemText>Chargeback</ListItemText> */}
        {/*  </MenuItem> */}
        {/* )} */}

        {/* {(isTransactionProcessed && canPatchTransaction) && ( */}
        {/*  <MenuItem onClick={handleDialogMenuClick(patchDetailsDialog)}> */}
        {/*    <ListItemIcon> */}
        {/*      <DriveFileRenameOutlineOutlinedIcon width={20} style={{fill: '#ADB0B9'}}/> */}
        {/*    </ListItemIcon> */}
        {/*    <ListItemText>Patch transaction details</ListItemText> */}
        {/*  </MenuItem> */}
        {/* )} */}

      </Menu>

      {canViewAPILookup && <ActionApiLookupDialog dialogStateHandler={apiLookupDialog}/>}
      {isRefundAllowed && canCreateRefund && (
        <ActionRefundDialog
          dialogStateHandler={refundDialog}
          action="refund"
        />
      )}
      {isRefundAllowed && canCreateRefund && (
        <ActionRefundDialog
          dialogStateHandler={requestRefundDialog}
          action="request-refund"
        />
      )}

      {(isCancelAllowed && canCancelTransaction) && (
        <ActionCancelDialog
          dialogStateHandler={cancelDialog}
          action={CancelActionEnum.CANCEL}
        />
      )}

      <ActionDeclineDialog
        dialogStateHandler={declineDialog}
        action={DeclineActionEnum.DECLINE}
      />

      <ActionUpdateUserLimitsDialog dialogStateHandler={updateUserLimitsDialog} email={email} sid={sid} />
      {(isDeclineAllowed && canDeclineTransaction) && (
        <ActionDeclineDialog
          dialogStateHandler={declineDialog}
          action={DeclineActionEnum.DECLINE}
        />
      )}

      <ActionResendInstructionsDialog dialogStateHandler={resendInstructionsDialog} />
      {/* {canCreateNote && <AddNoteDialog dialogStateHandler={addNoteDialog}/>} */}

      {/* {canViewText && <ViewTextDialog dialogStateHandler={viewTextDialog}/>} */}
      {/* {canAddToBlacklist && <BlacklistDialog dialogStateHandler={blacklistDialog}/>} */}
      {/* {canRecoverTransaction && <RecoverDialog dialogStateHandler={recoverDialog}/>} */}
      {/* {canCreateChargeback && <CreateChargebackDialog dialogStateHandler={createChargebackDialog}/>} */}

      {/* {canPatchTransaction && <PatchDetailsDialog dialogStateHandler={patchDetailsDialog}/>} */}
    </Box>
  )
}

export default TransactionActions;

import {DialogStateHandler} from "shared/hooks/useDialogState";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid,} from "@mui/material";
import CloseIconButton from "shared/CloseButton";
import TextFormField from "shared/components/formFields/TextFormField";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import SelectFormField from "shared/components/formFields/SelectFormField";
import PhoneFormField from "shared/components/formFields/PhoneFormField";
import CountryAutocompleteFormField from "shared/components/formFields/CountryAutocompleteFormField";
import FileFormField from "shared/components/formFields/FileFormField";
import {useCreateKYC} from "apps/KYCRequests/pages/create/useMutations";
import LoadingButton from "@mui/lab/LoadingButton";
import SidAutocompleteFormField from "shared/components/formDataFields/SidAutocompleteFormField";

interface SubmitKYCDialogProps {
  dialogStateHandler: DialogStateHandler
}

const schema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  merchantId: yup.number().min(0).required('This field is required.'),
  documentType: yup.string().required('This field is required.'),

  firstName: yup.string().matches(/^[ A-Za-z-]*$/, 'First name can\'t contain special symbols').max(128),
  lastName: yup.string().matches(/^[ A-Za-z-]*$/, 'Last name can\'t contain special symbols').max(128),
  phone: yup.string().matches(
    /^\+?(\d{1,3})?[ .-]?(\()?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{3})$/,
    {message: 'Invalid phone number', excludeEmptyString: true}
  ),

  address: yup.string().matches(/^[\d\s,A-Za-z-]*$/, 'Address can\'t contain special symbol').max(255),
  city: yup.string().matches(/^[ A-Za-z-]*$/, 'City can\'t contain special symbols').max(128),
  state: yup.string().matches(/^[ A-Za-z-]*$/, 'State can\'t contain special symbols').max(2),
  country: yup.string().matches(/^[ A-Za-z-]*$/, 'Country can\'t contain special symbols').max(2),
  zipCode: yup.string(),

  documentUpload: yup.mixed<Blob>().required('Attachment is required'),
})

type FormData = yup.InferType<typeof schema>;

const documentTypesList = [
  {value: "ID_CARD", label: "ID Card"},
  {value: "PASSPORT", label: "Passport"},
  {value: "DRIVERS", label: "Driver's Licence"},
  {value: "RESIDENCE_PERMIT", label: "Residence Permit"},
  {value: "UTILITY_BILL", label: "Utility Bill"},
  {value: "SELFIE", label: "Selfie"},
  {value: "VIDEO_SELFIE", label: "Video Selfie"},
  {value: "PROFILE_IMAGE", label: "Profile Image"},
  {value: "ID_DOC_PHOTO", label: "ID Document Photo"},
  {value: "AGREEMENT", label: "Agreement"},
  {value: "CONTRACT", label: "Contract"},
  {value: "DRIVERS_TRANSLATION", label: "Driver's Translation"},
  {value: "INVESTOR_DOC", label: "Investor Document"},
  {value: "VEHICLE_REGISTRATION_CERTIFICATE", label: "Vehicle Registration Certificate"},
  {value: "INCOME_SOURCE", label: "Income Source"},
  {value: "PAYMENT_METHOD", label: "Payment Method"},
  {value: "BANK_CARD", label: "Bank Card"},
  {value: "COVID_VACCINATION_FORM", label: "COVID-19 Vaccination Form"},
  {value: "OTHER", label: "Other"},
];

const SubmitKYCDialog = ({dialogStateHandler}: SubmitKYCDialogProps) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: '',
      merchantId: 0,
      documentType: '',
      firstName: '',
      lastName: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
    }
  });

  const {mutate, isPending, errorMessage, postalCodeErrorMessage} = useCreateKYC(dialogStateHandler, methods.reset)

  const handleReset = () => {
    methods.reset(methods.formState.defaultValues);
  }

  const onSubmit = (data: FormData) => {
    mutate({
      formData: {
        email: data.email,
        merchant_id: data.merchantId,
        document_type: data.documentType,
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
        address: data.address,
        city: data.city,
        state: data.state,
        country: data.country,
        zip_code: data.zipCode,
        document_upload: data.documentUpload
      }
    })
  }

  const handleOnClose = () => {
    dialogStateHandler.closeDialog();
    methods.reset();
  }

  return (
    <Dialog open={dialogStateHandler.isOpen} onClose={dialogStateHandler.closeDialog} fullWidth maxWidth='md'>
      <FormProvider {...methods}>
        <Box component='form' sx={{width: '100%'}} onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="modal-dialog-title">
            Submit KYC
          </DialogTitle>
          <CloseIconButton onClick={handleOnClose}/>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <TextFormField name="email" placeholder="Email"/>
              </Grid>
              <Grid item md={4}>
                <SidAutocompleteFormField name="merchantId"/>
              </Grid>
              <Grid item md={4}>
                <SelectFormField name="documentType" label="Document type" options={documentTypesList}/>
              </Grid>
            </Grid>

            <Divider sx={{mt: 2, mb: 2}}>Customer information (optional)</Divider>

            <Grid container spacing={2}>
              <Grid item md={4}>
                <TextFormField name="firstName" placeholder="First name"/>
              </Grid>
              <Grid item md={4}>
                <TextFormField name="lastName" placeholder="Last name"/>
              </Grid>
              <Grid item md={4}>
                <PhoneFormField name="phone" placeholder="Phone"/>
              </Grid>
              <Grid item md={8}>
                <TextFormField name="address" placeholder="Address"/>
              </Grid>
              <Grid item md={4}>
                <TextFormField name="city" placeholder="City"/>
              </Grid>
              <Grid item md={3}>
                <TextFormField name="state" placeholder="State"/>
              </Grid>
              <Grid item md={6}>
                <CountryAutocompleteFormField name="country" label="Country"/>
              </Grid>
              <Grid item md={3}>
                <TextFormField name="zipCode" placeholder="Zip code"/>
                {postalCodeErrorMessage && <Alert severity="error" sx={{mt: 1}}>{postalCodeErrorMessage}</Alert>}
              </Grid>
            </Grid>

            <Divider sx={{mt: 2, mb: 2}}>Document (required)</Divider>

            <Grid container spacing={2}>
              <Grid item>
                <FileFormField
                  name="documentUpload"
                  label="Document"
                  accept={{
                    'image/*': ['.jpg', '.jpeg', '.png'],
                    'application/pdf': ['.pdf'],
                    'video/*': ['.mp4'],
                    'application/msword': ['.doc'],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                  }}
                  supportedFiles="JPG (JPEG), PNG, PDF, DOC, DOCX, MP4"
                />
              </Grid>
            </Grid>

            {errorMessage && <Alert severity="error" sx={{mt: 1}}>{errorMessage}</Alert>}

          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleReset}>Reset</Button>
            <LoadingButton
              loading={isPending}
              variant="contained"
              loadingIndicator="Submitting…"
              type="submit"
              sx={{width: 130}}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default SubmitKYCDialog;

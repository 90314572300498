import {useQuery} from "@tanstack/react-query";
import ApiClient from "apiClient";


interface UseTransactionsFieldsArgs {
  enabled: boolean
}

export const useTransactionsFields = ({enabled}: UseTransactionsFieldsArgs) => {
  const {data, isLoading} = useQuery({
    queryKey: ['transactions'],
    queryFn: () => ApiClient.export.getTransactionsFields(),
    enabled,
  })

  return {
    data,
    isLoading,
  }
}

import { Components } from '@mui/material/styles';
import palette from "theme/palette";
import typography from "theme/typography";

const MuiDialogTitle: Components['MuiDialogTitle'] =  {
  styleOverrides: {
    root: {
      ...typography.B1,
      color: palette.secondary.main,
      backgroundColor: palette.secondary.white,
      padding: '12px',
      fontWeight: 700,
      borderBottom: '0.5px solid',
      borderColor: palette.themeColors.grey['50'],
    }
  }
}

export default MuiDialogTitle;

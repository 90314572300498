import { Components } from '@mui/material/styles';

const MuiTimeline: Components['MuiTimeline'] = {
  styleOverrides: {
    root: {
      margin: '12px 0px',
      padding: '0px 8px',

      '& .MuiTimelineContent-root': {
        padding: '0px 16px',
      }
    }
  }
}

export default MuiTimeline;
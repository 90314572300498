import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import {styled, useTheme,} from "@mui/material/styles";
import TopBreadcrumbs from "layouts/MainLayout/partials/TopNavigation/TopBreadcrumbs";
import {useMemo} from "react";
import {ReactComponent as LupeIcon} from "assets/iconsDS/lupe.svg";
import {ReactComponent as LogoSmall} from "assets/logo/logoSmall.svg";
import {ReactComponent as LogoBig} from "assets/logo/logoBig.svg";
import Notifications from "layouts/MainLayout/partials/TopNavigation/Notification";
import {Box, Stack} from "@mui/material";
import Timezone from "apps/account/components/Timezone";


interface TopNavigationProps {
  isDrawerOpen: boolean;
  drawerWidth: number;
  menuWidth: number;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const TopNavigation = ({isDrawerOpen, drawerWidth, menuWidth}: TopNavigationProps) => {
  const dsTheme = useTheme();
  const AppBar = useMemo(() => styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.themeColors.grey['05'],
    borderStyle: 'solid',
    borderBottomWidth: '1px',
    paddingLeft: `${menuWidth}px`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      paddingLeft: `${drawerWidth + menuWidth}px`,
      transition: theme.transitions.create(['width', 'margin', 'padding'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })), [drawerWidth]);

  return (
    <AppBar
      position="fixed"
      open={isDrawerOpen}
      elevation={0}
      sx={{
        border: 0,
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          height: '56px',
          transition: dsTheme.transitions.create("width", {
            easing: dsTheme.transitions.easing.sharp,
            duration: dsTheme.transitions.duration.enteringScreen,
          }),
          ...(isDrawerOpen
            ? {width: `${drawerWidth + menuWidth + 8}px`}
            : {width: `${menuWidth + 1}px`}),
          background: dsTheme.palette.themeColors.grey["20"],
          borderRight: isDrawerOpen ? 'none' : `1px solid ${dsTheme.palette.themeColors.grey.border}`,
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '12px',
            height: '56px',
          }}
        >
          {isDrawerOpen
            ? <Box component={LogoBig} ml={1}/>
            : <Box component={LogoSmall} mt='6px'/>}
        </Box>

      </Box>
      <Toolbar sx={{
        width: '100%',
        [dsTheme.breakpoints.up("sm")]: {
          minHeight: '56px',
        },
        borderBottom: `1px solid ${dsTheme.palette.themeColors.grey['30']}`,
      }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: '100%',
          }}
        >
          <Stack direction="row">
            <TopBreadcrumbs/>
          </Stack>

          <Stack direction="row" alignItems='center' gap='20px' sx={{marginRight: '12px'}}>
            <Timezone/>

            <Box>
              <IconButton sx={{padding: '8px 8px'}}>
                <LupeIcon/>
              </IconButton>
            </Box>

            <Box>
              <Notifications/>
            </Box>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default TopNavigation;

import {useMerchantDataQuery} from "apps/users/pages/create/partials/AccessTo/useDataQueries";
import {Box, LinearProgress, Radio, TextField} from "@mui/material";
import {useFormContext, useWatch} from "react-hook-form";
import {useCallback, useMemo, useState} from "react";
import {AutoSizer, List} from 'react-virtualized';
import {Roles} from "contexts/UserAccessContext/const";
import {MerchantSchema} from "generatedTypes";

interface AccessToProps {
  clearSelection: () => void;
}

const AccessTo = ({clearSelection}: AccessToProps) => {
  const {setValue} = useFormContext();

  const mid = useWatch({name: 'mid'});
  const sid = useWatch({name: 'sid'});
  const rid = useWatch({name: 'rid'});

  const selectedRole = useWatch({name: 'role'});

  const isMerchantEnabled = useMemo(() => selectedRole === Roles.MERCHANT, [selectedRole]);
  const isRetailerEnabled = useMemo(() => selectedRole === Roles.RETAILER, [selectedRole]);
  const isSiteEnabled = useMemo(() => selectedRole === Roles.SITE, [selectedRole]);

  const {data, isLoading} = useMerchantDataQuery();

  const [search, setSearch] = useState('');

  const handleMerchantChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    clearSelection();
    setValue('mid', e.target.value);
  }, []);

  const handleRetailerChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    clearSelection();
    setValue('rid', e.target.value);
  }, []);

  const handleSiteChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    clearSelection();
    setValue('sid', e.target.value);
  }, []);

  function filterData(searchPhrase: string) {
    const items: Array<MerchantSchema> = [];
    data?.items?.forEach(item => {
      const retailers: any = [];

      item.retailers?.forEach(retailer => {
        const sites = retailer.sites?.filter(site => String(site.sid).includes(searchPhrase) || site.name?.toLowerCase().includes(searchPhrase.toLowerCase())) || [];
        if (sites.length > 0) {
          retailers.push({...retailer, sites});
        } else if (String(retailer.rid).includes(searchPhrase) || retailer.name?.toLowerCase().includes(searchPhrase.toLowerCase())) {
          retailers.push({...retailer, sites: []});
        }
      });

      if (retailers.length > 0) {
        items.push({...item, retailers});
      } else if (String(item.mid).includes(searchPhrase) || item.name?.toLowerCase().includes(searchPhrase.toLowerCase())) {
        items.push({...item, retailers: []});
      }
    });

    return items;
  }

  const flatData = useMemo(() => {
    const _fdData = filterData(search);
    if (_fdData) {
      // @ts-ignore
      return _fdData.flatMap(merchant => [
        merchant,
        // @ts-ignore
        ...(merchant.retailers?.flatMap((retailer: { sites: any; }) => [
          retailer,
          ...retailer.sites || []
        ]) || [])
      ]);
    }
    return [];
  }, [filterData, search]);

  // @ts-ignore
  const rowRenderer = ({index, key, style}) => {
    const item = flatData?.[index];
    if (!item) {
      return null;
    }

    if ('mid' in item) {
      return (
        <Box key={key} style={style}>
          <Radio
            checked={mid === String(item.mid)}
            onChange={handleMerchantChange}
            value={item.mid}
            name="merchant"
            disabled={!isMerchantEnabled}
            inputProps={{'aria-label': String(item.mid)}}/>
          {item.name} (MID: {item.mid})
        </Box>
      );
    }
    if ('rid' in item) {
      return (
        <Box key={key} style={style} sx={{pl: 4}}>
          <Radio
            checked={rid === String(item.rid)}
            onChange={handleRetailerChange}
            value={item.rid}
            name="retailer"
            disabled={!isRetailerEnabled}
            inputProps={{'aria-label': String(item.rid)}}/>
          {/* @ts-ignore */}
          {item.name} (RID: {item.rid})
        </Box>
      );
    }
    if ('sid' in item) {
      return (
        <Box key={key} style={style} sx={{pl: 8}}>
          <Radio
            checked={sid === String(item.sid)}
            onChange={handleSiteChange}
            value={item.sid}
            name="site"
            disabled={!isSiteEnabled}
            inputProps={{'aria-label': String(item.sid)}}/>
          {/* @ts-ignore */}
          {item.name} (SID: {item.sid})
        </Box>
      );
    }

    return null;
  };

  if (isLoading) {
    return <LinearProgress/>;
  }

  return (
    <Box sx={{height: '100vh', overflow: 'auto'}}>
      <Box mb={2}>
        <TextField
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size='small'
          sx={{width: 350}}
        />
      </Box>
      <AutoSizer>
        {({height, width}) => (
          <List
            width={width}
            height={height}
            rowCount={flatData?.length || 0}
            rowHeight={42} // Adjust this value based on the height of your rows
            rowRenderer={rowRenderer}/>
        )}
      </AutoSizer>
    </Box>
  );
};

export default AccessTo;

import { useQuery } from "@tanstack/react-query";
import ApiClient from "apiClient";
import {CustomerQueryKeys} from "shared/queryKeys";

type Params = Parameters<typeof ApiClient.customers.getCustomerRelatedCustomers>[0]

export const useCustomerRelatedCustomers = ({email}: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: [CustomerQueryKeys.RELATED_CUSTOMERS, {email}],
    queryFn: () => ApiClient.customers.getCustomerRelatedCustomers({email}),
    enabled: !!email,
  });

  return {
    data,
    isLoading,
  };
};

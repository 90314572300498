import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {UserQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

interface UseUserDetailsArgs {
  userId: string;
}

export const useUserDetails = ({userId}: UseUserDetailsArgs) => {
  const {data, isLoading, isFetching} = useQuery({
    queryKey: [UserQueryKeys.DETAILS, {userId}],
    queryFn: () => ApiClient.users.getUserDetails({userId}),
    placeholderData: keepPreviousData,
  })

  return {
    data,
    isLoading,
    isFetching,
  }
}
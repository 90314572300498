import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AccountQueryKeys} from "shared/queryKeys";
import {IApiError} from "shared/types";
import {useErrorMessage} from "shared/hooks/useErrorMessage";
import {useParams} from "react-router-dom";

type Params = Parameters<typeof ApiClient.users.postEditUserPassword>[0]

export const useEditPassword = (reset: () => void) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const {errorMessage, handleSetErrorMessage} = useErrorMessage()
  const {userId} = useParams()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.users.postEditUserPassword(params),
    onMutate: () => {handleSetErrorMessage()},
    onSuccess: async () => {
      enqueueSnackbar('Password updated successfully', {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS, {userId}]})

      reset();
    },
    onError: (error: IApiError) => {
      handleSetErrorMessage(error)
    },
  })

  return {
    mutate,
    isPending,
    errorMessage,
  }
}

import {baseIconUrl} from "shared/const";
import SvgColor from './icons/SvgColor'

interface AppIconProps {
  src: string
  size?: number
  color?: string
}

const AppIcon = ({ src, color, size = 20 }: AppIconProps) => (
  <SvgColor
    src={`${baseIconUrl}/assets/icons/${src}.svg`}
    sx={{ width: size, height: size, verticalAlign: 'text-bottom' }}
    color={color}
  />
)

export default AppIcon

import {AppCard} from "shared/components/mui";
import {Box, Button, Chip, Grid, Typography} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import PluginDialog from "apps/plugins/pages/enable/PluginDialog";
import useDialogState from "shared/hooks/useDialogState";

interface PluginCardProps {
  enabled: boolean
}

const PluginCard = ({enabled}: PluginCardProps) => {
  const pluginDialog = useDialogState();

  return (
    <>
      <AppCard sx={{p: 3, width: 450}} bodyStyle={{p:0, m: 0}}>
        <Grid container>
          <Grid item lg={3} mr={3}>
            <img src="https://www.scantranx.com/images/moneris-logo-small.png" alt="" width="90px" height="90px" />
          </Grid>
          <Grid item lg={8}>
            <Box mt={1}>
              <Typography variant='h6'>
                Moneris
              </Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <Button variant='contained' endIcon={<DownloadIcon/>} onClick={pluginDialog.openDialog}>
                Install
              </Button>
              <Chip sx={{ml: 3}} variant={enabled ? 'success': 'pending'} label={enabled ? 'Enabled': 'Disabled'} />
            </Box>
          </Grid>
          <Grid item lg={12} mt={2}>
            <Typography>
              Accept payments online using the Moneris payment gateway.
            </Typography>
          </Grid>
        </Grid>
      </AppCard>

      <PluginDialog dialogStateHandler={pluginDialog}/>
    </>
  )
}

export default PluginCard;
import {Chip} from "@mui/material";

interface VerifiedChipProps {
  verified: boolean | null | undefined;
}

const VerifiedChip = ({ verified }: VerifiedChipProps) => (
  <Chip
    // verified is a boolean, but it can also be null or undefined
    label={
      verified === null || verified === undefined
        ? 'N/A'
        : verified
          ? 'True'
          : 'False'
    }
    color={
      verified === null || verified === undefined
        ? 'warning'
        : verified
          ? 'success'
          : 'error'
    }
  />
)

export default VerifiedChip;
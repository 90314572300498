import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Alert, Box, Button, Chip} from "@mui/material";
import TextFormField from "shared/components/formFields/TextFormField";
import {UserDetailsSchema} from "generatedTypes";
import LoadingButton from "@mui/lab/LoadingButton";
import {emailValidator} from "shared/schemas";
import {useEditEmail} from "apps/users/pages/edit/partials/EditEmail/useDataMutations";
import {useParams} from "react-router-dom";
import { AppCard } from "shared/components/mui";
import ManuallyVerifyDialog from "apps/users/pages/edit/partials/EditEmail/ManuallyVerifyDialog";
import useDialogState from "shared/hooks/useDialogState";
import ResendVerificationDialog from "apps/users/pages/edit/partials/EditEmail/ResendVerificationDialog";
import usePermissions from "apps/users/pages/edit/permissions/usePermissions";

const schema = yup.object().shape({
  email: emailValidator.required('Email is required'),
})

type FormData = yup.InferType<typeof schema>;

interface EditEmailProps {
  email: UserDetailsSchema["email_address"]
  verified: UserDetailsSchema["email_verified"]
}

const EditEmail = ({email, verified}: EditEmailProps) => {
  const {userId} = useParams()
  const manuallyVerifyDialogHandler = useDialogState();
  const resendVerificationDialogHandler = useDialogState();

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      email,
    }
  });

  const {canEnableDisableActiveAndVerify} = usePermissions()

  const {mutate, isPending, emailErrorMessage} = useEditEmail()

  const onSubmit = (data: FormData) => {
    if(data.email) {
      mutate({
        userId: userId || '',
        requestBody: {email: data.email}
      })
    }
  }

  return (
    <AppCard
      headStyle={{pb: 0}}
      bodyStyle={{m: 0, p: 1, pt: 2}}
      sx={{pb: 0, mt: 3}}
    >
      <FormProvider {...methods}>
        <Box component='form' mx={3} onSubmit={methods.handleSubmit(onSubmit)}>
          <TextFormField name="email" placeholder="Email"/>
          {emailErrorMessage && <Alert severity="error" sx={{mt: 1}}>{emailErrorMessage}</Alert>}
          <Box mt={2}>
            <Chip label={verified ? 'verified' : 'not verified'} color={verified ? 'success': 'error'}/>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Box>
              <Button
                onClick={resendVerificationDialogHandler.openDialog}
                variant='outlined'
                sx={{mr: 1}}
              >
                Resend
              </Button>

              {(canEnableDisableActiveAndVerify && !verified) && (
                <Button
                  onClick={manuallyVerifyDialogHandler.openDialog}
                  variant='outlined'
                >
                  Manually
                </Button>
              )}
            </Box>
            <LoadingButton
              loading={isPending}
              variant="contained"
              loadingIndicator="Submitting…"
              type="submit"
              sx={{mt: 2, mb: 2, width: 120}}
              disabled={!methods.formState.isDirty || !methods.formState.isValid}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>

      <ManuallyVerifyDialog dialogHandler={manuallyVerifyDialogHandler} email={email || ''} />
      <ResendVerificationDialog dialogHandler={resendVerificationDialogHandler} email={email || ''} />
    </AppCard>
  )
}

export default EditEmail

import {Components} from "@mui/material/styles";
import palette from "theme/palette";

const MuiLoadingButton: Components['MuiLoadingButton'] =  {
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        color: 'transparent !important',
      },
    },
    loadingIndicator: {
      color: palette.secondary.white,
    },
  }
}

export default MuiLoadingButton;

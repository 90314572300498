import {Components, Theme} from '@mui/material/styles';

const MuiRadio: Components<Theme>['MuiRadio'] = {
  styleOverrides: {
    root: ({theme}) => ({
      padding: 4,
      '& .MuiTypography-root': {
        ...theme.typography.B4,
        fontWeight: 400,
      },
      '& .MuiSvgIcon-fontSizeMedium': {
        width: 16,
        height: 16,
        '& path': {
          borderRadius: '50%',
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& .MuiSvgIcon-fontSizeMedium': {
          '& path': {
            outline: `2px solid ${theme.palette.themeColors.grey[40]}`,
          },
        },
      },
      '&:active': {
        '& .MuiSvgIcon-fontSizeMedium': {
          '& path': {
            fill: theme.palette.primary.main,
            outline: `2px solid ${theme.palette.themeColors.purple[10]}`,
          },
        },
      },
      '&.Mui-checked': {
        '& .MuiTypography-root': {
          color: theme.palette.primary.main,
        },
        '& .MuiSvgIcon-fontSizeMedium': {
          '& path': {
            stroke: 'currentColor',
            strokeWidth: '2px',
            outline: 'none',
          },
          '&:nth-of-type(2)': {
            display: 'none',
          },
        },
      },
    }),
  }
}

export default MuiRadio;

const paths = {
  auth: {
    login: '/login/',
    forgotPassword: '/forgot-password/',
    updatePassword: '/update-password/:verificationToken/:checksum/',
    autoLogin: '/auto-login/:verificationToken/:checksum/',
    register: '/register/',
  },
  transactions: {
    list: '/transactions/',
    details: '/transactions/:transactionId/',
  },
  customers: {
    list: '/customers/',
    details: '/customers/:customerEmail/',
  },
  accounts: {
    settings: '/account/settings/'
  },
  users: {
    list: '/users/',
    create: '/users/create/',
    edit: '/users/:userId/edit/',
  },
  emailVerfication: {
    verify: '/email-verification/:verificationCode/:userId/:email/',
  },
  kycRequests: {
    list: '/kyc-requests/'
  },
  actionCenter: {
    list: '/action-center/'
  },
  passwordUpdateRequired: {
    updatePassword: '/change-password/',
  },
  notifications: {
    list: '/notifications/',
    details: '/notifications/:notificationId/',
  },
  plugins : {
    enable: '/plugins'
  },
  merchantDirectory: {
    list: '/merchant-directory/'
  },
  dashboardRequests: {
    list: '/dashboard-requests/'
  },
  userLimits: {
    list: '/user-limits/'
  },
}

export default paths;

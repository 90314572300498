import Chart from "react-apexcharts";
import useChart from "shared/hooks/useChart";
import Paper from "@mui/material/Paper";
import {
  Box,
  Grid,
  Table,
  TableCell,
  TableContainer, TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import {round} from "lodash";
import {useChartPaymentsDataQueries} from "apps/dashboard/pages/Dashboard/partials/ChartPayments/useDataQueries";
import {MouseEvent, useMemo, useState} from "react";
import {ChartTypes} from "apps/dashboard/pages/Dashboard/enums";
import {format} from "date-fns";
import ExportButton from "apps/dashboard/pages/Dashboard/partials/ChartPayments/ExportButton";

enum PaymentType {
  PAYMENT = 'payment',
  PAYOUT = 'payout',
  REFUND = 'refund',
  CHARGEBACK = 'chargeback',
}

const calculateChartData = (data: any, paymentType: PaymentType, activeChart: ChartTypes) => data?.map((item: any) => {
  if (activeChart === ChartTypes.COUNT) {
    return item[`${paymentType}_count_total`] || 0
  }
  return round(item[`${paymentType}_amount_total`] || 0, 2)
}) || []

const ChartPayments = () => {
  const [paymentType, setPaymentType] = useState<PaymentType[]>([
    PaymentType.PAYMENT,
    PaymentType.PAYOUT,
    PaymentType.REFUND,
    PaymentType.CHARGEBACK
  ]);
  const [activeChart, setActiveChart] = useState<ChartTypes>(ChartTypes.AMOUNT);
  const {data} = useChartPaymentsDataQueries()

  const graphDataSeries = data?.map((item: any) => item.date) || [];

  const chartPaymentData = useMemo(() => calculateChartData(data, PaymentType.PAYMENT, activeChart), [data, activeChart]);
  const chartPayoutData = useMemo(() => calculateChartData(data, PaymentType.PAYOUT, activeChart), [data, activeChart]);
  const chartRefundData = useMemo(() => calculateChartData(data, PaymentType.REFUND, activeChart), [data, activeChart]);
  const chartChargebackData = useMemo(() => calculateChartData(data, PaymentType.CHARGEBACK, activeChart), [data, activeChart]);

  const seriesInfo: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined = [
    {name: 'Payments', data: chartPaymentData, paymentType: PaymentType.PAYMENT},
    {name: 'Payouts', data: chartPayoutData, paymentType: PaymentType.PAYOUT},
    {name: 'Refunds', data: chartRefundData, paymentType: PaymentType.REFUND},
    {name: 'Chargeback', data: chartChargebackData, paymentType: PaymentType.CHARGEBACK},
  ].filter((item) => paymentType.includes(item.paymentType))

  const chartOptions = useChart({
    xaxis: {
      type: 'datetime',
      categories: graphDataSeries,
    },
    yaxis: {
      labels: {
        formatter(originalVal: number) {
          const val = Math.abs(originalVal);

          if (val >= 10_000 && val < 1_000_000) {
            return `${round(val / 1000)} K`;
          }
          if (val >= 1_000_000) {
            return `${round(val / 1_000_000)} M`;
          }
          return val;
        },
      },
    },
  });

  const averagePayment = useMemo(() => chartPaymentData.reduce((a: number, b: number) => a + b, 0) / chartPaymentData.length, [chartPaymentData]);
  const averagePayout = useMemo(() => chartPayoutData.reduce((a: number, b: number) => a + b, 0) / chartPayoutData.length, [chartPayoutData]);
  const averageRefund = useMemo(() => chartRefundData.reduce((a: number, b: number) => a + b, 0) / chartRefundData.length, [chartRefundData]);
  const averageChargeback = useMemo(() => chartChargebackData.reduce((a: number, b: number) => a + b, 0) / chartChargebackData.length, [chartChargebackData]);

  const handlePaymentTypeChange = (event: MouseEvent<HTMLElement>, newType: PaymentType[]) => {
    setPaymentType(newType);
  }
  const handleChartChange = (event: MouseEvent<HTMLElement>, newType: ChartTypes) => {
    setActiveChart(newType)
  }

  return (
    <>

      <Paper sx={{p: 2}}>
        <ToggleButtonGroup
          value={paymentType}
          onChange={handlePaymentTypeChange}
          aria-label="text alignment"
        >
          <ToggleButton size='small' value={PaymentType.PAYMENT} aria-label={PaymentType.PAYMENT}>
            {PaymentType.PAYMENT.toUpperCase()}
          </ToggleButton>
          <ToggleButton size='small' value={PaymentType.PAYOUT} aria-label={PaymentType.PAYOUT}>
            {PaymentType.PAYOUT.toUpperCase()}
          </ToggleButton>
          <ToggleButton size='small' value={PaymentType.REFUND} aria-label={PaymentType.REFUND}>
            {PaymentType.REFUND.toUpperCase()}
          </ToggleButton>
          <ToggleButton size='small' value={PaymentType.CHARGEBACK} aria-label={PaymentType.CHARGEBACK}>
            {PaymentType.CHARGEBACK.toUpperCase()}
          </ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h5">Payments</Typography>

        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="body1">Average Payment</Typography>
            <Typography variant="body2">{round(averagePayment, 2)}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Average Payout</Typography>
            <Typography variant="body2">{round(averagePayout, 2)}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Average Refund</Typography>
            <Typography variant="body2">{round(averageRefund, 2)}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Average Chargeback</Typography>
            <Typography variant="body2">{round(averageChargeback, 2)}</Typography>
          </Grid>
        </Grid>


        <Chart type="line" series={seriesInfo} options={chartOptions} height={290}/>
        <ToggleButtonGroup
          value={activeChart}
          exclusive
          onChange={handleChartChange}
          aria-label="text alignment"
        >
          <ToggleButton value={ChartTypes.AMOUNT} aria-label="left aligned" size='small'>
          $
          </ToggleButton>
          <ToggleButton value={ChartTypes.COUNT} aria-label="centered" size='small'>
          #
          </ToggleButton>
        </ToggleButtonGroup>
      </Paper>

      <Paper sx={{mt: 2}}>
        <Box sx={{pt: 1, pl: 1, mb: 1}}>
          <ExportButton
            fileFormat="csv"
            filename="ChartData"
            graphDataSeries={graphDataSeries}
            chartPaymentData={chartPaymentData}
            chartPayoutData={chartPayoutData}
            chartRefundData={chartRefundData}
            chartChargebackData={chartChargebackData}
          />

          <ExportButton
            fileFormat="xlsx"
            filename="ChartData"
            graphDataSeries={graphDataSeries}
            chartPaymentData={chartPaymentData}
            chartPayoutData={chartPayoutData}
            chartRefundData={chartRefundData}
            chartChargebackData={chartChargebackData}
          />
        </Box>

        <TableContainer sx={{maxWidth: '1050px'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{position: 'sticky', left: '0', background: 'white'}}><strong>Breakout</strong></TableCell>
                {graphDataSeries.map((item) => (
                  <TableCell sx={{ whiteSpace: 'nowrap'}} key={item}>
                    {format(new Date(item), 'dd MMM')}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell sx={{position: 'sticky', left: '0', background: 'white'}}><strong>Payments</strong></TableCell>
              {chartPaymentData.map((item: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell sx={{position: 'sticky', left: '0', background: 'white'}}><strong>Payouts</strong></TableCell>
              {chartPayoutData.map((item: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell sx={{position: 'sticky', left: '0', background: 'white'}}><strong>Refunds</strong></TableCell>
              {chartRefundData.map((item: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell sx={{position: 'sticky', left: '0', background: 'white'}}><strong>Chargebacks</strong></TableCell>
              {chartChargebackData.map((item: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ChartPayments;

import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {MerchantQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

type Params = Parameters<typeof ApiClient.merchants.getMerchantDirectoryContacts>[0]

export const useMerchantDirectoryContacts = ({
  page,
  perPage,
  orderBy,
  ...filters
}: Params) => {
  const {data, isLoading, isFetching, refetch} = useQuery({
    queryKey: [MerchantQueryKeys.DIRECTORY_CONTACTS, {page, perPage, orderBy, filters: {filters}}],
    queryFn: () => ApiClient.merchants.getMerchantDirectoryContacts({page, perPage, orderBy, ...filters}),
    placeholderData: keepPreviousData,
  })

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  }
}

import {Avatar, Box, IconButton, Link, Stack, Tooltip, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {ReactComponent as CustomerDetailsIcon} from "assets/iconsDS/chevron-right.svg";
import ContactInfo from "apps/transactions/pages/TransactionDetails/partials/TransactionCustomer/CustomerContactInfo";
import CustomerAverageOrderValue
  from "apps/transactions/pages/TransactionDetails/partials/TransactionCustomer/CustomerAverageOrderValue";
import {TransactionBreakdown, TransactionDetailModel} from "generatedTypes";
import UiPaths from "routes/uiPaths";
import SkeletonTransactionCustomer
  from "apps/transactions/pages/TransactionDetails/partials/TransactionCustomer/SkeletonTransactionCustomer";

import {fCurrency} from "shared/utils";
import {useCustomerOverview} from "shared/features/queries/CustomerOverviewQuery";
import {AnimatedBox} from "shared/styled/AnimatedBox";
import {WrapperBox} from "shared/styled/WrapperBox";

interface TransactionCustomerProps {
  email: TransactionDetailModel['email'];
  firstName: TransactionDetailModel['first_name'];
  lastName: TransactionDetailModel['last_name'];
  address?: TransactionDetailModel['full_address'];
  phoneNumber?: TransactionDetailModel['phone'];
  ipAddress?: TransactionDetailModel['customer_ip_address'];
  isLoading: boolean
}

const getApprovedBreakdown = (breakdown: TransactionBreakdown[], status: string) =>
  breakdown.find(item => item.status === status)?.amount || 0;

const TransactionCustomer = ({email, firstName, lastName, address, phoneNumber, ipAddress, isLoading}: TransactionCustomerProps) => {
  const theme = useTheme();

  const {data} = useCustomerOverview({email: email || ''});

  if (isLoading) return <SkeletonTransactionCustomer />

  return (
    <WrapperBox>
      <Stack
        direction="row"
        sx={{
          marginBottom: '30px',
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            marginRight: theme.spacing(1.5),
          }}
        >
          {firstName && firstName.length > 0 ? firstName[0] : "?"}{lastName && lastName.length > 0 ? lastName[0] : "?"}
        </Avatar>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            <Stack direction="row">
              <Typography variant="B4" sx={{fontWeight: 600, lineHeight: '20px'}}>
                {firstName} {lastName}
              </Typography>
            </Stack>
            <Link
              component={RouterLink}
              to={UiPaths.customerPaths.detail.reverse({customerEmail: email})}
              sx={{
                color: '#1360F6',
                display: 'block',
              }}
            >
              {email}
            </Link>
          </Box>
          <Box>
            <Link
              component={RouterLink}
              to={UiPaths.customerPaths.detail.reverse({customerEmail: email})}
              sx={{
                color: '#1360F6',
                display: 'block',
              }}
            >
              <Tooltip title="Customer Details" placement="top">
                <IconButton color="primary">
                  <CustomerDetailsIcon
                    style={{
                      width: '20px',
                      height: '20px',
                      stroke: theme.palette.primary.main,
                      fill: 'none',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        className="parentBox"
        spacing={1}
        sx={{
          height: '16px',
          alignItems: 'flex-end',
        }}
      >
        <AnimatedBox sx={{backgroundColor: '#EBFAF1'}}>
          <Stack className="text" direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#085E2D'}}>
              TOTAL APPROVED
            </Typography>
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#085E2D'}}>
              {fCurrency(getApprovedBreakdown(data?.transactions.transaction_by_status.payment.breakdown || [], 'approved'))}
            </Typography>
          </Stack>
        </AnimatedBox>
        <AnimatedBox sx={{backgroundColor: '#FFF3DB'}}>
          <Stack className="text" direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#5C3C00'}}>
              PENDING
            </Typography>
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#5C3C00'}}>
              {fCurrency(getApprovedBreakdown(data?.transactions.transaction_by_status.payment.breakdown || [], 'pending'))}
            </Typography>
          </Stack>
        </AnimatedBox>
        <AnimatedBox sx={{backgroundColor: '#FFE6EA'}}>
          <Stack className="text" direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#710A06'}}>
              DECLINED
            </Typography>
            <Typography sx={{fontSize: '11px', fontWeight: 600, color: '#710A06'}}>
              {fCurrency(getApprovedBreakdown(data?.transactions.transaction_by_status.payment.breakdown || [], 'declined'))}
            </Typography>
          </Stack>
        </AnimatedBox>
      </Stack>

      <CustomerAverageOrderValue>
        $960
      </CustomerAverageOrderValue>

      <Box mt={2}>
        <ContactInfo
          address={address}
          phoneNumber={phoneNumber}
          ipAddress={ipAddress}
        />
      </Box>

    </WrapperBox>
  );
}

export default TransactionCustomer;

import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Box, Pagination, Stack, Typography} from "@mui/material";
import RSSelectFormField from "shared/components/formFields/RSSelectFormField";
import {FormProvider, useForm} from "react-hook-form";
import {useTheme} from "@mui/material/styles";

const DEFAULT_PAGE_SIZE = 10;

interface PaginationProps {
  gridRef: React.MutableRefObject<any>;
}

const AGPagination = forwardRef(({gridRef}: PaginationProps, ref) => {
  const theme = useTheme();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<undefined | number>();
  const [totalItems, setTotalItems] = useState<undefined | number>();
  const [startItem, setStartItem] = useState(1);
  const [endItem, setEndItem] = useState(DEFAULT_PAGE_SIZE);

  const methods = useForm({
    defaultValues: {
      pageSize: DEFAULT_PAGE_SIZE,
    },
  });

  const updatePaginationDetails = () => {
    const pageSize = gridRef.current!.api.paginationGetPageSize();
    setCurrentPage(gridRef.current!.api.paginationGetCurrentPage() + 1);
    setTotalPages(gridRef.current!.api.paginationGetTotalPages());
    setTotalItems(gridRef.current!.api.paginationGetRowCount());

    const start = (currentPage - 1) * pageSize + 1;
    const end = start + pageSize - 1;
    setStartItem(start);
    setEndItem(end);
  };

  useImperativeHandle(ref, () => ({
    updatePaginationDetails,
  }));

  const handlePageChange = (event: unknown, value: number) => {
    gridRef.current!.api.paginationGoToPage(value - 1);
    updatePaginationDetails();
  };

  const options = [
    {value: '10', label: '10'},
    {value: '20', label: '20'},
    {value: '30', label: '30'},
  ]

  const pageSizeSelect = methods.watch('pageSize');

  useEffect(() => {
    if (pageSizeSelect && gridRef.current.api) {
      gridRef.current.api.paginationSetPageSize(Number(pageSizeSelect));
    }
  }, [pageSizeSelect]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" spacing={1.5} alignItems="center">
        <Typography
          variant="B6"
          sx={{
            color: theme.palette.themeColors.grey['100'],
          }}
        >
          Rows
        </Typography>
        <FormProvider {...methods}>
          <Box width='54px'>
            <RSSelectFormField
              name="pageSize"
              options={options}
            />
          </Box>
        </FormProvider>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography
            variant="B6"
            sx={{
              color: theme.palette.themeColors.grey['100'],
            }}
          >
            Showing
          </Typography>
          <Typography
            variant="B6"
          >
            {startItem}
          </Typography>
          <Typography
            variant="B6"
            sx={{
              color: theme.palette.themeColors.grey['100'],
            }}
          >
            -
          </Typography>
          <Typography
            variant="B6"
            sx={{
              color: theme.palette.themeColors.grey['120'],
            }}
          >
            {endItem}
          </Typography>
          <Typography
            variant="B6"
            sx={{
              color: theme.palette.themeColors.grey['100'],
            }}
          >
            of
          </Typography>
          <Typography
            variant="B6"
            sx={{
              color: theme.palette.themeColors.grey['120'],
            }}
          >
            {totalItems === undefined || totalItems === 10 ? '...' : totalItems}
          </Typography>
        </Stack>
      </Stack>
      <Pagination
        count={totalPages === undefined || totalPages === 1 ? 3 : totalPages}
        page={currentPage}
        shape="rounded"
        onChange={handlePageChange}
      />
    </Stack>
  );
});

export default AGPagination;

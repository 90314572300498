import {useHasAccess} from "contexts/UserAccessContext";
import {Permissions, Roles} from "contexts/UserAccessContext/const";

const usePermissions = () => {
  const canCreateUpdateDeleteUserLimit = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.UPDATE_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST]
    }
  ])

  const canViewUserLimitOrBlacklist = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.ACCESS_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST]
    }
  ])

  return {
    canCreateUpdateDeleteUserLimit,
    canViewUserLimitOrBlacklist
  }
}

export default usePermissions

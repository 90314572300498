import {useFormContext} from "react-hook-form";
import {useDebounce} from "@uidotdev/usehooks";
import {useQuery} from "@tanstack/react-query";
import {TransactionQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

interface TerminalTransactionsHookAttrs {
  currentPage: number;
  perPage: number;
}

const useTerminalTransactions = ({currentPage, perPage}: TerminalTransactionsHookAttrs) => {
  const {watch, getFieldState} = useFormContext();
  const debouncedEmail = useDebounce(watch('email_address'), 400);
  const emailState = getFieldState('email_address');
  const siteId = watch('site_id');

  const {data, isLoading, isFetched} = useQuery({
    queryKey: [TransactionQueryKeys.VIRTUAL_TERMINAL_LIST, {
      currentPage,
      perPage,
      emailAddress: debouncedEmail,
      sid: siteId
    }],
    queryFn: () => ApiClient.transactions.getTransactionsList({
      emailAddress: debouncedEmail,
      sid: siteId,
      page: currentPage,
      perPage
    }),
    enabled: Boolean(!emailState.invalid && debouncedEmail && siteId),
  })

  return {data, isLoading, isFetched};
}

export default useTerminalTransactions;

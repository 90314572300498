import { Components } from '@mui/material/styles';

import typography from "theme/typography";
import palette from "theme/palette";

const MuiFormControl: Components['MuiFormControl'] = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      caretColor: palette.themeColors.purple['20'],

      '& .MuiInputBase-root': {
        padding: '8px',
        gap: '2px',
        borderRadius: '8px',
        border: '0.5px solid transparent',
        ...typography.B4,
        '&.Mui-error': {
          borderColor: palette.themeColors.red['20'],
        },
        '& .MuiInputBase-input::placeholder': {
          ...typography.B4,
          fontWeight: 400,
          color: palette.themeColors.grey["100"],
          opacity: 1,
        },
      },
      '& .MuiInputLabel-root': {
        position: 'static',
        transform: 'none',
        marginBottom: '8px',
        ...typography.B4,
      },
      '& .MuiFormHelperText-root': {
        margin: '2px 0 0',
        ...typography.B7,
        '&.Mui-error': {
          color: palette.themeColors.red['20'],
        },
      },
      '& .MuiInputAdornment-root': {
        '& ellipse, & path': {
          stroke: palette.themeColors.grey['80'],
        }
      }
    },
  },
  variants: [
    {
      props: {variant: 'outlined'},
      style: {
        '& .MuiInputBase-root:not(.Mui-focused):has(.MuiInputBase-input:not([value=""]))': {
          backgroundColor: palette.themeColors.grey['20'],
          color: palette.themeColors.grey['120'],
          borderColor: palette.themeColors.grey['40']
        },

        '& .MuiInputBase-root': {
          backgroundColor: palette.themeColors.grey['20'],
          color: palette.themeColors.grey['100'],
          borderColor: 'transparent',
        },

        '&:hover .MuiInputBase-root:not(.Mui-error)': {
          backgroundColor: palette.themeColors.grey['30'],
          color: palette.themeColors.grey['110'],
          borderColor: 'transparent',
        },

        '&:focus-within .MuiInputBase-root.Mui-focused': {
          backgroundColor: palette.themeColors.grey['30'],
          color: palette.themeColors.grey['120'],
          border: '0.5px solid',
          borderColor: palette.themeColors.purple['20'],
          outline: '3px solid rgba(129, 69, 181, 0.10)'
        },
      }
    },
    {
      props: {variant: 'filled'},
      style: {
        '& .MuiInputBase-root': {
          backgroundColor: palette.secondary.white,
          color: palette.themeColors.grey['100'],
          border: '1px solid',
          borderColor: palette.themeColors.grey['40'],
        },

        // Hover state
        '&:hover .MuiInputBase-root': {
          backgroundColor: palette.themeColors.grey['20'],
          color: palette.themeColors.grey['110'],
          border: '1px solid',
          borderColor: palette.themeColors.grey['50'],
        },

        // Focused state
        '& .MuiInputBase-root:focus': {
          backgroundColor: palette.themeColors.grey['30'],
          color: palette.themeColors.grey['120'],
          border: '0.5px solid',
          borderColor: palette.themeColors.purple['20'],
          outline: '3px solid rgba(129, 69, 181, 0.10)'
        },

        // Filled state (after input)
        '& .MuiInputBase-root:not(.MuiInputBase-inputAdornedStart):not(:placeholder-shown):not(:focus)': {
          backgroundColor: palette.themeColors.grey['20'],
          color: palette.themeColors.grey['120'],
        },
      }
    }
  ]
}

export default MuiFormControl;

import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {keepPreviousData, useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AccountQueryKeys} from "shared/queryKeys";
import {IApiError} from "shared/types";

type Params = Parameters<typeof ApiClient.account.postAccountEnableTwoFa>[0]

export const useEnableTwoFa = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.account.postAccountEnableTwoFa(params),
    onSuccess: async (data) => {
      enqueueSnackbar(data.message, {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS]})
    },
    onError: (error: IApiError) => {
      const {enable} = error.body.message
      enqueueSnackbar(enable, {variant: 'error'})
    }
  })

  return {
    mutate,
    isPending
  }
}

export const useTwoFaMethods = () => {
  const {data, isLoading,  isFetching} = useQuery({
    queryKey: [AccountQueryKeys.TWO_FA_METHODS],
    queryFn: () => ApiClient.account.getAccountEnableTwoFa({}),
    placeholderData: keepPreviousData,
  })

  return {
    data,
    isLoading,
    isFetching
  }
}

type SetPreferredMethodParams = Parameters<typeof ApiClient.account.postAccountSetPreferredTwoFaMethod>[0]

export const useSetPreferredMethod = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: SetPreferredMethodParams) => ApiClient.account.postAccountSetPreferredTwoFaMethod(params),
    onSuccess: async () => {
      enqueueSnackbar('Preferred method selected', {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS]})
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.TWO_FA_METHODS]})
    },
    onError: (error: IApiError) => {
      const {preferredMethod} = error.body.message.preferred_method
      enqueueSnackbar(preferredMethod, {variant: 'error'})
    }
  })

  return {
    mutate,
    isPending
  }
}
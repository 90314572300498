import {useMutation} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {useState} from "react";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import {IApiError} from "shared/types";

type Params = Parameters<typeof ApiClient.virtualTerminal.postVirtualTerminalCreateTransaction>[0]

interface UseCreateVirtualTerminalTransactionArgs {
  dialogStateHandler: DialogStateHandler,
}

interface TransactionDataResponse {
  bank_payment_url?: string,
  amount?: number,
  type?: string,
  transaction_id?: string,
}

const useCreateVirtualTerminalTransaction = ({dialogStateHandler}: UseCreateVirtualTerminalTransactionArgs) => {
  const [transactionData, setTransactionData] = useState<TransactionDataResponse | undefined>()

  const [udfsErrorMessage, setUdfsErrorMessage] = useState<string | undefined>('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string | undefined>('');
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState<string | undefined>('');

  const {mutate} = useMutation({
    mutationFn: (params: Params) => ApiClient.virtualTerminal.postVirtualTerminalCreateTransaction(params),
    onSuccess: (data) => {
      setTransactionData(data)
      setUdfsErrorMessage('')
      dialogStateHandler.openDialog()
    },
    onError: (error: IApiError) => {
      const {udfs} = error.body.message

      // @ts-ignore
      const {phone_number} = error.body.message
      const {postal_code} = error.body.message

      if (udfs && udfs.length > 0) {
        setUdfsErrorMessage(udfs[0])
      }

      if (phone_number && phone_number.length > 0) {
        setPhoneErrorMessage(phone_number[0])
      }

      if (postal_code && postal_code.length > 0) {
        setPostalCodeErrorMessage(postal_code[0])
      }

    }
  })

  return {
    mutate,
    transactionData,
    udfsErrorMessage,
    phoneErrorMessage,
    postalCodeErrorMessage,
  }
}

export default useCreateVirtualTerminalTransaction;

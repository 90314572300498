/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionCenterDetailsSchema } from '../models/ActionCenterDetailsSchema';
import type { ActionCenterPaginatedSchema } from '../models/ActionCenterPaginatedSchema';
import type { ActionCenterUpdateSchema } from '../models/ActionCenterUpdateSchema';
import type { Body_upload_kyc } from '../models/Body_upload_kyc';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ActionCenterService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Upload Kyc
     * # Upload KYC Endpoint
     *
     * This endpoint is used to upload KYC (Know Your Customer) data.
     *
     * ## KYC Data Upload
     *
     * The KYC data is provided by the user and it includes personal information such as first name, last name, phone number, address, city, state, country, postal code, and associated email. The user can also specify whether the KYC is whitelisted or not.
     *
     * In addition to the KYC data, the user can also upload files. The files are processed and stored in a dictionary where the key is the file name (in uppercase) and the value is the file itself. If multiple files are uploaded with the same name, they are stored in a list under the same key.
     *
     * The KYC data and the files are then sent to the Dashboard API for further processing.
     *
     * ## Validation
     *
     * The KYC data and files are validated before being processed. The validation checks include:
     * - Checking if the user role is SITE or MANAGER and if they have access to the specified SID.
     * - Checking if at least one file is provided for upload.
     * - Checking if the uploaded files are of type jpg, png, pdf, doc, docx, or mp4.
     * - Max file size is 10Mb.
     *
     * Additionally, the user's role is validated using the `get_role_based_match` method.
     * - If the user's role is MERCHANT, the user's merchant ID (mid) is matched with the KYC data.
     * - If the user's role is RETAILER, the user's retailer ID (rid) is matched with the KYC data.
     *
     * ## Response
     *
     * The response from the Dashboard API is returned as the response of this endpoint. If successful, a message indicating success is returned.
     *
     * ## Permissions
     *
     * Users must have one of the following roles:
     * - ADMIN
     * - CUSTOMER_SERVICE
     * - MERCHANT
     * - RETAILER
     * - MANAGER
     * - SITE
     *
     * Users must also have the following permission:
     * - UPDATE_ACTION_CENTER
     * @returns any Successful Response
     * @throws ApiError
     */
    public uploadKyc({
        formData,
    }: {
        formData: Body_upload_kyc,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/action-center/upload_kyc/',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get action center list
     * Roles: MERCHANT
     * Permissions: VIEW_ACTION_CENTER
     * @returns ActionCenterPaginatedSchema Success
     * @throws ApiError
     */
    public getActionCenterList({
        page,
        perPage,
        email,
        sids,
        actionType,
        status,
        comment,
        statusTimestamp,
        orderBy,
        xFields,
    }: {
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * Filter by email address.
         */
        email?: string,
        /**
         * Filter by sids.
         */
        sids?: Array<string>,
        /**
         * Filter by action type.
         */
        actionType?: string,
        /**
         * Filter by status.
         */
        status?: string,
        /**
         * Filter by comment.
         */
        comment?: string,
        /**
         * Filter by updated at.
         */
        statusTimestamp?: string,
        /**
         * Order by field.
         */
        orderBy?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ActionCenterPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/action-center/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
                'email': email,
                'sids': sids,
                'action_type': actionType,
                'status': status,
                'comment': comment,
                'status_timestamp': statusTimestamp,
                'order_by': orderBy,
            },
        });
    }
    /**
     * Update action center status
     * Roles: MERCHANT
     * Permissions: UPDATE_ACTION_CENTER
     * @returns ActionCenterUpdateSchema Success
     * @throws ApiError
     */
    public putActionCenterList({
        email,
        sid,
        actionType,
        xFields,
    }: {
        /**
         * Email is required.
         */
        email: string,
        /**
         * Sid is required.
         */
        sid: string,
        /**
         * Action type is required.
         */
        actionType: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ActionCenterUpdateSchema> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/action-center/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'email': email,
                'sid': sid,
                'action_type': actionType,
            },
        });
    }
    /**
     * Get action center details
     * @returns ActionCenterDetailsSchema Success
     * @throws ApiError
     */
    public getActionCenterDetails({
        email,
        sid,
        xFields,
    }: {
        email: string,
        sid: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ActionCenterDetailsSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/action-center/{email}/{sid}/',
            path: {
                'email': email,
                'sid': sid,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
}

import {styled, useTheme} from "@mui/material/styles";
import {Box, Stack, Tooltip, Typography} from "@mui/material";

const commonStyles = {
  height: '16px',
  '&:hover': {
    cursor: 'pointer',
  }
}

const BarPurple = styled(Box)(({theme}) => ({
  ...commonStyles,
  '&:hover': {
    backgroundColor: theme.palette.themeColors.purple[90],
  }
}));

const BarGrey = styled(Box)(({theme}) => ({
  ...commonStyles,
  backgroundColor: theme.palette.themeColors.grey[50],
}));

interface TooltipTitleProps {
  title: string;
  value: number;
}

const ToolTipTitle = ({title, value}: TooltipTitleProps) => {
  const theme = useTheme();
  return (
    <Stack direction='row' gap={1}>
      <Typography variant="B5" sx={{color: theme.palette.themeColors.purple[60]}}>{title}:</Typography>
      <Typography variant="B5">{value}%</Typography>
    </Stack>
  )
}


interface SummaryBarProps {
  data: { label: string; count: number }[];
}

const processData = ({data}: SummaryBarProps) => {
  // Step 1: Sort the data by count in descending order
  const _data = [...data];
  const sortedData = _data.sort((a, b) => b.count - a.count);

  // Step 2: Calculate the total count
  const totalCount = sortedData.reduce((sum, item) => sum + item.count, 0);

  // Step 3: Calculate percentages and create the result list
  const result = sortedData.slice(0, 2).map(item => ({
    name: item.label,
    percentage: Number(((item.count / totalCount) * 100).toFixed(2))
  }));

  // Step 4: Aggregate the remaining data
  const aggregatedCount = sortedData.slice(2).reduce((sum, item) => sum + item.count, 0);
  const aggregatedPercentage = Number(((aggregatedCount / totalCount) * 100).toFixed(2));

  result.push({
    name: "Others",
    percentage: aggregatedPercentage
  });

  return result;
};

const SummaryBar = ({data}: SummaryBarProps) => {
  const theme = useTheme();
  const processedData = processData({data});

  return (
    <Box sx={{display: 'flex', width: '100%', height: '16px'}}>
      {processedData.map((item, index) => (
        <Tooltip
          key={item.name}
          placement="top"
          title={<ToolTipTitle title={item.name} value={item.percentage}/>}
        >
          {item.name === 'Others'
            ? <BarGrey sx={{width: `${item.percentage}%`}}/>
            : (
              <BarPurple
                sx={{
                  width: `${item.percentage}%`,
                  // @ts-ignore
                  backgroundColor: theme.palette.themeColors.purple[80 - index * 10],
                }}
              />
            )}
        </Tooltip>
      ))}
    </Box>
  );
};

export default SummaryBar;

import {Components} from "@mui/material/styles";

const MuiDialogContent: Components['MuiDialogContent'] =  {
  styleOverrides: {
    root: {
      padding: '16px 12px'
    }
  }
}

export default MuiDialogContent;

import {createContext, ReactNode, useCallback, useContext, useMemo} from 'react';
import useCurrentUser from "shared/hooks/useCurrentUser";
import {Permissions, Roles} from "contexts/UserAccessContext/const";
import {AccessParams, UserContextType, ValidateAccessParams} from "contexts/UserAccessContext/types";
import {LinearProgress} from "@mui/material";


const UserContext = createContext<UserContextType | undefined>(undefined);

const validateAccess = ({userRole, userPermissions, accessParamsList}: ValidateAccessParams) => {
  if (userRole === Roles.SUPER_ADMIN) {
    return true;
  }

  for (const accessParam of accessParamsList) {
    const requiredRoles = accessParam.roles || [];
    const requiredPermissions = accessParam.permissions || [];

    if (requiredRoles.length === 0
      && requiredPermissions.length > 0
      && userPermissions
      && requiredPermissions.every(permission => userPermissions.includes(permission))) {
      return true;
    }

    if (requiredRoles.includes(userRole)) {
      if (requiredPermissions.length > 0) {
        if (userPermissions && requiredPermissions.every(permission => userPermissions.includes(permission))) {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  return false;
}

export const UserAccessProvider = ({children}: { children: ReactNode }) => {
  const {user, isLoading} = useCurrentUser();

  const hasAccess = useCallback((accessParamsList: AccessParams[]) => {
    if (!user) return false;

    return validateAccess({
      userRole: Roles[user.role?.toUpperCase() as keyof typeof Roles],
      userPermissions: user.permissions?.map(permission => Permissions[permission as keyof typeof Permissions]),
      accessParamsList
    })
  }, [user])

  const value = useMemo(() => (
    {
      hasAccess,
    }
  ), [{hasAccess}])

  return (
    <UserContext.Provider value={value}>
      {isLoading ? <LinearProgress /> : children}
    </UserContext.Provider>
  );
};

export const useHasAccess = (accessParamsList: AccessParams[]) => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useHasAccess must be used within a UserProvider');
  }
  return context.hasAccess(accessParamsList);
}

import {StatsByType} from "apps/customers/pages/CustomerDetails/shared/components/StatsByType";
import SkeletonStatsByType from "apps/customers/pages/CustomerDetails/shared/components/SkeletonStatsByType";

interface PayoutsProps {
  total: number;
  leftValue: number;
  rightValue: number;
  completionRate: number;
  isLoading: boolean
}

const Payouts = ({total, leftValue, rightValue, completionRate, isLoading}: PayoutsProps) => {
  if (isLoading) return <SkeletonStatsByType title='Payouts' />

  return(
    <StatsByType
      title='Payouts'
      total={total}
      leftTitle='Previous month spend'
      leftValue={leftValue}
      rightTitle='Avg. Payout Value'
      rightValue={rightValue}
      completionRate={completionRate}
    />
  )
}


export default Payouts;

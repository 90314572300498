import {Components} from "@mui/material/styles";
import palette from "theme/palette";
import typography from "theme/typography";

const MuiMenuItem: Components['MuiMenuItem'] =  {
  styleOverrides: {
    root: {
      ...typography.B4,
      borderRadius: '6px',
      padding: '8px 12px 8px 12px',
      lineHeight: '20px',
      margin: '2px 0',
      color: palette.themeColors.grey['110'],

      '&:hover': {
        color: palette.themeColors.grey['120'],
        backgroundColor: palette.themeColors.grey["30"],

        'svg path': {
          stroke: palette.themeColors.grey['120'],
        },

        'svg rect': {
          stroke: palette.themeColors.grey['120'],
        },

        'svg circle': {
          stroke: palette.themeColors.grey['120'],
        }
      },

      '& .MuiListItemIcon-root': {
        minWidth: '0',
        marginRight: '8px',
      }
    },
  }
}

export default MuiMenuItem;

import {Stack, Typography} from "@mui/material";
import {useState} from "react";

const PNG_BANKS = new Set(['binkii', 'dcbank', 'flinks', 'moneris', 'pateno', 'payfacto', 'peoples', 'psigate']);

const getBankImagePath = (bankId: string) => {
  if (PNG_BANKS.has(bankId)) {
    return `/bank-logos/${bankId}.png`
  }
  return `/bank-logos/${bankId}.svg`
}

interface BankProps {
  bankName: string;
  typographySX?: object;
}

const Bank = ({bankName, typographySX = {}}: BankProps) => {
  const [imageError, setImageError] = useState(false);

  return (
    <Stack direction="row" spacing={1} alignItems="end">
      {!imageError && (
        <img
          width={16}
          height={16}
          src={getBankImagePath(bankName.toLowerCase())}
          alt={bankName}
          onError={() => setImageError(true)}
        />
      )}
      <Typography
        variant='B4'
        sx={typographySX}
      >{bankName}</Typography>
    </Stack>
  );
}

export default Bank;

import * as yup from "yup";

export const documentsSchema = {
  id_card: yup.array<Blob>(),
  drivers: yup.array<Blob>(),
  passport: yup.array<Blob>(),
  residence_permit: yup.array<Blob>(),
  utility_bill: yup.array<Blob>(),
  selfie: yup.array<Blob>(),
  video_selfie: yup.array<Blob>(),
  profile_image: yup.array<Blob>(),
  id_doc_photo: yup.array<Blob>(),
  agreement: yup.array<Blob>(),
  contract: yup.array<Blob>(),
  drivers_translation: yup.array<Blob>(),
  investor_doc: yup.array<Blob>(),
  vehicle_registration_certificate: yup.array<Blob>(),
  income_source: yup.array<Blob>(),
  payment_method: yup.array<Blob>(),
  bank_card: yup.array<Blob>(),
  covid_vaccination_form: yup.array<Blob>(),
  other: yup.array<Blob>()
}

import {IconButton, InputAdornment, TextField} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";

type SelectFieldsProps = {
  name: string,
  placeholder?: string,
}

const PasswordFormField = ({name, placeholder}: SelectFieldsProps) => {
  const {control} = useFormContext();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <TextField
          {...field}
          fullWidth
          size="small"
          type={showPassword ? "text" : "password"}
          id={`${name}-field`}
          placeholder={placeholder}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default PasswordFormField;

/* eslint-disable import/order */
import {createBrowserRouter} from "react-router-dom";
import paths from "routes/paths";

// layouts
import MainLayout from "layouts/MainLayout";

// auth views
import LoginView from "apps/auth/pages/login";
import ForgotPasswordView from "apps/auth/pages/forgotPassword";
import UpdatePasswordView from "apps/auth/pages/updatePassword";
import AutoLoginView from "apps/auth/pages/autoLogin";

// transactions views
import TransactionsListView from "apps/transactions/pages/TransactionsList";
import TransactionDetailsView from "apps/transactions/pages/TransactionDetails";

import CustomersListView from "apps/customers/pages/CustomersList";
import CustomerDetails from "apps/customers/pages/CustomerDetails";

import VirtualTerminal from "apps/virtualTerminal/pages/VirtualTerminal";

import {loginLoader, protectedLoader} from "routes/loaders";
import authService from "routes/authService";
import uiPaths from "routes/uiPaths";
import AccountSettings from "apps/account/pages/settings";
import UsersList from "apps/users/pages/UsersList";
import Dashboard from "apps/dashboard/pages/Dashboard";
import KYCRequestsList from "apps/KYCRequests/pages/list";
import PasswordUpdateRequiredForm from "apps/account/pages/settings/PasswordUpdateRequired";
import ActionCenter from "apps/actionCenter/pages/list";
import UserCreate from "apps/users/pages/create";
import NotificationsList from "apps/notifications/list";
import NotificationDetails from "apps/notifications/details";
import EnablePlugin from "apps/plugins/pages/enable";
import MerchantDirectory from "apps/merchantDirectory/pages/list";
import EditUser from "apps/users/pages/edit";
import VerifyUserEmail from "apps/users/pages/edit/partials/VerifyEmail";
import BatchUpload from "apps/batchUpload/pages/index";
import DashboardRequests from "apps/dashboardRequests/pages/list";
import UserLimits from "apps/userLimits/pages/list";


interface BreadCrumbArgs {
  currentPageLabel?: string;
}

export const mainRoutes = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    loader: () => ({isAuthenticated: authService.isAuthenticated}),
    children: [
      {
        id: 'transactions',
        path: paths.transactions.list,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <TransactionsListView/>,
            handle: {
              breadcrumbs: () => [
                {label: "All Transactions"},
              ],
            },
          },
          {
            loader: protectedLoader,
            path: ':transactionId/*',
            element: <TransactionDetailsView/>,
            handle: {
              breadcrumbs: ({currentPageLabel}: BreadCrumbArgs) => [
                {label: "All Transactions", link: uiPaths.transactionPaths.list.reverse()},
                {label: currentPageLabel},
              ],
            },
          }
        ]
      },

      {
        id: 'customers',
        path: paths.customers.list,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <CustomersListView/>,
          },
          {
            loader: protectedLoader,
            path: ':customerEmail/*',
            element: <CustomerDetails/>,
          }
        ]
      },
      {
        id: 'virtualTerminal',
        path: uiPaths.virtualTerminalPaths.index.pattern,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <VirtualTerminal/>,
          },
        ]
      },

      {
        id: 'users',
        path: paths.users.list,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <UsersList/>,
          },
          {
            loader: protectedLoader,
            path: paths.users.create,
            element: <UserCreate/>,
          },
          {
            loader: protectedLoader,
            path: paths.users.edit,
            element: <EditUser/>,
          }
        ]
      },

      {
        id: 'emailVerification',
        path: paths.emailVerfication.verify,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <VerifyUserEmail/>
          }
        ]
      },

      {
        id: 'accounts',
        path: paths.accounts.settings,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <AccountSettings/>
          }
        ]
      },

      {
        id: 'kycRequests',
        path: paths.kycRequests.list,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <KYCRequestsList/>
          }
        ]
      },

      {
        id: 'actionCenter',
        path: paths.actionCenter.list,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <ActionCenter/>
          }
        ]
      },

      {
        'id': 'notifications',
        path: paths.notifications.list,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <NotificationsList/>
          },
          {
            loader: protectedLoader,
            path: ':notificationId/*',
            element: <NotificationDetails/>,
          }
        ]
      },

      {
        id: 'plugins',
        path: paths.plugins.enable,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <EnablePlugin/>
          }
        ]
      },

      {
        id: 'merchantDirectory',
        path: paths.merchantDirectory.list,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <MerchantDirectory/>,
          }
        ]
      },

      {
        id: 'batchUpload',
        path: uiPaths.batchUploadPaths.index.reverse(),
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <BatchUpload/>,
          }
        ]
      },
      {
        id: 'dashboardRequests',
        path: paths.dashboardRequests.list,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <DashboardRequests/>
          }
        ]
      },

      {
        id: 'userLimits',
        path: paths.userLimits.list,
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <UserLimits/>
          }
        ]
      },

      {
        id: 'dashboard',
        path: '/',
        Component: MainLayout,
        children: [
          {
            loader: protectedLoader,
            index: true,
            element: <Dashboard/>
          }
        ]
      },

      {
        id: 'auth',
        children: [
          {
            loader: loginLoader,
            path: paths.auth.login,
            Component: LoginView,
          },
          {
            path: paths.auth.forgotPassword,
            element: <ForgotPasswordView/>,
          },
          {
            path: paths.auth.updatePassword,
            element: <UpdatePasswordView/>,
          },
          {
            path: paths.auth.autoLogin,
            element: <AutoLoginView/>
          },
          // {
          //   path: paths.auth.register,
          //   element: <RegisterView />,
          // },
        ],
      },

    ]
  },
]);

export const passwordRequiredUpdateRoutes = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    children: [
      {
        loader: () => ({isAuthenticated: authService.isAuthenticated}),
        index: true,
        element: <PasswordUpdateRequiredForm/>,
      },
      {
        id: 'auth',
        children: [
          {
            loader: loginLoader,
            path: paths.auth.login,
            Component: LoginView,
          },
          {
            path: paths.auth.forgotPassword,
            element: <ForgotPasswordView/>,
          },
          {
            path: paths.auth.updatePassword,
            element: <UpdatePasswordView/>,
          },
          {
            path: paths.auth.autoLogin,
            element: <AutoLoginView/>
          }
        ],
      },
      {
        path: '*',
        element: <PasswordUpdateRequiredForm/>,
      }
    ]
  },
]);


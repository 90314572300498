import {Controller, useFormContext} from "react-hook-form";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

type SelectFieldsProps = {
  name: string,
  placeholder?: string,
  label?: string,
}

const DatePickerFormField = ({name, placeholder, label}: SelectFieldsProps) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <DatePicker
          {...field}
          label={label}
          slotProps={{
            textField: {
              fullWidth: true,
              size: 'small',
              error: fieldState.invalid,
              id: `${name}-field`,
              helperText: fieldState.error?.message,
              placeholder,
            }
          }}
        />
      )}
    />
  );
};

export default DatePickerFormField;

import {FormProvider, useForm} from "react-hook-form";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import * as yup from "yup";
import {LimitValidator, phoneValidator} from "shared/schemas";
import {yupResolver} from "@hookform/resolvers/yup";
import useCreateUserLimitOrBlacklist from "apps/userLimits/pages/create/useDataMutations";
import DialogGeneral from "shared/components/DialogGeneral";
import UpdateUserLimitOrBlacklistForm from "apps/userLimits/components/UpdateUserLimitOrBlacklistForm";

interface CreateUserLimitOrBlacklistProps {
  dialogStateHandler: DialogStateHandler
}

const schema = yup.object().shape({
  sid: yup.number().required('This field is required.'),
  email: yup.string().email(),
  phone: phoneValidator,
  paymentLimit: LimitValidator('paymentLimit'),
  payoutLimit: LimitValidator('payoutLimit'),
  isBlacklisted: yup.boolean().required('This field is required.').nullable(),
  expiryDate: yup.date().nullable(),
}).test('emailOrPhone', 'Either email or phone must be provided', (value, testContext) => {
  const {email, phone} = value;
  if (!email && !phone) {
    return testContext.createError({path: 'emailOrPhone', message: 'Either email or phone must be provided'})
  }
  return true;
});

type FormData = yup.InferType<typeof schema>;

const CreateUserLimitOrBlacklistDialog = ({dialogStateHandler}: CreateUserLimitOrBlacklistProps) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      sid: 0,
      email: '',
      phone: '',
      paymentLimit: {
        twentyFourHours: null,
        sevenDays: null,
        thirtyDays: null,
        perTransaction: null,
      },
      payoutLimit: {
        twentyFourHours: null,
        sevenDays: null,
        thirtyDays: null,
        perTransaction: null,
      },
      isBlacklisted: null,
      expiryDate: null,
    }
  });

  const handleSetPhoneValidationErrorMessage = (message: string) => {
    methods.setError('phone', {
      type: 'manual',
      message
    });
  }

  const {mutate, isPending} = useCreateUserLimitOrBlacklist({
    dialogHandler: dialogStateHandler,
    reset: methods.reset,
    handleSetPhoneValidationErrorMessage,
  });

  const onSubmit = (data: FormData) => {
    mutate({
      requestBody: {
        sid: data.sid,
        email: data.email || '',
        phone: data.phone || '',
        payment_limit: {
          twenty_four_hours: data.paymentLimit.twentyFourHours,
          seven_days: data.paymentLimit.sevenDays,
          thirty_days: data.paymentLimit.thirtyDays,
          per_transaction: data.paymentLimit.perTransaction,
        },
        payout_limit: {
          twenty_four_hours: data.payoutLimit.twentyFourHours,
          seven_days: data.payoutLimit.sevenDays,
          thirty_days: data.payoutLimit.thirtyDays,
          per_transaction: data.payoutLimit.perTransaction,
        },
        is_blacklisted: data.isBlacklisted === null ? undefined : data.isBlacklisted,
        expiry: data.expiryDate?.toISOString(),
      }
    })
  }

  const handleOnClose = () => {
    dialogStateHandler.closeDialog();
    methods.reset(methods.formState.defaultValues);
  }


  return (
    <FormProvider {...methods}>
      <DialogGeneral
        dialogStateHandler={dialogStateHandler}
        dialogTitle="Create user limit/blacklist"
        onSubmit={methods.handleSubmit(onSubmit)}
        submitBtnText="Create"
        onClose={handleOnClose}
        isPending={isPending}
      >
        {/* // @ts-ignore */}
        <UpdateUserLimitOrBlacklistForm
          fieldsList={[]}
          visibleGroups={[]}
          handleAddVisibleGroup={() => {}}
          handleRemoveVisibleGroup={() => {}}/>
      </DialogGeneral>
    </FormProvider>
  )
}

export default CreateUserLimitOrBlacklistDialog;

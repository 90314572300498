import {useEffect} from "react";
import {useDashboardRequests} from "apps/dashboardRequests/pages/list/useDataQueries";
import useFilters from "apps/dashboardRequests/pages/list/useFilters";

type DataFiltersParams = {
  currentPage: number;
  perPage: number;
  orderBy: string;
}

const useDataFilters = ({currentPage, perPage, orderBy}: DataFiltersParams) => {
  const {filterData, filters, submitCount} = useFilters()

  const {data, refetch, isLoading, isFetching} = useDashboardRequests({
    page: currentPage,
    perPage,
    orderBy,
    search: filterData.search,
    txn: filterData.txn,
    sid: filterData.sid,
    status: filterData.status,
    action: filterData.action,
  });

  useEffect(() => {
    void refetch();
  }, [submitCount]);

  return {
    data,
    isLoading,
    isFetching,
    filters,
  }
}

export default useDataFilters;

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {Controller, useFormContext} from "react-hook-form";
import {FormHelperText} from "@mui/material";

interface Option {
  value: string;
  label: string;
}

interface ToggleButtonsFormFieldProps {
  name: string;
  options: Option[];
}

const ToggleButtonsFormField = ({name, options}: ToggleButtonsFormFieldProps) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <>
          <ToggleButtonGroup
            value={field.value}
            exclusive
            onChange={(event, value) => field.onChange(value)}
            aria-label="text alignment"
          >
            {options.map((option) => (
              <ToggleButton size="small" value={option.value} aria-label={option.value}>
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {fieldState.invalid
            && <FormHelperText error={fieldState.invalid}>{fieldState.error?.message}</FormHelperText>
          }
        </>
      )}
    />
  );
}

export default ToggleButtonsFormField;

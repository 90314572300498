import {DialogStateHandler} from "shared/hooks/useDialogState";
import UniversalDialog from "shared/components/DS/UniversalDialog";
import {Box, Stack, Typography} from "@mui/material";
import {ReactComponent as WarningIcon} from 'assets/iconsDS/warning.svg';
import {useTheme} from "@mui/material/styles";
import {useParams} from "react-router-dom";
import {
  useResendInstructionsMutation
} from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/partials/ActionResendInstructionsDialog/useDataMutations";

interface ActionRefundProps {
  dialogStateHandler: DialogStateHandler
}


const ActionResendInstructionsDialog = ({dialogStateHandler}: ActionRefundProps) => {
  const {transactionId} = useParams();

  const theme = useTheme();

  const {mutate, isPending} = useResendInstructionsMutation({
    dialogStateHandler,
    notification: 'Resend payout instructions successful'
  });

  const onSubmit = () => {
    if (transactionId) {
      mutate({
        txn: transactionId,
      })
    }
  }


  return (
    <UniversalDialog
      dialogStateHandler={dialogStateHandler}
      title="Resend payout instructions"
      cancelButtonText="Cancel"
      submitButtonText="Confirm"
      onSubmitClick={onSubmit}
      onCancel={dialogStateHandler.closeDialog}
      isPending={isPending}
      dialogSX={{
        '& .MuiDialog-paper': {
          maxWidth: '400px',
        }
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          padding: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
        }}
      >
        <Stack
          sx={{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(3.5),
          }}
        >
          <WarningIcon/>
        </Stack>
        <Typography
          variant="B1"
          component={Box}
          sx={{
            textAlign: 'center',
            marginBottom: theme.spacing(3.5),
          }}
        >
          Please confirm resending payout instructions
        </Typography>
      </Stack>
    </UniversalDialog>
  );
};

export default ActionResendInstructionsDialog;

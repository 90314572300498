import {Box, Checkbox, TableCell, TableRow, Typography} from "@mui/material";
import SocialMediaLink from "apps/merchantDirectory/pages/list/ContactRow/SocialMediaLink";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";
import {MerchantDirectorySchema} from "generatedTypes";
import ContactTypeChip from "apps/merchantDirectory/pages/list/ContactRow/ContactTypeChip";
import {truncate} from "lodash";


interface ContactRowProps {
  _id: MerchantDirectorySchema['_id'];
  name: MerchantDirectorySchema['name'];
  title: MerchantDirectorySchema['title'];
  email: MerchantDirectorySchema['email'];
  phone: MerchantDirectorySchema['phone'];
  messaging_appname: MerchantDirectorySchema['messaging_appname'];
  messaging_username: MerchantDirectorySchema['messaging_username'];
  contact_type: MerchantDirectorySchema['contact_type'];
  created_by_username: MerchantDirectorySchema['created_by_username'];
  created_at: MerchantDirectorySchema['created_at'];
  additional_notes: MerchantDirectorySchema['additional_notes'];
  isItemSelected: boolean;
  handleOnClick: any
}

const ContactRow = (
  {
    _id,
    name,
    title,
    contact_type,
    email,
    phone,
    messaging_appname,
    created_by_username,
    created_at,
    messaging_username,
    isItemSelected,
    handleOnClick,
    additional_notes
  }: ContactRowProps
) => {
  const labelId = `enhanced-table-checkbox-${_id}`;

  return (
    <TableRow
      onClick={handleOnClick}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={_id}
      selected={isItemSelected}
      sx={{cursor: 'pointer'}}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': labelId,
          }}
        />
      </TableCell>
      <TableCell>
        <Typography variant='body2' sx={{textTransform: 'capitalize'}}>{truncate(`${name}`, {
          length: 20,
          omission: '...'
        })} ({truncate(`${title}`, {length: 20, omission: '...'})})</Typography>
      </TableCell>
      <TableCell>
        <Box display='flex' alignItems='center'>
          <Box mr={1}>
            {email && <Typography variant='body2'>{truncate(`${email}`, {length: 30, omission: '...'})}</Typography>}
            {phone && <Typography variant='body2'>{truncate(`${phone}`, {length: 30, omission: '...'})}</Typography>}
            {additional_notes &&
                <Typography variant='body2'>{truncate(`${additional_notes}`, {length: 20, omission: '...'})}</Typography>}
          </Box>
          <SocialMediaLink appName={messaging_appname || ''} username={messaging_username || ''}/>
        </Box>
      </TableCell>
      <TableCell>
        <ContactTypeChip contactType={contact_type || ''}/>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>{truncate(`${created_by_username}`, {length: 20, omission: '...'})}</Typography>
        <Typography
          variant='body2'>{created_at && convertIsoToCustomFormat(created_at, 'dd MMM yyyy HH:mm:ss')}</Typography>
      </TableCell>
    </TableRow>
  )
}

export default ContactRow;

import {Box, Skeleton, Stack, Typography} from "@mui/material";
import {fCurrency} from "shared/utils";
import TransactionActions from "apps/transactions/pages/TransactionDetails/partials/TransactionActions";
import React from "react";
import {useTheme} from "@mui/material/styles";

interface SkeletonTransactionHeadProps {
  children: React.ReactNode;
}

const SkeletonTransactionHead = ({children}: SkeletonTransactionHeadProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        backgroundRepeat: 'no-repeat',
        border: `1px solid ${theme.palette.themeColors.grey['40']}`,
        padding: theme.spacing(3),
        borderRadius: theme.spacing(1.5),
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Typography variant="h1" color={theme.palette.themeColors.grey['30']}>
            {fCurrency(0)}
          </Typography>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              marginTop: '2px',
            }}
          >
            <Stack direction="row" spacing={1}>
              <Skeleton variant="rounded" width={80} height={24} sx={{borderRadius: '16px'}}/>
            </Stack>
            <Stack
              direction="row"
              sx={{
                marginTop: '9px !important',
                alignItems: 'center',
              }}>
              <Typography
                variant="B4"
                color={theme.palette.themeColors.grey['90']}
                sx={{
                  fontWeight: 400,
                }}
              >
                <Skeleton variant="rounded" width={160} height={24}/>
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack>
          <TransactionActions
            transactionAction="capture"
            transactionStatus="test"
            transactionAmount={0}
            refundableBalance={0}
            transactionType="test"
            isTransactionProcessed={false}
            email=''
            sid={0}
          />
        </Stack>
      </Stack>
      <Box sx={{marginTop: '23px'}}>
        {children}
      </Box>
    </Box>
  )
}

export default SkeletonTransactionHead;

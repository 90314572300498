import React from 'react'
import {styled, Tab, TabProps} from '@mui/material'

// Components
import {AppBadge} from 'shared/components/mui';


interface TabLinkProps extends TabProps {
  to?: string,
}

interface AppTabProps extends TabLinkProps {
  item: {
    title: string
    icon?: React.ReactNode
    count?: number
  }
}

const MuiTab = styled((props: TabLinkProps) => <Tab disableRipple {...props} />)(
  ({theme}) => ({
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.palette.grey[600],
    padding: 0,
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(1.75),
    marginRight: theme.spacing(1.75),
    minWidth: 0,

    '&:first-of-type': {
      marginLeft: 0,
    },

    '&:last-child': {
      marginRight: 0,
    },

    '&.Mui-selected': {
      color: theme.palette.primary.dark,

      'svg *': {
        color: theme.palette.primary.dark,
        stroke: theme.palette.primary.dark
      }
    },
  })
)

const AppTab: React.FC<AppTabProps> = ({item, to, ...props}) => (
  <MuiTab
    to={to}
    label={
      <>
        <AppBadge badgeContent={item.count} sx={{mr: 1.25}}>
          {item.icon}
        </AppBadge>

        {item.title}
      </>
    }
    iconPosition='start'
    {...props}
  />
)

export default AppTab

import {Backdrop, CircularProgress} from "@mui/material";

interface LoaderBackdropProps {
  open: boolean;
}

const LoaderBackdrop = ({open}: LoaderBackdropProps) => (
  <Backdrop open={open} sx={{zIndex: 99, position: 'absolute', background: 'none', backdropFilter: 'blur(2px)'}}>
    <CircularProgress color="inherit" sx={{zIndex: 100}}/>
  </Backdrop>
);

export default LoaderBackdrop;

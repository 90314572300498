import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {AppCard} from "shared/components/mui";
import {Box} from "@mui/material";
import TextFormField from "shared/components/formFields/TextFormField";
import {UserSettingsSchema} from "generatedTypes";
import {useUpdateUserSettings} from "apps/account/pages/settings/useDataQueries";
import LoadingButton from "@mui/lab/LoadingButton";

const schema = yup.object().shape({
  firstName: yup.string().matches(/^[ A-Za-z-]*$/, 'First name can\'t contain special symbols').max(128).required('First name is required'),
  lastName: yup.string().matches(/^[ A-Za-z-]*$/, 'Last name can\'t contain special symbols').max(128).required('Last name is required'),
})

type FormData = yup.InferType<typeof schema>;

interface PersonalInfoProps {
  firstName: UserSettingsSchema["first_name"],
  lastName: UserSettingsSchema["last_name"],
}

const PersonalInfo = ({firstName, lastName}: PersonalInfoProps) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      firstName,
      lastName
    }
  });

  const {mutate, isPending} = useUpdateUserSettings({successText: 'Personal info updated successfully'});

  const onSubmit = (data: FormData) => {
    if (data.firstName !== firstName && data.lastName !== lastName) {
      mutate({requestBody: {first_name: data.firstName, last_name: data.lastName}});
    }

    if (data.firstName !== firstName && data.lastName === lastName) {
      mutate({requestBody: {first_name: data.firstName}});
    }

    if (data.lastName !== lastName && data.firstName === firstName) {
      mutate({requestBody: {last_name: data.lastName}});
    }
  };


  return (
    <AppCard
      title='Personal Info'
      headStyle={{pb: 0}}
      bodyStyle={{m: 0}}
      sx={{pb: 0}}
    >
      <FormProvider {...methods}>
        <Box component='form' mx={3} mt={1} onSubmit={methods.handleSubmit(onSubmit)}>
          <Box sx={{display: 'flex'}}>
            <Box sx={{width: '100%'}}>
              <TextFormField name="firstName" placeholder="First name"/>
            </Box>
            <Box sx={{width: '100%'}} ml={3}>
              <TextFormField name="lastName" placeholder="Last name"/>
            </Box>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <LoadingButton
              loading={isPending}
              variant="contained"
              loadingIndicator="Submitting…"
              type="submit"
              sx={{mt: 2, mb: 2, width: 120}}
              disabled={!methods.formState.isDirty || !methods.formState.isValid || methods.formState.isSubmitting}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>
    </AppCard>
  )
}

export default PersonalInfo;
import { Components } from '@mui/material/styles';

import typography from "theme/typography";
import palette from "theme/palette";

const MuiChip: Components['MuiChip'] = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      borderRadius: 16,
      textTransform: "capitalize",

      '& .MuiChip-label': {
        ...typography.B5,
        padding: '0px 6px',
      },
    },
    icon: {
      marginLeft: '6px',
    },
    colorSuccess: {
      backgroundColor: palette.themeColors.green['10'],
      color: palette.themeColors.green['20'],
      height: '24px',

      '& .MuiChip-label': {
        paddingLeft: '8px',
        paddingRight: '10px',
      }
    },
    colorWarning: {
      backgroundColor: palette.themeColors.yellow['10'],
      color: palette.themeColors.yellow['20'],
      height: '24px',

      '& .MuiChip-label': {
        paddingLeft: '8px',
        paddingRight: '10px',
      }
    },
    colorError: {
      backgroundColor: palette.themeColors.red['10'],
      color: palette.themeColors.red['20'],
      height: '24px',

      '& .MuiChip-label': {
        paddingLeft: '8px',
        paddingRight: '10px',
      }
    },
    colorPrimary: {
      backgroundColor: palette.themeColors.purple['10'],
      color: palette.themeColors.purple['20'],
      height: '24px',

      '& .MuiChip-label': {
        paddingLeft: '8px',
        paddingRight: '10px',
      }
    }
  },
  variants: [
    {
      props: {variant: 'approved'},
      style: {
        backgroundColor: '#EAF9F1',
        color: '#2AC36E',
      }
    },
    {
      props: {variant: 'pending'},
      style: {
        backgroundColor: '#FFF3E9',
        color: '#FF8A00',
      }
    },
    {
      props: {variant: 'cancelled'},
      style: {
        backgroundColor: '#FFF2F4',
        color: '#EC6764',
      }
    },
    {
      props: {variant: 'claimed'},
      style: {
        backgroundColor: '#42a5f5',
        color: '#1565c0',
      }
    },
    {
      props: {variant: 'declined'},
      style: {
        backgroundColor: '#FFF2F4',
        color: '#EC6764',
      }
    },
    {
      props: {variant: 'failed'},
      style: {
        backgroundColor: '#FFF2F4',
        color: '#EC6764',
      }
    },
    {
      props: {variant: 'expired'},
      style: {
        backgroundColor: '#FFF2F4',
        color: '#EC6764',
      }
    },
    {
      props: {variant: 'pg_error'},
      style: {
        backgroundColor: '#FFF2F4',
        color: '#EC6764',
      }
    },
    {
      props: {variant: 'pl_error'},
      style: {
        backgroundColor: '#FFF2F4',
        color: '#EC6764',
      }
    },
    {
      props: {variant: 'process_expired'},
      style: {
        backgroundColor: '#FFF2F4',
        color: '#EC6764',
      }
    },
    {
      props: {variant: 'returned'},
      style: {
        backgroundColor: '#FFF2F4',
        color: '#EC6764',
      }
    },
    {
      props: {variant: 'sent'},
      style: {
        backgroundColor: '#42a5f5',
        color: '#1565c0',
      }
    },
    {
      props: {variant: 'success'},
      style: {
        backgroundColor: '#EAF9F1',
        color: '#2AC36E',
      }
    },
    {
      props: {variant: 'completed'},
      style: {
        backgroundColor: '#EAF9F1',
        color: '#2AC36E',
      }
    },
    {
      props: {variant: 'error'},
      style: {
        backgroundColor: '#FFF2F4',
        color: '#EC6764',
      }
    },

    {
      props: {variant: 'blueBadge'},
      style: {
        backgroundColor: palette.themeColors.blue['10'],
        color: palette.themeColors.blue['30'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B4,
        },
      }
    },
    {
      props: {variant: 'orangeBadge'},
      style: {
        backgroundColor: palette.themeColors.orange['10'],
        color: palette.themeColors.orange['30'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B4,
        },
      }
    },
    {
      props: {variant: 'pinkBadge'},
      style: {
        backgroundColor: palette.themeColors.pink['10'],
        color: palette.themeColors.pink['30'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B4,
        },
      }
    },
    {
      props: {variant: 'magentaBadge'},
      style: {
        backgroundColor: palette.themeColors.magenta['10'],
        color: palette.themeColors.magenta['30'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B4,
        },
      }
    },
    {
      props: {variant: 'seaBadge'},
      style: {
        backgroundColor: palette.themeColors.sea['10'],
        color: palette.themeColors.sea['30'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B4,
        },
      }
    },
    {
      props: {variant: 'brownBadge'},
      style: {
        backgroundColor: palette.themeColors.brown['10'],
        color: palette.themeColors.brown['30'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B4,
        },
      }
    },
    {
      props: {variant: 'tealBadge'},
      style: {
        backgroundColor: palette.themeColors.teal['10'],
        color: palette.themeColors.teal['30'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B4,
        },
      }
    },
    {
      props: {variant: 'algeBadge'},
      style: {
        backgroundColor: palette.themeColors.alge['10'],
        color: palette.themeColors.alge['30'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B4,
        },
      },
    },
    {
      props: {variant: 'greyBadge'},
      style: {
        backgroundColor: palette.themeColors.grey['30'],
        color: palette.themeColors.grey['120'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B4,
        },
      },
    },
    {
      props: {variant: 'secondaryBadge'},
      style: {
        backgroundColor: palette.themeColors.grey['30'],
        color: palette.themeColors.grey['120'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B4,
        },
      },
    },
    {
      props: {variant: 'outlined'},
      style: {
        backgroundColor: 'white',
        color: palette.themeColors.grey['80'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        border: '1px solid',
        borderColor: palette.themeColors.grey['80'],
        '& .MuiChip-label': {
          ...typography.B4,
        },
      },
    },
    {
      props: {variant: 'contained'},
      style: {
        backgroundColor: palette.themeColors.grey['30'],
        color: palette.themeColors.grey['90'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B4,
        },
      },

    },
    {
      props: {variant: 'filterCondition'},
      style: {
        backgroundColor: palette.themeColors.purple['30'],
        color: palette.themeColors.purple['110'],
        borderRadius: 4,
        height: '1.25rem', // 20px
        '& .MuiChip-label': {
          ...typography.B7,
        },
      },

    },
  ]
};

export default MuiChip;

import {Card, CardContent, Typography} from "@mui/material";
import {round} from "lodash";

interface StatCardProps {
  amountTotal: number;
  countTotal: number;
  statName: string;
}

const StatCard = ({amountTotal, countTotal, statName}: StatCardProps) => (
  <Card>
    <CardContent>
      <Typography variant='h5'>
        {round(amountTotal, 2)}
      </Typography>
      <Typography sx={{mb: 1.5}} color="text.secondary">
        {countTotal}
      </Typography>
      <Typography color="text.secondary">
        {statName}
      </Typography>
    </CardContent>
  </Card>
);

export default StatCard;

import {FormProvider, useForm} from "react-hook-form";
import {Alert, Box} from "@mui/material";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import PasswordFormField from "shared/components/formFields/PasswordFormField";
import LoadingButton from "@mui/lab/LoadingButton";
import {useChangePassword} from "apps/account/pages/settings/ChangePassword/useDataQueries";
import {passwordUpdateSchema} from "apps/account/pages/settings/ChangePassword";
import AuthFormsContainer from "apps/auth/components/AuthFormsWrapper";

type FormData = yup.InferType<typeof passwordUpdateSchema>;

const PasswordUpdateRequiredForm = () => {
  const methods = useForm<FormData>({
    resolver: yupResolver(passwordUpdateSchema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  });

  const {isDirty, isValid} = methods.formState

  const {mutate, isPending, errorMessage} = useChangePassword(methods.reset)

  const onSubmit = (data: FormData) => {
    mutate({oldPassword: data.oldPassword, newPassword: data.newPassword, confirmPassword: data.confirmPassword})
  };

  return (
    <AuthFormsContainer subtitle="Your password expired, please update it.">
      <FormProvider {...methods}>
        <Box component='form' sx={{width: '100%'}} mx={3} mt={1} onSubmit={methods.handleSubmit(onSubmit)}>
          <Box mb={2}>
            <PasswordFormField name='oldPassword' placeholder='Old Password' />
          </Box>
          <Box mb={2}>
            <PasswordFormField name='newPassword' placeholder='New Password' />
          </Box>
          <Box mb={2}>
            <PasswordFormField name='confirmPassword' placeholder='Confirm Password' />
          </Box>

          {errorMessage && <Alert sx={{mt: 1}} severity="error">{errorMessage}</Alert>}

          <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 2}}>
            <LoadingButton
              loading={isPending}
              variant="contained"
              loadingIndicator="Submitting…"
              type="submit"
              sx={{mt: 1, width: 120}}
              disabled={!isValid || !isDirty}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>
    </AuthFormsContainer>
  );
}

export default PasswordUpdateRequiredForm;

import {DialogStateHandler} from "shared/hooks/useDialogState";
import UniversalDialog from "shared/components/DS/UniversalDialog";
import {Alert, Box, Grid, Stack, Typography} from "@mui/material";
import * as yup from "yup";
import {LimitValidator} from "shared/schemas";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import TextFormField from "shared/components/formFields/TextFormField";
import SidAutocompleteFormField from "shared/components/formDataFields/SidAutocompleteFormField";
import {useUserLimitsDetails} from "shared/features/ActionUpdateUserLimits/useDataQueries";
import {TransactionDetailModel} from "generatedTypes";
import {useEffect} from "react";
import {useCreateUserLimits} from "shared/features/ActionUpdateUserLimits/useDataMutations";
import {useTheme} from "@mui/material/styles";
import {ReactComponent as DollarIcon} from "assets/iconsDS/dollar.svg";


interface ActionUpdateUserLimitsProps {
  dialogStateHandler: DialogStateHandler,
  email?: TransactionDetailModel['email'],
  sid?: TransactionDetailModel['sid'],
}

const schema = yup.object().shape({
  sid: yup.number().required('Please select a SID.').nullable(),
  // email: yup.string().email().nullable(),
  paymentLimit: LimitValidator('paymentLimit'),
  payoutLimit: LimitValidator('payoutLimit'),
  // isBlacklisted: yup.boolean().required(),
  // expiryDate: yup.date().nullable(),
})

export type FormData = yup.InferType<typeof schema>;

const getValues = ({sid, data}: { sid?: number | null, data?: any }): FormData => ({
  sid: sid || null,
  paymentLimit: {
    twentyFourHours: data?.payment_limits?.["24h_limit"] || null,
    sevenDays: data?.payment_limits?.["7d_limit"] || null,
    thirtyDays: data?.payment_limits?.["30d_limit"] || null,
    perTransaction: data?.payment_limits?.transaction_limit || null,
  },
  payoutLimit: {
    twentyFourHours: data?.payout_limits?.["24h_limit"] || null,
    sevenDays: data?.payout_limits?.["7d_limit"] || null,
    thirtyDays: data?.payout_limits?.["30d_limit"] || null,
    perTransaction: data?.payout_limits?.transaction_limit || null,
  },
})

const ActionUpdateUserLimitsDialog = ({dialogStateHandler, email, sid}: ActionUpdateUserLimitsProps) => {
  const theme = useTheme();

  const defaultValues = getValues({sid});

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues
  });

  // const selectedEmail = methods.watch('email');
  const selectedSid = methods.watch('sid');

  const {data} = useUserLimitsDetails({contact: email, sid: selectedSid});

  const createLimitMutation = useCreateUserLimits({
    setError: methods.setError,
  })

  const onSubmit = (formData: FormData) => {
    const mutationData = {
      email: email!,
      sid: formData.sid!,
      payment_limit: {
        twenty_four_hours: formData.paymentLimit.twentyFourHours,
        seven_days: formData.paymentLimit.sevenDays,
        thirty_days: formData.paymentLimit.thirtyDays,
        per_transaction: formData.paymentLimit.perTransaction,
      },
      payout_limit: {
        twenty_four_hours: formData.payoutLimit.twentyFourHours,
        seven_days: formData.payoutLimit.sevenDays,
        thirty_days: formData.payoutLimit.thirtyDays,
        per_transaction: formData.payoutLimit.perTransaction,
      },
    }

    createLimitMutation.mutation.mutate({
      requestBody: mutationData,
    })
  }

  const onClose = () => {
    methods.reset(defaultValues);
    dialogStateHandler.closeDialog();
  }

  useEffect(() => {
    if (data) {
      const values = getValues({sid: data.sid, data});
      methods.reset(values)
    } else {
      const values = getValues({sid});
      methods.reset(values);
    }
    return () => {
      const emptyValues = getValues({});
      methods.reset(emptyValues);
    }
  }, [data, sid, dialogStateHandler.isOpen]);

  const limitFields = [
    {name: "twentyFourHours", label: "24 hours"},
    {name: "sevenDays", label: "7 days"},
    {name: "thirtyDays", label: "30 days"},
    {name: "perTransaction", label: "Transactions"},
  ];

  return (
    <FormProvider {...methods}>
      <UniversalDialog
        dialogStateHandler={dialogStateHandler}
        title="Update User limits"
        submitButtonText="Create"
        onSubmit={methods.handleSubmit(onSubmit)}
        onCancel={onClose}
        isPending={createLimitMutation.mutation.isPending}
      >
        <>
          <Stack
            sx={{
              padding: '16px 12px',
              backgroundColor: theme.palette.themeColors.grey[20],
              gap: '8px',
              borderRadius: '8px',
            }}
          >
            <Typography
              variant="B6"
              sx={{
                color: theme.palette.themeColors.grey[90],

              }}
            >
              CUSTOMER EMAIL
            </Typography>
            <Typography
              variant="B4"
              sx={{
                color: theme.palette.themeColors.grey[120],
              }}
            >
              {email}
            </Typography>
          </Stack>

          <Box
            sx={{
              margin: '28px 0'
            }}
          >
            <SidAutocompleteFormField
              name="sid"
              label="Choose the website"
              isReadonly={!!sid}
            />
          </Box>

          <Grid container spacing={3} mb={3}>
            <Grid item sm={6}>
              <Typography variant="B1">Payment limits</Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography variant="B1">Payout limits</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {limitFields.map((field) => (
              <>
                <Grid item sm={6} sx={{paddingTop: '16px !important'}}>
                  <TextFormField
                    key={`paymentLimit.${field.name}`}
                    name={`paymentLimit.${field.name}`}
                    type='number'
                    label={field.label}
                    startIcon={<DollarIcon/>}
                  />
                </Grid>
                <Grid item sm={6} sx={{paddingTop: '16px !important'}}>
                  <TextFormField
                    key={`payoutLimit.${field.name}`}
                    name={`payoutLimit.${field.name}`}
                    type='number'
                    label={field.label}
                    startIcon={<DollarIcon/>}
                  />
                </Grid>
              </>
            ))}
          </Grid>

          {(methods.formState.errors?.paymentLimit || methods.formState.errors?.payoutLimit) && (
            <Alert
              severity="error"
              sx={{
                marginTop: '16px',
              }}
            >
              {methods.formState.errors?.paymentLimit && (
                <Box>
                  Payment limits: {methods.formState.errors?.paymentLimit?.root?.message}
                </Box>
              )}
              {methods.formState.errors?.payoutLimit && (
                <Box>
                  Payout limits: {methods.formState.errors?.payoutLimit?.root?.message}
                </Box>
              )}
            </Alert>
          )}
        </>
      </UniversalDialog>
    </FormProvider>
  );
};

export default ActionUpdateUserLimitsDialog;

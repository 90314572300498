import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {MerchantQueryKeys} from "shared/queryKeys";
import {DialogStateHandler} from "shared/hooks/useDialogState";

type Params = Parameters<typeof ApiClient.merchants.deleteMerchantDirectoryContacts>[0]

interface UseDeleteMerchantDirectoryContactsAttrs {
  dialogStateHandler: DialogStateHandler
}

export const useDeleteMerchantDirectoryContacts = ({dialogStateHandler}: UseDeleteMerchantDirectoryContactsAttrs) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.merchants.deleteMerchantDirectoryContacts(params),
    onSuccess: async () => {
      enqueueSnackbar('Merchant directory contacts deleted', {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [MerchantQueryKeys.DIRECTORY_CONTACTS]})
      dialogStateHandler.closeDialog()
    },
  })

  return {
    deleteContacts: mutate,
    isPending
  }
}
import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {UserQueryKeys} from "shared/queryKeys";
import {useParams} from "react-router-dom";

type Params = Parameters<typeof ApiClient.users.postEditUserPermissions>[0]

export const useEditPermissions = () => {
  const {userId} = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.users.postEditUserPermissions(params),
    onSuccess: async () => {
      enqueueSnackbar('Permissions updated successfully', {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [UserQueryKeys.DETAILS, {userId}]})
    }
  })

  return {
    mutate,
    isPending
  }
}
import {Components, Theme} from '@mui/material/styles';
import {ReactComponent as ErrorIcon} from 'assets/iconsDS/alert/error.svg';

const MuiAlert: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    root: ({theme}) => ({
      borderRadius: '4px',
      padding: '8px',
      ...theme.typography.B3,
    }),
    icon: {
      alignItems: 'center',
    },
    colorError: ({theme}) => ({
      backgroundColor: theme.palette.themeColors.red['10'],
      color: theme.palette.themeColors.red['30'],
    })
  },
  defaultProps: {
    iconMapping: {
      error: <ErrorIcon />,
    },
  },
}

export default MuiAlert;

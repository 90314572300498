import React from 'react'
import { IconButton, IconButtonProps, styled } from '@mui/material'
import { Check, Close, TimerOutlined } from '@mui/icons-material'
import {PaymentStatus} from "apps/transactions/pages/TransactionDetails/types";

interface AppIconButtonProps extends IconButtonProps {
  icon?: React.ReactNode
  status?: PaymentStatus
  round?: boolean
  name?: string
}

const MuiIconButton = styled((props: AppIconButtonProps) => (
  <IconButton size='large' disableRipple color='inherit' {...props} />
))(({ theme, round = false, status }) => ({
  borderRadius: 12,
  backgroundColor: theme.palette.grey[200],

  ...(round && {
    borderRadius: '50%',
  }),

  ...(status?.toLocaleLowerCase() === 'approved' && {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  }),

  ...(status?.toLocaleLowerCase() === 'pending' && {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  }),

  ...(status?.toLocaleLowerCase() === 'rejected' && {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  }),
}))

const AppIconButton: React.FC<AppIconButtonProps> = ({
  icon,
  status,
  round = false,
  ...props
}) => (
  <MuiIconButton status={status} round={round} {...props}>
    {!status && icon}
    {status?.toLocaleLowerCase() === 'approved' ? (
      <Check fontSize='large' />
    ) : status?.toLocaleLowerCase() === 'pending' ? (
      <TimerOutlined fontSize='large' />
    ) : status?.toLocaleLowerCase() === 'rejected' ? (
      <Close fontSize='large' />
    ) : null}
  </MuiIconButton>
)

export default AppIconButton

import {createTheme} from '@mui/material/styles';

import typography from "theme/typography";
import palette from "theme/palette";
import MuiChip from "theme/components/MuiChip";
import MuiDialog from "theme/components/MuiDialog";
import MuiDialogTitle from "theme/components/MuiDialogTitle";
import MuiDialogContent from "theme/components/MuiDialogContent";
import MuiDialogActions from "theme/components/MuiDialogActions";
import MuiTooltip from "theme/components/MuiTooltip";
import MuiButton from "theme/components/MuiButton";
import MuiLoadingButton from "theme/components/MuiLoadingButton";
import MuiToggleButton from "theme/components/MuiToggleButton";
import MuiMenuItem from "theme/components/MuiMenuItem";
import MuiMenu from "theme/components/MuiMenu";
import MuiList from "theme/components/MuiList";
import MuiLink from "theme/components/MuiLink";
import MuiToggleButtonGroup from "theme/components/MuiToggleButtonGroup";
import MuiAccordion from "theme/components/MuiAccordion";
import MuiAccordionSummary from "theme/components/MuiAccordionSummary";
import MuiAvatar from "theme/components/MuiAvatar";
import MuiIconButton from "theme/components/MuiIconButton";
import MuiTimeline from "theme/components/MuiTimeline";
import MuiTimelineDot from "theme/components/MuiTimelineDot";
import MuiTimelineConnector from "theme/components/MuiTimelineConnector";
import MuiPaper from "theme/components/MuiPaper";
import MuiPaginationItem from "theme/components/MuiPaginationItem";
import MuiBackdrop from "theme/components/MuiBackdrop";
import type {} from '@mui/lab/themeAugmentation';
import MuiSwitch from "theme/components/MuiSwitch";
import MuiRadio from "theme/components/MuiRadio";
import MuiFormControl from "theme/components/MuiFormControl";
import MuiListItemIcon from "theme/components/MuiListItemIcon";
import MuiTab from "theme/components/MuiTab";
import MuiTabs from "theme/components/MuiTabs";
import MuiAlert from "theme/components/MuiAlert";

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiAccordion,
    MuiAccordionSummary,
    MuiAlert,
    MuiAvatar,
    MuiButton,
    MuiLoadingButton,
    MuiChip,
    MuiDialog,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogTitle,
    MuiFormControl,
    MuiIconButton,
    MuiLink,
    MuiList,
    MuiListItemIcon,
    MuiMenu,
    MuiMenuItem,
    MuiRadio,
    MuiSwitch,
    MuiTab,
    MuiTabs,
    MuiToggleButton,
    MuiToggleButtonGroup,
    MuiTooltip,
    MuiTimeline,
    MuiTimelineDot,
    MuiTimelineConnector,
    MuiPaginationItem,
    MuiPaper,
    MuiBackdrop,
  },
});

export default theme;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {Button, Paper, Card, Stack, Typography, FormHelperText} from "@mui/material";
import DialogGeneral from "shared/components/DialogGeneral";
import useDialogState from "shared/hooks/useDialogState";
import {FormProvider, useForm} from "react-hook-form";
import DocumentFieldArray from "shared/components/DocumentFieldArray";
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {useUploadDocuments} from "apps/actionCenter/pages/list/partials/UploadDocuments/useDataMutations";
import {useState} from "react";
import FileListSelector from "shared/components/FileListSelector";
import {startCase, toLower} from "lodash";
import CustomerDetailsForm from "shared/components/forms/CustomerDetailsForm";
import CheckboxFormField from "shared/components/formFields/CheckboxFormField";
import {ActionCenterSchema} from "generatedTypes";
import SubmissionInfo from "apps/actionCenter/pages/list/partials/UploadDocuments/SubmissionInfo";

const fieldsList = [
  "id_card",
  "drivers",
  "passport",
  "residence_permit",
  "utility_bill",
  "selfie",
  "video_selfie",
  "profile_image",
  "id_doc_photo",
  "agreement",
  "contract",
  "drivers_translation",
  "investor_doc",
  "vehicle_registration_certificate",
  "income_source",
  "payment_method",
  "bank_card",
  "covid_vaccination_form",
  "other"
]

interface UploadDocumentsProps {
  email: ActionCenterSchema['email'];
  id: ActionCenterSchema['_id'];
}

const schema = yup.object().shape({
  fields: yup.object().shape({
    id_card: yup.array<Blob>(),
    drivers: yup.array<Blob>(),
    passport: yup.array<Blob>(),
    residence_permit: yup.array<Blob>(),
    utility_bill: yup.array<Blob>(),
    selfie: yup.array<Blob>(),
    video_selfie: yup.array<Blob>(),
    profile_image: yup.array<Blob>(),
    id_doc_photo: yup.array<Blob>(),
    agreement: yup.array<Blob>(),
    contract: yup.array<Blob>(),
    drivers_translation: yup.array<Blob>(),
    investor_doc: yup.array<Blob>(),
    vehicle_registration_certificate: yup.array<Blob>(),
    income_source: yup.array<Blob>(),
    payment_method: yup.array<Blob>(),
    bank_card: yup.array<Blob>(),
    covid_vaccination_form: yup.array<Blob>(),
    other: yup.array<Blob>()
  }).test(
    'file-check',
    'At least one file must be uploaded',
    (value) => Object.values(value)
      .some(
        (fieldArray: { [key: string]: Blob | null }[]) => fieldArray.some(
          (fieldObject) => Object.values(fieldObject).some(
            (file) => file instanceof Blob)))
  ),
  kyc_data: yup.object().shape({
    first_name: yup.string().nullable(),
    last_name: yup.string().nullable(),
    phone_number: yup.string().nullable(),
    associated_email: yup.string().nullable().email(),
    address: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    country: yup.string().nullable(),
    postal_code: yup.string().nullable(),
    kyc_whitelist: yup.boolean().nullable(),
    kyc_whitelist_confirmation: yup.boolean().nullable().when('kyc_whitelist', (kyc_whitelist, _schema) =>
      kyc_whitelist ? _schema.required('Confirmation is required') : _schema
    ),
    kyc_confirmation: yup.boolean()
      .required('Confirmation is required')
      .oneOf([true], 'Confirmation is required')})
});

type FormData = yup.InferType<typeof schema>

function convertStructure(data: FormData['fields']) {
  const result: { [key: string]: string[] } = {};

  Object.entries(data).forEach(([key, items]) => {
    const files = items?.filter(item => Boolean(item[key])).map(item => item[key]);
    if (files?.length) {
      result[key] = files;
    }
  });

  return result;
}

const UploadDocuments = ({email, id}: UploadDocumentsProps) => {
  const [visibleGroups, setVisibleGroups] = useState<string[]>([fieldsList[0], fieldsList[1]]);
  const [showCustomerDetails, setShowCustomerDetails] = useState<boolean>(false);
  const [submittedData, setSubmittedData] = useState<FormData | null>(null);

  const dialogHandler = useDialogState();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fields: {
        id_card: [{id_card: null}],
        drivers: [{drivers: null}],
        passport: [{passport: null}],
        residence_permit: [{residence_permit: null}],
        utility_bill: [{utility_bill: null}],
        selfie: [{selfie: null}],
        video_selfie: [{video_selfie: null}],
        profile_image: [{profile_image: null}],
        id_doc_photo: [{id_doc_photo: null}],
        agreement: [{agreement: null}],
        contract: [{contract: null}],
        drivers_translation: [{drivers_translation: null}],
        investor_doc: [{investor_doc: null}],
        vehicle_registration_certificate: [{vehicle_registration_certificate: null}],
        income_source: [{income_source: null}],
        payment_method: [{payment_method: null}],
        bank_card: [{bank_card: null}],
        covid_vaccination_form: [{covid_vaccination_form: null}],
        other: [{other: null}]
      },
      kyc_data: {
        first_name: '',
        last_name: '',
        phone_number: '',
        associated_email: email,
        address: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
        kyc_whitelist: false,
        kyc_whitelist_confirmation: false,
        kyc_confirmation: false,
      }
    }
  });

  const kycWhitelist = methods.watch('kyc_data.kyc_whitelist');

  const {mutate, isPending, isSuccess} = useUploadDocuments({
    // @ts-ignore
    setError: methods.setError,
    reset: methods.reset,
  })

  const handleCustomerDetails = () => {
    setShowCustomerDetails((prev) => !prev);
  }

  const handleAddVisibleGroup = (field: string) => {
    setVisibleGroups((prev) => [...prev, field]);
  }

  const handleRemoveVisibleGroup = (field: string) => {
    setVisibleGroups((prev) => prev.filter((item) => item !== field));
  }

  const onSubmit = (data: FormData) => {
    const fields = convertStructure(data.fields);
    mutate({
      formData: {
        kyc_data: {
          ...data.kyc_data,
          id,
        },
        ...fields,
      }
    });
    setSubmittedData({fields, kyc_data: data.kyc_data});
  }

  const onClose = () => {
    methods.reset(methods.formState.defaultValues);
    setVisibleGroups([fieldsList[0], fieldsList[1]]);
    setShowCustomerDetails(false);
  }

  // @ts-ignore
  return (
    <>
      <Button
        onClick={dialogHandler.openDialog}
        variant="contained"
        size="small"
        sx={{ml: 1}}
      >
        Upload
      </Button>
      <DialogGeneral
        dialogStateHandler={dialogHandler}
        dialogTitle={`KYC documents for ${email}`}
        onSubmit={methods.handleSubmit(onSubmit)}
        isPending={isPending}
        submitBtnText={isSuccess ? undefined : "Submit"}
        onClose={onClose}
        maxWidth="sm"
      >
        {!isSuccess && (
          <>
            <Typography variant="body1">
          Requires the documents below <strong>{email}</strong>
            </Typography>
            <ol>
              <li>Select document type</li>
              <li>Upload document</li>
              <li>Check the box below to confirm</li>
            </ol>
            <FormProvider {...methods}>
              {visibleGroups.map((fieldName) =>
                <Card sx={{p: 2, mb: 2}} variant='outlined' key={fieldName}>
                  <DocumentFieldArray
                    arrayName={fieldName}
                    fieldName={fieldName}
                    sectionName={startCase(toLower(fieldName))}
                    groupsCount={visibleGroups.length}
                    handleRemoveVisibleGroup={handleRemoveVisibleGroup}
                  />
                </Card>
              )}
              {showCustomerDetails && (
                <Card sx={{p: 2}} variant='outlined'>
                  <CustomerDetailsForm
                    arrayName='kyc_data'
                    fields={{
                      email: {name: 'associated_email', label: 'Associated Email'},
                    }}
                  />
                </Card>
              )}

              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
              >
                <FileListSelector
                  fieldsList={fieldsList}
                  visibleGroups={visibleGroups}
                  handleAddVisibleGroup={handleAddVisibleGroup}
                />
                <Button onClick={handleCustomerDetails}>
                  {showCustomerDetails ? 'Remove customer details' : 'Add customer details'}
                </Button>
              </Stack>

              <Stack>
                <CheckboxFormField
                  name='kyc_data.kyc_whitelist'
                  label='Whitelist this customer.' />
                {kycWhitelist && (
                  <CheckboxFormField
                    name='kyc_data.kyc_whitelist_confirmation'
                    label='I authorize this customer to be whitelisted.' />
                )}
                <CheckboxFormField
                  name='kyc_data.kyc_confirmation'
                  label='I confirm that the documents uploaded are correct' />
              </Stack>

              {methods.formState.errors?.fields?.root?.message &&
            <FormHelperText error>
              {methods.formState.errors?.fields?.root?.message}
            </FormHelperText>
              }
            </FormProvider>
          </>
        )}

        {isSuccess && (
          <SubmissionInfo
            email={email}
            documents={submittedData?.fields}
            customerDetails={{
              first_name: submittedData?.kyc_data.first_name,
              last_name: submittedData?.kyc_data.last_name,
              phone_number: submittedData?.kyc_data.phone_number,
              associated_email: submittedData?.kyc_data.associated_email,
              address: submittedData?.kyc_data.address,
              city: submittedData?.kyc_data.city,
              state: submittedData?.kyc_data.state,
              country: submittedData?.kyc_data.country,
              postal_code: submittedData?.kyc_data.postal_code,
            }}
            whiteListStatus={Boolean(submittedData?.kyc_data.kyc_whitelist)}
          />
        )}
      </DialogGeneral>
    </>
  );
};

export default UploadDocuments;

import * as yup from "yup";

export const emailValidator = yup.string().email()

export const firstNameValidator = yup.string()
  .matches(/^[ A-Za-z-]*$/, 'First name can only contain letters')
  .max(128);

export const lastNameValidator = yup.string()
  .matches(/^[ A-Za-z-]*$/, 'Last name can only contain letters')
  .max(128);

const passwordValidator = yup.string()
  .min(8, 'Password must be at least 8 characters long')
  .max(20, 'Password must be at most 20 characters long');

export const newPasswordValidator = passwordValidator
  .matches(/^\S*$/, 'Password cannot contain whitespace characters')
  .matches(/(?=.*[a-z])/, 'Password must contain at least 1 lowercase letter')
  .matches(/(?=.*[A-Z])/, 'Password must contain at least 1 uppercase letter')
  .matches(/(?=.*\d)/, 'Password must contain at least 1 digit')
  .matches(/(?=.*[!#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, 'Password must contain at least 1 special character')

export const phoneValidator = yup.string().matches(
  /^\+?(\d{1,3})?[ .-]?(\()?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{3})$/,
  {message: 'Invalid phone number', excludeEmptyString: true}
)

const transformEmptyStringToNull = (value: any, originalValue: any) =>
  originalValue === '' ? null : value;

const createLimitField = (fieldName: string) =>
  yup.number()
    .min(0, `${fieldName} limit must be at least 0`)
    .max(30_000, `${fieldName} limit must be at most 30,000`)
    .nullable()
    .transform(transformEmptyStringToNull);

export const LimitValidator = (limitType: string) =>
  yup.object().shape({
    twentyFourHours: createLimitField('24 hours'),
    sevenDays: createLimitField('7 days'),
    thirtyDays: createLimitField('30 days'),
    perTransaction: createLimitField('per transaction'),
  }).test(limitType, `${limitType} Fill all fields if one is filled`, (value, testContext) => {
    const { twentyFourHours, sevenDays, thirtyDays, perTransaction } = value;
    const allFields = [twentyFourHours, sevenDays, thirtyDays, perTransaction];
    const filledFields = allFields.filter(field => field !== null && field !== undefined);
    if (filledFields.length > 0 && filledFields.length !== allFields.length) {
      return testContext.createError({path: limitType, message: 'All fields must be filled if one is filled' });
    }
    return true;
  });

import {useHasAccess} from "contexts/UserAccessContext";
import {Permissions, Roles} from "contexts/UserAccessContext/const";

const usePermissions = () => {
  const canViewUserList = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.MANAGE_USERS]
    }
  ])

  return {
    canViewUserList
  }
}

export default usePermissions

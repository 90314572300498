import {Box, Button, Chip, TableCell, TableRow, Typography} from "@mui/material";
import {ActionCenterSchema} from "generatedTypes";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";
import truncate from "lodash/truncate";
import ActionCenterDialog from "apps/actionCenter/pages/list/partials/ActionCenterDialog";
import useDialogState from "shared/hooks/useDialogState";
import useCompleteAction from "apps/actionCenter/pages/list/useDataMutations";
import LoadingButton from "@mui/lab/LoadingButton";
import usePermissions from "apps/actionCenter/pages/list/permissions/usePermissions";
import UploadDocuments from "apps/actionCenter/pages/list/partials/UploadDocuments";

enum STATUS_TYPES {
  PENDING = 'pending',
  SUBMITTED = 'submitted',
  COMPLETE = 'complete',
}

function ActionCenterRow({
  _id,
  email,
  site_name,
  sid,
  action_type,
  status,
  comment,
  status_timestamp,
}: ActionCenterSchema) {
  const detailsDialog = useDialogState();

  const  {canUpdateActionCenter, canUploadDocuments} = usePermissions()

  const {mutate, isPending} = useCompleteAction()

  const handleCompleteAction = () => {
    mutate({email, sid: String(sid), actionType: action_type})
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant='body2'>{email}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2'>
            {site_name} - ({sid})
          </Typography>
        </TableCell>
        <TableCell>
          <Chip label={action_type} color='secondary' />
        </TableCell>
        <TableCell>
          <Typography variant='body2'>
            {truncate(`${comment}`, {length: 40, omission: '...'})}
          </Typography>
        </TableCell>
        <TableCell>
          <Chip label={status} color={status === 'complete' ? 'success' : 'warning'} />
        </TableCell>
        <TableCell>
          <Typography variant='body2'>
            {status_timestamp && convertIsoToCustomFormat(status_timestamp)}
          </Typography>
        </TableCell>
        <TableCell>
          <Box display='flex' alignItems='center'>
            <Button variant='contained' size='small' onClick={detailsDialog.openDialog}>Details</Button>
            {(canUpdateActionCenter && [STATUS_TYPES.PENDING, STATUS_TYPES.SUBMITTED].includes(status as STATUS_TYPES)) && (
              <LoadingButton
                loading={isPending}
                variant="contained"
                size="small"
                loadingIndicator="Submitting…"
                onClick={handleCompleteAction}
                sx={{ml: 1}}
              >
                Complete
              </LoadingButton>
            )}

            { (canUploadDocuments && status as STATUS_TYPES !== STATUS_TYPES.COMPLETE) && (
              <UploadDocuments email={email} id={_id} />
            )}

          </Box>
        </TableCell>
      </TableRow>

      <ActionCenterDialog dialogHandler={detailsDialog} email={email} sid={String(sid)} />
    </>
  )
}

export default ActionCenterRow;

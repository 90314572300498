import {DialogStateHandler} from "shared/hooks/useDialogState";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import CloseIconButton from "shared/CloseButton";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import {useSnackbar} from "notistack";
import {VerifyAuthAppSchema} from "generatedTypes";

interface RecoveryCodeDialogProps {
  dialogStateHandler: DialogStateHandler,
  oneTimeUseCodes: VerifyAuthAppSchema["one_time_use_codes"]
}

const RecoveryCodeDialog = ({dialogStateHandler, oneTimeUseCodes}: RecoveryCodeDialogProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isCopied, copyToClipboard] = useCopyToClipboard();

  if (!oneTimeUseCodes) {
    return null;
  }

  const handleCopyToClipboard = () => {
    void copyToClipboard(oneTimeUseCodes.join(" "));
    if (isCopied) {
      enqueueSnackbar("Copied to clipboard!", { variant: 'success' });
    } else {
      enqueueSnackbar("Copied to clipboard!", { variant: 'success' });
    }
  };

  return (
    <Dialog open={dialogStateHandler.isOpen} onClose={dialogStateHandler.closeDialog} fullWidth maxWidth='xs'>
      <DialogTitle id="modal-dialog-title">
        Authenticator App Verification Activated
      </DialogTitle>
      <CloseIconButton onClick={dialogStateHandler.closeDialog}/>
      <Box sx={{width: '100%'}}>
        <DialogContent>
          <Typography>
            Please save your one time use recovery codes below. You will not be able to view them again!
          </Typography>
          <Box sx={{mt: 2}}>
            <Grid container lg={12} spacing={2}>
              {oneTimeUseCodes.map((code) => (
                <Grid item lg={6} key={code}>
                  <Typography align='center' color='text.secondary' fontWeight={600} variant="body2">{code}</Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCopyToClipboard}
            sx={{width: '100%'}}
          >
            Copy to clipboard
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
};

export default RecoveryCodeDialog;
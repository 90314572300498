import { Components } from '@mui/material/styles';

import typography from "theme/typography";
import palette from "theme/palette";

const MuiAccordion: Components['MuiAccordion'] = {
  styleOverrides: {
    root: {
      ...typography.B4,
      boxShadow: 'none',
      borderRadius: '8px',
      border: '1px solid',
      borderColor: palette.themeColors.grey['40'],

      '&:last-of-type': {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
      },

      '&::before': {
        display: 'none',
      }
    },
  }
}

export default MuiAccordion;
/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomerNoteSchema } from '../models/CreateCustomerNoteSchema';
import type { CustomerAccountsPaginatedSchema } from '../models/CustomerAccountsPaginatedSchema';
import type { CustomerAliasesSchema } from '../models/CustomerAliasesSchema';
import type { CustomerBlacklistPaginatedSchema } from '../models/CustomerBlacklistPaginatedSchema';
import type { CustomerCardsPaginatedSchema } from '../models/CustomerCardsPaginatedSchema';
import type { CustomerDeleteAliasSchema } from '../models/CustomerDeleteAliasSchema';
import type { CustomerDeleteBlacklistSchema } from '../models/CustomerDeleteBlacklistSchema';
import type { CustomerDetailsSchema } from '../models/CustomerDetailsSchema';
import type { CustomerInformationSchema } from '../models/CustomerInformationSchema';
import type { CustomerMerchantPaginatedSchema } from '../models/CustomerMerchantPaginatedSchema';
import type { CustomerNameMatchingAliases } from '../models/CustomerNameMatchingAliases';
import type { CustomerNotesPaginatedSchema } from '../models/CustomerNotesPaginatedSchema';
import type { CustomerOverview } from '../models/CustomerOverview';
import type { CustomerRecentOrdersPaginatedSchema } from '../models/CustomerRecentOrdersPaginatedSchema';
import type { CustomerRetailerPaginatedSchema } from '../models/CustomerRetailerPaginatedSchema';
import type { CustomerSitePaginatedSchema } from '../models/CustomerSitePaginatedSchema';
import type { CustomersListPaginatedSchema } from '../models/CustomersListPaginatedSchema';
import type { CustomerTopMerchantsSchema } from '../models/CustomerTopMerchantsSchema';
import type { CustomerTransactionChartSchema } from '../models/CustomerTransactionChartSchema';
import type { FinancialDetailsModel } from '../models/FinancialDetailsModel';
import type { RelatedCustomersModel } from '../models/RelatedCustomersModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CustomersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Customers
     * @returns CustomersListPaginatedSchema Successful Response
     * @throws ApiError
     */
    public getCustomersList({
        page,
        perPage,
        search,
        orderBy,
    }: {
        page?: (number | null),
        perPage?: (number | null),
        search?: (string | null),
        orderBy?: (string | null),
    }): CancelablePromise<CustomersListPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/',
            query: {
                'page': page,
                'per_page': perPage,
                'search': search,
                'order_by': orderBy,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Customer Information
     * @returns CustomerInformationSchema Successful Response
     * @throws ApiError
     */
    public getCustomersInformation({
        email,
    }: {
        email: string,
    }): CancelablePromise<CustomerInformationSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/information/',
            path: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Customer Overview
     * @returns CustomerOverview Successful Response
     * @throws ApiError
     */
    public getCustomerOverview({
        email,
    }: {
        email: string,
    }): CancelablePromise<CustomerOverview> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/overview/',
            path: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Customer Financial Details
     * @returns FinancialDetailsModel Successful Response
     * @throws ApiError
     */
    public getCustomerFinancialDetails({
        email,
    }: {
        email: string,
    }): CancelablePromise<FinancialDetailsModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/financial-details/',
            path: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Related Customers
     * @returns RelatedCustomersModel Successful Response
     * @throws ApiError
     */
    public getCustomerRelatedCustomers({
        email,
    }: {
        email: string,
    }): CancelablePromise<RelatedCustomersModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/related-customers/',
            path: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get customer details
     * @returns CustomerDetailsSchema Success
     * @throws ApiError
     */
    public getCustomerDetails({
        email,
        xFields = '{*}',
    }: {
        email: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerDetailsSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Get customer accounts
     * @returns CustomerAccountsPaginatedSchema Success
     * @throws ApiError
     */
    public getCustomerAccount({
        email,
        page,
        perPage,
        xFields,
    }: {
        email: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerAccountsPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/accounts/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Get customer aliases
     * @returns CustomerAliasesSchema Success
     * @throws ApiError
     */
    public getCustomerAliases({
        email,
        xFields,
    }: {
        email: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerAliasesSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/aliases/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Delete customer aliases
     * Roles: ADMIN, CUSTOMER_SERVICE
     * PERMISSION: UPDATE_CUSTOMER_ALIASES
     * @returns CustomerDeleteAliasSchema Success
     * @throws ApiError
     */
    public deleteCustomerAliases({
        email,
        requestBody,
        xFields,
    }: {
        email: string,
        requestBody: {
            alias_email_address?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerDeleteAliasSchema> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/customers/{email}/aliases/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get customer blacklist
     * @returns CustomerBlacklistPaginatedSchema Success
     * @throws ApiError
     */
    public getCustomerBlacklist({
        email,
        page,
        perPage,
        xFields,
    }: {
        email: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerBlacklistPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/blacklist/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Delete customer blacklist
     * Roles: ADMIN
     * Permissions: REMOVE_FROM_BLACKLIST
     * @returns CustomerDeleteBlacklistSchema Success
     * @throws ApiError
     */
    public postCustomerBlacklist({
        email,
        requestBody,
        xFields,
    }: {
        email: string,
        requestBody: {
            _id?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerDeleteBlacklistSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/customers/{email}/blacklist/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get customer cards
     * @returns CustomerCardsPaginatedSchema Success
     * @throws ApiError
     */
    public getCustomerCards({
        email,
        page,
        perPage,
        xFields,
    }: {
        email: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerCardsPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/cards/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Get customer merchants
     * @returns CustomerMerchantPaginatedSchema Success
     * @throws ApiError
     */
    public getCustomerMerchants({
        email,
        page,
        perPage,
        xFields,
    }: {
        email: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerMerchantPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/merchants/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Get customer name matching aliases
     * @returns CustomerNameMatchingAliases Success
     * @throws ApiError
     */
    public getCustomerNameMatchingAliases({
        email,
        xFields,
    }: {
        email: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerNameMatchingAliases> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/name-matching-aliases/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Delete customer name matching aliases
     * Roles: ADMIN, CUSTOMER_SERVICE
     * PERMISSION: UPDATE_CUSTOMER_ALIASES
     * @returns CustomerDeleteAliasSchema Success
     * @throws ApiError
     */
    public deleteCustomerNameMatchingAliases({
        email,
        requestBody,
        xFields,
    }: {
        email: string,
        requestBody: {
            full_name?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerDeleteAliasSchema> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/customers/{email}/name-matching-aliases/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get customer notes
     * @returns CustomerNotesPaginatedSchema Success
     * @throws ApiError
     */
    public getCustomerNotes({
        email,
        page,
        perPage,
        noteType,
        xFields,
    }: {
        email: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * Customer email
         */
        noteType?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerNotesPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/notes/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
                'note_type': noteType,
            },
        });
    }
    /**
     * Create customers note
     * @returns CreateCustomerNoteSchema Success
     * @throws ApiError
     */
    public postCustomerNotes({
        email,
        requestBody,
        xFields,
    }: {
        email: string,
        requestBody: {
            note?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CreateCustomerNoteSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/customers/{email}/notes/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get customer recent orders
     * @returns CustomerRecentOrdersPaginatedSchema Success
     * @throws ApiError
     */
    public getCustomerRecentOrders({
        email,
        page,
        perPage,
        xFields,
    }: {
        email: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerRecentOrdersPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/recent-orders/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Get customer retailers
     * @returns CustomerRetailerPaginatedSchema Success
     * @throws ApiError
     */
    public getCustomerRetailers({
        email,
        page,
        perPage,
        xFields,
    }: {
        email: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerRetailerPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/retailers/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Get customer sites
     * @returns CustomerSitePaginatedSchema Success
     * @throws ApiError
     */
    public getCustomerSites({
        email,
        page,
        perPage,
        xFields,
    }: {
        email: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerSitePaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/sites/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Get customer top merchants
     * @returns CustomerTopMerchantsSchema Success
     * @throws ApiError
     */
    public getCustomerTopMerchants({
        email,
        page,
        perPage,
        xFields,
    }: {
        email: string,
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerTopMerchantsSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/top-merchants/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Get customer transactions chart data
     * email: customer email
     *
     * chart_type: amount or count
     *
     * chart_payment_type: payment or payout
     *
     * merchant_selector: mid, rid or sid
     *
     * merchant_id: merchant id (optional)
     *
     * start_date: start date (format: YYYY-MM-DD)
     *
     * end_date: end date (format: YYYY-MM-DD)
     * @returns CustomerTransactionChartSchema Success
     * @throws ApiError
     */
    public getCustomerTransactions({
        email,
        startDate,
        endDate,
        chartType,
        chartPaymentType,
        merchantSelector,
        merchantId,
        xFields,
    }: {
        email: string,
        startDate: string,
        endDate: string,
        chartType: string,
        chartPaymentType: string,
        merchantSelector: string,
        merchantId?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CustomerTransactionChartSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/customers/{email}/transactions-chart/',
            path: {
                'email': email,
            },
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'chart_type': chartType,
                'chart_payment_type': chartPaymentType,
                'merchant_selector': merchantSelector,
                'merchant_id': merchantId,
            },
        });
    }
}

import AppIcon from "shared/components/AppIcon";
import ICONS from "shared/components/icons";
// import uiPaths from "routes/uiPaths";
import React from "react";

export interface ISidebarMenuItem {
  name: string;
  icon?: React.ReactNode;
  link: string;
  subMenu?: ISidebarMenuItem[];
}

const menuItems: ISidebarMenuItem[] = [
  {
    name: 'Dashboard',
    icon: <AppIcon src={ICONS.dashboard}/>,
    link: '/',
  },
  // {
  //   name: 'Advanced Reporting',
  //   icon: <AppIcon src={ICONS.activity}/>,
  //   link: '/advanced-reporting',
  // },
  {
    name: 'Transactions',
    icon: <AppIcon src={ICONS.repeat}/>,
    link: '/transactions/',
  },
  {
    name: 'Customers',
    icon: <AppIcon src={ICONS.groups}/>,
    link: '/customers/',
  },
  // {
  //   name: 'Notes',
  //   icon: <AppIcon src={ICONS.notes}/>,
  //   link: '/notes',
  // },
  // {
  //   name: 'Dashboard Requests',
  //   icon: <AppIcon src={ICONS.computer}/>,
  //   link: '/dashboard-requests',
  // },
  // {
  //   name: 'Emails',
  //   icon: <AppIcon src={ICONS.email}/>,
  //   link: '/emails',
  // },
  // {
  //   name: 'Merchants',
  //   icon: <AppIcon src={ICONS.briefcase}/>,
  //   link: '/merchants',
  // },
  // {
  //   name: 'Virtual Terminal',
  //   icon: <AppIcon src={ICONS.terminal}/>,
  //   link: uiPaths.virtualTerminalPaths.index.reverse(),
  // },
  // {
  //   name: 'Resellers',
  //   icon: <AppIcon src={ICONS.cpu}/>,
  //   link: '/resellers',
  // },
  // {
  //   name: 'Batches',
  //   icon: <AppIcon src={ICONS.layers}/>,
  //   link: '/batches',
  // },
  // {
  //   name: 'Batch Groups',
  //   icon: <AppIcon src={ICONS.database}/>,
  //   link: '/batch-groups',
  // },
  // {
  //   name: 'Flinks Process',
  //   icon: <AppIcon src={ICONS.doubleArrowLeft}/>,
  //   link: '/flinks-process',
  // },
  // {
  //   name: 'Blacklist',
  //   icon: <AppIcon src={ICONS.block}/>,
  //   link: '/blacklist',
  // },
  // {
  //   name: 'Users',
  //   icon: <AppIcon src={ICONS.users}/>,
  //   link: '/users',
  // },
  // // {
  // //   name: 'Logs',
  // //   icon: <AppIcon src={ICONS.log}/>,
  // //   link: '/logs',
  // // },
  // {
  //   name: 'Know Your Customer',
  //   icon: <AppIcon src={ICONS.users}/>,
  //   link: '/kyc-requests',
  // },
  // {
  //   name: 'Action Center',
  //   icon: <AppIcon src={ICONS.users}/>,
  //   link: '/action-center',
  // },
  // {
  //   name: 'Notifications',
  //   icon: <AppIcon src={ICONS.notification}/>,
  //   link: '/notifications'
  // },
  // {
  //   name: 'Plugins',
  //   icon: <AppIcon src={ICONS.plus}/>,
  //   link: '/plugins',
  // },
  // {
  //   name: 'Merchant Directory',
  //   icon: <AppIcon src={ICONS.notebook}/>,
  //   link: '/merchant-directory',
  // },
  // {
  //   name: 'Batch Upload',
  //   icon: <AppIcon src={ICONS.upload}/>,
  //   link: uiPaths.batchUploadPaths.index.reverse(),
  // },
  // {
  //   name: 'Dashboard Requests',
  //   icon: <AppIcon src={ICONS.notebook}/>,
  //   link: '/dashboard-requests',
  // },
  // {
  //   name: 'User Limits/Blacklist',
  //   icon: <AppIcon src={ICONS.bag}/>,
  //   link: '/user-limits',
  // },
  // {
  //   name: 'Action Center 2',
  //   icon: <AppIcon src={ICONS.bag}/>,
  //   link: '/user-limits',
  //   subMenu: [
  //     {
  //       name: 'KYC',
  //       icon: <AppIcon src={ICONS.bag}/>,
  //       link: '/submenu1',
  //     },
  //     {
  //       name: 'Dashboard requests',
  //       icon: <AppIcon src={ICONS.bag}/>,
  //       link: '/submenu2',
  //     },
  //     {
  //       name: 'User Limits',
  //       icon: <AppIcon src={ICONS.bag}/>,
  //       link: '/submenu3',
  //     },
  //   ]
  // },
]

export default menuItems;

import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {ExportQueryKeys} from "shared/queryKeys";

type Params = Parameters<typeof ApiClient.export.createTemplate>[0]

// interface CreateTemplateArgs {
//   setTemplateName: (name: string) => void;
// }

const useCreateTemplate = () => {
  const {enqueueSnackbar} = useSnackbar()
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.export.createTemplate(params),
    onSuccess: async () => {
      enqueueSnackbar('Template has been saved', {variant: 'success'});
      await queryClient.invalidateQueries({queryKey: [ExportQueryKeys.AGGRID_COLUMNS]})
    },
    onError: () => {
      enqueueSnackbar('Failed to save template', {variant: 'error'});
    }
  })

  return {
    mutate,
    isPending,
  }
}

export default useCreateTemplate;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_update_user_limit_or_blacklist } from '../models/Body_update_user_limit_or_blacklist';
import type { Body_update_user_limit_or_blacklist_by_id } from '../models/Body_update_user_limit_or_blacklist_by_id';
import type { CreateUserLimitOrBlacklistModelBase_Input } from '../models/CreateUserLimitOrBlacklistModelBase_Input';
import type { CreateUserLimitOrBlacklistModelBase_Output } from '../models/CreateUserLimitOrBlacklistModelBase_Output';
import type { DeleteUserLimitRequest } from '../models/DeleteUserLimitRequest';
import type { UpdateUserLimitOrBlacklistModelBase_Output } from '../models/UpdateUserLimitOrBlacklistModelBase_Output';
import type { UserLimitModel } from '../models/UserLimitModel';
import type { UserLimitsModelPaginatedModel } from '../models/UserLimitsModelPaginatedModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserLimitsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create User Limit Or Blacklist
     * # Create User Limit or Blacklist Endpoint
     *
     * This endpoint is used to create a user limit or blacklist.
     *
     * ## User Limit or Blacklist Creation
     *
     * The user limit or blacklist is created by the user and it includes the user limit or blacklist data.
     *
     * The user limit or blacklist data is then sent to the Dashboard API for further processing.
     *
     * ## Validation
     *
     * The user limit or blacklist data is validated before being processed. The validation checks include:
     * - Checking if the user role is MERCHANT and if they have access to the specified SID.
     * - Checking if the user limit or blacklist data is provided.
     *
     * ## Response
     *
     * The response from the Dashboard API is returned as the response of this endpoint. If successful, a message indicating success is returned.
     *
     * ## Permissions
     *
     * Users must have the following role:
     * - MERCHANT
     *
     * Users must also have the following permission:
     * - UPDATE_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST
     * @returns CreateUserLimitOrBlacklistModelBase_Output Successful Response
     * @throws ApiError
     */
    public createUserLimitOrBlacklist({
        requestBody,
    }: {
        requestBody: CreateUserLimitOrBlacklistModelBase_Input,
    }): CancelablePromise<CreateUserLimitOrBlacklistModelBase_Output> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/user-limits/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Limits
     * # Get User Limits Endpoint
     *
     * This endpoint is used to get a list of user limits.
     *
     * ## User Limits Retrieval
     *
     * The user limits are retrieved based on the provided filters. The filters include:
     * - `page`: The page number.
     * - `per_page`: The number of items per page.
     * - `order_by`: The field to order the results by.
     * - `search`: The search query.
     *
     * The user limits data is then sent to the Dashboard API for further processing.
     *
     * ## Response
     *
     * The response from the Dashboard API is returned as the response of this endpoint. It includes a paginated list of user limits.
     *
     * ## Permissions
     *
     * Users must have the following role:
     * - MERCHANT
     *
     * Users must also have the following permission:
     * - ACCESS_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST
     * @returns UserLimitsModelPaginatedModel Successful Response
     * @throws ApiError
     */
    public getUserLimits({
        page,
        perPage,
        search,
        orderBy,
    }: {
        page?: (number | null),
        perPage?: (number | null),
        search?: (string | null),
        orderBy?: (string | null),
    }): CancelablePromise<UserLimitsModelPaginatedModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/user-limits/',
            query: {
                'page': page,
                'per_page': perPage,
                'search': search,
                'order_by': orderBy,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update User Limit Or Blacklist
     * # Update User Limit or Blacklist Endpoint
     *
     * This endpoint is used to update the user limit or blacklist status.
     *
     * ## Request
     *
     * The request should include the following parameters:
     *
     * - `id`: The ID of the user limit or blacklist entry to be updated.
     * - `request`: The request object, which includes the state of the application and the user making the request.
     * - `payload`: An instance of the `UpdateUserLimitOrBlacklist` model, which includes the new values for the user limit or blacklist entry.
     *
     * ## Process
     *
     * The function first creates a pipeline to match the user limit or blacklist entry with the provided ID.
     *
     * It then retrieves the existing user limit or blacklist entry from the database.
     *
     * The function then creates an instance of the `UpdateUserLimitOrBlacklistService` service, passing in the request, payload, and existing user limit or blacklist entry.
     *
     * The function then retrieves the JSON data, payment object, and payout object from the service.
     *
     * The function then posts the payment object, payout object, and blacklist status to the appropriate endpoints.
     *
     * ## Response
     *
     * The function returns the JSON data of the updated user limit or blacklist entry.
     *
     * ## Permissions
     *
     * Users must have the MERCHANT role and the UPDATE_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST permission to access this endpoint.
     * @returns UpdateUserLimitOrBlacklistModelBase_Output Successful Response
     * @throws ApiError
     */
    public updateUserLimitOrBlacklist({
        formData,
        id,
    }: {
        formData: Body_update_user_limit_or_blacklist,
        id?: string,
    }): CancelablePromise<UpdateUserLimitOrBlacklistModelBase_Output> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/user-limits/',
            query: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete User Limit Or Blacklist
     * # Delete User Limit or Blacklist Endpoint
     *
     * This endpoint is used to delete a user limit or blacklist.
     *
     * ## User Limit or Blacklist Deletion
     *
     * The user limit or blacklist is deleted by the user by providing the id of the user limit or blacklist.
     *
     * The id is then sent to the Dashboard API for further processing.
     *
     * ## Validation
     *
     * The id is validated before being processed. The validation checks include:
     * - Checking if the user role is MERCHANT and if they have access to the specified SID.
     * - Checking if the id is provided.
     *
     * ## Response
     *
     * The response from the Dashboard API is returned as the response of this endpoint. If successful, a message indicating success is returned.
     *
     * ## Permissions
     *
     * Users must have the following role:
     * - MERCHANT
     *
     * Users must also have the following permission:
     * - UPDATE_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST
     * @returns DeleteUserLimitRequest Successful Response
     * @throws ApiError
     */
    public deleteUserLimitOrBlacklist({
        id,
    }: {
        id: string,
    }): CancelablePromise<DeleteUserLimitRequest> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/user-limits/{id}/',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update User Limit Or Blacklist
     * # Update User Limit or Blacklist Endpoint
     *
     * This endpoint is used to update the user limit or blacklist status.
     *
     * ## Request
     *
     * The request should include the following parameters:
     *
     * - `id`: The ID of the user limit or blacklist entry to be updated.
     * - `request`: The request object, which includes the state of the application and the user making the request.
     * - `payload`: An instance of the `UpdateUserLimitOrBlacklist` model, which includes the new values for the user limit or blacklist entry.
     *
     * ## Process
     *
     * The function first creates a pipeline to match the user limit or blacklist entry with the provided ID.
     *
     * It then retrieves the existing user limit or blacklist entry from the database.
     *
     * The function then creates an instance of the `UpdateUserLimitOrBlacklistService` service, passing in the request, payload, and existing user limit or blacklist entry.
     *
     * The function then retrieves the JSON data, payment object, and payout object from the service.
     *
     * The function then posts the payment object, payout object, and blacklist status to the appropriate endpoints.
     *
     * ## Response
     *
     * The function returns the JSON data of the updated user limit or blacklist entry.
     *
     * ## Permissions
     *
     * Users must have the MERCHANT role and the UPDATE_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST permission to access this endpoint.
     * @returns UpdateUserLimitOrBlacklistModelBase_Output Successful Response
     * @throws ApiError
     */
    public updateUserLimitOrBlacklistById({
        id,
        formData,
    }: {
        id: string,
        formData: Body_update_user_limit_or_blacklist_by_id,
    }): CancelablePromise<UpdateUserLimitOrBlacklistModelBase_Output> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/user-limits/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Limits Detail
     * @returns UserLimitModel Successful Response
     * @throws ApiError
     */
    public getUserLimitsDetail({
        contact,
        sid,
    }: {
        contact: string,
        sid: number,
    }): CancelablePromise<UserLimitModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/user-limits/{contact}/{sid}/',
            path: {
                'contact': contact,
                'sid': sid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}

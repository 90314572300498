import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIconButton from "shared/CloseButton";
import LoadingButton from "@mui/lab/LoadingButton";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import {FormProvider, useForm} from "react-hook-form";
import TextFormField from "shared/components/formFields/TextFormField";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useVerifyUserEmail} from "apps/account/pages/settings/ContacInfo/useDataQueries";

interface VerifyEmailDialogProps {
  dialogStateHandler: DialogStateHandler,
}

const schema = yup.object().shape({
  securityCode: yup.string().max(5).required('Security code is required'),
})

type FormData = yup.InferType<typeof schema>;

const VerifyEmailDialog = ({dialogStateHandler}: VerifyEmailDialogProps) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const {mutate, isPending, errorMessage} = useVerifyUserEmail(dialogStateHandler)

  const onSubmit = (data: FormData) => {
    if (data.securityCode){
      mutate({securityCode: data.securityCode})
    }
  }

  return (
    <Dialog open={dialogStateHandler.isOpen} onClose={dialogStateHandler.closeDialog} fullWidth maxWidth='xs'>
      <DialogTitle id="modal-dialog-title">
        Verify user email
      </DialogTitle>
      <CloseIconButton onClick={dialogStateHandler.closeDialog}/>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} sx={{mt: 1, width: '100%'}}>
        <FormProvider {...methods}>
          <DialogContent>
            <>
              <TextFormField name="securityCode" placeholder="Security code"/>
              {errorMessage && <Alert severity="error" sx={{mt: 1}}>{ errorMessage }</Alert>}
            </>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={isPending}
              variant="contained"
              loadingIndicator="Submitting…"
              type="submit"
              disabled={!methods.formState.isDirty || !methods.formState.isValid}
              sx={{width: 120}}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Box>
    </Dialog>
  )
}

export default VerifyEmailDialog;
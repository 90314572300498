import * as yup from "yup";

export default {
  password: yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .max(20, 'Password must be at most 20 characters long')
    .matches(/^\S*$/, 'Password cannot contain whitespace characters')
    .matches(/(?=.*[a-z])/, 'Password must contain at least 1 lowercase letter')
    .matches(/(?=.*[A-Z])/, 'Password must contain at least 1 uppercase letter')
    .matches(/(?=.*\d)/, 'Password must contain at least 1 digit')
    .matches(/(?=.*[!#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, 'Password must contain at least 1 special character'),
  confirm_password: yup.string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
}

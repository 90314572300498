import {useState} from 'react';
import {Box, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useFormContext} from "react-hook-form";
import useCurrentUser from "shared/hooks/useCurrentUser";
import {Roles} from "contexts/UserAccessContext/const";
import RidAutocompleteFormField from "shared/components/formDataFields/RidAutocompleteFormField";
import SidAutocompleteFormField from "shared/components/formDataFields/SidAutocompleteFormField";
import MidAutocompleteFormField from "shared/components/formDataFields/MidAutocompleteFormField";

type Selection = 'rids' | 'mids' | 'sids';

const RidMidSidSelect = () => {
  const {user} = useCurrentUser();

  const [activeSelection, setActiveSelection] = useState<Selection>('sids');
  // const [valueId, setValueId] = useState<string>('');
  const methods = useFormContext();

  const handleActiveSelectionChange = (event: SelectChangeEvent) => {
    methods.setValue(activeSelection, []);
    methods.setValue('sids', []);
    methods.setValue('mids', []);
    methods.setValue('rids', []);
    setActiveSelection(event.target.value as Selection);
  }

  const isMIDAvailable = user?.role === Roles.SUPER_ADMIN || user?.role === Roles.ADMIN;

  return (
    <Box sx={{display: 'flex'}}>
      <Select
        value={activeSelection}
        onChange={handleActiveSelectionChange}
        size="small"
      >
        <MenuItem value="rids">RID</MenuItem>
        {isMIDAvailable && <MenuItem value="mids">MID</MenuItem>}
        <MenuItem value="sids">SID</MenuItem>
      </Select>

      {activeSelection === 'rids' && <RidAutocompleteFormField name="rids" isMulti />}
      {activeSelection === 'sids' && <SidAutocompleteFormField name="sids" isMulti />}
      {activeSelection === 'mids' && <MidAutocompleteFormField name="mids" isMulti />}
    </Box>
  );
};

export default RidMidSidSelect;

import React from 'react'
import {
  Box,
  BoxProps,
  Modal,
  ModalProps,
  styled,
  Typography,
} from '@mui/material'

// Components
import { AppIconButton} from 'shared/components/mui';
import AppIcon from 'shared/components/AppIcon';
import ICONS from 'shared/components/icons'

interface AppModalProps extends ModalProps {
  setOpen: (open: boolean) => void
  bodyProps?: BoxProps
  size?: 'small' | 'medium' | 'large'
}

interface MuiModalProps extends ModalProps {
  size?: 'small' | 'medium' | 'large'
}

const MuiModal = styled((props: MuiModalProps) => (
  <Modal closeAfterTransition {...props} />
))(({ theme, size }) => ({
  '& .pmModal': {
    position: 'absolute',
    width: size === 'small' ? 400 : size === 'medium' ? 800 : '80%',
    maxWidth: 1200,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 8,
  },

  '& .pmModalHead': {
    '& > .MuiIconButton-root': {
      position: 'absolute',
      top: 15,
      right: 15,

      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
    },
  },
}))

const AppModal: React.FC<AppModalProps> = ({
  open,
  setOpen,
  title,
  bodyProps,
  size,
  children,
  ...props
}) => {
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <MuiModal open={open} onClose={handleClose} size={size} {...props}>
      <Box className='pmModal'>
        <Box className='pmModalHead'>
          <Typography variant='body1' fontWeight='medium' fontSize={18}>
            {title}
          </Typography>
          <AppIconButton
            onClick={() => setOpen(false)}
            round
            size='small'
            icon={<AppIcon src={ICONS.close} />}
          />
        </Box>
        <Box sx={{ mt: 2, ...bodyProps }}>{children}</Box>
      </Box>
    </MuiModal>
  )
}

export default AppModal

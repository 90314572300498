import {mainRoutes, passwordRequiredUpdateRoutes} from "routes";
import {RouterProvider} from "react-router-dom";
import {useIdleTimer} from 'react-idle-timer'
import useUserLogout from "shared/hooks/useLogoutUser";
import {useEffect} from "react";
import {socket} from "socket";
import useCurrentUser from "shared/hooks/useCurrentUser";
import {parseISO} from "date-fns";

function App() {
  const {user} = useCurrentUser();
  const {mutate} = useUserLogout();

  const onIdle = () => mutate()

  useIdleTimer({
    onIdle,
    timeout: 20*60*1000,
    throttle: 500
  })

  useEffect(() => {
    socket.auth = {token: user?.session_uuid};
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  const currentDate = new Date();

  const isPasswordUpdateRequired = parseISO(user?.password_updated_required_at) < currentDate;

  const routes = isPasswordUpdateRequired ? passwordRequiredUpdateRoutes : mainRoutes;

  return (
    <RouterProvider router={routes}/>
  );
}

export default App;

import {Avatar, Box, Divider, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {useState} from "react";
import {styled, useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import useUserLogout from "shared/hooks/useLogoutUser";
import useCurrentUser from "shared/hooks/useCurrentUser";
import Role from "shared/components/DS/Role";
import truncate from "lodash/truncate";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const StyledMenuItem = styled(MenuItem)(({theme}) => ({
//   display: 'flex',
//   padding: '1px 6px',
//   alignItems: 'center',
//   alignSelf: 'stretch',
//   '&:hover': {
//     backgroundColor: 'transparent',
//   },
// }));
//
// const StyledText = styled(Box)(({theme}) => ({
//   ...theme.typography.B4,
//   padding: '8px 12px',
//   borderRadius: '6px',
//   fontWeight: 500,
//   color: theme.palette.themeColors.grey["110"],
//   width: '100%',
//   '&:hover': {
//     backgroundColor: theme.palette.themeColors.grey["30"],
//     color: theme.palette.themeColors.grey["120"],
//   },
// }));

const ProfileAvatar = styled(Avatar)(({theme}) => ({
  width: '36px',
  height: '36px',
  flexShrink: 0,
  borderRadius: '8px',
  border: `1px solid ${theme.palette.themeColors.grey["30"]}`,
  cursor: 'pointer',
}));

const ProfileMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {user} = useCurrentUser();

  const {mutate: logoutMutation} = useUserLogout()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (link: string = '') => () => {
    setAnchorEl(null);
    if (link) {
      navigate(link);
    }
  };

  const handleLogout = () => {
    logoutMutation()
  }

  return (
    <>
      <ProfileAvatar
        onClick={handleAvatarClick}
        variant="square"
        sx={{
          margin: '28px auto 0',
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          transform: 'translateX(10px)',
          '& .MuiMenu-paper': {
            minWidth: '232px',
            border: 'none'
          },

          '& .MuiMenu-list': {
            padding: '4px 0px 4px 0px',


            '& .MuiMenuItem-root': {
              margin: '2px 6px'
            }

          }
        }}
      >
        <Box padding='8px 16px'>
          <Typography
            variant='B6'
            sx={{
              color: theme.palette.themeColors.grey['90'],
              fontWeight: 600,
            }}
            component={Box}
          >
            {user?.email_address}
          </Typography>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          sx={{padding: '8px 16px', gap: '8px'}}
        >
          <ProfileAvatar
            variant="square"
          />

          <Stack gap='4px' alignItems='start'>
            <Typography
              variant="B6"
              sx={{fontWeight: 700}}
            >
              {truncate(`${user?.first_name} ${user?.last_name}`, {length: 20, omission: '...'})}
            </Typography>
            <Role value={user?.role || ''} />
          </Stack>
        </Stack>

        <MenuItem
          onClick={handleMenuClose()}
        >
          Manage Account
        </MenuItem>
        <MenuItem
          onClick={handleMenuClose()}
        >
          Export History
        </MenuItem>

        <Divider sx={{margin: '2px 0px !important'}}/>
        <MenuItem
          onClick={handleLogout}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;

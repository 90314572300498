import { useState } from 'react';
import {IApiError} from "shared/types";
import {isEmpty} from "lodash";

export const useErrorMessage = () => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const handleSetErrorMessage = (error?: IApiError) => {
    if (isEmpty(error)) {
      setErrorMessage('');
    }

    const {message = '', detail = ''} = error?.body || {}

    if (message) {
      if (typeof message === 'object') {
        const messages = Object.keys(message).map(key => (message as Record<string, string>)[key]);
        setErrorMessage(messages.join('\n'));
      } else {
        setErrorMessage(message);
      }
    }

    if (detail) {
      setErrorMessage(detail);
    }
  };

  return {
    errorMessage,
    handleSetErrorMessage,
  };
};

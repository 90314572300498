export const fieldsList = [
  "id_card",
  "drivers",
  "passport",
  "residence_permit",
  "utility_bill",
  "selfie",
  "video_selfie",
  "profile_image",
  "id_doc_photo",
  "agreement",
  "contract",
  "drivers_translation",
  "investor_doc",
  "vehicle_registration_certificate",
  "income_source",
  "payment_method",
  "bank_card",
  "covid_vaccination_form",
  "other"
]

export const documentsDefaultValues =  {
  id_card: [{id_card: null}],
  drivers: [{drivers: null}],
  passport: [{passport: null}],
  residence_permit: [{residence_permit: null}],
  utility_bill: [{utility_bill: null}],
  selfie: [{selfie: null}],
  video_selfie: [{video_selfie: null}],
  profile_image: [{profile_image: null}],
  id_doc_photo: [{id_doc_photo: null}],
  agreement: [{agreement: null}],
  contract: [{contract: null}],
  drivers_translation: [{drivers_translation: null}],
  investor_doc: [{investor_doc: null}],
  vehicle_registration_certificate: [{vehicle_registration_certificate: null}],
  income_source: [{income_source: null}],
  payment_method: [{payment_method: null}],
  bank_card: [{bank_card: null}],
  covid_vaccination_form: [{covid_vaccination_form: null}],
  other: [{other: null}]
}

import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {useQueryClient, useMutation} from "@tanstack/react-query";
import {UserLimitsQueryKeys} from "shared/queryKeys";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import {IApiError} from "shared/types";

type Params = Parameters<typeof ApiClient.userLimits.deleteUserLimitOrBlacklist>[0]

interface DeleteUserLimitOrBlacklistAttrs {
  dialogStateHandler: DialogStateHandler
}

const useDeleteUserLimitOrBlacklist = ({dialogStateHandler}: DeleteUserLimitOrBlacklistAttrs) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient();

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.userLimits.deleteUserLimitOrBlacklist(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: [UserLimitsQueryKeys.LIST]})
      dialogStateHandler.closeDialog()
      enqueueSnackbar('User limit/blacklisted deleted successfully', {variant: 'success'})
    },
    onError: (error: IApiError) => {
      const {comment} = error.body
      enqueueSnackbar(comment, {variant: 'error'})
    }
  })

  return {
    mutate,
    isPending,
  }
}

export default useDeleteUserLimitOrBlacklist;
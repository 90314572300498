import {FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import CustomerSitesAutocompleteFormField from "shared/components/formDataFields/CustomerSitesAutocompleteFormField";
import {Alert, Box, Button, Chip, Grid, Typography} from "@mui/material";
import TextFormField from "shared/components/formFields/TextFormField";
import PhoneFormField from "shared/components/formFields/PhoneFormField";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import FileFormField from "shared/components/formFields/FileFormField";
import {emailRegex} from "shared/utils/regex";
import TerminalTransactions from "apps/virtualTerminal/pages/VirtualTerminal/TerminalTransactions";
import ToggleButtonsFormField from "shared/components/formFields/ToggleButtonsFormField";
import TerminalPaymentMethods from "apps/virtualTerminal/pages/VirtualTerminal/TerminalPaymentMethods";
import TerminalSummary from "apps/virtualTerminal/pages/VirtualTerminal/TerminalSummary";
import useCreateVirtualTerminalTransaction from "apps/virtualTerminal/pages/VirtualTerminal/useDataMutations";
import TextArrayFormField from "shared/components/formFields/TextArrayFormField";
import DialogGeneral from "shared/components/DialogGeneral";
import useDialogState from "shared/hooks/useDialogState";
import usePermissions from "apps/virtualTerminal/pages/VirtualTerminal/permissions/usePermissions";
import PermissionError from "shared/PermissionError";
import {fCurrency} from "shared/utils";
import {Link as RouterLink} from "react-router-dom";
import uiPaths from "routes/uiPaths";
import {isEmpty} from "lodash";
import useBreadcrumb from "shared/hooks/useBreadcrumb";
import {commonBreadcrumbs} from "shared/breadcrumbConfig";

const schema = yup.object().shape({
  site_id: yup.number().required('This field is required.').nullable(),
  // file: yup.mixed().nullable(),
  email_address: yup.string().required('This field is required.')
    .test('email', 'Invalid email', (value) => emailRegex.test(value || '')),
  transaction_type: yup.string().required('This field is required.'),
  payment_method: yup.string().required('This field is required.'),

  amount: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .min(1)
    .max(3000)
    .test('is-float', 'Invalid float format (up to 2 decimals allowed)', value => {
      const regex = /^\d+(\.\d{1,2})?$/;
      return regex.test(`${value}`);
    })
    .required('This field is required.'),

  first_name: yup.string().matches(/^[ A-Za-z-]*$/, 'First name can\'t contain special symbols').max(128).required('This field is required.'),
  last_name: yup.string().matches(/^[ A-Za-z-]*$/, 'Last name can\'t contain special symbols').max(128).required('This field is required.'),
  phone_number: yup.string().matches(
    /^\+?(\d{1,3})?[ .-]?(\()?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{3})$/,
    {message: 'Invalid phone number', excludeEmptyString: true}
  ).required('Phone is required'),
  address: yup.string().matches(/^[\d\s,A-Za-z-]*$/, 'Address can\'t contain special symbol').max(255).required('This field is required.'),
  city: yup.string().matches(/^[ A-Za-z-]*$/, 'City can\'t contain special symbols').max(128).required('This field is required.'),
  state: yup.string().matches(/^[ A-Za-z-]*$/, 'State can\'t contain special symbols').max(2).required('This field is required.'),
  country: yup.string().matches(/^[ A-Za-z-]*$/, 'Country can\'t contain special symbols').max(2).required('This field is required.'),
  postal_code: yup.string().required('This field is required.'),
  udfs: yup.array().of(yup.object().shape({value: yup.string().required('This field is required.')})),
})

type FormData = yup.InferType<typeof schema>;

const transactionTypeOptions = [
  {value: 'payment', label: 'Payment'},
  {value: 'payout', label: 'Payout'},
]

const defaultValues: FormData = {
  site_id: null,
  // file: null,
  email_address: '',
  transaction_type: 'payment',
  payment_method: '',
  amount: 0,
  first_name: '',
  last_name: '',
  phone_number: '',
  address: '',
  city: '',
  state: '',
  country: '',
  postal_code: '',
  udfs: [],
}

const VirtualTerminal = () => {
  useBreadcrumb([commonBreadcrumbs.transactionsList]);
  const dialogStateHandler = useDialogState()
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  })

  const {
    mutate,
    transactionData,
    udfsErrorMessage,
    phoneErrorMessage,
    postalCodeErrorMessage,
  } = useCreateVirtualTerminalTransaction({dialogStateHandler})

  const onSubmit = (data: FormData) => {
    mutate({
      requestBody: {
        ...data,
        site_id: Number(data.site_id),
        amount: Number(data.amount),
        // @ts-ignore
        udfs: data?.udfs?.filter(Boolean).map((udf) => udf.value) || [],
      }
    })
  }

  const {canUseVirtualTerminal} = usePermissions();

  if (!canUseVirtualTerminal){
    return <PermissionError />
  }

  return (
    <FormProvider {...methods}>
      <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Paper sx={{p: 2}}>
              <Typography variant='h6'>Create Transaction</Typography>
              <Box mt={2}>
                <CustomerSitesAutocompleteFormField
                  name='site_id'
                  label='Site'
                />
              </Box>
              <Box mt={2}>
                <TextFormField name='email_address' label='Email' type='email'/>
              </Box>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <TextFormField name='amount' label='Amount' type='number'/>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ToggleButtonsFormField name='transaction_type' options={transactionTypeOptions}/>
                  </Grid>
                </Grid>
              </Box>
            </Paper>

            <Paper sx={{p: 2, mt: 2}}>
              <Typography variant='h6'>Payment Method</Typography>
              <TerminalPaymentMethods/>
            </Paper>

            <Paper sx={{p: 2, mt: 2}}>
              <Typography variant='h6'>Billing Information</Typography>
              <Typography variant="subtitle2">Please enter the customer&apos;s billing information below.</Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={4} mt={2}>
                  <TextFormField name='first_name' label='First Name' maxLength={128}/>
                </Grid>
                <Grid item xs={12} md={4} mt={2}>
                  <TextFormField name='last_name' label='Last Name' maxLength={128}/>
                </Grid>
                <Grid item xs={12} md={4} mt={2}>
                  <PhoneFormField name='phone_number' label='Phone number'/>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} mt={2}>
                      <TextFormField name='address' label='Address'/>
                    </Grid>
                    <Grid item xs={12} md={6} mt={2}>
                      <TextFormField name='city' label='City' maxLength={128}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} mt={2}>
                      <TextFormField name='state' label='State/Province' maxLength={2}/>
                    </Grid>
                    <Grid item xs={12} md={4} mt={2}>
                      <TextFormField name='country' label='Country' maxLength={2}/>
                    </Grid>
                    <Grid item xs={12} md={4} mt={2}>
                      <TextFormField name='postal_code' label='Postal Code'/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {phoneErrorMessage && <Alert severity="error" sx={{mt: 1}}>{phoneErrorMessage}</Alert>}
              {postalCodeErrorMessage && <Alert severity="error" sx={{mt: 1}}>{postalCodeErrorMessage}</Alert>}
            </Paper>
            <Paper sx={{p: 2, mt: 2}}>
              <FileFormField name='file' label='Attach a file'/>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper sx={{p: 2}}>
              <TerminalTransactions/>
            </Paper>
            <Paper sx={{p: 2, mt: 2}}>
              <TerminalSummary/>
              <Box my={2}>
                <TextArrayFormField
                  name='udfs'
                  label='UDF'
                  appendButtonTitle="+ Add Merchant Reference Code"
                  fieldContainerSX={{mb: 2}}
                />
                {!isEmpty(udfsErrorMessage) && <Alert severity="error" sx={{mt: 1}}>{udfsErrorMessage}</Alert>}
              </Box>

              <Button type="submit" variant="contained" color="primary">Submit</Button>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <DialogGeneral dialogStateHandler={dialogStateHandler} dialogTitle='Transaction data' reset={methods.reset}>
        {transactionData?.amount && (
          <Typography variant='body1'>
            Amount - {fCurrency(transactionData.amount)}
          </Typography>
        )}

        {transactionData?.type && (
          <Typography variant='body1'>
            Type - <Chip color='success' label={transactionData.type} />
          </Typography>
        )}

        {transactionData?.transaction_id && (
          <Typography variant='body1'>
            Transaction ID - <Link component={RouterLink} to={uiPaths.transactionPaths.detailNotesTab.reverse({transactionId: transactionData?.transaction_id})}>{transactionData?.transaction_id}</Link>
          </Typography>
        )}

        {transactionData?.bank_payment_url && (
          <Typography variant='body1'>
            Bank Payment URL - <Link href={transactionData.bank_payment_url}>{transactionData.bank_payment_url}</Link>
          </Typography>
        )}
      </DialogGeneral>
    </FormProvider>
  );
};

export default VirtualTerminal;

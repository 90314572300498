import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {AppCard} from "shared/components/mui";
import {Box, Chip} from "@mui/material";
import TextFormField from "shared/components/formFields/TextFormField";
import {UserDetailsSchema} from "generatedTypes";
import LoadingButton from "@mui/lab/LoadingButton";
import SwitchFormField from "shared/components/formFields/SwitchFormField";
import {useEditProfile} from "apps/users/pages/edit/partials/EditProfile/useMutations";
import {useParams} from "react-router-dom";
import getRoleColor from "shared/components/roleColor";
import usePermissions from "apps/users/pages/edit/permissions/usePermissions";

const schema = yup.object().shape({
  firstName: yup.string().matches(/^[\d A-Za-z-]*$/, 'First name can\'t contain special symbols').max(20).required('This field is required'),
  lastName: yup.string().matches(/^[\d A-Za-z-]*$/, 'Last name can\'t contain special symbols').max(20).required('This field is required'),
  isActive: yup.boolean().required('This field is required'),
  isTwoFactorEnabled: yup.boolean().required('This field is required'),
})

type FormData = yup.InferType<typeof schema>;

interface PersonalInfoProps {
  firstName: UserDetailsSchema["first_name"],
  lastName: UserDetailsSchema["last_name"],
  role: UserDetailsSchema["role"],
  isActive: UserDetailsSchema["is_active"],
  isTwoFactorEnabled: UserDetailsSchema["two_fa_enabled"],
}

const EditProfile = ({firstName, lastName, role, isActive, isTwoFactorEnabled}: PersonalInfoProps) => {
  const {userId} = useParams()

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      firstName,
      lastName,
      isActive,
      isTwoFactorEnabled
    }
  });

  const {canEnableDisableActiveAndVerify} = usePermissions()

  const handleReset = () => {
    methods.reset(methods.getValues());
  }

  const {mutate, isPending} = useEditProfile(handleReset);

  const onSubmit = (data: FormData) => {
    mutate(
      {
        userId: userId || '',
        requestBody: {
          first_name: data.firstName,
          last_name: data.lastName,
          is_active: data.isActive,
          is_2fa_enabled: data.isTwoFactorEnabled
        }
      }
    )
  };


  return (
    <AppCard
      headStyle={{pb: 0}}
      bodyStyle={{m: 0, p: 1, pt: 2}}
      sx={{pb: 0}}
    >
      <FormProvider {...methods}>
        <Box component='form' mx={3} mt={1} onSubmit={methods.handleSubmit(onSubmit)}>
          <Box sx={{mb: 2}}>
            <Chip label={role} color={getRoleColor(role || '')} />
          </Box>
          <Box sx={{display: 'flex'}}>
            <Box sx={{width: '100%'}}>
              <TextFormField name="firstName" placeholder="First name"/>
            </Box>
            <Box sx={{width: '100%'}} ml={3}>
              <TextFormField name="lastName" placeholder="Last name"/>
            </Box>
          </Box>
          <Box mt={2}>
            {canEnableDisableActiveAndVerify && (
              <SwitchFormField name="isActive" label="Active"/>
            )}
            <SwitchFormField name="isTwoFactorEnabled" label="Is 2FA enabled"/>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <LoadingButton
              loading={isPending}
              variant="contained"
              loadingIndicator="Submitting…"
              type="submit"
              sx={{mt: 2, mb: 2, width: 120}}
              disabled={!methods.formState.isDirty || !methods.formState.isValid || methods.formState.isSubmitting}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>
    </AppCard>
  )
}

export default EditProfile;

import { Chip } from "@mui/material";
import { ChipVariants } from "shared/types";

interface PaymentTypeProps {
  value: string | null | undefined;
}

const paymentTypes: Record<string, ChipVariants> = {
  payment: 'tealBadge',
  payout: 'orangeBadge',
  refund: 'magentaBadge',
  chargeback: 'seaBadge',
  capture: 'brownBadge',
  preauth: 'blueBadge',
  void: 'algeBadge',
}

// Helper function to format label
const formatLabel = (value: string): string => {
  const labelFormats: Record<string, string> = {
    preauth: 'Pre-Auth',
  };

  // Return the custom formatted label if it exists, otherwise return the value as is
  return labelFormats[value] || value;
}

const PaymentType = ({ value }: PaymentTypeProps) => {
  if (!value || !paymentTypes[value]) return null;

  // Format label based on the value
  const label = formatLabel(value);

  return (
    <Chip label={label} variant={paymentTypes[value]} />
  )
}

export default PaymentType;
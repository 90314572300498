import { Components, Theme } from '@mui/material/styles';

const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      display: 'flex',
      padding: theme.spacing(0.5),
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.spacing(1),
    }),
    colorPrimary: ({ theme }) => ({
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.themeColors.purple['40'],
      },
    }),
  },
};

export default MuiIconButton;

import {Components} from "@mui/material/styles";
import palette from "theme/palette";

const MuiTooltip: Components['MuiTooltip'] =  {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      backgroundColor: palette.themeColors.purple['130'],
      color: '#FFFFFF',
      fontSize: 13,
      fontWeight: 500,
      borderRadius: 4,
      padding: '2px 8px',
      marginBottom: '0'
    },
    arrow: {
      color: palette.themeColors.purple['130'],
      marginBottom: '-0.69em'
    }
  }
}

export default MuiTooltip;

import {FieldsModel} from "generatedTypes";

export const groupFieldsByGroup = (fields: FieldsModel[]) => fields.reduce((acc: { [key: string]: FieldsModel[] }, field) => {
  const {group} = field;
  if (!group) return acc;

  if (!acc[group]) {
    acc[group] = [];
  }
  acc[group].push(field);
  return acc;
}, {})

import RoutePath from "routes/utils";

export default {
  list: new RoutePath('/api/transactions/'),
  details: new RoutePath('/api/transactions/:transaction_id/'),

  notes: new RoutePath('/api/transactions/:txn/notes/'),
  billingEvents: new RoutePath('/api/transactions/:txn/billing-events/'),
  financialDetails: new RoutePath('/api/transactions/:txn/financial-details/'),
  cart: new RoutePath('/api/transactions/:txn/cart/'),
  view_text: new RoutePath('/api/transactions/:txn/view-text/'),
  bookmark: new RoutePath('/api/transactions/:txn/bookmark/'),
  apiLookup: new RoutePath('/api/transactions/:txn/api-lookup/'),

  postbackProcesses: new RoutePath('/api/transactions/:txn/postback-process/'),
  postbackProcessesResend: new RoutePath('/api/transactions/:txn/postback-process/resend/'),
  postbackInProcess: new RoutePath('/api/transactions/:txn/postback-process/in-process/'),
  postbackPostbackDetails: new RoutePath('/api/transactions/:txn/postback-process/:postback_id/postback/'),
  postbackWebhookDetails: new RoutePath('/api/transactions/:txn/postback-process/:postback_id/webhook/'),

  timeline: new RoutePath('/api/transactions/:txn/timeline/'),
  timelineDialog: new RoutePath('/api/transactions/:txn/timeline/:dialog/'),

  rawEmails: new RoutePath('/api/transactions/:txn/emails/'),
  rawEmail: new RoutePath('/api/transactions/:txn/emails/:email_id/'),

  developerPanel: new RoutePath('/api/transactions/:txn/developers/:panel/'),

  blacklist: new RoutePath('/api/transactions/:txn/blacklist/'),

  recover: new RoutePath('/api/transactions/:txn/recover/'),

  chargeback: new RoutePath('/api/transactions/:txn/chargeback/'),
  chargeback_reasons: new RoutePath('/api/configs/chargeback-reasons/'),

  notifications: new RoutePath('/api/transactions/:txn/notifications/'),
  notificationDetails: new RoutePath('/api/transactions/:txn/notifications/:notification_id/'),
  resendNotification: new RoutePath('/api/transactions/:txn/notifications/resend/'),

  refund: new RoutePath('/api/transactions/:txn/refund/'),
  refund_reasons: new RoutePath('/api/configs/refund-transaction-reasons/'),

  linkedTransaction: new RoutePath('/api/transactions/:txn/linked/'),

  patchTransaction: new RoutePath('/api/transactions/:txn/patch-details/'),
}

import {AppCard} from "shared/components/mui";
import {FormControlLabel, Switch, Box} from "@mui/material";
import {UserSettingsSchema} from "generatedTypes";
import {useEnableTwoFa} from "apps/account/pages/settings/TwoFa/useDataQueries";
import SelectPreferredMethod from "apps/account/pages/settings/TwoFa/SelectPreferredMethod";
import {useCallback} from "react";

interface TwoFaProps {
  twoFaEnabled: UserSettingsSchema["two_fa_enabled"],
  preferredMethod: UserSettingsSchema["preferred_2fa"],
}

const TwoFa = ({twoFaEnabled, preferredMethod}: TwoFaProps) => {
  const {mutate} = useEnableTwoFa()

  const handleEnableTwoFa = useCallback((event: any, enable: boolean) =>
    mutate({requestBody: {enable}})
  , [mutate]);

  return (
    <AppCard
      title='Two-Factor authentication'
      headStyle={{pb: 0}}
      bodyStyle={{m: 0}}
      sx={{pb: 0}}
    >
      <Box mx={2} mt={2} mb={3} sx={{display: 'flex', alignItems: 'center'}}>
        <FormControlLabel
          value="top"
          control={
            <Switch
              size="small"
              color="primary"
              defaultChecked={twoFaEnabled}
              onChange={handleEnableTwoFa}
            />
          }
          label='Enable 2FA'
          labelPlacement="top"
          sx={{mr: 10}}
        />
        {twoFaEnabled && <SelectPreferredMethod userPreferredMethod={preferredMethod} />}
      </Box>
    </AppCard>
  )
}

export default TwoFa;

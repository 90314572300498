import useTerminalTransactions from "apps/virtualTerminal/pages/VirtualTerminal/TerminalTransactions/useDataQuery";
import {Box, Divider, Grid, LinearProgress, Typography} from "@mui/material";
import usePagination from "shared/hooks/usePagination";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";
import {isEmpty} from "lodash";

const TerminalTransactions = () => {
  const {currentPage, perPage, pagination} = usePagination()

  const {data, isLoading} = useTerminalTransactions({currentPage, perPage});

  return (
    <Box>
      <Typography variant='h6'>Recent Transactions</Typography>

      {isLoading && <LinearProgress/>}

      <Box
        sx={{
          height: 400,
          overflow: 'scroll',
        }}
      >
        {isEmpty(data?.items) && <Typography variant="body2">Enter customer&apos;s email to see the transactions...</Typography>}

        {data?.items?.map((item) => (
          <Box key={item.txn}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
              TXID: <Box
                  component="span"
                  sx={{maxWidth: '135px', direction: 'rtl', display: 'inline-block', verticalAlign: 'bottom'}}>
                  <Typography noWrap>{item.txn}</Typography>
                </Box>
                <br/>
                {item.status_timestamp && (
                  <>
                    Timestamp: {convertIsoToCustomFormat(item.status_timestamp, "dd MMM yyyy")} <br/>
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
              Type: {item.transaction_type} <br/>
              Status: {item.transaction_status} <br/>
              Amount: {item.amount} <br/>
              </Grid>
            </Grid>
            <Divider sx={{my: 1}}/>
          </Box>
        ))}
      </Box>
      {data?.total && data?.total > 0 && (
        <Box sx={{mt: 2}}>
          {pagination({total: data?.total, pages: data?.pages})}
        </Box>
      )}
    </Box>
  );
};

export default TerminalTransactions;

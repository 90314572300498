import {useTheme} from "@mui/material/styles";
import {Box, Button, Skeleton, Stack, Typography} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, {timelineItemClasses} from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import {ReactComponent as TimelineIcon} from 'assets/iconsDS/Timeline.svg';


const SkeletonTimeline = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginTop: '36px',
        borderRadius: theme.spacing(1),
        padding: '16px 20px',
        border: `1px solid ${theme.palette.themeColors.grey['40']}`,
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',

            'svg path': {
              stroke: theme.palette.themeColors.grey[30],
            }
          }}
        >
          <TimelineIcon/>
        </Box>
        <Box>
          <Typography>
            <Skeleton variant='rounded' width={80} height={16}/>
          </Typography>
        </Box>
      </Box>

      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },

          [`& .${timelineItemClasses.root}:first-of-type .MuiTimelineDot-root`]: {
            marginBottom: '0',
          },

          [`& .${timelineItemClasses.root}:first-of-type .MuiTimelineConnector-root`]: {
            position: 'absolute',
            height: '80%',
            top: '14px',
          },

          [`& .${timelineItemClasses.root} .MuiTimelineConnector-root`]: {
            borderRadius: '4px'
          },

          '& .MuiTimelineConnector-root': {
            backgroundColor: theme.palette.themeColors.grey['40'],
          },

          '& .MuiTimelineDot-root': {
            backgroundColor: theme.palette.themeColors.grey['40'],
          },
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Stack gap='4px'>
              <Typography variant='B4'>
                <Skeleton variant='rounded' width={80} height={16} />
              </Typography>
              <Typography variant='B4'>
                <Skeleton variant='rounded' width={120} height={16} />
              </Typography>
            </Stack>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Button variant='outlined' color='secondary' size='small' disabled>
                See All Timeline
              </Button>
            </Box>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
          </TimelineSeparator>
          <TimelineContent>
            <Stack gap='4px'>
              <Typography variant='B4'>
                <Skeleton variant='rounded' width={80} height={16} />
              </Typography>
              <Typography variant='B4'>
                <Skeleton variant='rounded' width={120} height={16} />
              </Typography>
            </Stack>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  )
}

export default SkeletonTimeline;
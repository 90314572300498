/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrentUserSchema } from '../models/CurrentUserSchema';
import type { ProfileChangePasswordSchema } from '../models/ProfileChangePasswordSchema';
import type { ProfileSettingsSchema } from '../models/ProfileSettingsSchema';
import type { ProfileTwoFaSettingsSchema } from '../models/ProfileTwoFaSettingsSchema';
import type { SettingsAuthAppSchema } from '../models/SettingsAuthAppSchema';
import type { TimezoneListSchema } from '../models/TimezoneListSchema';
import type { TwoFaMethodsSchema } from '../models/TwoFaMethodsSchema';
import type { UserSettingsSchema } from '../models/UserSettingsSchema';
import type { UserTimezoneSchema } from '../models/UserTimezoneSchema';
import type { VerifyAuthAppSchema } from '../models/VerifyAuthAppSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AccountService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Current User
     * @returns CurrentUserSchema Successful Response
     * @throws ApiError
     */
    public getCurrentUser(): CancelablePromise<CurrentUserSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/me/',
        });
    }
    /**
     * @returns CurrentUserSchema Success
     * @throws ApiError
     */
    public getCurrentUser1({
        xFields,
    }: {
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CurrentUserSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/account/current-user/',
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Get user settings
     * @returns UserSettingsSchema Success
     * @throws ApiError
     */
    public getAccountSettings({
        xFields,
    }: {
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UserSettingsSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/account/settings/',
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Update user settings
     * @returns ProfileSettingsSchema Success
     * @throws ApiError
     */
    public patchAccountSettings({
        requestBody,
        xFields,
    }: {
        requestBody: {
            first_name?: string;
            last_name?: string;
            email?: string;
            phone?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ProfileSettingsSchema> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/account/settings/',
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Authenticator app
     * @returns SettingsAuthAppSchema Success
     * @throws ApiError
     */
    public postAccountEnableAuthApp({
        requestBody,
        xFields,
    }: {
        requestBody: {
            enable?: boolean;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<SettingsAuthAppSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/account/settings/auth-app/',
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Verify authenticator app
     * @returns VerifyAuthAppSchema Success
     * @throws ApiError
     */
    public postAccountVerifyAuthApp({
        securityCode,
        xFields,
    }: {
        securityCode: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<VerifyAuthAppSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/account/settings/auth-app/verify/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'security_code': securityCode,
            },
        });
    }
    /**
     * Change user password
     * @returns ProfileChangePasswordSchema Success
     * @throws ApiError
     */
    public postChangeUserPassword({
        oldPassword,
        newPassword,
        confirmPassword,
        xFields,
    }: {
        oldPassword: string,
        newPassword: string,
        confirmPassword: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ProfileChangePasswordSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/account/settings/change-password/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'old_password': oldPassword,
                'new_password': newPassword,
                'confirm_password': confirmPassword,
            },
        });
    }
    /**
     * Get two-factor authentication settings
     * @returns TwoFaMethodsSchema Success
     * @throws ApiError
     */
    public getAccountEnableTwoFa({
        xFields,
    }: {
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<TwoFaMethodsSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/account/settings/enable-two-fa/',
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Enable two-factor authentication
     * @returns ProfileTwoFaSettingsSchema Success
     * @throws ApiError
     */
    public postAccountEnableTwoFa({
        requestBody,
        xFields,
    }: {
        requestBody: {
            enable?: boolean;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ProfileTwoFaSettingsSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/account/settings/enable-two-fa/',
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Set preferred two-factor authentication method
     * @returns ProfileTwoFaSettingsSchema Success
     * @throws ApiError
     */
    public postAccountSetPreferredTwoFaMethod({
        preferredMethod,
        xFields,
    }: {
        preferredMethod: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ProfileTwoFaSettingsSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/account/settings/set-preferred-two-fa-method/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'preferred_method': preferredMethod,
            },
        });
    }
    /**
     * Verify email
     * @returns ProfileSettingsSchema Success
     * @throws ApiError
     */
    public postAccountSettingsVerifyEmail({
        securityCode,
        xFields,
    }: {
        securityCode: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ProfileSettingsSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/account/settings/verify-email/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'security_code': securityCode,
            },
        });
    }
    /**
     * Verify phone
     * @returns ProfileSettingsSchema Success
     * @throws ApiError
     */
    public postAccountSettingsVerifyPhone({
        securityCode,
        xFields,
    }: {
        securityCode: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ProfileSettingsSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/account/settings/verify-phone/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'security_code': securityCode,
            },
        });
    }
    /**
     * Get all timezones
     * @returns TimezoneListSchema Success
     * @throws ApiError
     */
    public getTimezone({
        xFields,
    }: {
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<TimezoneListSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/account/timezones/',
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Set the user timezone
     * @returns UserTimezoneSchema Success
     * @throws ApiError
     */
    public patchTimezone({
        requestBody,
        xFields,
    }: {
        requestBody: {
            timezone?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<UserTimezoneSchema> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/account/timezones/',
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}

import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: ['Inter', 'Arial', 'sans-serif',].join(','),
  h1: {
    fontSize: '3rem', // 48px
    lineHeight: '3.625rem', // 58px
    fontWeight: '600', // Semi Bold
    letterSpacing: '-0.015em',
  },
  h2: {
    fontSize: '2.25rem', // 36px
    lineHeight: '2.75rem', // 44px
    fontWeight: '600', // Semi Bold
    letterSpacing: '-0.015em',
  },
  h3: {
    fontSize: '1.5rem', // 24px
    lineHeight: '2.125rem', // 34px
    fontWeight: 'bold',
    letterSpacing: '-0.015em',
  },
  h4: {
    fontSize: '1.125rem', // 18px
    lineHeight: '1.5rem', // 24px
    fontWeight: 'bold',
    letterSpacing: '-0.015em',
  },
  h5: {
    fontSize: '0.875rem', // 14px
    lineHeight: '1.25rem', // 20px
    fontWeight: 600, // Semi Bold
    letterSpacing: '-0.015em',
  },
  h6: {
    fontSize: '0.75rem', // 12px
    lineHeight: '0.75rem', // 12px
    fontWeight: 600, // Semi Bold
    letterSpacing: '-0.015em',
  },
  B1: {
    fontSize: '1.125rem', // 18px
    lineHeight: '1.5rem', // 24px
    fontWeight: 500,
    letterSpacing: '-0.015em',
  },
  B2: {
    fontSize: '1rem', // 16px
    lineHeight: '1.375rem', // 22px
    fontWeight: 500,
    letterSpacing: '-0.015em',
  },
  B3: {
    fontSize: '0.938rem', // 15px
    lineHeight: '1.25rem', // 20px
    fontWeight: 500,
    letterSpacing: '-0.015em',
  },
  B4: {
    fontSize: '0.875rem', // 14px
    lineHeight: '1.25rem', // 20px
    fontWeight: 500,
    letterSpacing: '-0.015em',
  },
  B5: {
    fontSize: '0.813rem', // 13px
    lineHeight: '1.25rem', // 20px
    fontWeight: 500,
    letterSpacing: '-0.015em',
  },
  B6: {
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
    fontWeight: 500,
    letterSpacing: '-0.015em',
  },
  B7: {
    fontSize: '0.688rem', // 11px
    lineHeight: '1rem', // 16px
    fontWeight: 500,
    letterSpacing: '-0.015em',
  },
};

export default typography;

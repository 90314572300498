import React from 'react'
import { styled, TextField, TextFieldProps } from '@mui/material'

const MuiTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[400],
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
  },
}))

const AppTextField: React.FC<TextFieldProps> = ({ ...props }) => <MuiTextField fullWidth size='small' variant='outlined' {...props} />

export default AppTextField

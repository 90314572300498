import DialogConfirm from "shared/components/DialogConfirm";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import {useParams} from "react-router-dom";
import {useVerifyUser} from "apps/users/pages/edit/partials/useDataMutations";

interface ManuallyVerifyDialogProps  {
  dialogHandler: DialogStateHandler;
  email: string;
}

const ResendVerificationDialog = ({dialogHandler, email}: ManuallyVerifyDialogProps) => {
  const {userId} = useParams()

  const {mutate} = useVerifyUser({dialogHandler})
  const handleConfirm = () => {
    mutate(
      {
        userId: userId || '',
        action: 'send-verification-link',
        verificationType: 'email',
        value: email
      }
    )
  }

  return (
    <DialogConfirm
      dialogStateHandler={dialogHandler}
      handleConfirm={handleConfirm}
      title={`Are you sure to send a verification email to ${email}?`}
    />
  )
}

export default ResendVerificationDialog;

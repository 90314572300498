import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form"
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box} from "@mui/material";
import FilterButtonsComponent from "apps/transactions/pages/TransactionsList/useFilters/FilterButtonsComponent";
import useQueryString from "shared/hooks/useQueryString";
import {useDebounce} from "@uidotdev/usehooks";
import FilterComponent from "apps/dashboardRequests/pages/list/useFilters/FilterComponent";

const schema = yup.object().shape({
  search: yup.string(),
  txn: yup.string(),
  sid: yup.array(),
  action: yup.string(),
  status: yup.string(),
})

type FormData = yup.InferType<typeof schema>;

const defaultValues = {
  email: '',
  sid: [],
  action: '',
  status: '',
  comment: '',
  status_timestamp: null,
}

const useFilters = () => {
  const {urlParams, setQueryString, getDefaultValues} = useQueryString()
  const [formData, setFormData] = useState<FormData>(defaultValues);

  const defaultSearch = urlParams.search ? String(urlParams.search) : '';

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: getDefaultValues(defaultValues),
  });

  const {formState} = methods;

  const currentSearch = useDebounce(methods.watch('search'), 400);

  const handleSubmit = (data: FormData) => {
    setFormData({search: data.search});
    if (defaultSearch !== currentSearch) {
      setQueryString({
        page: 1,
        search: data.search || ''
      });
    }
  };

  useEffect(() => {
    handleSubmit({search: currentSearch});
  }, [currentSearch]);

  const onSubmit = (data: FormData) => {
    setFormData(data);
    setQueryString({
      page: 1,
      ...data,
    });
  }

  const filters = (
    <FormProvider {...methods}>
      <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
        <FilterComponent/>
        <FilterButtonsComponent onSubmit={onSubmit}/>
      </Box>
    </FormProvider>
  )

  const filterData = {
    ...formData,
  }

  return {
    filterData,
    filters,
    submitCount: formState.submitCount,
    isValid: formState.isValid,
  };
}

export default useFilters;

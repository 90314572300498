import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {useSnackbar} from "notistack";
import {useState} from "react";
import {IApiError} from "shared/types";
import {FieldValues, Path, UseFormSetError} from "react-hook-form";

type SetError<FormData extends FieldValues> = UseFormSetError<FormData>;

interface MutationWithHandlersOptions<TData, TError = IApiError, TVariables = void, TContext = unknown, FormData extends FieldValues = FieldValues>
  extends UseMutationOptions<TData, TError, TVariables, TContext> {
  setError?: SetError<FormData>;
  resetForm?: () => void;
}

function setFormErrors<FormData extends FieldValues>(error: IApiError, setError?: SetError<FormData>) {
  if (!setError) {
    return;
  }
  error.body.errors.forEach((err: any) => {
    Object.keys(err).forEach((key: string) => {
      setError(key as Path<FormData>, {type: 'api_response', message: err[key].message});
    });
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface UseMutationWithHandlersArgs<TData, TError, TVariables, TContext, FormData extends FieldValues, ApiErrorsType> {
  mutationFn: (variables: TVariables) => Promise<TData>;
  options?: MutationWithHandlersOptions<TData, TError, TVariables, TContext, FormData>;
  successMessage?: string;
}

export function useMutationWithHandlers<
  TData,
  TError = IApiError,
  TVariables = void,
  TContext = unknown,
  FormData extends FieldValues = FieldValues,
  ApiErrorsType = unknown, // Define a generic type for apiErrors
>(
  {
    mutationFn,
    options = {},
    successMessage,
  }: UseMutationWithHandlersArgs<TData, TError, TVariables, TContext, FormData, ApiErrorsType>
) {
  const {enqueueSnackbar} = useSnackbar();
  const [apiErrors, setApiErrors] = useState<ApiErrorsType | null>(null);  // Use the generic type for apiErrors
  const [apiSuccess, setApiSuccess] = useState<TData | null>(null);

  const {
    onMutate,
    onError,
    onSuccess,
    resetForm,
    setError,
    ...reactQueryOptions
  } = options;

  // Custom error checking function to narrow down the type
  const isIApiError = (error: any): error is IApiError => (error as IApiError).body !== undefined;

  const mutationResult = useMutation<TData, TError, TVariables, TContext>({
    mutationFn,
    // eslint-disable-next-line consistent-return
    onMutate: async (variables) => {
      setApiErrors(null);
      if (onMutate) {
        return onMutate(variables);
      }
    },
    onError: (error: TError, variables, context) => {
      if (isIApiError(error)) {
        if (error.body.errors) {
          setFormErrors<FormData>(error, setError);
        }
        if (error.body.detail.api_errors) {
          setApiErrors(error.body.detail.api_errors as ApiErrorsType); // Cast to ApiErrorsType
        }
        if (error.body.detail.permissions) {
          enqueueSnackbar(error.body.detail.permissions, {variant: 'error'});
        }
      }

      if (onError) {
        onError(error, variables, context);
      }
    },
    onSuccess: (data, variables, context) => {
      if (resetForm) {
        resetForm();
      }

      setApiSuccess(data);
      if (successMessage) {
        enqueueSnackbar(successMessage, {variant: 'success'});
      }

      if (onSuccess) {
        onSuccess(data, variables, context);
      }
    },
    ...reactQueryOptions
  });

  return {
    ...mutationResult,
    apiErrors,
    apiSuccess,
  };
}

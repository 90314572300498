import {exportToCSV, exportToXLSX} from "shared/utils/files";
import {format} from "date-fns";
import {Button} from "@mui/material";

interface ExportButtonProps {
  fileFormat: 'csv' | 'xlsx';
  filename: string;
  graphDataSeries: Array<string>;
  chartPaymentData: Array<number>;
  chartPayoutData: Array<number>;
  chartRefundData: Array<number>;
  chartChargebackData: Array<number>;
}

const exportFns = {
  csv: exportToCSV,
  xlsx: exportToXLSX
}

const ExportButton = ({
  fileFormat,
  filename,
  graphDataSeries,
  chartPaymentData,
  chartPayoutData,
  chartRefundData,
  chartChargebackData
}: ExportButtonProps) => {
  const exportFn = exportFns[fileFormat]

  if (!exportFn) return null;

  const handleExport = () => {
    const tableData = graphDataSeries.map((date, index) => ({
      Date: format(new Date(date), 'dd MMM'),
      Payments: chartPaymentData[index],
      Payouts: chartPayoutData[index],
      Refunds: chartRefundData[index],
      Chargebacks: chartChargebackData[index],
    }));

    // Then call the export function with the calculated data and filename
    exportFn(tableData, filename);
  }

  return (
    <Button onClick={handleExport}>
      Export to {fileFormat.toUpperCase()}
    </Button>
  );
};

export default ExportButton;

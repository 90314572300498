import {Alert, AlertTitle, Container} from "@mui/material";

const PermissionError = () => (
  <Container maxWidth="xs">
    <Alert severity="error" variant="filled">
      <AlertTitle>403 Forbidden</AlertTitle>
      You are not allowed to perform this action!
    </Alert>
  </Container>
)

export default PermissionError;
import Box from '@mui/material/Box';
import {ReactComponent as LocationIcon} from "assets/iconsDS/location.svg";
import {ReactComponent as HouseIcon} from "assets/iconsDS/house.svg";
import {ReactComponent as PhoneIcon} from "assets/iconsDS/telephone.svg";
import {ReactComponent as IPIcon} from "assets/iconsDS/modern-tv.svg";

import {CustomerOverview, LatestInfo} from "generatedTypes";
import {InfoItem} from "shared/components/InfoItem";
import {InfoItemWithCopy} from "apps/customers/pages/CustomerDetails/partials/CustomerInfo/InfoItemWithCopy";
import SeeIPsDialog from "apps/customers/pages/CustomerDetails/partials/CustomerInfo/SeeIPsDialog";
import useDialogState from "shared/hooks/useDialogState";

interface ContactInfoProps {
  billingAddress?: LatestInfo['billing_address'];
  address?: LatestInfo['address'];
  phoneNumber?: LatestInfo['phone'];
  ipAddress?: LatestInfo['ip_address'];
  ipAddresses?: CustomerOverview['ip_addresses'];
}

const ContactInfo = ({ billingAddress, address, phoneNumber, ipAddress, ipAddresses }: ContactInfoProps) => {
  const seeIPsDialog = useDialogState();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 12px',
          flexDirection: 'column',
          borderRadius: '8px',
          background: '#F9F9F7',
        }}
        className="contact-info-container"
      >
        {billingAddress && <InfoItem Icon={LocationIcon} text={billingAddress}/>}
        {address && <InfoItem Icon={HouseIcon} text={address}/>}
        {phoneNumber && <InfoItem Icon={PhoneIcon} text={phoneNumber}/>}
        {ipAddress && <InfoItemWithCopy Icon={IPIcon} text={ipAddress} openDialog={seeIPsDialog.openDialog}/>}
      </Box>

      <SeeIPsDialog dialogStateHandler={seeIPsDialog} ipAddresses={ipAddresses || []} />
    </>
  )
};

export default ContactInfo;

import usePagination from "shared/hooks/usePagination";
import {
  Button,
  Chip,
  LinearProgress, Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Typography
} from "@mui/material";
import {AppCard, AppTableHead} from "shared/components/mui";
import LoaderBackdrop from "shared/components/LoaderBackdrop";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";
import truncate from "lodash/truncate";
import {Link as RouterLink} from "react-router-dom";
import useDataFilters from "apps/notifications/list/useDataFilters";
import uiPaths from "../uiPaths";

const TABLE_HEAD = [
  {id: 'title', label: 'Title'},
  {id: 'body', label: 'Body'},
  {id: 'read', label: 'Status'},
  {id: 'timestamp', label: 'Created At'},
  {id: 'actions', label: ''},
]

const NotificationsList = () => {
  const {currentPage, perPage, pagination} = usePagination();

  const {data, isLoading, isFetching, filters} = useDataFilters({currentPage, perPage, orderBy: '-read'});

  if (isLoading) return <LinearProgress/>

  return (
    <AppCard
      title='Notifications'
      action={filters}
    >
      <TableContainer sx={{overflow: 'unset'}}>
        <Table size='small' sx={{minWidth: 800, bgcolor: '#fff', position: 'relative'}}>
          <AppTableHead
            headLabel={TABLE_HEAD}
          />

          <TableBody sx={{position: 'relative'}}>
            {isLoading && 'Loading...'}
            <LoaderBackdrop open={!isLoading && isFetching} />
            {data && data.items?.map((el => (
              <TableRow key={el._id}>
                <TableCell>
                  <Typography variant='body2'>{el.title}</Typography>
                </TableCell>
                <TableCell>
                  {/* eslint-disable react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: truncate(el.body || '', { 'length': 50, 'omission': '...' }) }} />
                </TableCell>
                <TableCell>
                  <Chip color={el.read ? 'success' : 'warning'} label={el.read ? 'Read' : 'Unread'} />
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>{el.timestamp && convertIsoToCustomFormat(el.timestamp)}</Typography>
                </TableCell>
                <TableCell>
                  <Link component={RouterLink} to={uiPaths.details.reverse({notificationId: el._id})}>
                    <Button variant='contained'>Details</Button>
                  </Link>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>

          <TableFooter>
            <TableCell colSpan={9} align='right' sx={{py: 1, border: 'none'}}>
              {pagination({total: data?.total, pages: data?.pages})}
            </TableCell>
          </TableFooter>
        </Table>
      </TableContainer>
    </AppCard>
  )
};

export default NotificationsList;

import {CustomCellRendererProps} from "@ag-grid-community/react";
import {Box} from "@mui/material";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";

const DateRenderer = (params: CustomCellRendererProps) => {
  if (!params.value) return null;

  return (
    <Box component='span'>
      {convertIsoToCustomFormat(params.value as string, 'dd MMM yyyy HH:mm:ss')}
    </Box>
  )
}

export default DateRenderer;

import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Chip} from "@mui/material";
import TextFormField from "shared/components/formFields/TextFormField";
import {UserSettingsSchema} from "generatedTypes";
import useDialogState from "shared/hooks/useDialogState";
import LoadingButton from "@mui/lab/LoadingButton";
import VerifyEmailDialog from "apps/account/pages/settings/ContacInfo/VerifyEmailDialog";
import {useUpdateUserSettings} from "apps/account/pages/settings/useDataQueries";

const schema = yup.object().shape({
  email: yup.string().email().max(255).required('Email is required'),
})

type FormData = yup.InferType<typeof schema>;

interface EmailFormProps {
  email: UserSettingsSchema["email_address"]
  verified: UserSettingsSchema["email_verified"]
}

const EmailForm = ({email, verified}: EmailFormProps) => {
  const verifyEmailDialogHandler = useDialogState();

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      email,
    }
  });

  const {mutate, isPending} = useUpdateUserSettings({successText: 'Verification code sent to email', dialogHandler: verifyEmailDialogHandler})

  const onSubmit = (data: FormData) => {
    if(data.email) {
      mutate({requestBody: {email: data.email}})
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <Box component='form' mx={3} sx={{width: '100%'}} onSubmit={methods.handleSubmit(onSubmit)}>
          <TextFormField name="email" placeholder="Email"/>
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Chip label={verified ? 'verified' : 'not verified'} color={verified ? 'success': 'error'}/>
            <LoadingButton
              loading={isPending}
              variant="contained"
              loadingIndicator="Submitting…"
              type="submit"
              sx={{mt: 2, mb: 2, width: 120}}
              disabled={!methods.formState.isDirty || !methods.formState.isValid}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>

      <VerifyEmailDialog dialogStateHandler={verifyEmailDialogHandler} />
    </>
  )
}

export default EmailForm
import {Avatar, Box, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import UiPaths from "routes/uiPaths";
import {useTheme} from "@mui/material/styles";
import {CustomerOverview} from "generatedTypes";
import CustomerActions from "apps/customers/shared/CustomerActions";
import SkeletonCustomerHead from "apps/customers/pages/CustomerDetails/partials/CustomerHead/SkeletonCustomerHead";

const getInitials = (fullName: string) => {
  if (fullName && fullName.length > 0) {
    const words = fullName.split(' ');
    if (words.length >= 3) {
      return `${words[0][0]}${words[2][0]}`;
    }
    if (words.length === 2) {
      return `${words[0][0]}${words[1][0]}`;
    }
    return words[0][0];
  }
  return "?";
};

interface CustomerHeadProps {
  fullName: CustomerOverview['full_name'];
  email: string;
  isLoading: boolean;
}

const CustomerHead = ({fullName, email, isLoading}: CustomerHeadProps) => {
  const theme = useTheme();

  if (isLoading) return <SkeletonCustomerHead />

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{
        marginBottom: '16px',
      }}
    >
      <Stack
        direction="row"
        sx={{
          width: '100%',
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            marginRight: theme.spacing(1.5),
          }}
        >
          {getInitials(fullName || '')}
        </Avatar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems='center'
          width="100%"
        >
          <Box>
            <Stack direction="row">
              <Typography variant="B4" sx={{fontWeight: 600, lineHeight: '20px'}}>
                {fullName}
              </Typography>
            </Stack>
            <Link
              component={RouterLink}
              to={UiPaths.customerPaths.detail.reverse({customerEmail: email})}
              sx={{
                color: '#1360F6',
                display: 'block',
              }}
            >
              {email}
            </Link>
          </Box>
          <CustomerActions customerEmail={email} />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CustomerHead;

import axios from "axios";
import apiPaths from "routes/apiPaths";
import {TimezoneMutationArgs, TimezoneResponse, TimezonesResponse} from "./types";

export const updateTimezoneMutation = async ({ timezone }: TimezoneMutationArgs): Promise<TimezoneResponse> => {
  const { data } = await axios.patch<TimezoneResponse>(apiPaths.accountPaths.timezone.reverse(), { timezone }, { withCredentials: true });
  return data;
};

export const getTimezones = async () => {
  const {data} = await axios.get<TimezonesResponse>(apiPaths.accountPaths.timezone.reverse())
  return data
}

import UniversalDialog from "shared/components/DS/UniversalDialog";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import {Box, Button, LinearProgress, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";

interface FileDownloadDialogProps {
  isFileLoading: boolean;
  downloadLink?: string | null;
  exportStatusError?: string;
  dialogStateHandler: DialogStateHandler,
}

const FileDownloadDialog = ({
  isFileLoading,
  exportStatusError,
  downloadLink,
  dialogStateHandler
}: FileDownloadDialogProps) => {
  const theme = useTheme();

  return (
    <UniversalDialog
      dialogStateHandler={dialogStateHandler}
      title="Export Transactions"
      cancelButtonText="Close"
      submitButtonText=""
      onCancel={() => dialogStateHandler.closeDialog()}
    >
      {isFileLoading && <LinearProgress/>}

      {exportStatusError && (
        <Box sx={{color: theme.palette.error.main, mt: 2, mb: 2}}>
          {exportStatusError}
        </Box>
      )}

      { !isFileLoading && downloadLink && (
        <>
          <Typography
            variant="B4"
            color={theme.palette.themeColors.grey['110']}
            sx={{
              display: 'block',
              mt: 2,
              mb: 2,
            }}
          >
            Transaction export is ready. Click the button below to download the file.
          </Typography>
          <Button
            href={downloadLink}
            variant="contained"
            color="primary"
          >
            Download
          </Button>
        </>

      )}
    </UniversalDialog>
  );
};

export default FileDownloadDialog;
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as HelpIcon} from "assets/iconsDS/help.svg";

interface HelpToolTipProps {
  title: string;
}

const HelpToolTip = ({title}: HelpToolTipProps) => (
  <Tooltip title={title} placement='top' arrow>
    <IconButton sx={{padding: 0}}>
      <HelpIcon/>
    </IconButton>
  </Tooltip>
);

export default HelpToolTip;

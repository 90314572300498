import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {IApiError} from "shared/types";
import {useState} from "react";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import {useErrorMessage} from "shared/hooks/useErrorMessage";
import {AccountQueryKeys} from "shared/queryKeys";
import {isEmpty} from "lodash";

type Params = Parameters<typeof ApiClient.account.postAccountEnableAuthApp>[0]

export const useAuthApp = (dialogHandler: DialogStateHandler) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [totpUri, setTotpUri] = useState<string>('')

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.account.postAccountEnableAuthApp(params),
    onSuccess: async (data) => {
      if (!isEmpty(data.totp_uri) && data.totp_uri){
        enqueueSnackbar(data.message, {variant: 'success'})
        setTotpUri(data.totp_uri)
        dialogHandler.openDialog()
      } else {
        enqueueSnackbar(data.message, {variant: 'success'})
        await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS]})
        await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.TWO_FA_METHODS]})
      }
    },
    onError: (error: IApiError) => {
      const {message} = error.body
      enqueueSnackbar(message, {variant: 'error'})
    }
  })

  return {
    mutate,
    isPending,
    totpUri
  }
}

type VerifyAuthAppParams = Parameters<typeof ApiClient.account.postAccountVerifyAuthApp>[0]

interface VerifyAuthAppAttrs {
  dialogHandler: DialogStateHandler
  codesDialogHandler: DialogStateHandler
}

export const useVerifyAuthApp = ({dialogHandler, codesDialogHandler}: VerifyAuthAppAttrs) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const {errorMessage, handleSetErrorMessage} = useErrorMessage()
  const [oneTimeUseCodes, setOneTimeUseCodes] = useState<string[]>()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: VerifyAuthAppParams) => ApiClient.account.postAccountVerifyAuthApp(params),
    onMutate: () => handleSetErrorMessage(),
    onSuccess: async (data) => {
      enqueueSnackbar(data.message, {variant: 'success'})
      setOneTimeUseCodes(data.one_time_use_codes)
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS]})
      dialogHandler.closeDialog()

      if (data.one_time_use_codes) {
        codesDialogHandler.openDialog()
      }
    },
    onError: (error: IApiError) => {
      handleSetErrorMessage(error)
    }
  })

  return {
    mutate,
    isPending,
    errorMessage,
    oneTimeUseCodes
  }
}
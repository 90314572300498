import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {DashboardQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

type Params = Parameters<typeof ApiClient.dashboardRequests.getDashboardRequests>[0]

export const useDashboardRequests = ({
  page,
  perPage,
  orderBy,
  ...filters
}: Params) => {
  const {data, isLoading, isFetching, refetch} = useQuery({
    queryKey: [DashboardQueryKeys.LIST, {page, perPage, orderBy, filters: {filters}}],
    queryFn: () => ApiClient.dashboardRequests.getDashboardRequests({page, perPage, orderBy, ...filters}),
    placeholderData: keepPreviousData,
  })

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  }
}
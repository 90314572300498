import { Components } from '@mui/material/styles';

import palette from "theme/palette";

const MuiTimelineConnector: Components['MuiTimelineConnector'] = {
  styleOverrides: {
    root: {
      backgroundColor: palette.themeColors.purple['20'],
      width: '4px',
    }
  }
}

export default MuiTimelineConnector;
import {Avatar, Box, IconButton, Skeleton, Stack, Tooltip, Typography} from "@mui/material";
import {ReactComponent as CustomerDetailsIcon} from "assets/iconsDS/chevron-right.svg";
import {ReactComponent as LocationIcon} from "assets/iconsDS/location.svg";
import {ReactComponent as PhoneIcon} from "assets/iconsDS/telephone.svg";
import {ReactComponent as IPIcon} from "assets/iconsDS/modern-tv.svg";
import {useTheme} from "@mui/material/styles";
import {WrapperBox} from "shared/styled/WrapperBox";


const SkeletonTransactionCustomer = () => {
  const theme = useTheme();

  return(
    <WrapperBox>
      <Stack
        direction="row"
        sx={{
          marginBottom: '30px',
          gap: '12px',
        }}
      >
        <Skeleton variant="rounded" width={40} height={40}>
          <Avatar variant="rounded"/>
        </Skeleton>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            <Stack gap='4px'>
              <Typography variant="B4">
                <Skeleton variant="rounded" width={120} height={16}/>
              </Typography>
              <Typography variant="B4">
                <Skeleton variant="rounded" width={160} height={20}/>
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Tooltip title="Customer Details" placement="top">
              <IconButton color="primary">
                <CustomerDetailsIcon
                  style={{
                    width: '20px',
                    height: '20px',
                    stroke: theme.palette.primary.main,
                    fill: 'none',
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        className="parentBox"
        spacing={1}
        sx={{
          height: '16px',
          alignItems: 'flex-end',
        }}
      >
        <Box sx={{width: '33.3333%'}}>
          <Skeleton variant="rounded" height={8} />
        </Box>
        <Box sx={{width: '33.3333%'}}>
          <Skeleton variant="rounded" height={8} />
        </Box>
        <Box sx={{width: '33.3333%'}}>
          <Skeleton variant="rounded" height={8} />
        </Box>
      </Stack>

      <Box mt={2}>
        <Box
          sx={{
            display: 'flex',
            padding: '16px 12px',
            flexDirection: 'column',
            borderRadius: '8px',
            background: '#F9F9F7',
          }}
          className="contact-info-container"
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', '&:not(:last-child)': { mb: 1 } }}>
            <LocationIcon
              style={{
                width: '20px',
                height: '20px',
                fill: theme.palette.themeColors.grey['80']
              }}
            />
            <Typography variant="B4" sx={{ color: theme.palette.secondary.main }}>
              <Skeleton variant="rounded" width={220} height={16} />
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', '&:not(:last-child)': { mb: 1 } }}>
            <PhoneIcon
              style={{
                width: '20px',
                height: '20px',
                fill: theme.palette.themeColors.grey['80']
              }}
            />
            <Typography variant="B4" sx={{ color: theme.palette.secondary.main }}>
              <Skeleton variant="rounded" width={200} height={16} />
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', '&:not(:last-child)': { mb: 1 } }}>
            <IPIcon
              style={{
                width: '20px',
                height: '20px',
                fill: theme.palette.themeColors.grey['80']
              }}
            />
            <Typography variant="B4" sx={{ color: theme.palette.secondary.main }}>
              <Skeleton variant="rounded" width={160} height={16} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </WrapperBox>
  )
}

export default SkeletonTransactionCustomer;
import {useHasAccess} from "contexts/UserAccessContext";
import {Permissions, Roles} from "contexts/UserAccessContext/const";

const usePermissions = () => {
  const canAccessMerchantDirectory = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.ACCESS_MERCHANT_DIRECTORY]
    }
  ])

  const canManageMerchantDirectory = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.MANAGE_MERCHANT_DIRECTORY]
    }
  ])

  return {
    canAccessMerchantDirectory,
    canManageMerchantDirectory
  }
}

export default usePermissions

import { useQuery } from "@tanstack/react-query";
import ApiClient from "apiClient";
import {UserLimitsQueryKeys} from "shared/queryKeys";

type Params = Parameters<typeof ApiClient.userLimits.getUserLimitsDetail>[0]

interface UserLimitsQueryArgs {
  contact?: Params['contact'] | null;
  sid?: Params['sid'] | null;
}

export const useUserLimitsDetails = ({contact, sid}: UserLimitsQueryArgs) => {
  const { data, isLoading} = useQuery({
    queryKey: [UserLimitsQueryKeys.DETAILS, {contact, sid}],
    queryFn: () => ApiClient.userLimits.getUserLimitsDetail({
      contact: contact || "",
      sid: sid || 0,
    }),
    enabled: !!contact && !!sid,
  });

  return {
    data,
    isLoading,
  };
};

import {useHasAccess} from "contexts/UserAccessContext";
import {Roles} from "contexts/UserAccessContext/const";

const usePermissions = () => {
  const canViewCustomerDetails = useHasAccess([
    {
      'roles': [Roles.ADMIN, Roles.CUSTOMER_SERVICE, Roles.MANAGER, Roles.MERCHANT, Roles.RETAILER, Roles.SITE],
    }
  ])

  return {
    canViewCustomerDetails
  }
}

export default usePermissions

import {useHasAccess} from "contexts/UserAccessContext";
import {Permissions, Roles} from "contexts/UserAccessContext/const";

const usePermissions = () => {
  const canViewPluginPage = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.CAN_VIEW_PLUGIN_PAGE]
    }
  ])

  return {
    canViewPluginPage
  }
}

export default usePermissions

import SelectFormField from "shared/components/formFields/SelectFormField";
import {useQuery} from "@tanstack/react-query";
import {ConfigsQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

interface RolesSelectFormFieldProps {
  name: string;
  label: string;
}

const RolesSelectFormField = ({name, label}: RolesSelectFormFieldProps) => {
  const {data} = useQuery({
    queryKey: [ConfigsQueryKeys.USER_ROLES],
    queryFn: () => ApiClient.configs.getUserRoles({}),
  })

  const rolesList = data?.roles?.map((role) => ({value: role, label: role.replaceAll('_', ' ').toUpperCase()})) || [];

  return (
    <SelectFormField
      name={name}
      label={label}
      options={rolesList}
    />
  );
};

export default RolesSelectFormField;

import {keepPreviousData, useQuery, useQueryClient} from "@tanstack/react-query";
import {NotificationsQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";
import {useEffect} from "react";

export const useNotificationDetails = (notificationId: string) => {
  const queryClient = useQueryClient()

  const {data, isLoading, isFetching} = useQuery({
    queryKey: [NotificationsQueryKeys.DETAILS, {notificationId}],
    queryFn: () => ApiClient.notifications.getNotificationDetail({notificationId}),
    placeholderData: keepPreviousData,
  })

  useEffect(() => {
    void queryClient.invalidateQueries({queryKey: [NotificationsQueryKeys.LIST]})
  }, []);

  return {
    data,
    isLoading,
    isFetching,
  }
}
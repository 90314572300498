import axios from 'axios';
import {request as __request} from 'generatedTypes/core/request';
import {CancelablePromise} from 'generatedTypes/core/CancelablePromise';
import {BaseHttpRequest} from 'generatedTypes/core/BaseHttpRequest';
import {ApiRequestOptions} from 'generatedTypes/core/ApiRequestOptions';
import type {OpenAPIConfig} from 'generatedTypes/core/OpenAPI';

import {GeneratedClient} from 'generatedTypes/GeneratedClient';
import Cookies from "js-cookie";
import authService from "routes/authService";

export class AxiosApiClient extends BaseHttpRequest {
  axiosInstance = axios.create();

  constructor(config: OpenAPIConfig) {
    super(config);
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          authService.logout();
          return Promise.reject(error)
        }

        return Promise.reject(error)
      }
    );
  }

  public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
    return __request(this.config, options, this.axiosInstance);
  }
}

const ApiClient = new GeneratedClient({
  BASE: process.env.REACT_APP_API_BASE_URL,
  WITH_CREDENTIALS: true,
  HEADERS: {
    "X-CSRF-Token": Cookies.get("csrf_access_token") || '',
  },
}, AxiosApiClient)

export default ApiClient;

import {Controller, useFormContext} from "react-hook-form";
import {MuiTelInput} from 'mui-tel-input'

type SelectFieldsProps = {
  name: string,
  label?: string,
  placeholder?: string,
  isReadOnly?: boolean,
}

const PhoneFormField = ({name, label, placeholder, isReadOnly}: SelectFieldsProps) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <MuiTelInput
          {...field}
          onChange={(e) => field.onChange(e.replaceAll(' ', ''))}
          fullWidth
          size="small"
          label={label}
          id={`${name}-field`}
          placeholder={placeholder}
          helperText={fieldState.error?.message}
          error={fieldState.invalid}
          defaultCountry="CA"
          disabled={isReadOnly}
        />
      )}
    />
  );
};

export default PhoneFormField;

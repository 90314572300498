import {ColDef} from "@ag-grid-community/core";
import {Draggable} from "@hello-pangea/dnd";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {ReactComponent as DragIcon} from 'assets/iconsDS/aggrid-drag-icon.svg'

interface ColumItemProps {
  column: ColDef;
  index: number;
  gridRef: any;
}

const ColumnItem = ({column, index, gridRef}: ColumItemProps) => {
  const theme = useTheme();
  const [isColVisible, setColVisible] = useState(true)
  const gridColumn = gridRef.current?.api?.getColumn(column.field);

  const toggleVisibilityChange = () => {
    if (gridColumn) {
      gridRef.current.api.setColumnVisible(column.field, !isColVisible);
      setColVisible(!isColVisible);
    }
  }

  useEffect(() => {
    if (gridColumn) {
      setColVisible(gridColumn.visible)
    }
  }, []);

  return (
    <Draggable draggableId={String(column.field)} index={index}>
      {provided => (
        <Stack
          direction="row"
          alignItems="center"
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{
            left: "auto !important",
            top: "auto !important",
            marginBottom: '2px'
          }}
        >
          <Checkbox
            checked={isColVisible}
            onClick={toggleVisibilityChange}
            sx={{
              padding: 0,
              '& .MuiSvgIcon-root': {
                width: '20px',
              }
            }}
          />
          <Box sx={{width: '34px'}}>
            <IconButton
              aria-label="drag"
              {...provided.dragHandleProps}
              sx={{
                padding: '6px'
              }}
            >
              <DragIcon/>
            </IconButton>
          </Box>

          <Typography
            variant='B2'
            component={Box}
            sx={{
              fontWeight: 400,
              color: isColVisible ? theme.palette.themeColors.grey['120'] : theme.palette.themeColors.grey['110'],
            }}
          >
            {column.headerName}
          </Typography>
        </Stack>
      )}
    </Draggable>
  )
}

export default ColumnItem;

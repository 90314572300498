import ApiClient from 'apiClient';
import {type CreateCustomerNoteSchema} from 'generatedTypes';
import {UseFormSetError} from 'react-hook-form';
import {IApiError} from "shared/types";
import {FormData} from "apps/customers/shared/CustomerActions/ActionAddNote/index";
import {useMutationWithHandlers} from "shared/hooks/useDataHandlers/useMutationHandler";

type Params = Parameters<typeof ApiClient.customers.postCustomerNotes>[0];

interface ProcessBatchUploadArgs {
  setError: UseFormSetError<FormData>;
  reset: () => void;
  onSuccess: (data: CreateCustomerNoteSchema) => void;
}

const useCreateNote = ({setError, reset, onSuccess}: ProcessBatchUploadArgs) => {
  const {mutate, isPending, apiErrors, apiSuccess} = useMutationWithHandlers<
    CreateCustomerNoteSchema,
    IApiError,
    Params,
    unknown,
    FormData
  >({
    mutationFn: (params: Params) => ApiClient.customers.postCustomerNotes(params),
    options: {
      setError,
      resetForm: reset,
      onSuccess,
    },
    successMessage: 'Note added successfully',
  });

  return {
    mutate,
    isPending,
    apiErrors,
    apiSuccess,
  };
};

export default useCreateNote;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateKYCRequestsResponse } from '../models/CreateKYCRequestsResponse';
import type { KYCRequestDetailsSchema } from '../models/KYCRequestDetailsSchema';
import type { KYCRequestsPaginatedSchema } from '../models/KYCRequestsPaginatedSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class KycRequestsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get KYC requests list
     * @returns KYCRequestsPaginatedSchema Success
     * @throws ApiError
     */
    public getKycRequestsList({
        page,
        perPage,
        search,
        status,
        sid,
        xFields,
    }: {
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * Filter by email address.
         */
        search?: string,
        /**
         * Filter by status.
         */
        status?: string,
        /**
         * Filter by SID.
         */
        sid?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<KYCRequestsPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/kyc-requests/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
                'search': search,
                'status': status,
                'sid': sid,
            },
        });
    }
    /**
     * Submit KYC
     * Roles: merchant, retailer, site, manager
     * Permissions: UPLOAD_KYC_REQUESTS
     * @returns CreateKYCRequestsResponse Success
     * @throws ApiError
     */
    public postCreateKycRequests({
        formData,
        xFields,
    }: {
        formData: {
            email: string;
            merchant_id: number;
            document_type: string;
            first_name?: string;
            last_name?: string;
            phone?: string;
            address?: string;
            city?: string;
            state?: string;
            country?: string;
            zip_code?: string;
            document_upload: Blob;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<CreateKYCRequestsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/kyc-requests/submit-kyc/',
            headers: {
                'X-Fields': xFields,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Get KYC request details
     * @returns KYCRequestDetailsSchema Success
     * @throws ApiError
     */
    public getKycRequestDetail({
        email,
        kycId,
        xFields,
    }: {
        email: string,
        kycId: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<KYCRequestDetailsSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/kyc-requests/{email}/{kyc_id}/',
            path: {
                'email': email,
                'kyc_id': kycId,
            },
            headers: {
                'X-Fields': xFields,
            },
        });
    }
}

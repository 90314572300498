import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Chip} from "@mui/material";
import PhoneFormField from "shared/components/formFields/PhoneFormField";
import {UserSettingsSchema} from "generatedTypes";
import useDialogState from "shared/hooks/useDialogState";
import LoadingButton from "@mui/lab/LoadingButton";
import VerifyPhoneDialog from "apps/account/pages/settings/ContacInfo/VerifyPhoneDialog";
import {useUpdateUserSettings} from "apps/account/pages/settings/useDataQueries";

const schema = yup.object().shape({
  phone: yup.string().matches(
    /^\+?(\d{1,3})?[ .-]?(\()?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{3})$/,
    {message: 'Invalid phone number', excludeEmptyString: true}
  ).required('Phone is required'),
})

type FormData = yup.InferType<typeof schema>;

interface PhoneFormProps {
    phone: UserSettingsSchema["phone"]
    verified: UserSettingsSchema["phone_verified"]
}

const PhoneForm = ({phone, verified}: PhoneFormProps) => {
  const verifyPhoneDialogHandler = useDialogState();

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      phone
    }
  });

  const {mutate, isPending} = useUpdateUserSettings({successText: 'Verification code sent to phone', dialogHandler: verifyPhoneDialogHandler})

  const onSubmit = (data: FormData) => {
    if(data.phone){
      mutate({requestBody: {phone: data.phone}});
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <Box component='form' mx={3} sx={{width: '100%'}} onSubmit={methods.handleSubmit(onSubmit)}>
          <PhoneFormField name="phone" placeholder="Phone"/>
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Chip label={verified ? 'verified' : 'not verified'} color={verified ? 'success': 'error'} />
            <LoadingButton
              loading={isPending}
              variant="contained"
              loadingIndicator="Submitting…"
              type="submit"
              sx={{mt: 2, mb: 2, ml: 1, width: 120}}
              disabled={!methods.formState.isDirty || !methods.formState.isValid}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>

      <VerifyPhoneDialog dialogStateHandler={verifyPhoneDialogHandler} />
    </>
  )
}

export default PhoneForm;
import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {useQueryClient, useMutation} from "@tanstack/react-query";
import {UserLimitsQueryKeys} from "shared/queryKeys";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import {useErrorMessage} from "shared/hooks/useErrorMessage";
import {IApiError} from "shared/types";

type Params = Parameters<typeof ApiClient.userLimits.updateUserLimitOrBlacklist>[0]

interface updateUserLimitOrBlacklistApiUserLimitsPostAttrs{
  dialogHandler: DialogStateHandler;
  reset: () => void;
}

const useUpdateUserLimitOrBlacklist = ({dialogHandler, reset}: updateUserLimitOrBlacklistApiUserLimitsPostAttrs) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient();

  const {errorMessage, handleSetErrorMessage} = useErrorMessage()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.userLimits.updateUserLimitOrBlacklist(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: [UserLimitsQueryKeys.LIST]})
      dialogHandler.closeDialog()
      enqueueSnackbar('User limit/blacklisted update successfully.', {variant: 'success'})
      reset()
    },
    onError: (error: IApiError) => {
      handleSetErrorMessage(error)
      enqueueSnackbar('User limit/blacklisted update failed', {variant: 'error'})
    }
  })

  return {
    mutate,
    isPending,
    errorMessage
  }
}

export default useUpdateUserLimitOrBlacklist;

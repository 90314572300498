import React from 'react';
import ReactDOM from 'react-dom/client';
import {ThemeProvider} from '@mui/material/styles';
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {SnackbarProvider} from 'notistack'
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";
import axios from "axios";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import reportWebVitals from 'reportWebVitals';
import theme from "theme";

import 'index.css';
import authService from "routes/authService";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import {UserAccessProvider} from "contexts/UserAccessContext";
import App from "App";
import {ComponentPreviews, useInitial} from "dev";
import {DevSupport} from "@react-buddy/ide-toolbox";

import "assets/css/ag_grid_theme.css";

import { LicenseManager } from "@ag-grid-enterprise/core";
import {BreadcrumbProvider} from "contexts/BreadcrumbContext";

import {ReactComponent as SuccessIcon} from "assets/iconsDS/SuccessIcon.svg";
import {ReactComponent as ErrorIcon} from "assets/iconsDS/ErrorIcon.svg";
import {ReactComponent as InfoIcon} from "assets/iconsDS/InfoIcon.svg";
import SnackbarCloseButton from "shared/components/DS/SnackbarCloseButton";
import {StyledSnackbar} from "shared/styled/SnackbarStyled";

LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-061632}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Payspace}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Payspace}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Payspace}_need_to_be_licensed___{Payspace}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{12_June_2025}____[v3]_[0102]_MTc0OTY4MjgwMDAwMA==c46811fec47775513f19bb8f2b898193");

if (process.env.REACT_APP_ENV && process.env.REACT_APP_SENTRY_DOMAIN && process.env.REACT_APP_SENTRY_API_DOMAIN && process.env.REACT_APP_SENTRY_DSN && ['staging', 'sandbox', 'production'].includes(process.env.REACT_APP_ENV)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),

      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true
      })
    ],
    tracesSampleRate: 1,

    tracePropagationTargets: [process.env.REACT_APP_SENTRY_DOMAIN, process.env.REACT_APP_SENTRY_API_DOMAIN],

    sendDefaultPii: true,
    sendClientReports: false
  });
}

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers["X-CSRF-Token"] = Cookies.get("csrf_access_token") || '';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      authService.logout();
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
);


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const root = ReactDOM.createRoot(
  document.querySelector('#root') as HTMLElement
);

const SuccessIconComponent = () => <SuccessIcon style={{}} />;
const ErrorIconComponent = () => <ErrorIcon />;
const InfoIconComponent = () => <InfoIcon />;

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          anchorOrigin={{horizontal: 'right', vertical: 'top'}}
          Components={{
            success: StyledSnackbar,
            error: StyledSnackbar,
            info: StyledSnackbar,
            default: StyledSnackbar,
          }}
          iconVariant={{
            success: <SuccessIconComponent />,
            error: <ErrorIconComponent />,
            info: <InfoIconComponent />,
            default: <InfoIconComponent />,
          }}
          action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <UserAccessProvider>
              <DevSupport ComponentPreviews={ComponentPreviews}
                useInitialHook={useInitial}
              >
                <BreadcrumbProvider>
                  <App />
                </BreadcrumbProvider>
              </DevSupport>
            </UserAccessProvider>
          </LocalizationProvider>
          {/* TODO: decide what to do with devtools in production
        https://tanstack.com/query/v4/docs/react/devtools
        */}
          <ReactQueryDevtools initialIsOpen={false}/>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import usePermissions from "apps/customers/pages/CustomerDetails/permissions/usePermissions";
import {Alert, AlertTitle, Container, Divider, Grid} from "@mui/material";
import useBreadcrumb from "shared/hooks/useBreadcrumb";
import {commonBreadcrumbs} from "shared/breadcrumbConfig";
import CustomerHead from "apps/customers/pages/CustomerDetails/partials/CustomerHead";
import {useParams} from "react-router-dom";
import TransactionsTable from "apps/customers/pages/CustomerDetails/partials/TransactionsTable";
import CustomerInfo from "apps/customers/pages/CustomerDetails/partials/CustomerInfo";
import {useCustomerOverview} from "shared/features/queries/CustomerOverviewQuery";
import FinancialDetails from "apps/customers/pages/CustomerDetails/partials/FinancialDetails";
import Payments from "apps/customers/pages/CustomerDetails/partials/Payments";
import Payouts from "apps/customers/pages/CustomerDetails/partials/Payout";
import FinancialInstitutionSummary from "apps/customers/pages/CustomerDetails/partials/FinancialInstitutionSummary";
import RelatedCustomers from "apps/customers/pages/CustomerDetails/partials/RelatedCustomers";
import {calculateAvgValue} from "apps/customers/pages/CustomerDetails/shared/utils";
import {useMemo} from "react";

const CustomerDetails = () => {
  const {customerEmail} = useParams();

  const {data, isLoading} = useCustomerOverview({email: customerEmail || ''})

  useBreadcrumb([commonBreadcrumbs.customersList, {name: data?.full_name || ''}]);

  const paymentRightValue = useMemo(() => calculateAvgValue(
    data?.transactions.transaction_by_status.payment.total?.amount || 0,
    data?.transactions.transaction_by_status.payment.total?.count || 0
  ), [
    data?.transactions.transaction_by_status.payment.total?.amount,
    data?.transactions.transaction_by_status.payment.total?.count
  ]);

  const payoutRightValue = useMemo(() => calculateAvgValue(
    data?.transactions.transaction_by_status.payout.total?.amount || 0,
    data?.transactions.transaction_by_status.payout.total?.count || 0
  ), [
    data?.transactions.transaction_by_status.payout.total?.amount,
    data?.transactions.transaction_by_status.payout.total?.count
  ]);

  const {
    canViewCustomerDetails
  } = usePermissions();

  if (!canViewCustomerDetails) {
    return (
      <Container maxWidth="xs">
        <Alert severity="error" variant="filled">
          <AlertTitle>403 Forbidden</AlertTitle>
          You are not allowed to perform this action!
        </Alert>
      </Container>
    )
  }


  return (
    <>
      <CustomerHead email={customerEmail || ''} fullName={data?.full_name} isLoading={isLoading} />

      <Grid container spacing={3}>
        <Grid item md={4}>
          <CustomerInfo
            paymentBreakdown={data?.transactions.transaction_by_status.payment.breakdown || []}
            billingAddress={data?.latest_info.billing_address || ''}
            address={data?.latest_info.address || ''}
            phoneNumber={data?.latest_info.phone || ''}
            ipAddress={data?.latest_info.ip_address || ''}
            ipAddresses={data?.ip_addresses || []}
            startDate={data?.first_tx.start || ''}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item md={4}>
          <Payments
            total={data?.transactions.transaction_by_status.payment.total?.amount || 0}
            leftValue={data?.previous_month?.payment?.amount || 0}
            rightValue={paymentRightValue}
            completionRate={data?.transactions.transaction_by_status.payment.completion_rate?.count || 0}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item md={4}>
          <Payouts
            total={data?.transactions.transaction_by_status.payout.total?.amount || 0}
            leftValue={data?.previous_month?.payout?.amount || 0}
            rightValue={payoutRightValue}
            completionRate={data?.transactions.transaction_by_status.payout.completion_rate?.count || 0}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>

      <TransactionsTable />

      <Divider />

      <Grid container spacing={3} sx={{marginTop: '8px'}}>
        <Grid item md={8}>
          <FinancialDetails />
        </Grid>
        <Grid item md={4}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FinancialInstitutionSummary />
            </Grid>
            <Grid item md={12}>
              <RelatedCustomers />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default CustomerDetails;

import {DialogStateHandler} from "shared/hooks/useDialogState";
import {Box, Button, Dialog, DialogContent, Typography} from "@mui/material";

interface ConfirmDialogProps {
  dialogStateHandler: DialogStateHandler,
  handleConfirm: () => void,
  title: string
}

const DialogConfirm = ({dialogStateHandler, handleConfirm, title}: ConfirmDialogProps) => (
  <Dialog open={dialogStateHandler.isOpen} onClose={dialogStateHandler.closeDialog} fullWidth>
    <DialogContent>
      <Box display='flex' justifyContent='center' mt={3}>
        <Box
          width={77}
          height={77}
          borderRadius={50}
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{border: '3.5px solid', borderColor: '#facea8', paddingTop: '8px'}}
        >
          <Box color='#f8bb86' fontSize='3.75em'>!</Box>
        </Box>
      </Box>
      <Typography pr={5} pl={5} pt={3} variant='h1' align='center' fontWeight='bold'>
        Confirmation!
      </Typography>
      <Typography pr={5} pl={5} pt={2} pb={2} variant='h2' align='center'>
        {title}
      </Typography>
      <Box display='flex' alignItems='center' justifyContent="center" mt={1}>
        <Button onClick={handleConfirm} size='small' variant="contained" sx={{marginRight: 1}}>
          Confirm
        </Button>
        <Button variant="outlined" size='small' onClick={dialogStateHandler.closeDialog}>
          Cancel
        </Button>
      </Box>
    </DialogContent>
  </Dialog>

)

export default DialogConfirm;

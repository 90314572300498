import {Alert, Box, Grid, Link, Stack} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useMutation} from "@tanstack/react-query";
import AuthFormsContainer from "apps/auth/components/AuthFormsWrapper";
import {loginMutation} from "apps/auth/pages/login/mutations";
import paths from "routes/paths";
import {useState} from "react";
import {AxiosError} from "axios";
import authService from "routes/authService";
import TextFormField from "shared/components/formFields/TextFormField";


const schema = yup.object().shape({
  email: yup.string().email('Please enter a valid email').required('This field is required.'),
  password: yup.string().required('This field is required.'),
});

type FormData = yup.InferType<typeof schema>;

interface LoginProps {
  handle2FAChange: (two_fa_enabled: boolean) => void
  handleMethod2FAChange: (preferred_2fa: string) => void
  handleEmailChange: (email: string) => void
}

function LoginForm({handle2FAChange, handleMethod2FAChange, handleEmailChange}: LoginProps) {
  const [errorMessage, setErrorMessage] = useState('');

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const {mutate, isPending} = useMutation({
    mutationFn: loginMutation,
    onMutate: () => {
      setErrorMessage('')
    },
    onSuccess: (data) => {
      handle2FAChange(data.two_fa_enabled)
      handleMethod2FAChange(data.preferred_2fa)
      if (data.is_authenticated) {
        authService.login(data.is_authenticated)
      }
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      const message = error.response?.data?.message
      if (typeof message === 'string') {
        setErrorMessage(message)
      }

      if (typeof message === 'object' && message !== null) {
        const messages = Object.keys(message).map(key => message[key])
        setErrorMessage(messages.join('\n'))
      }
    }
  })

  const onSubmit = (data: FormData) => {
    handleEmailChange(data.email)
    mutate({email: data.email, password: data.password})
  }

  return (
    <AuthFormsContainer subtitle="sign in here to continue">
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
          sx={{
            mt: 1,
            width: '100%',
          }}
        >
          <Stack spacing={2}>
            <TextFormField
              name="email"
              placeholder="Email Address"
            />
            <TextFormField
              name="password"
              type="password"
              placeholder="Password"
            />
          </Stack>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <LoadingButton
            fullWidth
            loading={isPending}
            variant="contained"
            loadingIndicator="Signing in…"
            type="submit"
            sx={{mt: 3, mb: 2}}
          >
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href={paths.auth.forgotPassword} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            {/* <Grid item> */}
            {/*  <Link href={paths.auth.register} variant="body2"> */}
            {/*      Don&apos;t have an account? Sign Up */}
            {/*  </Link> */}
            {/* </Grid> */}
          </Grid>
        </Box>
      </FormProvider>
    </AuthFormsContainer>
  );
}

export default LoginForm;

import {forwardRef, useImperativeHandle, useMemo, useRef} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ColDef, GridReadyEvent, IServerSideDatasource, ModuleRegistry } from "@ag-grid-community/core";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { ItemModel } from "generatedTypes";
import { useQueryClient } from "@tanstack/react-query";
import ApiClient from "apiClient";
import { TransactionQueryKeys } from "shared/queryKeys";
import { Box, Divider, Typography } from "@mui/material";
import useColumnsConfig from "apps/transactions/pages/TransactionDetails/partials/tabs/Items/useColumnsConfig";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
]);

interface ItemsProps {
  setCount: any;
}

const Items = forwardRef(({setCount}: ItemsProps, ref) => {
  const theme = useTheme();
  const { transactionId } = useParams();
  const queryClient = useQueryClient();
  const { gridRef, columnDefs } = useColumnsConfig();

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    minWidth: 100,
    resizable: false,
  }), []);

  const autoGroupColumnDef = useMemo<ColDef>(() => ({
    minWidth: 200,
  }), []);

  const innerRef = useRef<HTMLDivElement | null>(null);

  const getServerSideDatasource: IServerSideDatasource = {
    getRows: async (params) => {
      const queryKey = [TransactionQueryKeys.ITEMS, { transactionId }];
      const queryFn = () => ApiClient.transactions.getTransactionItems({ txn: transactionId || '' });

      try {
        const trans = await queryClient.fetchQuery({ queryKey, queryFn });

        let rowData = [
          ...trans.items.map(item => ({
            ...item,
            currency_code: trans.currency_code,
          })),
          {
            name: "Subtotal",
            item_total: trans.subtotal,
            currency_code: trans.currency_code,
          },
          {
            name: "Shipping",
            item_total: trans.shipping_cost,
            currency_code: trans.currency_code,
          },
          {
            name: "Total",
            item_total: trans.amount,
            currency_code: trans.currency_code,
          }
        ];

        if (trans.items.length === 0) {
          rowData = [];
        }

        setCount(trans.items.length)

        params.success({ rowData });
      } catch {
        params.fail();
      }
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setGridOption("serverSideDatasource", getServerSideDatasource);
  }

  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      if (innerRef.current) {
        innerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        const rect = innerRef.current?.getBoundingClientRect();
        if (rect) {
          window.scrollBy({ top: rect.top - 70, behavior: 'smooth' });
        }
      }
    }
  }), []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          marginTop: "2rem",
          marginBottom: "2rem"
        }}
        ref={innerRef}
      >
        <Typography variant='h4' fontWeight={600}>
          Items
        </Typography>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            marginTop: "1.25rem",
            '.ag-center-cols-container > div:nth-last-child(3), .ag-center-cols-container > div:nth-last-child(2), .ag-center-cols-container > div:nth-last-child(1)': {
              borderBottom: 'none',
            },
            '.ag-center-cols-container > div:nth-last-child(3) .ag-cell:first-child, .ag-center-cols-container > div:nth-last-child(2) .ag-cell:first-child, .ag-center-cols-container > div:nth-last-child(1) .ag-cell:first-child': {
              textAlign: 'right',
              color: theme.palette.themeColors.grey['110']
            }
          }}
          className="ag-theme-paybilt"
        >
          <AgGridReact<ItemModel>
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            onGridReady={onGridReady}
            rowModelType="serverSide"
            domLayout="autoHeight"
            serverSideInitialRowCount={3}
            suppressServerSideFullWidthLoadingRow
          />
        </Box>
      </Box>

      <Divider />
    </>
  );
});

export default Items;

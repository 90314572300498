import {useWatch} from "react-hook-form";
import {usePermissionsDataQuery} from "apps/users/pages/create/partials/Permissions/useDataQueries";
import {Box, Chip, LinearProgress, TextField, Typography} from "@mui/material";
import {useMemo, useState} from "react";
import {AutoSizer, List} from "react-virtualized";
import CheckboxFormField from "shared/components/formFields/CheckboxFormField";

const Permissions = () => {
  const selectedRole = useWatch({name: 'role'});

  const [search, setSearch] = useState('')

  const {data, isLoading} = usePermissionsDataQuery()

  const isDisabled = selectedRole === '' || selectedRole === 'super_admin';

  function filterData(searchPhrase: string) {
    if (!searchPhrase && !selectedRole) {
      return data?.permissions || [];
    }

    if (selectedRole === 'super_admin') {
      return data?.permissions || [];
    }

    return data?.permissions?.filter(
      (item) => {
        if (searchPhrase && selectedRole) {
          return item?.name?.toLowerCase().includes(searchPhrase.toLowerCase()) && item?.roles?.includes(selectedRole)
        }

        if (searchPhrase){
          return item?.name?.toLowerCase().includes(searchPhrase.toLowerCase())
        }

        if (selectedRole){
          return item?.roles?.includes(selectedRole)
        }

        return false
      }
    ) || [];
  }

  const flatData = useMemo(() => filterData(search), [data, search, selectedRole]);

  // @ts-ignore
  const rowRenderer = ({index, key, style}) => {
    const item = flatData[index];

    return (
      <Box key={key} style={style} display='flex' alignItems='flex-start'>
        <CheckboxFormField
          name={`permissions.${item.name}`}
          label=''
          disabled={isDisabled}
        />
        <Box display='flex' flexDirection='column'>
          <Typography>
            {item.name}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            {item.description}
          </Typography>
          <Chip label={`[${item?.roles?.map(role => `'${role}'`).join(', ')}]`} color='success' sx={{width: 'max-content'}}/>
        </Box>
      </Box>
    )
  }

  if (isLoading) {
    return <LinearProgress/>;
  }

  return (
    <Box sx={{height: '100vh', overflow: 'auto'}}>
      <Box mb={2}>
        <TextField
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size='small'
          sx={{width: 350}}
        />
      </Box>
      <AutoSizer>
        {({height, width}) => (
          <List
            width={width}
            height={height}
            rowCount={flatData.length || 0}
            rowHeight={92}
            rowRenderer={rowRenderer}/>
        )}
      </AutoSizer>
    </Box>
  )
}

export default Permissions;

import {Box} from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import {ReactComponent as NotesIcon} from "assets/iconsDS/Notes.svg";
import {ReactComponent as ItemsIcon} from "assets/iconsDS/Items.svg";
import {ReactComponent as RelatedIcon} from "assets/iconsDS/RelatedTransactions.svg";

interface NavigationProps {
  handleScroll: (value: string) => void;
  itemsCount: number | undefined;
  relatedCount: number | undefined;
  notesCount: number | undefined;
  isLoading: boolean;
}

const Navigation = ({handleScroll, itemsCount, relatedCount, notesCount, isLoading}: NavigationProps) => {
  if (isLoading) {
    return (
      <Box sx={{marginTop: "1.5rem", paddingTop: '0.75rem'}}>
        <ToggleButtonGroup
          value={[]}
        >
          <ToggleButton value="notes" sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
            <NotesIcon/>
            Notes
          </ToggleButton>
          <ToggleButton value="items" sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
            <ItemsIcon/>
            Items
          </ToggleButton>
          <ToggleButton value="related" sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
            <RelatedIcon/>
            Related Transactions
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    )
  }

  return (
    <Box sx={{marginTop: "1.5rem", paddingTop: '0.75rem'}}>
      <ToggleButtonGroup
        value={["notes", "items", "related"]}
        exclusive
        onChange={(_, value) => handleScroll(value)}
      >
        <ToggleButton value="notes" sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
          <NotesIcon/>
          Notes {notesCount !== undefined && `(${notesCount})`}
        </ToggleButton>
        <ToggleButton value="items" sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
          <ItemsIcon/>
          Items {itemsCount !== undefined && `(${itemsCount})`}
        </ToggleButton>
        {relatedCount && relatedCount > 0 && (
          <ToggleButton value="related" sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
            <RelatedIcon/>
            Related Transactions ({relatedCount})
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Box>
  )
}

export default Navigation;
import {Button, Link, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {ReactComponent as CopyIcon} from "assets/iconsDS/copy.svg";
import {ReactComponent as IPAddressIcon} from "assets/iconsDS/IPAddress.svg";
import {IpAddressModel} from "generatedTypes";
import {useCopyToClipboard} from "@uidotdev/usehooks";
import {useSnackbar} from "notistack";
import {useEffect} from "react";

interface IPAddressItemProps {
  item: IpAddressModel
}

const IPAddressItem = ({item}: IPAddressItemProps) => {
  const theme = useTheme();

  const [isCopied, copyToClipboard] = useCopyToClipboard();
  const {enqueueSnackbar} = useSnackbar()

  const handleOnClick = () => {
    void copyToClipboard(item.ip_address);
  }

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar('Copied to clipboard', {variant: 'success'});
    }
  }, [isCopied]);

  return (
    <ListItem
      secondaryAction={
        <Button
          variant='text'
          color='primary'
          size='small'
          onClick={handleOnClick}
          sx={{'svg path': {stroke: theme.palette.primary.main}}}
          endIcon={<CopyIcon/>}
        >
          Copy IP Address
        </Button>
      }
      sx={{padding: '6px 0px'}}
    >
      <ListItemIcon sx={{minWidth: '28px'}}>
        <IPAddressIcon/>
      </ListItemIcon>
      <ListItemText
        primary={
          <Link href={`http://${item.ip_address}`} target="_blank" rel="noopener noreferrer">
            {item.ip_address}
          </Link>
        }
      />
    </ListItem>
  )
}

export default IPAddressItem;
import {useMemo, useRef, useState,} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColDef, GridReadyEvent, IServerSideDatasource, ModuleRegistry} from "@ag-grid-community/core";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {TransactionListItemModel} from "generatedTypes";
import {useQueryClient} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {CustomerQueryKeys} from "shared/queryKeys";
import {Box, Skeleton, Stack, Typography} from "@mui/material";
import AutoSuggestSearch from "apps/transactions/pages/TransactionsList/AutoSuggestSearch";
import AGPagination from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/Pagination";
import {useTheme} from "@mui/material/styles";
import ExportToolBar from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ExportToolBar";
import ColumnsToolBar from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ColumnsToolBar";
import {useParams} from "react-router-dom";
import useColumnsConfig from "apps/customers/pages/CustomerDetails/partials/TransactionsTable/useColumnsConfig";


ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  ColumnsToolPanelModule,
]);

const TransactionsTable = () => {
  const theme = useTheme();

  const {customerEmail} = useParams();

  const [totalCount, setTotalCount] = useState<number | undefined>();

  const queryClient = useQueryClient();
  const {gridRef, columnDefs} = useColumnsConfig()
  const paginationRef = useRef(null);
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    minWidth: 100,
    resizable: false,
  }), []);

  const autoGroupColumnDef = useMemo<ColDef>(() => ({
    minWidth: 200,
  }), []);

  const getServerSideDatasource: IServerSideDatasource = ({
    getRows: async (params) => {
      const {request} = params;
      const {filterModel, sortModel, startRow, endRow} = request;

      const queryKey = [CustomerQueryKeys.TRANSACTIONS, {startRow, endRow, filterModel, sortModel, customerEmail}];

      const perPage = 10;  // should be equal to cacheBlockSize
      const page = startRow ? Math.floor(startRow / perPage) + 1 : 1;

      let orderBy = '';
      if (sortModel.length > 0) {
        const sort = sortModel[0];
        const direction = sort.sort === 'asc' ? '+' : '-';
        orderBy = `${direction}${sort.colId}`;
      }

      const queryFn = () => ApiClient.transactions.getTransactionsList({page, perPage, orderBy, emailAddress: customerEmail});

      try {
        const trans = await queryClient.fetchQuery({queryKey, queryFn});
        setTotalCount(trans.total);
        params.success({
          rowData: trans.items,
          rowCount: trans.total,
        });
      } catch {
        params.fail();
      }
    }
  });

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setGridOption("serverSideDatasource", getServerSideDatasource);
  }

  const onPaginationChanged = () => {
    if (paginationRef.current) {
      // @ts-ignore
      paginationRef.current.updatePaginationDetails();
    }
  };

  return (
    <Box
      sx={{width: "100%", margin: '32px 0px'}}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{
          marginBottom: '22px',
        }}
      >
        <Stack>
          <Box sx={{display: 'flex', gap: '8px', alignItems: 'center', height: '44px'}}>
            <Typography variant="h3">
              Transactions
            </Typography>
            <Typography variant="h3" sx={{display: 'flex', color: theme.palette.themeColors.grey['80']}}>
              {totalCount === undefined ? <Skeleton width={80} height={66} /> : totalCount}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" spacing={2}>
          <AutoSuggestSearch/>
          <ColumnsToolBar
            gridRef={gridRef}
          />
          <ExportToolBar gridRef={gridRef} customerEmail={customerEmail}/>
        </Stack>
      </Stack>

      <Box
        sx={{width: "100%"}}
        className="ag-theme-paybilt"
      >
        <Box height='322px'>
          <AgGridReact<TransactionListItemModel>
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            onGridReady={onGridReady}
            rowModelType="serverSide"
            pagination
            cacheBlockSize={10}
            paginationPageSize={10}
            serverSideInitialRowCount={10}
            paginationPageSizeSelector={[10, 20, 30]}
            rowSelection="multiple"
            onPaginationChanged={onPaginationChanged}
            suppressServerSideFullWidthLoadingRow
          />
        </Box>
        <Box
          sx={{
            backgroundColor: 'white',
            border: `1px solid ${theme.palette.themeColors.grey['40']}`,
            borderTop: 'none',
            borderRadius: '8px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            padding: '12px 16px',
          }}
        >
          <AGPagination
            gridRef={gridRef}
            ref={paginationRef}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionsTable;

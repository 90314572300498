import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {UserQueryKeys} from "shared/queryKeys";
import {useParams} from "react-router-dom";
import {DialogStateHandler} from "shared/hooks/useDialogState";

type Params = Parameters<typeof ApiClient.users.postVerifyUser>[0]

interface VerifyUserDialogAttrs {
  dialogHandler: DialogStateHandler
}

export const useVerifyUser = ({dialogHandler}: VerifyUserDialogAttrs) => {
  const {userId} = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.users.postVerifyUser(params),
    onSuccess: async (data) => {
      enqueueSnackbar(data.message, {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [UserQueryKeys.DETAILS, {userId}]})
      dialogHandler.closeDialog()
    },
  })

  return {
    mutate,
    isPending
  }
}
import {Button, Grid} from "@mui/material";
import {useFormContext} from "react-hook-form";

import {defaultValues} from "apps/transactions/pages/TransactionsList/useFilters/consts";


type FilterComponentProps = {
  onSubmit: (data: any) => void;
}

const FilterButtonsComponent = ({onSubmit}: FilterComponentProps) => {
  const {reset, handleSubmit} = useFormContext();

  const handleResetAndSubmit = async () => {
    reset(defaultValues);
    await handleSubmit((data) => {
      onSubmit(data);
    })();
  }

  return (
    <Grid container spacing={3} justifyContent="right">
      <Grid item>
        <Button type="reset" variant="outlined" onClick={handleResetAndSubmit}>Reset</Button>
      </Grid>
      <Grid item>
        <Button type="submit" variant="contained" color="primary">Search</Button>
      </Grid>
    </Grid>
  );
};

export default FilterButtonsComponent;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import {useCallback} from "react";
import {createBookmark, deleteBookmark} from "apps/transactions/pages/TransactionDetails/queries";
import {useSnackbar} from "notistack";
import {CurrentUserSchema} from "generatedTypes";
import {AccountQueryKeys} from "shared/queryKeys";

function useBookmarkMutations() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar()

  const createBookmarkMutation = useMutation({
    mutationFn: createBookmark,
    onMutate: async (newBookmark) => {
      await queryClient.cancelQueries({ queryKey: [AccountQueryKeys.CURRENT_USER]});
      const currentUser = queryClient.getQueryData([AccountQueryKeys.CURRENT_USER]) as CurrentUserSchema;
      const updatedUser = {
        ...currentUser,
        bookmarked_transactions: [...currentUser.bookmarked_transactions || [], newBookmark.txn]
      }
      queryClient.setQueryData([AccountQueryKeys.CURRENT_USER], updatedUser);
      return { currentUser, newBookmark };
    },
    onSuccess: () => {
      enqueueSnackbar('Bookmark added', { variant: 'success' });
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([AccountQueryKeys.CURRENT_USER], context?.currentUser);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.CURRENT_USER]});
    },
  });

  const deleteBookmarkMutation = useMutation({
    mutationFn: deleteBookmark,
    onMutate: async (deletedBookmark) => {
      await queryClient.cancelQueries({ queryKey: [AccountQueryKeys.CURRENT_USER] });
      const currentUser = queryClient.getQueryData([AccountQueryKeys.CURRENT_USER]) as CurrentUserSchema;
      const updatedUser = {
        ...currentUser,
        bookmarked_transactions: currentUser.bookmarked_transactions?.filter(txn => txn !== deletedBookmark.txn)
      };
      queryClient.setQueryData([AccountQueryKeys.CURRENT_USER], updatedUser);
      return { currentUser, deletedBookmark };
    },
    onSuccess: () => {
      enqueueSnackbar('Bookmark has been deleted', { variant: 'success' });
    },
    onError: (err, deletedBookmark, context) => {
      queryClient.setQueryData([AccountQueryKeys.CURRENT_USER], context?.currentUser);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.CURRENT_USER]});
    },
  });

  const handleCreateBookmark = useCallback(
    async (transactionId: string) => {
      await createBookmarkMutation.mutateAsync({ txn: transactionId });
    },
    [createBookmarkMutation]
  );

  const handleDeleteBookmark = useCallback(
    async (transactionId: string) => {
      await deleteBookmarkMutation.mutateAsync({ txn: transactionId });
    },
    [deleteBookmarkMutation]
  );

  return { handleCreateBookmark, handleDeleteBookmark };
}

export default useBookmarkMutations;

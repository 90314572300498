import {Box, Divider, Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import type {FiDetailsModel, TransactionDetailModel} from "generatedTypes";
import MarkedBy from "shared/components/DS/MarkedBy";
import Site from "shared/components/DS/Site";
import DetailItem from "apps/transactions/pages/TransactionDetails/partials/TransactionDetailsData/DetailItem";
import MethodIcon from "shared/components/MethodIcon";
import UDFS from "shared/components/DS/UDFS";
import Bank from "shared/components/DS/Bank";
import {TransactionStatus, TransactionType} from "shared/enum_const";
import SkeletonTransactionDetailsData
  from "apps/transactions/pages/TransactionDetails/partials/TransactionDetailsData/SkeletonTransactionDetailsData";
import {fCurrency} from "shared/utils";

interface TransactionDetailsDataProps {
  txn: TransactionDetailModel['txn']
  markedBy?: TransactionDetailModel['marked_by']
  sid?: TransactionDetailModel['sid']
  siteName?: TransactionDetailModel['site_name']
  refundableBalance?: TransactionDetailModel['refundable_balance']
  firstName?: TransactionDetailModel['first_name']
  lastName?: TransactionDetailModel['last_name']
  method?: TransactionDetailModel['method']
  descriptor?: TransactionDetailModel['descriptor']
  udfs?: TransactionDetailModel['udfs']
  bankName?: FiDetailsModel['fi_name']
  status?: TransactionDetailModel['transaction_status']
  type?: TransactionDetailModel['type']
  isLoading: boolean
}

const TransactionDetailsData = ({
  txn, markedBy, sid, siteName, refundableBalance, firstName, lastName,
  method, descriptor, udfs, bankName, status, type, isLoading
}: TransactionDetailsDataProps) => {
  const theme = useTheme();
  const isRefundableBalanceVisible = TransactionType.payment === type && TransactionStatus.approved === status;

  if (isLoading) return <SkeletonTransactionDetailsData />

  return (
    <>
      <Box
        sx={{
          borderRadius: theme.spacing(1),
          padding: theme.spacing(2),
          paddingTop: '13px',
          border: `1px solid ${theme.palette.themeColors.grey['40']}`,
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Stack direction="row">
          <Box sx={{
            marginRight: '11px',
            marginTop: '2px'
          }}>
            <MethodIcon method={method} descriptor={descriptor} width={40} />
          </Box>

          <Stack justifyContent='center'>
            <Typography variant='h5' fontWeight={600}>
              Transaction Details
            </Typography>

            <Stack
              direction="row"
              spacing={1}
            >
              <Typography
                variant="B4"
                sx={{
                  color: theme.palette.themeColors.grey['90'],
                  fontWeight: 400
                }}
              >
                {txn}
              </Typography>
              <MarkedBy values={markedBy} />
            </Stack>

          </Stack>
        </Stack>

        <Divider
          sx={{
            margin: '12px 0',
          }}
        />

        <Stack
          direction='row'
          justifyContent='flex-start'
          spacing={5 * 2}
        >
          <DetailItem name="Website">
            {siteName && sid && (
              <Site siteName={siteName} sid={sid}/>
            )}
          </DetailItem>

          {isRefundableBalanceVisible && (
            <DetailItem name="Refundable Balance" title="Amount that can be refunded">
              <Typography variant="B4" color={theme.palette.secondary.main}>
                {fCurrency(refundableBalance)}
              </Typography>
            </DetailItem>
          )}


          {bankName && (
            <DetailItem name="Bank">
              <Bank
                bankName={bankName}
                typographySX={{
                  color: theme.palette.secondary.main
                }}
              />
            </DetailItem>
          )}

          <DetailItem name="Account Holder">
            <Typography variant="B4" color={theme.palette.secondary.main}>
              {firstName} {lastName}
            </Typography>
          </DetailItem>
        </Stack>
      </Box>
      {udfs &&
        <Box
          sx={{
            marginTop: '20px'
          }}
        >
          <UDFS values={udfs} udf1Title="Merchant-provided unique ID" />
        </Box>
      }
    </>
  );
};

export default TransactionDetailsData;

import {useState, useMemo, useEffect} from "react";
import {debounce} from "@mui/material/utils";
import * as React from "react";
import {PlaceType} from "shared/components/formFields/AddressAutocompleteFormField/types";

const autocompleteService = { current: null };



const useOptions = () => {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const [inputValue, setInputValue] = useState('');

  const fetch = useMemo(() => debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
    (autocompleteService.current as any).getPlacePredictions(
      request,
      callback,
    );
  }, 400), []);

  const handleValueChange = (newValue: PlaceType | null) => {
    setValue(newValue);
  }

  const handleInputValueChange = (newInputValue: string) => {
    setInputValue(newInputValue);
  }

  const handleOptionsChange = (newOptions: readonly PlaceType[]) => {
    setOptions(newOptions);
  }

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    // eslint-disable-next-line consistent-return
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return {
    value,
    options,
    inputValue,
    handleInputValueChange,
    handleValueChange,
    handleOptionsChange,
  }
}

export default useOptions;

import {Box, Typography} from "@mui/material";
import {colorDot, colorText, methodText} from "shared/components/DS/Method/consts";

interface MethodProps {
  name: string;
}

const Method = ({name}: MethodProps) => (
  <Typography
    variant='B4'
    sx={{
      display: 'inline-flex',
      alignItems: 'center',
      color: colorText[name] || 'black',
      fontWeight: 500,
      fontSize: 15,
    }}
  >
    <Box
      sx={{
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: colorDot[name] || 'black',
        marginRight: 1,
      }}
    />
    {methodText[name] || name}
  </Typography>
)

export default Method

import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColDef, GridReadyEvent, IServerSideDatasource, ModuleRegistry} from "@ag-grid-community/core";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {LinkedTransactionModel} from "generatedTypes";
import {useQueryClient} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {TransactionQueryKeys} from "shared/queryKeys";
import {Box, Divider, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import useColumnsConfig
  from "apps/transactions/pages/TransactionDetails/partials/tabs/RelatedTransactions/useColumnsConfig";

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
]);

interface RelatedTransactionsProps {
  setCount: any;
}

const RelatedTransactions = forwardRef(({setCount}: RelatedTransactionsProps, ref) => {
  const {transactionId} = useParams()
  const queryClient = useQueryClient()
  const {columnDefs} = useColumnsConfig()
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    minWidth: 100,
    resizable: false,
  }), []);

  const autoGroupColumnDef = useMemo<ColDef>(() => ({
    minWidth: 200,
  }), []);

  const [isEmpty, setIsEmpty] = useState(false);

  const getServerSideDatasource: IServerSideDatasource = ({
    getRows: async (params) => {
      const queryKey = [TransactionQueryKeys.RELATED_TRANSACTIONS, {transactionId}];

      const queryFn = () => ApiClient.transactions.getLinkedTransactions({txn: transactionId || ''})

      try {
        const trans = await queryClient.fetchQuery({queryKey, queryFn});

        if (trans.transactions && trans.transactions.length === 0) {
          setIsEmpty(true);
          setCount(0);
        }

        if (trans.transactions){
          setCount(trans.transactions.length);
        }

        params.success({
          rowData: trans.transactions || [],
        });
      } catch {
        params.fail();
      }
    }
  });

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setGridOption("serverSideDatasource", getServerSideDatasource);
  }

  const innerRef = useRef<HTMLDivElement | null>(null);

  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      if (innerRef.current) {
        innerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
        const rect = innerRef.current?.getBoundingClientRect();
        if (rect) {
          window.scrollBy({ top: rect.top - 70, behavior: 'smooth' });
        }
      }
    }
  }), []);

  if (isEmpty) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          marginTop: "2rem",
          marginBottom: "2rem"
        }}
        ref={innerRef}
      >
        <Typography variant='h4' fontWeight={600}>
          Related Transactions
        </Typography>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            marginTop: "1.25rem"
          }}
          className="ag-theme-paybilt"
        >
          <AgGridReact<LinkedTransactionModel>
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            onGridReady={onGridReady}
            rowModelType="serverSide"
            domLayout="autoHeight"
          />
        </Box>
      </Box>

      <Divider />
    </>
  );
})

export default RelatedTransactions;

import {Components} from "@mui/material/styles";
import palette from "theme/palette";
import typography from "theme/typography";

const MuiToggleButton: Components['MuiToggleButton'] =   {
  styleOverrides: {
    root: {
      textTransform: 'none',
      border: '1px solid',
      borderColor: palette.themeColors.grey['70'],
      borderRadius: '4px',
      color: palette.themeColors.grey['100'],
      backgroundColor: palette.themeColors.grey['20'],
      ...typography.h6,
      '&:hover': {
        color: palette.themeColors.grey['110'],
        borderColor: palette.themeColors.grey['80'],
        backgroundColor: palette.themeColors.grey['30'],
      },
      '&.Mui-focusVisible': {
        color: palette.themeColors.purple['20'],
        backgroundColor: palette.themeColors.grey['30'],
        outline: '3px solid rgba(129, 69, 181, 0.10)'
      },
      '&.Mui-selected': {
        borderColor: palette.themeColors.purple['20'],
        backgroundColor: palette.themeColors.purple['30'],
        color: palette.themeColors.purple['20'],
      },
      '&.Mui-selected:hover': {
        color: palette.themeColors.purple['20'],
        backgroundColor: palette.themeColors.purple['50'],
      },
      '&.Mui-selected:active': {
        backgroundColor: palette.themeColors.purple['50'],
        borderColor: palette.themeColors.purple['20'],
        color: palette.themeColors.purple['20'],
      },
    },
    sizeSmall: {
      height: '28px',
      padding: '6px 8px',
    }
  },
}

export default MuiToggleButton;

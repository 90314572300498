import {useQuery} from "@tanstack/react-query";
import authService from "routes/authService";
import {AccountQueryKeys} from "shared/queryKeys";
import apiClient from "apiClient";

const useCurrentUser = () => {
  const {data, isLoading} = useQuery({
    queryKey: [AccountQueryKeys.CURRENT_USER],
    queryFn: () => apiClient.account.getCurrentUser(),
    enabled: authService.isAuthenticated
  })

  return {user: data, isLoading}
};

export default useCurrentUser;

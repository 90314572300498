import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {useSnackbar} from 'notistack';
import {DialogStateHandler} from "shared/hooks/useDialogState";
import {useParams} from "react-router-dom";
import {TransactionQueryKeys} from "shared/queryKeys";

type Params = Parameters<typeof ApiClient.transactions.refundTransaction>[0]

interface RefundMutationArgs {
  dialogStateHandler: DialogStateHandler
  notification: string
}

export const useRefundMutation = ({dialogStateHandler, notification}: RefundMutationArgs) => {
  const {enqueueSnackbar} = useSnackbar();
  const queryClient = useQueryClient();
  const {transactionId} = useParams();

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.transactions.refundTransaction(params),
    onSuccess: async () => {
      enqueueSnackbar(notification, {variant: 'success'});
      dialogStateHandler.closeDialog();
      await queryClient.invalidateQueries({queryKey: [TransactionQueryKeys.DETAILS, {txn: transactionId}]});
    },
    onError: () => {
      enqueueSnackbar('Refund request failed', {variant: 'error'});
      dialogStateHandler.closeDialog();
    }
  })

  return {
    mutate,
    isPending,
  }
}

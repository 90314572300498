import {useFieldArray, useFormContext} from 'react-hook-form';
import TextFormField from "shared/components/formFields/TextFormField";
import Button from "@mui/material/Button";
import {Grid} from "@mui/material";

interface BaseProps {
  name: string;
  label: string;
  fieldContainerSX?: {[key: string]: any}
}

interface AppendTextArrayFormProps extends BaseProps {
  appendButtonTitle: string;
  prependButtonTitle?: string;
}

interface PrependTextArrayFormProps extends BaseProps {
  prependButtonTitle: string;
  appendButtonTitle?: string;
}

type TextArrayFormFieldProps = AppendTextArrayFormProps | PrependTextArrayFormProps;

const TextArrayFormField = ({name, label, appendButtonTitle, prependButtonTitle, fieldContainerSX}: TextArrayFormFieldProps) => {
  const {control} = useFormContext();
  const {fields, prepend, append, remove} = useFieldArray({
    name,
    control,
  });

  return (
    <>
      {fields.map((field, i) => (
        <Grid container spacing={2} key={field.id} sx={fieldContainerSX}>
          <Grid item xs>
            <TextFormField
              key={field.id}
              name={`${name}.${i}.value`}
              label={`${label} ${i+1}`}
            />
          </Grid>
          <Grid item xs="auto">
            <Button onClick={() => remove(i)}>Remove</Button>
          </Grid>
        </Grid>
      ))}
      {prependButtonTitle && (
        <Button onClick={() => prepend({value: ''}, {focusIndex: 1})}>
          {prependButtonTitle}
        </Button>
      )}

      {appendButtonTitle && (
        <Button onClick={() => append({value: ''}, {focusName: `${name}.0.value`})}>
          {appendButtonTitle}
        </Button>
      )}
    </>
  );
};

export default TextArrayFormField;

import { compile, pathToRegexp, PathFunction } from 'path-to-regexp';

interface Params {
  [key: string]: string | number | boolean | undefined | null;
}

class RoutePath {
  private _pattern: string;

  private _compiledPattern: PathFunction;

  private _regexp: RegExp;

  constructor(pattern: string) {
    if (!pattern) {
      throw new Error('Pattern is required');
    }
    try {
      this._compiledPattern = compile(pattern, { encode: encodeURIComponent });
      this._regexp = pathToRegexp(pattern);
    } catch (error) {
      throw new Error(`Invalid pattern: ${(error as Error).message}`);
    }
    this._pattern = pattern;
  }

  /**
   * Reverses the pattern into a URL string with the provided parameters.
   * @param params - The parameters to substitute in the pattern.
   * @returns The generated URL string.
   */
  reverse(params?: Params): string {
    try {
      const url = this._compiledPattern(params || {});
      if (!this.validate(url)) {
        throw new Error(`Generated URL does not match the pattern: ${url}`);
      }
      return url;
    } catch (error) {
      throw new Error(`Failed to generate URL: ${(error as Error).message}`);
    }
  }

  /**
   * Validates the generated URL against the pattern.
   * @param url - The URL to validate.
   * @returns True if the URL matches the pattern, false otherwise.
   */
  validate(url: string): boolean {
    return this._regexp.test(url);
  }

  /**
   * Gets the pattern string.
   * @returns The pattern string.
   */
  get pattern(): string {
    return this._pattern;
  }
}

export default RoutePath;

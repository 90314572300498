import {Grid} from "@mui/material";
import {memo} from "react";
import SelectFormField from "shared/components/formFields/SelectFormField";
import TextFormField from "shared/components/formFields/TextFormField";
import SidAutocompleteFormField from "shared/components/formDataFields/SidAutocompleteFormField";

const actionList = [
  {value: "cancel_transaction", label: "Cancel"},
  {value: "refund_transaction", label: "Refund"},
]

const statusList = [
  {value: "completed", label: "Completed"},
  {value: "pending", label: "Pending"},
  {value: "processing", label: "Processing"},
];

const FilterComponent = memo(() => (
  <>
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextFormField name="search" placeholder="Search"/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFormField name="txn" placeholder="TXID"/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SidAutocompleteFormField name="sid" isMulti/>
      </Grid>
    </Grid>
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12} sm={6}>
        <SelectFormField name="action" label="Action" options={actionList}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectFormField name="status" label="Status" options={statusList}/>
      </Grid>
    </Grid>
  </>
));

export default FilterComponent;

import {Box, Grid} from "@mui/material";
import {usePluginSidDetails} from "apps/plugins/pages/enable/useDataQueries";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useDebounce} from "@uidotdev/usehooks";
import SidAutocompleteFormField from "shared/components/formDataFields/SidAutocompleteFormField";

import PluginCard from "apps/plugins/pages/enable/PluginCard";
import PluginSkeleton from "apps/plugins/pages/enable/PluginSkeleton";
import PermissionError from "shared/PermissionError";
import usePermissions from "apps/plugins/pages/enable/permissions/usePermissions";

const schema = yup.object().shape({
  sid: yup.number().required('SID is required'),
})

type FormData = yup.InferType<typeof schema>;

const EnablePlugin = () => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur'
  });

  const sidField = useDebounce(methods.watch('sid'), 400);

  const {data, isLoading, isFetching} = usePluginSidDetails(sidField);

  const {canViewPluginPage} = usePermissions();

  if (!canViewPluginPage){
    return <PermissionError />
  }

  return (
    <>
      <Box display='flex' alignItems='flex-start' justifyContent='flex-end'>
        <Box>
          <FormProvider {...methods}>
            <Box display='flex'>
              <Box width={350}>
                <SidAutocompleteFormField name="sid" preselectFirst/>
              </Box>
            </Box>
          </FormProvider>
        </Box>
      </Box>

      <Grid container spacing={3} mt={3}>
        <Grid item lg={4}>
          {isLoading || isFetching ? <PluginSkeleton /> : <PluginCard enabled={data?.plugins?.moneris?.enabled || false}/>}
        </Grid>
      </Grid>
    </>
  )
};

export default EnablePlugin;

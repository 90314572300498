import {useMutation} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {IApiError} from "shared/types";
import {useState} from "react";
import {Report} from "apps/batchUpload/pages/index/ErrorsList";
import {ProcessBarchFileResponseModel} from "generatedTypes";

type Params = Parameters<typeof ApiClient.batchUploads.processBatchFile>[0]

type SetError = (
  name: string,
  error: any,
) => void;

function setFormErrors(error: IApiError, setError: SetError) {
  error.body.errors.forEach((err: any) => {
    Object.keys(err).forEach((key: string) => {
      setError(key, {type: 'api_response', message: err[key].message});
    });
  });
}

interface ProcessBatchUploadArgs {
  setError: SetError
  reset: () => void
}

const useProcessBatchUpload = ({setError, reset}: ProcessBatchUploadArgs) => {
  const [apiErrors, setApiErrors] = useState<Report>()
  const [apiSuccess, setApiSuccess] = useState<ProcessBarchFileResponseModel>()
  const { enqueueSnackbar } = useSnackbar()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.batchUploads.processBatchFile(params),
    onMutate: () => {
      setApiErrors(undefined)
    },
    onError: (error: IApiError) => {
      if (error.body.errors) {
        setFormErrors(error, setError)
      }
      if (error.body.detail.api_errors) {
        setApiErrors(error.body.detail.api_errors as Report)
      }
      if (error.body.detail.permissions) {
        enqueueSnackbar(error.body.detail.permissions, {variant: 'error'})
      }
    },
    onSuccess: (data) => {
      reset()
      setApiSuccess(data)
      enqueueSnackbar('Batch has been uploaded successfully', {variant: 'success'})
    },
  })

  return {
    mutate,
    isPending,
    apiErrors,
    apiSuccess,
  }
}

export default useProcessBatchUpload;

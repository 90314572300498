import {Badge, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {ProcessBarchFileResponseModel} from "generatedTypes";


interface SuccessImportListProps {
  comment: ProcessBarchFileResponseModel['comment'];
  report: ProcessBarchFileResponseModel['report'];
}

const SuccessImportList = ({comment, report}: SuccessImportListProps) => {
  const total = report.items.length;
  const totalAmount = report.total_amount;
  const approved = report.items.filter(item => item.status).length;

  return (
    <Box>
      <Box mb={1}>
        <Badge variant="standard" color="info">Total: {total}</Badge><br/>
        <Badge variant="standard" color="info">Total amount: ${totalAmount.toFixed(2)} CAD</Badge><br/>
        <Badge variant="standard" color="success">Approved: {approved}</Badge><br/>
      </Box>
      <Box mb={1}>
        <Typography>Comment: </Typography>
        <Badge variant="standard" color="primary">{comment}</Badge>
      </Box>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>UDF1</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.items.map((row, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.udf1}</TableCell>
                <TableCell>
                  <Badge variant="standard"
                    color={row.status ? 'success' : 'error'}>{row.status ? 'Success' : 'Failed'}</Badge>
                </TableCell>
                <TableCell>
                  <ol>
                    {row.message.map((message, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={i}>{message}</li>
                    ))}
                  </ol>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SuccessImportList;

import axios from "axios";
import apiPaths from 'routes/apiPaths'
import {
  APILookupQueryArgs,
  APILookupResponse,
  BookmarkQueryArgs,
  BookmarkResponse,
  LinkedTransactionQueryArgs,
  LinkedTransactionResponse,
  TimelineDialogQueryArgs,
  TimelineDialogResponse,
  TimelineQueryArgs,
  TimelineResponse,
  TransactionFinancialDetailsQueryArgs,
  TransactionFinancialDetailsResponse,
  ViewTextQueryArgs,
  ViewTextResponse,
} from "./types";

export const getTransactionFinancialDetails = async ({txn, page, perPage}: TransactionFinancialDetailsQueryArgs) => {
  const {data} = await axios.get<TransactionFinancialDetailsResponse>(apiPaths.transactionPaths.financialDetails.reverse({txn: String(txn)}), {
    params: {
      page,
      per_page: perPage,
    }
  })
  return data
}

export const getViewText = async ({txn}: ViewTextQueryArgs) => {
  const {data} = await axios.get<ViewTextResponse>(apiPaths.transactionPaths.view_text.reverse({txn: String(txn)}))
  return data
}

export const createBookmark = async ({txn}: BookmarkQueryArgs) => {
  const {data} = await axios.post<BookmarkResponse>(apiPaths.transactionPaths.bookmark.reverse({txn: String(txn)}))
  return data
}

export const deleteBookmark = async ({txn}: BookmarkQueryArgs) => {
  const {data} = await axios.patch<BookmarkResponse>(apiPaths.transactionPaths.bookmark.reverse({txn: String(txn)}))
  return data
}

export const getAPILookup = async ({txn}: APILookupQueryArgs) => {
  const {data} = await axios.get<APILookupResponse>(apiPaths.transactionPaths.apiLookup.reverse({txn: String(txn)}))
  return data
}

export const getTimeline = async ({txn}: TimelineQueryArgs) => {
  const {data} = await axios.get<TimelineResponse>(apiPaths.transactionPaths.timeline.reverse({txn: String(txn)}))
  return data
}

export const getTimelineDialog = async ({txn, dialog}: TimelineDialogQueryArgs) => {
  const {data} = await axios.get<TimelineDialogResponse>(apiPaths.transactionPaths.timelineDialog.reverse({txn: String(txn), dialog: String(dialog)}))
  return data
}

export const getLinkedTransaction = async ({txn}: LinkedTransactionQueryArgs) => {
  const {data} = await axios.get<LinkedTransactionResponse>(apiPaths.transactionPaths.linkedTransaction.reverse({txn: String(txn)}))
  return data
}

import React from 'react'
import {Outlet} from 'react-router-dom'
import {Box, CssBaseline,} from '@mui/material'

import TopNavigation from "layouts/MainLayout/partials/TopNavigation";
import Sidebar from "layouts/MainLayout/partials/Sidebar";
import {useTheme} from "@mui/material/styles";

const drawerWidth = 240
const menuWidth = 56

const Layout = () => {
  const theme = useTheme();
  const [isDrawerOpen, setDrawerOpen] = React.useState(false)

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev)
  }

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline/>
      <TopNavigation
        isDrawerOpen={isDrawerOpen}
        drawerWidth={drawerWidth}
        menuWidth={menuWidth}
      />
      <Sidebar
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        drawerWidth={drawerWidth}
        menuWidth={menuWidth}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: '32px',
          pt: '75px',
          minHeight: '100vh',
          backgroundColor: theme.palette.themeColors.grey['10'],
          ...(isDrawerOpen
            ? {width: drawerWidth + menuWidth}
            : {width: menuWidth}),
        }}>
        <Outlet/>
      </Box>
    </Box>
  )
}

export default Layout

import {
  Box, Container,
  LinearProgress,
  Typography
} from "@mui/material";
import {AppCard} from "shared/components/mui";
import {useNotificationDetails} from "apps/notifications/details/useDataQueries";
import {useParams} from "react-router-dom";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";

const NotificationDetails = () => {
  const {notificationId} = useParams()

  const {data, isLoading} = useNotificationDetails(notificationId || '')

  if (isLoading) return <LinearProgress/>

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        height: "50vh",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <AppCard sx={{p: 3}}>
        <Box>
          <Box mb={3}>
            <Typography variant='h6' align='center'>{data?.title}</Typography>
          </Box>
          <Box>
            {/* eslint-disable react/no-danger */}
            <div style={{fontSize: 15}} dangerouslySetInnerHTML={{ __html: data?.body || '' }} />
          </Box>
          <Box mt={2}>
            <Typography fontSize={15} align='right'>{data?.timestamp && convertIsoToCustomFormat(data?.timestamp)}</Typography>
          </Box>
        </Box>
      </AppCard>
    </Container>
  )
};

export default NotificationDetails;

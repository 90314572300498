import {useState} from "react";

enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

interface useSortingArgs {
  defaultOrderBy: string;
  defaultOrderDirection?: OrderDirection;
}

const useSorting = ({defaultOrderBy, defaultOrderDirection = OrderDirection.DESC}: useSortingArgs) => {
  const directionMap = {
    [OrderDirection.ASC]: '+',
    [OrderDirection.DESC]: '-',
  }

  const [orderDirection, setOrderDirection] = useState<OrderDirection>(defaultOrderDirection);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);

  const orderByField = directionMap[orderDirection] + orderBy;

  const handleOrderBy = (id: string) => {
    if (orderBy === id) {
      setOrderDirection((prev) => (prev === OrderDirection.ASC ? OrderDirection.DESC : OrderDirection.ASC));
    } else {
      setOrderBy(id);
    }
  }

  return {
    orderDirection,
    orderByField,
    orderBy,
    handleOrderBy
  }
};

export default useSorting;
import {useQuery} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {useFormFilters} from "apps/dashboard/pages/Dashboard/useFormFilters";
import {StatisticsQueryKeys} from "shared/queryKeys";
import {ChartTypes} from "apps/dashboard/pages/Dashboard/enums";

interface SiteSharePaymentDataArgs {
  activeChart: ChartTypes
}

export const useSiteSharePaymentDataQueries = ({activeChart}: SiteSharePaymentDataArgs) => {
  const filters = useFormFilters()

  const {data} = useQuery({
    queryKey: [StatisticsQueryKeys.SITE_BREAKDOWN, {activeChart}, filters],
    queryFn: () => ApiClient.dashboard.getSiteBreakdown({...filters, sortBy: activeChart}),
  })

  return {
    data
  }
}

import React from 'react'
import { TableRow, TableRowProps, styled } from '@mui/material'

interface AppTableRowProps extends TableRowProps {
  progress: number
}

const MuiTableRow = styled(TableRow)<AppTableRowProps>(({ theme, progress }) => ({
  position: 'relative',
  zIndex: 1,

  '&:after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    width: `${progress}%`,
    height: 35,
    backgroundColor: theme.palette.grey[200],
    zIndex: -1,
  },

  '& .MuiTableCell-root': {
    borderBottom: 'none',
    padding: theme.spacing(2, 1),
  },
}))

const AppTableRow: React.FC<AppTableRowProps> = ({
  children,
  progress,
  ...props
}) => (
  <MuiTableRow progress={progress} {...props}>
    {children}
  </MuiTableRow>
)

export default AppTableRow

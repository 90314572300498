import {Box, Typography} from "@mui/material";

interface NoRecordsFoundProps {
  text: string
}

const NoRecordsFound = ({text}: NoRecordsFoundProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100% - 56px)',
      p: 3,
    }}
  >
    <Typography variant='body2' color='text.secondary'>
      {text}
    </Typography>
  </Box>
)

export default NoRecordsFound
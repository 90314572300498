import React from "react";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {ReactComponent as CloseIcon} from "assets/iconsDS/Close.svg";

interface ToolBarHeadProps {
  title: string;
  onClose: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

const ToolBarHead = ({title, onClose}: ToolBarHeadProps) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        padding: '12px',
        borderBottom: `1px solid ${theme.palette.themeColors.grey[50]}`,
      }}
    >
      <Typography
        variant='B1'
        sx={{
          fontWeight: 700,
        }}
      >
        {title}
      </Typography>
      <IconButton>
        <Box
          component={CloseIcon}
          onClick={onClose}
          sx={{
            '& path': {fill: theme.palette.themeColors.grey[90]},
          }}
        />
      </IconButton>
    </Stack>
  );
}

export default ToolBarHead;

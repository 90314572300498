import AuthFormsContainer from "apps/auth/components/AuthFormsWrapper";
import {Box, TextField} from "@mui/material";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {useMutation} from "@tanstack/react-query";
import {validate2FAMutation} from "apps/auth/pages/login/mutations";
import authService from "routes/authService";

const schema = yup.object().shape({
  code: yup.string().required('This field is required.'),
});

type FormData = yup.InferType<typeof schema>;

interface EmailAddressCodeProps {
  user_email?: string
}

const EmailAddressCode = ({user_email}: EmailAddressCodeProps) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const { mutate, isPending } = useMutation({
    mutationFn: validate2FAMutation,
    onSuccess: (data) => {
      if (data.data.is_authenticated) {
        authService.login(data.data.is_authenticated)
      }
    }
  })

  const onSubmit = (data: FormData) => {
    mutate({code: data.code, email: user_email})
  }

  return (
    <AuthFormsContainer title="Submit Two FA Code" subtitle="submit two fa code sent to your email or phone.">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, width: '100%' }}>
        <TextField
          margin="normal"
          size="small"
          required
          fullWidth
          id="code"
          placeholder="Code"
          autoFocus
          error={!!errors?.code}
          helperText={errors?.code?.message}
          {...register("code")}
        />
        <LoadingButton
          fullWidth
          loading={isPending}
          variant="contained"
          loadingIndicator="Signing in…"
          type="submit"
          sx={{ mt: 3, mb: 2 }}
        >
            Submit
        </LoadingButton>
      </Box>
    </AuthFormsContainer>
  );
}

export default EmailAddressCode;

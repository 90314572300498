import { Box } from '@mui/material';
import AutocompleteFormField from "shared/components/formFields/AutocompleteFormField";

interface CountryAutocompleteFormFieldProps {
  name: string,
  label: string,
}

interface CountryType {
  value: string;
  label: string;
  suggested?: boolean;
}

const countries: CountryType[] = [
  { value: 'AD', label: 'Andorra'},
  {
    value: 'AE',
    label: 'United Arab Emirates',
  },
  { value: 'AF', label: 'Afghanistan'},
  {
    value: 'AG',
    label: 'Antigua and Barbuda',
  },
  { value: 'AI', label: 'Anguilla',},
  { value: 'AL', label: 'Albania'},
  { value: 'AM', label: 'Armenia'},
  { value: 'AO', label: 'Angola'},
  { value: 'AQ', label: 'Antarctica'},
  { value: 'AR', label: 'Argentina'},
  { value: 'AS', label: 'American Samoa'},
  { value: 'AT', label: 'Austria'},
  {
    value: 'AU',
    label: 'Australia',
    suggested: true,
  },
  {
    value: 'BA',
    label: 'Bosnia and Herzegovina',
  },
  { value: 'BD', label: 'Bangladesh'},
  { value: 'BE', label: 'Belgium'},
  { value: 'BF', label: 'Burkina Faso'},
  { value: 'BG', label: 'Bulgaria'},
  { value: 'BH', label: 'Bahrain'},
  { value: 'BR', label: 'Brazil'},
  { value: 'BS', label: 'Bahamas'},
  { value: 'BY', label: 'Belarus'},
  {
    value: 'CA',
    label: 'Canada',
    suggested: true,
  },
  { value: 'CH', label: 'Switzerland'},
  { value: 'CI', label: "Cote d'Ivoire"},
  { value: 'CL', label: 'Chile'},
  { value: 'CM', label: 'Cameroon'},
  { value: 'CN', label: 'China'},
  { value: 'CO', label: 'Colombia'},
  { value: 'CR', label: 'Costa Rica'},
  { value: 'CU', label: 'Cuba'},
  { value: 'CV', label: 'Cape Verde'},
  { value: 'CW', label: 'Curacao'},
  { value: 'CX', label: 'Christmas Island'},
  { value: 'CY', label: 'Cyprus'},
  { value: 'CZ', label: 'Czech Republic'},
  {
    value: 'DE',
    label: 'Germany',
    suggested: true,
  },
  { value: 'DK', label: 'Denmark'},
  { value: 'DM', label: 'Dominica',},
  {
    value: 'DO',
    label: 'Dominican Republic',
  },
  { value: 'DZ', label: 'Algeria'},
  { value: 'EC', label: 'Ecuador'},
  { value: 'EE', label: 'Estonia'},
  { value: 'EG', label: 'Egypt'},
  { value: 'EH', label: 'Western Sahara'},
  { value: 'ER', label: 'Eritrea'},
  { value: 'ES', label: 'Spain'},
  { value: 'ET', label: 'Ethiopia'},
  { value: 'FI', label: 'Finland'},
  { value: 'FJ', label: 'Fiji'},
  {
    value: 'FK',
    label: 'Falkland Islands (Malvinas)',
  },
  {
    value: 'FR',
    label: 'France',
    suggested: true,
  },
  { value: 'GB', label: 'United Kingdom'},
  { value: 'GE', label: 'Georgia'},
  { value: 'GF', label: 'French Guiana'},
  { value: 'GG', label: 'Guernsey'},
  { value: 'GH', label: 'Ghana'},
  { value: 'GI', label: 'Gibraltar'},
  { value: 'GL', label: 'Greenland'},
  { value: 'GM', label: 'Gambia'},
  { value: 'GN', label: 'Guinea'},
  { value: 'GP', label: 'Guadeloupe'},
  { value: 'GQ', label: 'Equatorial Guinea'},
  { value: 'GR', label: 'Greece'},
  {
    value: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
  },
  { value: 'GT', label: 'Guatemala'},
  { value: 'GW', label: 'Guinea-Bissau'},
  { value: 'GY', label: 'Guyana'},
  { value: 'HK', label: 'Hong Kong'},
  { value: 'HN', label: 'Honduras'},
  { value: 'HR', label: 'Croatia'},
  { value: 'HT', label: 'Haiti'},
  { value: 'HU', label: 'Hungary'},
  { value: 'ID', label: 'Indonesia'},
  { value: 'IE', label: 'Ireland'},
  { value: 'IL', label: 'Israel'},
  { value: 'IM', label: 'Isle of Man'},
  { value: 'IN', label: 'India'},
  {
    value: 'IO',
    label: 'British Indian Ocean Territory',
  },
  { value: 'IS', label: 'Iceland'},
  { value: 'IT', label: 'Italy'},
  { value: 'JE', label: 'Jersey'},
  { value: 'JM', label: 'Jamaica'},
  { value: 'JO', label: 'Jordan'},
  {
    value: 'JP',
    label: 'Japan',
    suggested: true,
  },
  { value: 'KE', label: 'Kenya'},
  { value: 'KG', label: 'Kyrgyzstan'},
  { value: 'KH', label: 'Cambodia'},
  { value: 'KW', label: 'Kuwait'},
  { value: 'KZ', label: 'Kazakhstan'},
  { value: 'LB', label: 'Lebanon'},
  { value: 'LC', label: 'Saint Lucia'},
  { value: 'LI', label: 'Liechtenstein'},
  { value: 'LK', label: 'Sri Lanka'},
  { value: 'LR', label: 'Liberia'},
  { value: 'LS', label: 'Lesotho'},
  { value: 'LT', label: 'Lithuania'},
  { value: 'LU', label: 'Luxembourg'},
  { value: 'LV', label: 'Latvia'},
  { value: 'LY', label: 'Libya'},
  { value: 'MA', label: 'Morocco'},
  { value: 'MC', label: 'Monaco'},
  {
    value: 'MD',
    label: 'Moldova',
  },
  { value: 'ME', label: 'Montenegro'},
  {
    value: 'MF',
    label: 'Saint Martin (French part)',
  },
  { value: 'MG', label: 'Madagascar'},
  { value: 'MH', label: 'Marshall Islands'},
  {
    value: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
  },
  { value: 'MN', label: 'Mongolia'},
  { value: 'MO', label: 'Macao'},
  {
    value: 'MP',
    label: 'Northern Mariana Islands',
  },
  { value: 'MQ', label: 'Martinique'},
  { value: 'MR', label: 'Mauritania'},
  { value: 'MS', label: 'Montserrat'},
  { value: 'MT', label: 'Malta'},
  { value: 'MU', label: 'Mauritius'},
  { value: 'MV', label: 'Maldives'},
  { value: 'MW', label: 'Malawi'},
  { value: 'MX', label: 'Mexico'},
  { value: 'MY', label: 'Malaysia'},
  { value: 'MZ', label: 'Mozambique'},
  { value: 'NA', label: 'Namibia'},
  { value: 'NC', label: 'New Caledonia'},
  { value: 'NE', label: 'Niger'},
  { value: 'NF', label: 'Norfolk Island'},
  { value: 'NG', label: 'Nigeria'},
  { value: 'NI', label: 'Nicaragua'},
  { value: 'NL', label: 'Netherlands'},
  { value: 'NO', label: 'Norway'},
  { value: 'NP', label: 'Nepal'},
  { value: 'NR', label: 'Nauru'},
  { value: 'NU', label: 'Niue'},
  { value: 'NZ', label: 'New Zealand'},
  { value: 'OM', label: 'Oman'},
  { value: 'PA', label: 'Panama'},
  { value: 'PE', label: 'Peru'},
  { value: 'PF', label: 'French Polynesia'},
  { value: 'PG', label: 'Papua New Guinea'},
  { value: 'PH', label: 'Philippines'},
  { value: 'PK', label: 'Pakistan'},
  { value: 'PL', label: 'Poland'},
  { value: 'PR', label: 'Puerto Rico'},
  { value: 'PT', label: 'Portugal'},
  { value: 'PW', label: 'Palau'},
  { value: 'PY', label: 'Paraguay'},
  { value: 'QA', label: 'Qatar'},
  { value: 'RE', label: 'Reunion'},
  { value: 'RO', label: 'Romania'},
  { value: 'RS', label: 'Serbia'},
  { value: 'RW', label: 'Rwanda'},
  { value: 'SA', label: 'Saudi Arabia'},
  { value: 'SB', label: 'Solomon Islands'},
  { value: 'SC', label: 'Seychelles'},
  { value: 'SD', label: 'Sudan'},
  { value: 'SE', label: 'Sweden'},
  { value: 'SG', label: 'Singapore'},
  { value: 'SH', label: 'Saint Helena'},
  { value: 'SI', label: 'Slovenia'},
  { value: 'SK', label: 'Slovakia'},
  { value: 'SL', label: 'Sierra Leone'},
  { value: 'SM', label: 'San Marino'},
  { value: 'SN', label: 'Senegal'},
  { value: 'SO', label: 'Somalia'},
  { value: 'SR', label: 'Suriname'},
  { value: 'SS', label: 'South Sudan'},
  { value: 'SV', label: 'El Salvador'},
  {
    value: 'SX',
    label: 'Sint Maarten (Dutch part)',
  },
  {
    value: 'SY',
    label: 'Syrian Arab Republic',
  },
  { value: 'SZ', label: 'Swaziland'},
  { value: 'TH', label: 'Thailand'},
  { value: 'TJ', label: 'Tajikistan'},
  { value: 'TK', label: 'Tokelau'},
  { value: 'TL', label: 'Timor-Leste'},
  { value: 'TM', label: 'Turkmenistan'},
  { value: 'TN', label: 'Tunisia'},
  { value: 'TO', label: 'Tonga'},
  { value: 'TR', label: 'Turkey'},
  {
    value: 'TW',
    label: 'Taiwan',
  },
  {
    value: 'TZ',
    label: 'United Republic of Tanzania',
  },
  { value: 'UA', label: 'Ukraine'},
  { value: 'UG', label: 'Uganda'},
  {
    value: 'US',
    label: 'United States',
    suggested: true,
  },
  { value: 'UY', label: 'Uruguay'},
  { value: 'UZ', label: 'Uzbekistan'},
  { value: 'VN', label: 'Vietnam'},
  { value: 'VU', label: 'Vanuatu'},
  { value: 'WF', label: 'Wallis and Futuna'},
  { value: 'WS', label: 'Samoa'},
  { value: 'XK', label: 'Kosovo'},
  { value: 'YE', label: 'Yemen'},
  { value: 'YT', label: 'Mayotte'},
  { value: 'ZA', label: 'South Africa'},
  { value: 'ZM', label: 'Zambia'},
  { value: 'ZW', label: 'Zimbabwe'},
];

const CountryAutocompleteFormField = ({name, label}: CountryAutocompleteFormFieldProps) => (
  <AutocompleteFormField
    name={name}
    options={countries}
    label={label}
    renderOption={(props, option) => (
      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        <img
          loading="lazy"
          width="20"
          srcSet={`https://flagcdn.com/w40/${String(option.value).toLowerCase()}.png 2x`}
          src={`https://flagcdn.com/w20/${String(option.value).toLowerCase()}.png`}
          alt=""
        />
        {option.label} ({option.value})
      </Box>
    )}
  />
)

export default CountryAutocompleteFormField;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_process_batch_file } from '../models/Body_process_batch_file';
import type { ProcessBarchFileResponseModel } from '../models/ProcessBarchFileResponseModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BatchUploadsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Process Batch File
     * # Process Batch File Endpoint
     *
     * This endpoint is used to process a batch file.
     *
     * ## File Upload
     *
     * The batch file is uploaded by the user and it should be in csv, xls or xlsx format. The file is then read and its data is extracted.
     *
     * The extracted data along with the file name and site id (sid) are sent to the Dashboard API for further processing.
     *
     * A background task is also initiated to handle the file upload.
     *
     * ## Response
     *
     * The response from the Dashboard API is returned as the response of this endpoint.
     *
     * ## Permissions
     *
     * Users must have one of the following roles:
     * - ADMIN
     * - MERCHANT
     * - RETAILER
     * - MANAGER
     * - SITE
     *
     * Users must also have the following permission:
     * - UPLOAD_BATCH
     * @returns ProcessBarchFileResponseModel Successful Response
     * @throws ApiError
     */
    public processBatchFile({
        formData,
    }: {
        formData: Body_process_batch_file,
    }): CancelablePromise<ProcessBarchFileResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/batches/process-batch-file/',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}

import {AppCard} from "shared/components/mui";
import {FormControlLabel, Switch, Box, Chip} from "@mui/material";
import {UserSettingsSchema} from "generatedTypes";
import {useAuthApp} from "apps/account/pages/settings/AuthApp/useDataMutations";
import VerifyAuthAppDialog from "apps/account/pages/settings/AuthApp/VerifyAuthAppDialog";
import useDialogState from "shared/hooks/useDialogState";
import {useCallback} from "react";
import DialogConfirm from "shared/components/DialogConfirm";


interface AuthAppProps {
  authenticator_app_enabled: UserSettingsSchema["authenticator_app_enabled"]
}

const AuthApp = ({authenticator_app_enabled}: AuthAppProps) => {
  const dialogHandler = useDialogState();
  const confirmDialogHandler = useDialogState();

  const {mutate, totpUri} = useAuthApp(dialogHandler)

  const handleConfirm = () => {
    confirmDialogHandler.closeDialog()
    mutate({requestBody: {enable: false}})
  }

  const handleAuthApp = useCallback((event: any, enable: boolean) => {
    if (enable){
      mutate({requestBody: {enable}})
    } else {
      confirmDialogHandler.openDialog()
    }
  }, [mutate]);

  return (
    <>
      <AppCard
        title='Authenticator app'
        headStyle={{pb: 0}}
        bodyStyle={{m: 0}}
        sx={{pb: 0, mt: 2}}
      >
        <Box mx={2} mt={2} mb={3} sx={{display: 'flex', alignItems: 'center'}}>
          <Box>
            <FormControlLabel
              value="top"
              control={
                <Switch
                  size="small"
                  color="primary"
                  defaultChecked={authenticator_app_enabled}
                  onChange={handleAuthApp}
                />
              }
              label='Enable'
              labelPlacement="top"
              sx={{mr: 10}}
            />
          </Box>
          <Box ml={2}>
            <Chip variant={authenticator_app_enabled ? 'success' : 'error'} label={authenticator_app_enabled ? 'Verified' : 'Not verified'} />
          </Box>
        </Box>
      </AppCard>

      <VerifyAuthAppDialog dialogStateHandler={dialogHandler} totpUri={totpUri} />
      <DialogConfirm
        dialogStateHandler={confirmDialogHandler}
        handleConfirm={handleConfirm}
        title='Are you sure you want to disable this 2FA method?'
      />
    </>
  )
}

export default AuthApp;

import { Components } from '@mui/material/styles';

const MuiPaper: Components['MuiPaper'] = {
  styleOverrides: {
    elevation1: {
      boxShadow: '0px 10px 20px -4px rgba(33, 32, 29, 0.15)',
      borderRadius: '8px'
    },
    elevation8: {
      boxShadow: '0px 20px 25px -5px rgba(33, 32, 29, 0.15)',
      borderRadius: '8px'
    }
  }
}

export default MuiPaper;
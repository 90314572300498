import RSAutocompleteFormField from "shared/components/formFields/RSAutocompleteFormField";
import ApiClient from "apiClient";
import {useState} from "react";
import {useInfiniteQuery} from "@tanstack/react-query";
import {AutocompleteQueryKeys} from "shared/queryKeys";

interface MidAutocompleteFormFieldProps {
  name: string;
  isMulti?: boolean;
  preselectFirst?: boolean;
}

const MidAutocompleteFormField = ({name, isMulti, preselectFirst}: MidAutocompleteFormFieldProps) => {
  const [inputValue, setInputValue] = useState('');
  const {data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage} = useInfiniteQuery({
    queryKey: [AutocompleteQueryKeys.MID, inputValue],
    initialPageParam: 1,
    queryFn: ({pageParam}) => ApiClient.autocomplete.getMidAutocomplete({page: pageParam, search: inputValue, perPage: 30}),
    getNextPageParam: (lastPage) => {
      const {page = 1, pages = 1} = lastPage;
      if (page < pages) {
        return page + 1;
      }
      return null;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam, allPageParams) => {
      const lastPageNumber = allPageParams[0]
      if (lastPageNumber > 1) {
        return lastPageNumber - 1;
      }
      return null;
    },
  });

  const handleInputChange = (value: string) => {
    setInputValue(value);
  }

  const loadMoreOptions = () => {
    if (!isFetchingNextPage && hasNextPage) {
      void fetchNextPage();
    }
  };

  const options = data?.pages.flatMap((page) => page?.items?.map((item) => ({
    value: item.mid,
    label: `${item.name} (${item.mid})`,
  }))) || [];

  return (
    <RSAutocompleteFormField
      name={name}
      isMulti={isMulti}
      loadMoreOptions={loadMoreOptions}
      options={options}
      isLoading={isLoading || isFetchingNextPage}
      handleInputChange={handleInputChange}
      preselectFirst={preselectFirst}
    />
  );
}

export default MidAutocompleteFormField;

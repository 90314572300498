import ApiClient from "apiClient";
import {useState} from "react";
import {IApiError} from "shared/types";
import {useSnackbar} from "notistack";
import {useQueryClient, useMutation} from "@tanstack/react-query";
import {MerchantQueryKeys} from "shared/queryKeys";
import {DialogStateHandler} from "shared/hooks/useDialogState";

type Params = Parameters<typeof ApiClient.merchants.postMerchantDirectoryContacts>[0]

interface MerchantDirectoryContactsAttrs{
  dialogHandler: DialogStateHandler;
  reset: () => void;
}

const useMerchantDirectoryContacts = ({dialogHandler, reset}: MerchantDirectoryContactsAttrs) => {
  const { enqueueSnackbar } = useSnackbar()

  const queryClient = useQueryClient();

  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string | undefined>('');
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>('');

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.merchants.postMerchantDirectoryContacts(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: [MerchantQueryKeys.DIRECTORY_CONTACTS]})
      setPhoneErrorMessage('')
      setEmailErrorMessage('')
      dialogHandler.closeDialog()
      enqueueSnackbar('Merchant directory contacts created successfully', {variant: 'success'})
      reset()
    },
    onError: (error: IApiError) => {
      const {phone} = error.body.message
      const {email} = error.body.message

      if (phone && phone.length > 0) {
        setPhoneErrorMessage(phone[0])
      }

      if (email && email.length > 0) {
        setEmailErrorMessage(email[0])
      }
    }
  })

  return {
    mutate,
    isPending,
    phoneErrorMessage,
    emailErrorMessage
  }
}

export default useMerchantDirectoryContacts;

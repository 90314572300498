import {Chip} from "@mui/material";

import {ChipVariants} from "shared/types";

interface RoleProps {
  value: string
}

const roles: Record<RoleProps['value'], ChipVariants> = {
  super_admin: 'tealBadge',
  admin: 'seaBadge',
  merchant: 'orangeBadge',

  // need update
  manager: 'greyBadge',
  retailer: 'greyBadge',
  customer_service: 'greyBadge',

  reseller: 'pinkBadge',
  accounting: 'pinkBadge',
  site_admin: 'orangeBadge',
}

function formatRole(value: string){
  if (['super_admin', 'customer_service', 'site_admin'].includes(value)) {
    return value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
  return value
}

const Role = ({value}: RoleProps) => (
  <Chip variant={roles[value]} label={formatRole(value)} />
)

export default Role;
import {FormProvider, useForm} from "react-hook-form";
import {format, utcToZonedTime} from 'date-fns-tz'
import {useEffect} from "react";
import {Box} from "@mui/material";
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {TimezoneMutationArgs} from "apps/account/types";
import RSSelectFormField from "shared/components/formFields/RSSelectFormField";
import {useTimeZoneOptions} from "apps/account/useDataQueries";
import useCurrentUser from "shared/hooks/useCurrentUser";
import useUpdateTimezone from "apps/account/components/Timezone/useDataMutations";

const schema = yup
  .object()
  .shape({
    timezone: yup.string().required(),
  })
  .required();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const toTimeZone = (timeZone: string) => {
  const date = new Date()
  const zonedDate = utcToZonedTime(date, timeZone)
  const pattern = 'd MMM yyyy - HH:mm:ss'
  return format(zonedDate, pattern, {timeZone})
}

const Timezone = () => {
  const methods = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      timezone: 'UTC',
    },
  });

  const {mutate} = useUpdateTimezone()
  const {data, isLoading} = useTimeZoneOptions()
  const {user} = useCurrentUser();

  useEffect(() => {
    if (user?.timezone && data?.timezones) {
      methods.setValue('timezone', user.timezone);
    }
  }, [user, data]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSubmit = (data: { timezone: string }) => {
    const timezoneData: TimezoneMutationArgs = {
      timezone: data.timezone,
    };
    mutate(timezoneData);
  };

  const timezoneOptions = data?.timezones?.map((timezone) => ({
    // label: toTimeZone(timezone),
    label: timezone,
    value: timezone,
  })) || []

  return (
    <FormProvider {...methods}>
      <Box sx={{minWidth: '160px'}}
      >
        <RSSelectFormField
          name='timezone'
          options={timezoneOptions}
          isLoading={isLoading}
          isCustom
          onChange={() => methods.handleSubmit(onSubmit)()}
        />
      </Box>
    </FormProvider>
  );
}

export default Timezone;

import RoutePath from "routes/utils";

const baseBase = '/customers';

export default {
  list: new RoutePath(`${baseBase}/`),

  detail: new RoutePath(`${baseBase}/:customerEmail/`),
  detailTransactionsTab: new RoutePath(`${baseBase}/:customerEmail/transactions/`),
  detailDetailsTab: new RoutePath(`${baseBase}/:customerEmail/details/`),
  detailMerchantsTab: new RoutePath(`${baseBase}/:customerEmail/merchants/`),
  detailCardsTab: new RoutePath(`${baseBase}/:customerEmail/cards/`),
  detailAccountsTab: new RoutePath(`${baseBase}/:customerEmail/accounts/`),
  detailFinancialTab: new RoutePath(`${baseBase}/:customerEmail/financial/`),
  detailBlackListTab: new RoutePath(`${baseBase}/:customerEmail/blacklist/`),
  detailAliasesTab: new RoutePath(`${baseBase}/:customerEmail/aliases/`),
  detailNotesTab: new RoutePath(`${baseBase}/:customerEmail/notes/`),
}

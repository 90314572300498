import {StatsByType} from "apps/customers/pages/CustomerDetails/shared/components/StatsByType";
import SkeletonStatsByType from "apps/customers/pages/CustomerDetails/shared/components/SkeletonStatsByType";

interface PaymentsProps {
  total: number;
  leftValue: number;
  rightValue: number;
  completionRate: number;
  isLoading: boolean
}

const Payments = ({total, leftValue, rightValue, completionRate, isLoading}: PaymentsProps) => {
  if (isLoading) return <SkeletonStatsByType title='Payments' />

  return(
    <StatsByType
      title='Payments'
      total={total}
      leftTitle='Previous month spend'
      leftValue={leftValue}
      rightTitle='Avg. Payment Value'
      rightValue={rightValue}
      completionRate={completionRate}
    />
  )
}


export default Payments;

import useBreadcrumb from "shared/hooks/useBreadcrumb";
import {commonBreadcrumbs} from "shared/breadcrumbConfig";
import CustomersTable from "apps/customers/pages/CustomersList/partials/CustomersTable";

const CustomersListView = () => {
  useBreadcrumb([commonBreadcrumbs.customersList]);

  return (
    <CustomersTable />
  )
};

export default CustomersListView;

import {Box, Typography} from "@mui/material";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {truncate} from "lodash";
import {Link} from "react-router-dom";

interface SocialMediaLinkProps {
  username: string;
  appName: string;
}

const SocialMediaLink = ({ username, appName }: SocialMediaLinkProps) => {
  const appUrls: { [key: string]: string } = {
    'slack': `https://slack.com/${username}`,
    'telegram': `https://t.me/${username}`,
    'whatsapp': `https://wa.me/${username}`,
    'linkedin': `https://www.linkedin.com/in/${username}`,
    'skype': `skype:${username}?chat`,
    'monday.com': `https://monday.com/?username=${username}`,
    'stackfield': `https://stackfield.com/?username=${username}`,
  };

  if (!username || !appName) return null;

  const appUrl = appUrls[appName.toLowerCase()];

  if (!appUrl) {
    return (
      <Box display='flex' alignItems='center'>
        <PermIdentityIcon width={14} />
        <Typography variant='body2'>
          {truncate(`${username}`, {length: 10, omission: '...'})}
        </Typography>
      </Box>
    );
  }

  return (
    <Link to={appUrl} target="_blank" onClick={(event) => event.stopPropagation()}>
      <img src={`/messaging-apps/${appName}.png`} alt={appName} width={18} />
    </Link>
  );
};

export default SocialMediaLink;
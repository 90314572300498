import {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Skeleton, Stack, Typography} from "@mui/material"
import {ReactComponent as VisibilityIcon} from "assets/iconsDS/VisibilityIcon.svg";
import {ReactComponent as VisibilityOffIcon} from "assets/iconsDS/VisibilityOffIcon.svg";
import palette from "theme/palette";
import HelpToolTip from "shared/components/DS/HelpToolTip";

interface UDFSProps {
  values: Array<string>;
  udf1Title?: string;
  isLoading?: boolean;
}

const UDFS = ({values, udf1Title, isLoading = false}: UDFSProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderExpandIcon = () => (
    expanded === 'panel1' ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '6px',
          borderRadius: '8px',
        }}
      >
        <VisibilityOffIcon/>
        <Typography variant='B6' sx={{color: palette.themeColors.grey['120'], ml: '4px'}}>Hide UDFs</Typography>
      </Box>
    ) : (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '6px',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: palette.themeColors.grey['30'],

            '& .MuiTypography-root': {
              color: palette.themeColors.grey['120']
            },

            '& svg path:first-of-type': {
              stroke: palette.themeColors.grey['110']
            },

            '& svg path:last-of-type': {
              stroke: palette.themeColors.grey['110']
            }
          }
        }}
      >
        <VisibilityIcon/>
        <Typography variant='B6' sx={{color: palette.themeColors.grey['100'], ml: '4px'}}>See UDFs</Typography>
      </Box>
    )
  )

  return (
    <Accordion sx={{borderRadius: '8px'}} onChange={handleChange('panel1')}>
      <AccordionSummary
        expandIcon={values.length > 1 ? renderExpandIcon() : null}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            right: 20,
            top: 24,
          },

          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            top: 22
          }
        }}
      >
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          {isLoading ? (
            <Stack gap='8px'>
              <Skeleton variant="rounded" width={60} height={16}/>
              <Skeleton variant="rounded" width={320} height={20}/>
            </Stack>
          ): (
            <>
              <Box sx={{width: '100%', alignItems: 'center', flexDirection: 'row', display: 'flex'}}>
                <Typography variant='B4' sx={{color: palette.themeColors.grey['90'], fontWeight: 400}}>UDF-1</Typography>
                {udf1Title && (
                  <Box sx={{marginLeft: '4px'}}>
                    <HelpToolTip title={udf1Title}/>
                  </Box>
                )}
              </Box>
              <Typography variant='B4' sx={{color: palette.secondary.main}}>{values[0]}</Typography>
            </>
          )}
        </Box>
      </AccordionSummary>
      {values.length > 1 && (
        <AccordionDetails sx={{paddingTop: 0, maxHeight: '200px', overflowY: 'auto'}}>
          {values.slice(1).map((value, index) => (
            <Box key={value} sx={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: index === values.length - 2 ? '0px' : '16px'
            }}>
              <Typography variant='B4' sx={{color: palette.themeColors.grey['90'], fontWeight: 400}}>{`UDF-${index + 2}`}</Typography>
              <Typography variant='B4' sx={{color: palette.secondary.main}}>{value}</Typography>
            </Box>
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
}

export default UDFS;


import {Checkbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";

type SelectFieldsProps = {
  name: string,
  label: string
  disabled?: boolean
}

const CheckboxFormField = ({name, label, disabled = false}: SelectFieldsProps) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <FormControl error={Boolean(fieldState.error)}>
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                id={`${name}-field`}
                color="primary"
                disabled={disabled}
                checked={field.value}
              />
            }
            label={label}
          />
          {fieldState.error && (
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default CheckboxFormField;

import {FormControl, FormControlLabel, FormControlLabelProps, Radio, RadioGroup, useRadioGroup} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {styled} from "@mui/material/styles";

type RadioGroupOption = {
  value: string,
  label: string
}

type RadioGroupFieldsProps = {
  name: string,
  options: RadioGroupOption[]
}

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  '.MuiFormControlLabel-label': {
    color: checked ? theme.palette.primary.main : theme.palette.secondary.main,
    ...theme.typography.B4,
  }
}));

function CustomFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

const RadioGroupFormField = ({name, options}: RadioGroupFieldsProps) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <FormControl>
          {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
          <RadioGroup
            {...field}
            aria-labelledby={`${name}-radio-buttons-group`}
            // value={value}
            // onChange={handleChange}
          >
            {options.map(option => (
              <CustomFormControlLabel key={option.value} value={option.value} control={<Radio/>} label={option.label}/>
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

export default RadioGroupFormField;

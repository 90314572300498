import {useMutation} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {useState} from "react";
import {IApiError} from "shared/types";
import {useSnackbar} from "notistack";

type Params = Parameters<typeof ApiClient.users.postUsersList>[0]

const useCreateUser = (reset: () => void) => {
  const { enqueueSnackbar } = useSnackbar()
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string | undefined>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.users.postUsersList(params),
    onSuccess: () => {
      setPhoneErrorMessage('')
      setEmailErrorMessage('')
      enqueueSnackbar('User created successfully', {variant: 'success'})
      reset()
    },
    onError: (error: IApiError) => {
      const {phone} = error.body.message
      const {email} = error.body.message
      const {_schema} = error.body.message

      if (phone && phone.length > 0) {
        setPhoneErrorMessage(phone[0])
      }

      if (email && email.length > 0) {
        setEmailErrorMessage(email[0])
      }

      if (_schema && _schema.length > 0) {
        setErrorMessage(_schema)
      }
    }
  })

  return {
    mutate,
    isPending,
    phoneErrorMessage,
    emailErrorMessage,
    errorMessage
  }
}

export default useCreateUser;

import { Components } from '@mui/material/styles';

import palette from "theme/palette";

const MuiTimelineDot: Components['MuiTimelineDot'] = {
  styleOverrides: {
    root: {
      backgroundColor: palette.themeColors.purple['20'],
      boxShadow: 'none',
      padding: '2px',
      margin: '8px 0px'
    }
  }
}

export default MuiTimelineDot;
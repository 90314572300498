import {WrapperBox} from "shared/styled/WrapperBox";
import {Box, Stack, Typography} from "@mui/material";
import {fCurrency} from "shared/utils";
import ProgressBar from "shared/components/DS/ProgressBar";
import {useTheme} from "@mui/material/styles";
import SkeletonStatsItem
  from "apps/customers/pages/CustomerDetails/shared/components/SkeletonStatsByType/SkeletonStatsItem";

interface SkeletonStatsByTypeProps {
  title: string
}

const SkeletonStatsByType = ({title}: SkeletonStatsByTypeProps) => {
  const theme = useTheme();

  return (
    <WrapperBox>
      <Stack spacing={1}>
        <Stack sx={{padding: '3px 0px 3px 0px', color: theme.palette.themeColors.grey['70']}}>
          <Typography variant='h4'>
            {title}
          </Typography>
        </Stack>
        <Box sx={{padding: '12px 0px 24px 0px', marginTop: '0px !important'}}>
          <Typography variant='h2' sx={{color: theme.palette.themeColors.grey['70']}}>
            {fCurrency(0)}
          </Typography>
        </Box>
        <Stack direction='row' alignItems='center' justifyContent='space-between' gap='8px' sx={{marginTop: '0px !important'}}>
          <SkeletonStatsItem />
          <SkeletonStatsItem />
        </Stack>
        <ProgressBar value={0} label='COMPLETION RATE' isSkeleton/>
      </Stack>
    </WrapperBox>
  )
}

export default SkeletonStatsByType;
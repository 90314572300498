/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns any Success
     * @throws ApiError
     */
    public autoLogin({
        verificationToken,
        checksum,
    }: {
        /**
         * Verification code
         */
        verificationToken: string,
        /**
         * Checksum
         */
        checksum: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/auth/auto-login/',
            query: {
                'verification_token': verificationToken,
                'checksum': checksum,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public listTodos({
        verificationToken,
        id,
        email,
    }: {
        /**
         * Verification token
         */
        verificationToken: string,
        /**
         * ID
         */
        id: string,
        /**
         * Email address
         */
        email: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/auth/email-verification/',
            query: {
                'verification_token': verificationToken,
                '_id': id,
                'email': email,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public forgotPassword({
        email,
    }: {
        /**
         * The user's email address
         */
        email: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/forgot-password/',
            query: {
                'email': email,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public login({
        email,
        password,
    }: {
        /**
         * The user's email address
         */
        email: string,
        /**
         * The user's password
         */
        password: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/login/',
            query: {
                'email': email,
                'password': password,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public getLogout(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/auth/logout/',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public refreshToken({
        refreshToken,
    }: {
        refreshToken: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/refresh/',
            query: {
                'refresh_token': refreshToken,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public listTodos1({
        firstName,
        lastName,
        email,
        phoneCountryCode,
        phone,
        password,
        confirmPassword,
    }: {
        /**
         * The user's first name
         */
        firstName: string,
        /**
         * The user's last name
         */
        lastName: string,
        /**
         * The user's email address
         */
        email: string,
        /**
         * The user's phone country code
         */
        phoneCountryCode: string,
        /**
         * The user's phone
         */
        phone: string,
        /**
         * The user's password
         */
        password: string,
        /**
         * The user's password
         */
        confirmPassword: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/register/',
            query: {
                'first_name': firstName,
                'last_name': lastName,
                'email': email,
                'phone_country_code': phoneCountryCode,
                'phone': phone,
                'password': password,
                'confirm_password': confirmPassword,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public selectTwoFaMethod({
        selectedMethod,
        email,
    }: {
        /**
         * Method
         */
        selectedMethod: string,
        /**
         * The user's email address
         */
        email: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/set-two-fa-method/',
            query: {
                'selected_method': selectedMethod,
                'email': email,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public updatePassword({
        verificationToken,
        checksum,
        password,
        confirmPassword,
    }: {
        /**
         * Verification code
         */
        verificationToken: string,
        /**
         * Checksum
         */
        checksum: string,
        /**
         * The user's password
         */
        password: string,
        /**
         * The user's password
         */
        confirmPassword: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/update-password/',
            query: {
                'verification_token': verificationToken,
                'checksum': checksum,
                'password': password,
                'confirm_password': confirmPassword,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public validate2Fa({
        email,
        code,
    }: {
        /**
         * The user's email address
         */
        email: string,
        /**
         * 2FA code
         */
        code: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/validate-2fa/',
            query: {
                'email': email,
                'code': code,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public validateAuthenticationApp({
        email,
        authAppCode,
    }: {
        /**
         * The user's email address
         */
        email: string,
        /**
         * Auth app code
         */
        authAppCode: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/validate-auth-app/',
            query: {
                'email': email,
                'auth_app_code': authAppCode,
            },
        });
    }
}

import { Components } from '@mui/material/styles';
import palette from "theme/palette";

const MuiAvatar: Components['MuiAvatar'] = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      color: palette.themeColors.purple['700'],
      backgroundColor: '#D1CCF4',
      fontSize: '14px',
    },
  }
}

export default MuiAvatar;

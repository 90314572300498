import React, {useEffect, useState} from 'react';
import {Stack, Typography} from "@mui/material";
import RadioGroupFormField from "shared/components/formFields/RadioGroupFormField";

interface SelectOptionProps {
  gridRef: React.MutableRefObject<any>;
}

const SelectOption = ({gridRef}: SelectOptionProps) => {
  const [totalCount, setTotalCount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    const handleRowSelected = () => {
      setSelectedCount(gridRef.current!.api?.getSelectedRows().length);
    };

    const handlePaginationChanged = () => {
      setTotalCount(gridRef.current!.api?.paginationGetRowCount());
    };

    setSelectedCount(gridRef.current!.api?.getSelectedRows().length);
    setTotalCount(gridRef.current!.api?.paginationGetRowCount());

    if (gridRef.current) {
      gridRef.current!.api?.addEventListener('rowSelected', handleRowSelected);
      gridRef.current!.api?.addEventListener('paginationChanged', handlePaginationChanged);
    }


    return () => {
      if (gridRef.current) {
        gridRef.current!.api?.removeEventListener('rowSelected', handleRowSelected);
        gridRef.current!.api?.removeEventListener('paginationChanged', handlePaginationChanged);
      }
    };
  }, [gridRef.current.api]);

  return (
    <Stack mb={3}>
      <Typography variant='B1' sx={{marginBottom: '10px'}}>Select the option</Typography>

      <RadioGroupFormField
        name="exportOption"
        options={[
          {label: `All Transactioins (${totalCount})`, value: 'all'},
          {label: `Selected Transactions ${selectedCount > 0 ? `(${selectedCount})` : ''}`, value: 'selected'},]}
      />
    </Stack>
  );
};

export default SelectOption;

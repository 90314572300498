import {useMemo} from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";

type SelectFieldsProps = {
  name: string,
  options: {value: string, label: string}[],
  label: string,
  isLoading?: boolean,
  multiple?: boolean,
}

const SelectFormField = ({name, options, label, isLoading, multiple = false}: SelectFieldsProps) => {
  const {control} = useFormContext();

  const optionsList = useMemo(() => options.map(({value: optionValue, label: optionLabel}) => (
    <MenuItem key={optionValue} value={optionValue}>{optionLabel}</MenuItem>
  )), [options])

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => {
        const value = multiple && !Array.isArray(field.value) ? [field.value] : field.value;
        return (
          <FormControl fullWidth error={fieldState.invalid}>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
              {...field}
              value={value}
              labelId={`{${name}-label`}
              id={`${name}-field`}
              label={label}
              size="small"
              multiple={multiple}
              endAdornment={isLoading && <CircularProgress color="inherit" size={20} />}
            >
              {optionsList}
            </Select>
            {fieldState.invalid && <FormHelperText>{fieldState.error?.message}</FormHelperText>}
          </FormControl>
        )
      }}
    />
  );
};

export default SelectFormField;

import {Box, Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import HelpToolTip from "shared/components/DS/HelpToolTip";
import truncate from "lodash/truncate";

interface DetailItemProps {
  name: string;
  children: React.ReactNode;
  title?: string;
}

const DetailItem = ({name, children, title}: DetailItemProps) => {
  const theme = useTheme();

  const cleanedName =  truncate(name, {length: 30, omission: '...'})
  return (
    <Stack>
      <Typography
        variant='B4'
        sx={{
          color: theme.palette.themeColors.grey['90'],
          fontWeight: 400,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {cleanedName}
        {title && (
          <Box
            component="span"
            sx={{
              marginLeft: '4px',
            }}
          >
            <HelpToolTip title={title} />
          </Box>
        )}
      </Typography>
      {children}
    </Stack>
  )
}

export default DetailItem;

import {Divider, Typography} from "@mui/material";
import {useFormContext} from "react-hook-form";
import {VirtualTerminalQueryKeys} from "shared/queryKeys";
import {useQueryClient} from "@tanstack/react-query";
import type {VirtualTerminalSitePaginatedSchema} from "generatedTypes";

const TerminalSummary = () => {
  const queryClient = useQueryClient();
  const {watch} = useFormContext();
  const paymentMethod = watch('payment_method');
  const emailAddress = watch('email_address');

  const siteId = watch('site_id');
  const sitesList = queryClient.getQueryData([VirtualTerminalQueryKeys.SITES]) as VirtualTerminalSitePaginatedSchema;
  const site = sitesList?.items?.find(el => el.sid === siteId);

  return (
    <>
      <Typography variant='h6'>Summary</Typography>
      <Typography variant="subtitle2">Please review the order summary below.</Typography>

      <Divider sx={{my: 2}}/>

      <Typography>Site: {site?.name} - ({site?.sid})</Typography>
      <Typography>Email: {emailAddress}</Typography>
      <Typography>Payment Method: {paymentMethod}</Typography>
    </>
  );
};

export default TerminalSummary;

import { Components } from '@mui/material/styles';

const MuiBackdrop: Components['MuiBackdrop'] = {
  styleOverrides: {
    root: {
      // backgroundColor: 'rgba(43, 20, 61, 0.60)',
      // backdropFilter: 'blur(4.5px)',
    },
  }
}

export default MuiBackdrop;

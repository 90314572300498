import {useTheme} from "@mui/material/styles";
import {Box, Typography} from "@mui/material";
import { ReactComponent as TimelineIcon } from 'assets/iconsDS/Timeline.svg';
import {useTransactionTimeline} from "apps/transactions/pages/TransactionDetails/partials/tabs/Timeline/useDataQueries";
import {useState} from "react";
import LargeTimeline from "apps/transactions/pages/TransactionDetails/partials/tabs/Timeline/partials/LargeTimeline";
import ShortTimeline from "apps/transactions/pages/TransactionDetails/partials/tabs/Timeline/partials/ShortTimeline";
import SkeletonTimeline
  from "apps/transactions/pages/TransactionDetails/partials/tabs/Timeline/partials/SkeletonTimeline";

interface TransactionTimelineProps {
  isLoading: boolean
}

const TransactionTimeline = ({isLoading}: TransactionTimelineProps) => {
  const theme = useTheme();

  const {data} = useTransactionTimeline()

  const [isHideTimeline, setIsHideTimeline] = useState(false);

  const handleSeeAllTimelineClick = () => {
    if (isHideTimeline) {
      setIsHideTimeline(false);
    } else {
      setIsHideTimeline(true);
    }
  };

  if (isLoading) return <SkeletonTimeline />

  if (!data) return null

  return (
    <Box
      sx={{
        marginTop: '36px',
        borderRadius: theme.spacing(1),
        padding: '16px 20px',
        border: `1px solid ${theme.palette.themeColors.grey['40']}`,
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <TimelineIcon />
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <Typography variant='h5' color={theme.palette.themeColors.grey['120']}>
            Timeline
          </Typography>
          <Typography variant='B6' color={theme.palette.themeColors.purple['100']}>
            {data?.total_time} in total
          </Typography>
        </Box>
      </Box>

      {isHideTimeline
        ? <LargeTimeline data={data} handleButtonClick={handleSeeAllTimelineClick} />
        : <ShortTimeline data={data} handleButtonClick={handleSeeAllTimelineClick} />
      }

    </Box>
  )
}

export default TransactionTimeline
import {useEffect} from "react";
import {useNotificationsList} from "apps/notifications/list/useDataQueries";
import useFilters from "apps/notifications/list/useFilters";

type DataFiltersParams = {
  currentPage: number;
  perPage: number;
  orderBy: string;
}

const useDataFilters = ({currentPage, perPage, orderBy}: DataFiltersParams) => {
  const {filterData, filters, submitCount} = useFilters()

  const {data, refetch, isLoading, isFetching} = useNotificationsList({
    page: currentPage,
    perPage,
    orderBy,
    search: filterData.search,
  });

  useEffect(() => {
    void refetch();
  }, [submitCount]);

  return {
    data,
    isLoading,
    isFetching,
    filters,
  }
}

export default useDataFilters;

import {Grid} from "@mui/material";
import {memo} from "react";
import SelectFormField from "shared/components/formFields/SelectFormField";
import TextFormField from "shared/components/formFields/TextFormField";
import DateTimePickerFormField from "shared/components/formFields/DateTimePickerFormField";
import SidAutocompleteFormField from "shared/components/formDataFields/SidAutocompleteFormField";

const actionTypeList = [
  {value: "blacklist_kyc", label: "Blacklist KYC"},
  {value: "fraud_kyc", label: "Fraud KYC"},
  {value: "limit_kyc", label: "Limit KYC"},
]

const statusList = [
  {value: "complete", label: "Complete"},
  {value: "decline", label: "Decline"},
  {value: "pending", label: "Pending"},
  {value: "submitted", label: "Submitted"},
];

const FilterComponent = memo(() => (
  <>
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12} sm={6}>
        <TextFormField name="email" placeholder="Email"/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SidAutocompleteFormField name="sids" isMulti/>
      </Grid>
    </Grid>
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12} sm={6}>
        <SelectFormField name="action_type" label="Action type" options={actionTypeList}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectFormField name="status" label="Status" options={statusList}/>
      </Grid>
    </Grid>
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12} sm={6}>
        <TextFormField name="comment" label="Comment"/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateTimePickerFormField name="status_timestamp" label="Updated At"/>
      </Grid>
    </Grid>
  </>
));

export default FilterComponent;

import {WrapperBox} from "shared/styled/WrapperBox";
import {Box, Stack, Typography} from "@mui/material";
import {fCurrency} from "shared/utils";
import StatsItem from "apps/customers/pages/CustomerDetails/shared/components/StatsItem";
import ProgressBar from "shared/components/DS/ProgressBar";
import {useTheme} from "@mui/material/styles";

interface PaymentsProps {
  title: string;
  total: number;
  leftTitle: string;
  leftValue: number;
  rightTitle: string;
  rightValue: number;
  completionRate: number;
}

export const StatsByType = ({title, total, leftTitle, leftValue, rightTitle, rightValue, completionRate}: PaymentsProps) => {
  const theme = useTheme();
  const formattedValue = fCurrency(total);

  return (
    <WrapperBox>
      <Stack
        spacing={1}
      >
        <Stack sx={{padding: '4px 0px 4px 0px'}}>
          <Typography variant='h4'>
            {title}
          </Typography>
        </Stack>
        <Box sx={{padding: '12px 0px 24px 0px', marginTop: '0px !important'}}>
          <Typography variant='h2' sx={{color: theme.palette.themeColors.grey['120'], display: 'flex'}}>
            {formattedValue.split('.')[0]}
            <Typography variant='h2' color={theme.palette.themeColors.grey['80']}>
              .{formattedValue.split('.')[1]}
            </Typography>
          </Typography>
        </Box>
        <Stack direction='row' alignItems='center' justifyContent='space-between' gap='8px' sx={{marginTop: '0px !important'}}>
          <StatsItem title={leftTitle} value={leftValue}/>
          <StatsItem title={rightTitle} value={rightValue}/>
        </Stack>

        <ProgressBar value={completionRate} label='COMPLETION RATE'/>
      </Stack>
    </WrapperBox>
  )
}
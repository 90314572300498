import Timeline from "@mui/lab/Timeline";
import TimelineItem, {timelineItemClasses} from "@mui/lab/TimelineItem";
import {isEmpty} from "lodash";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import {Box, Button, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {TimelineSchema} from "generatedTypes";
import DatetimeTypography from "apps/transactions/pages/TransactionDetails/partials/tabs/Timeline/partials/DatetimeTypography";
import useCurrentUser from "shared/hooks/useCurrentUser";

interface LargeTimelineProps {
  data: TimelineSchema
  handleButtonClick: () => void
}

const LargeTimeline = ({data, handleButtonClick}: LargeTimelineProps) => {
  const theme = useTheme();
  const {user} = useCurrentUser();

  const rolesRequired = (['super_admin', 'admin', 'customer_service'].includes(user?.role || ''))

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },

        [`& .${timelineItemClasses.root} .MuiTimelineDot-root`]: {
          marginBottom: '0',
        },

        [`& .${timelineItemClasses.root} .MuiTimelineConnector-root`]: {
          position: 'absolute',
          height: '100%',
          top: '10px'
        },

        [`& .${timelineItemClasses.root}:first-of-type .MuiTimelineConnector-root`]: {
          borderRadius: '4px 4px 0px 0px'
        },

        [`& .${timelineItemClasses.root}:last-of-type .MuiTimelineConnector-root`]: {
          borderRadius: '0px 0px 4px 4px'
        },

        [`& .${timelineItemClasses.root}:nth-last-of-type(2) .MuiTimelineConnector-root`]: {
          borderRadius: '0px 0px 4px 4px'
        },
      }}
    >
      {!isEmpty(data.api_started) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                Transaction created
              </Typography>
              <DatetimeTypography datetime={data.api_started.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}

      {(!isEmpty(data.api_gateway_started) && rolesRequired) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                API Gateway Started
              </Typography>
              <DatetimeTypography datetime={data.api_gateway_started.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}

      {(!isEmpty(data.api_gateway_ended) && rolesRequired) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                API Gateway Ended
              </Typography>
              <DatetimeTypography datetime={data.api_gateway_ended.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}

      {(!isEmpty(data.api_ended) && rolesRequired) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                API Ended
              </Typography>
              <DatetimeTypography datetime={data.api_ended.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}

      {(!isEmpty(data.gateway_process_started) && rolesRequired) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                Gateway Process Started
              </Typography>
              <DatetimeTypography datetime={data.gateway_process_started.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}

      {(!isEmpty(data.gateway_process_ended) && rolesRequired) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                Gateway Process Ended
              </Typography>
              <DatetimeTypography datetime={data.gateway_process_ended.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}

      {(!isEmpty(data.tx_process_started) && rolesRequired) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                TX Process Started
              </Typography>
              <DatetimeTypography datetime={data.tx_process_started.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}

      {!isEmpty(data.process_started) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                Transaction processing
              </Typography>
              <DatetimeTypography datetime={data.process_started.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}

      {!isEmpty(data.process_ended) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                Transaction process ended
              </Typography>
              <DatetimeTypography datetime={data.process_ended.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}

      {(!isEmpty(data.tx_process_ended) && rolesRequired) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                TX Process Ended
              </Typography>
              <DatetimeTypography datetime={data.tx_process_ended.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot/>
        </TimelineSeparator>
        <TimelineContent>
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Button variant='outlined' color='secondary' size='small' onClick={handleButtonClick}>
              Hide All details
            </Button>
          </Box>
        </TimelineContent>
      </TimelineItem>

    </Timeline>
  )
}

export default LargeTimeline;
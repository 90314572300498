export enum Roles {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  CUSTOMER_SERVICE = 'customer_service',
  MANAGER = 'manager',
  MERCHANT = 'merchant',
  RETAILER = 'retailer',
  SITE = 'site',
  RESELLER = 'reseller',
  ACCOUNTING = 'accounting',
  SITE_ADMIN = 'site_admin'
}

export enum Permissions {
    ADD_NOTE = 'ADD_NOTE',
    ADD_TO_BLACKLIST = 'ADD_TO_BLACKLIST',
    REMOVE_FROM_BLACKLIST = 'REMOVE_FROM_BLACKLIST',
    API_LOOKUP = 'API_LOOKUP',
    CHARGEBACK = 'CHARGEBACK',
    RECOVER_WORKER = 'RECOVER_WORKER',
    VIEW_EMAIL_DATA = 'VIEW_EMAIL_DATA',
    VIEW_BILLING_EVENTS = 'VIEW_BILLING_EVENTS',
    VIEW_FINANCIAL_DETAILS = 'VIEW_FINANCIAL_DETAILS',
    REFUND_TRANSACTION = 'REFUND_TRANSACTION',
    CANCEL_TRANSACTION = 'CANCEL_TRANSACTION',
    DECLINE_TRANSACTION = 'DECLINE_TRANSACTION',
    REQUEST_REFUND_TRANSACTION = 'REQUEST_REFUND_TRANSACTION',
    REQUEST_DECLINE_TRANSACTION = 'REQUEST_DECLINE_TRANSACTION',
    REQUEST_CANCEL_TRANSACTION = 'REQUEST_CANCEL_TRANSACTION',
    VIEW_CONFIDENTIAL_INFORMATION = 'VIEW_CONFIDENTIAL_INFORMATION',
    SEND_POSTBACK = 'SEND_POSTBACK',
    UPDATE_CUSTOMER_ALIASES = 'UPDATE_CUSTOMER_ALIASES',
    MANAGE_USERS = 'MANAGE_USERS',
    VIEW_KYC_REQUESTS = 'VIEW_KYC_REQUESTS',
    USE_VIRTUAL_TERMINAL = 'USE_VIRTUAL_TERMINAL',
    VIEW_ACTION_CENTER = 'VIEW_ACTION_CENTER',
    UPLOAD_KYC_REQUESTS = 'UPLOAD_KYC_REQUESTS',
    CAN_VIEW_PLUGIN_PAGE = 'CAN_VIEW_PLUGIN_PAGE',
    UPDATE_ACTION_CENTER = 'UPDATE_ACTION_CENTER',
    MANAGE_MERCHANT_DIRECTORY = 'MANAGE_MERCHANT_DIRECTORY',
    ACCESS_MERCHANT_DIRECTORY = 'ACCESS_MERCHANT_DIRECTORY',
    EDIT_USERS = 'EDIT_USERS',
    UPDATE_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST = 'UPDATE_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST',
    ACCESS_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST = 'ACCESS_RESPONSIBLE_GAMING_LIMIT_AND_BLACKLIST',
}

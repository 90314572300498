import {Chip} from "@mui/material";

interface ContactTypeChipProps {
  contactType: string;
}

const contactTypes = [
  {
    'name': 'primary',
    'label': 'Primary',
    'bullet': 'primary'
  },
  {
    'name': 'secondary',
    'label': 'Secondary',
    'bullet': 'secondary'
  },
  {
    'name': 'accounting_team',
    'label': 'Accounting team',
    'bullet': 'success'
  },
  {
    'name': 'authorized_signatory',
    'label': 'Authorized signatory',
    'bullet': 'error'
  },
  {
    'name': 'compliance_officer',
    'label': 'Compliance officer',
    'bullet': 'warning'
  },
  {
    'name': 'customer_support',
    'label': 'Customer support',
    'bullet': 'info'
  },
  {
    'name': 'customer_support_department_head',
    'label': 'Customer support department head',
    'bullet': 'dark'
  },
  {
    'name': 'director',
    'label': 'Director',
    'bullet': 'primary'
  },
  {
    'name': 'escalations',
    'label': 'Escalations',
    'bullet': 'secondary'
  },
  {
    'name': 'finance_department_head',
    'label': 'Finance department head',
    'bullet': 'success'
  },
  {
    'name': 'integrations_team',
    'label': 'Integrations team',
    'bullet': 'error'
  },
  {
    'name': 'legal_contact',
    'label': 'Legal contact',
    'bullet': 'warning'
  },
  {
    'name': 'management',
    'label': 'Management',
    'bullet': 'info'
  },
  {
    'name': 'officer',
    'label': 'Officer',
    'bullet': 'secondary'
  },
  {
    'name': 'payments_department_head',
    'label': 'Payments department head',
    'bullet': 'primary'
  },
  {
    'name': 'payments_transactions_team',
    'label': 'Payments / transactions team',
    'bullet': 'secondary'
  },
  {
    'name': 'risk_fraud',
    'label': 'Risk / fraud',
    'bullet': 'success'
  },
  {
    'name': 'settlements_billing_treasury',
    'label': 'Settlements / billing / treasury',
    'bullet': 'error'
  },
  {
    'name': 'technical_department_head',
    'label': 'Technical department head',
    'bullet': 'warning'
  },
  {
    'name': 'technical_support',
    'label': 'Technical support',
    'bullet': 'info'
  },
  {
    'name': 'other',
    'label': 'Other',
    'bullet': 'secondary'
  }
]

const colorMap: { [key: string]: "primary" | "secondary" | "success" | "error" | "warning" | "info" | "default" } = {
  'primary': 'primary',
  'secondary': 'secondary',
  'success': 'success',
  'danger': 'error',
  'warning': 'warning',
  'info': 'info',
  'dark': 'default'
};

const ContactTypeChip = ({ contactType }: ContactTypeChipProps) => {
  const contact = contactTypes.find(ct => ct.name === contactType);
  const color: "primary" | "secondary" | "success" | "error" | "warning" | "info" | "default" | undefined = contact ? colorMap[contact.bullet] : 'default';

  return <Chip label={contactType.replaceAll('_', ' ')} color={color} />;
}

export default ContactTypeChip;
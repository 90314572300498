import {Box, Chip, IconButton, Stack, Typography, useTheme} from "@mui/material"
import {ExportTemplate} from "generatedTypes";
import {ReactComponent as EditIcon} from 'assets/iconsDS/pencil.svg'
import {ReactComponent as DeleteIcon} from 'assets/iconsDS/Delete.svg'

interface TemplateItemProps {
  item: ExportTemplate;
  selectedTemplate: ExportTemplate | null;
  onClick: (selectedItem: ExportTemplate) => void;
  // gridRef: MutableRefObject<any>;
}

const TemplateItem = ({item, selectedTemplate, onClick}: TemplateItemProps) => {
  const theme = useTheme();

  const isSelected = selectedTemplate?._id === item._id;

  const selectedStyle = isSelected ? {
    borderColor: theme.palette.themeColors.purple[20],
    backgroundColor: theme.palette.themeColors.purple[30]
  } : {};

  return (
    <Box
      onClick={() => onClick(item)}
      sx={{
        padding: '16px 12px 12px',
        borderRadius: '8px',
        cursor: 'pointer',
        marginBottom: '4px',
        border: '1px solid transparent',
        '&:hover': {
          backgroundColor: theme.palette.themeColors.grey[20]
        },
        ...selectedStyle,
      }}
    >
      <Stack
        direction="column"
        sx={{
          gap: '4px',
        }}
      >
        <Typography
          variant="B4"
          sx={{
            fontWeight: 700,
            color: theme.palette.themeColors.grey[120],
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
          {item.name}
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Chip
            variant="filterCondition"
            label={`${item.aggrid_columns?.filter((i) => !i.hide).length} conditions`}
            sx={{
              width: 'fit-content',
            }}
          />
          <Stack direction="row" spacing="4px">
            <IconButton>
              <EditIcon/>
            </IconButton>
            <IconButton>
              <DeleteIcon/>
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

export default TemplateItem;

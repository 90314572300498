import {Box, MenuItem, Pagination, Select, SelectChangeEvent, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {ReactNode} from "react";

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
});


interface PaginationComponentProps {
  pages: number;
  perPage: number;
  currentPage: number;
  itemsRange: string;
  handlePageChange: (event: unknown, value: number) => void;
  handlePerPageChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
}

const PaginationComponent = ({
  pages = 1,
  perPage,
  currentPage,
  itemsRange,
  handlePageChange,
  handlePerPageChange
}: PaginationComponentProps) => (
  <Box
    display='flex'
    alignItems='center'
    justifyContent='space-between'
  >
    <Typography variant='body2' sx={{mr: 1}} color='#999'>
      {itemsRange}
    </Typography>

    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
    >
      <>
        <Pagination
          size='small'
          count={pages}
          variant='outlined'
          shape='rounded'
          page={currentPage}
          onChange={handlePageChange}
        />

        <StyledSelect size='small' sx={{ml: 2}} value={String(perPage)} onChange={handlePerPageChange}>
          <MenuItem value="10">10</MenuItem>
          <MenuItem value="20">20</MenuItem>
          <MenuItem value="30">30</MenuItem>
        </StyledSelect>
      </>
    </Box>
  </Box>
);

export default PaginationComponent;

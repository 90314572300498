import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AccountQueryKeys} from "shared/queryKeys";
import {IApiError} from "shared/types";
import {useErrorMessage} from "shared/hooks/useErrorMessage";
import authService from "routes/authService";

type Params = Parameters<typeof ApiClient.account.postChangeUserPassword>[0]

export const useChangePassword = (reset: () => void) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const {errorMessage, handleSetErrorMessage} = useErrorMessage()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.account.postChangeUserPassword(params),
    onMutate: () => {handleSetErrorMessage()},
    onSuccess: async () => {
      enqueueSnackbar('Password changed', {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [AccountQueryKeys.ACCOUNT_SETTINGS]})

      reset();

      authService.logout()
    },
    onError: (error: IApiError) => {
      handleSetErrorMessage(error)
    },
  })

  return {
    mutate,
    isPending,
    errorMessage,
  }
}

import transactionPaths from "apps/transactions/uiPaths";
import customerPaths from "apps/customers/uiPaths";
import virtualTerminalPaths from "apps/virtualTerminal/uiPaths";
import accountPaths from "apps/account/uiPaths";
import batchUploadPaths from "apps/batchUpload/uiPaths";

export default {
  transactionPaths,
  customerPaths,
  virtualTerminalPaths,
  accountPaths,
  batchUploadPaths,
}

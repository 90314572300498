import {useFormContext} from "react-hook-form";
import {useQuery} from "@tanstack/react-query";
import {VirtualTerminalQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

const useTerminalPaymentMethods = () => {
  const {watch} = useFormContext();
  const transactionType = watch('transaction_type');
  const siteId = watch('site_id');

  const {data, isLoading, isFetched} = useQuery({
    queryKey: [VirtualTerminalQueryKeys.PAYMENT_METHODS, {siteId, transactionType}],
    queryFn: () => ApiClient.virtualTerminal.getVirtualTerminalEnabledPaymentMethods({siteId, transactionType}),
    enabled: !!transactionType && !!siteId,
  })

  return {data, isLoading, isFetched};
}

export default useTerminalPaymentMethods;

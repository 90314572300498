import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {ExportQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";


export const useColumnTemplates = () => {
  const {data, isLoading} = useQuery({
    queryKey: [ExportQueryKeys.AGGRID_COLUMNS],
    queryFn: () => ApiClient.export.getTemplates(),
    placeholderData: keepPreviousData,
  })

  return {
    data,
    isLoading,
  }
}

const grey = {
  '05': '#F4F4F5',
  '08': '#BCBBB7',
  '10': '#FCFCFB',
  '20': '#F9F9F7',
  '30': '#F0EFED',
  '40': '#E9E8E4',
  '50': '#E2E0DD',
  '60': '#DAD9D5',
  '70': '#CFCECA',
  '90': '#8E8D89',
  '80': '#BCBBB7',
  '100': '#84827F',
  '110': '#656461',
  '120': '#21201D',
  'border': '#EDECE9',
}

const green = {
  '10': '#E7F5E4',
  '20': '#298E33'
}

const yellow = {
  '10': '#FFE6C1',
  '20': '#A86807'
}

const red = {
  '10': '#FCDEDF',
  '20': '#D43338',
  '30': '#641A1A'
}

const purple = {
  '10': '#EFE3FE',
  '20': '#8145B5',
  '30': '#F5EEFE',
  '40': '#EFE3FE',
  '50': '#E7D5FC',
  '60': '#DCC6F7',
  '70': '#CEB0EE',
  '80': '#BB93E5',
  '90': '#8145B5',
  '100': '#7336A5',
  '110': '#8145B5',
  '120': '#421F5E',
  '130': '#2B143D',
  '700': '#2D2156',
}

const blue = {
  '10': '#D8F3FF',
  '20': '#18B1DE',
  '30': '#065871'
}

const orange = {
  '10': '#FFF0E9',
  '20': '#F78D59',
  '30': '#6B2E06'
}

const pink = {
  '10': '#FDDFF1',
  '20': '#F96EC1',
  '30': '#7D094F'
}

const magenta = {
  '10': '#FAE4F2',
  '30': '#5C1240'
}

const sea = {
  '10': '#E4EEFA',
  '30': '#183658'
}

const brown = {
  '10': '#F9EAE1',
  '20': '#CD865C',
  '30': '#592A0F'
}

const teal = {
  '10': '#E0F1F1',
  '20': '#6FA6AA',
  '30': '#0E3F43'
}

const alge = {
  '10': '#F2F6CF',
  '20': '#B7CA06',
  '30': '#5C6507'
}

const iris = {
  '20': '#796EF9',
  '30': '#12097D'
}

const blush = {
  '20': '#AA6F6F',
  '30': '#590F0F'
}

const violet = {
  '20': '#9670D3',
  '30': '#311461'
}

const turquoise = {
  '20': '#03D1A0',
  '30': '#027359'
}

const primaryShades = {
  purple30: '#F5EEFE',
}

const palette = {
  success: {
    main: '#298E33',
  },
  warning: {
    main: '#A86807',
  },
  error: {
    main: '#D43338',
  },
  info: {
    main: '#4D7D87',
  },
  primary: {
    main: '#8145B5',
  },
  secondary: {
    main: '#21201D',
    white: '#fff'
  },
  text: {
    primary: '#21201D',
  },
  themeColors: {
    grey,
    green,
    yellow,
    red,
    purple,
    blue,
    orange,
    pink,
    magenta,
    sea,
    brown,
    teal,
    alge,
    iris,
    blush,
    violet,
    turquoise,
    primaryShades,
  },
}

declare module '@mui/material/styles' {
  interface Palette {
    themeColors: {
      grey: typeof grey;
      green: typeof green;
      yellow: typeof yellow;
      red: typeof red;
      purple: typeof purple;
      magenta: typeof magenta;
      blue: typeof blue;
      orange: typeof orange;
      pink: typeof pink;
      sea: typeof sea;
      brown: typeof brown;
      teal: typeof teal;
      alge: typeof alge;
      iris: typeof iris;
      blush: typeof blush;
      violet: typeof violet;
      turquoise: typeof turquoise;
      primaryShades: typeof primaryShades;
    };
  }
  interface PaletteOptions {
    themeColors: {
      grey: typeof grey;
      green: typeof green;
      yellow: typeof yellow;
      red: typeof red;
      purple: typeof purple;
      blue: typeof blue;
      orange: typeof orange;
      pink: typeof pink;
      magenta: typeof magenta;
      sea: typeof sea;
      brown: typeof brown;
      teal: typeof teal;
      alge: typeof alge;
      iris: typeof iris;
      blush: typeof blush;
      violet: typeof violet;
      turquoise: typeof turquoise;
      primaryShades: typeof primaryShades;
    };
  }
}

export default palette ;

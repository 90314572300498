import {AppCard, AppTableHead} from "shared/components/mui";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress, LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
} from "@mui/material";
import usePagination from "shared/hooks/usePagination";
import useDataFilters from "apps/KYCRequests/pages/list/useDataFilters";
import KYCRequestRow from "apps/KYCRequests/pages/list/KYCRequestRow";
import usePermissions from "apps/KYCRequests/pages/list/permissions/usePermissions";

import SubmitKYCDialog from "apps/KYCRequests/pages/create";
import useDialogState from "shared/hooks/useDialogState";

import PermissionError from "shared/PermissionError";

const TABLE_HEAD = [
  {id: 'email', label: 'EMAIL'},
  {id: 'site', label: 'SITE'},
  {id: 'status', label: 'STATUS'},
  {id: 'verified_at', label: 'VERIFIED AT'},
  {id: 'verified_by', label: 'VERIFIED BY'},
  {id: 'actions', label: ''},
]

const KYCRequestsList = () => {
  const {currentPage, perPage, pagination} = usePagination();

  const {data, isLoading, isFetching, filters} = useDataFilters({currentPage, perPage});

  const {canViewKYCRequests, canSubmitKYCRequest} = usePermissions();

  const submitKYCDialog = useDialogState();

  if (!canViewKYCRequests){
    return <PermissionError />
  }

  if (isLoading) return <LinearProgress/>

  return (
    <>
      <Box mb={4} maxWidth={600}>
        {filters}
      </Box>

      <AppCard
        title='KYC'
        action={canSubmitKYCRequest && <Button variant="contained" onClick={submitKYCDialog.openDialog}>Submit KYC</Button>}
      >
        <TableContainer>
          <Table size='small' sx={{minWidth: 800, bgcolor: '#fff', position: 'relative'}}>
            <AppTableHead headLabel={TABLE_HEAD}/>

            <TableBody sx={{position: 'relative'}}>
              {isLoading && 'Loading...'}
              <Backdrop open={!isLoading && isFetching} sx={{zIndex: 99, position: 'absolute'}}>
                <CircularProgress color="inherit" sx={{zIndex: 100}}/>
              </Backdrop>

              {data?.items?.map((item) => (
                <KYCRequestRow
                  _id={item._id}
                  email={item.email}
                  site_name={item.site_name}
                  sid={item.sid}
                  status={item.status}
                  verified_at={item.verified_at}
                  verified_by={item.verified_by}
                />
              ))}
            </TableBody>

            <TableFooter>
              <TableCell colSpan={9} align='right' sx={{py: 1, border: 'none'}}>
                {pagination({total: data?.total, pages: data?.pages})}
              </TableCell>
            </TableFooter>
          </Table>
        </TableContainer>
      </AppCard>

      <SubmitKYCDialog dialogStateHandler={submitKYCDialog}/>
    </>
  );
}

export default KYCRequestsList;

import {Components} from "@mui/material/styles";
import typography from "theme/typography";
import palette from "theme/palette";

const MuiButton: Components['MuiButton'] =  {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      textTransform: 'none',
    },
    colorPrimary: {
      color: palette.themeColors.purple['20'],
      borderColor: palette.themeColors.purple['20'],
    },
    colorError: {
      backgroundColor: palette.themeColors.red['20'],
      color: palette.secondary.white,
    },
    contained: {
      color: '#fff',

      '&.MuiButton-containedPrimary': {
        boxShadow: 'none',
        backgroundColor: palette.themeColors.purple['20'],
        '&:hover': {
          backgroundColor: palette.themeColors.purple['120'],
          outline: '3px solid rgba(129, 69, 181, 0.10)'
        },
        '&:active': {
          backgroundColor: palette.themeColors.purple['120'],
          outline: '3px solid rgba(129, 69, 181, 0.10)'
        },
        '&.Mui-disabled': {
          backgroundColor: palette.themeColors.purple['70'],
          color: '#fff',
        },
      },
    },

    outlined: {
      '&.MuiButton-outlinedSecondary': {
        color: palette.themeColors.grey['110'],
        border: "0.5px solid",
        borderColor: palette.themeColors.grey['50'],
        backgroundColor: '#fff',

        '&:hover': {
          color: palette.themeColors.grey['120'],
          outline: '3px solid rgba(218, 217, 213, 0.20)',
          backgroundColor: '#fff',
        },
        '&:active': {
          color: palette.themeColors.purple['20'],
          borderColor: palette.themeColors.purple['20'],
        },
        '&.Mui-disabled': {
          color: palette.themeColors.grey['80'],
          borderColor: palette.themeColors.grey['40'],
        },
      },
    },

    text: {
      '&.MuiButton-textInfo': {
        color: palette.themeColors.grey['110'],
        '&:hover': {
          color: palette.themeColors.grey['120'],
          backgroundColor: palette.themeColors.grey['30'],
        },
        '&:active': {
          color: palette.themeColors.grey['120'],
          backgroundColor: palette.themeColors.grey['30'],
          outline: '3px solid rgba(129, 69, 181, 0.10)'
        },
      }
    },

    startIcon: {
      marginRight: '4px',
      marginLeft: '-4px',
    },

    sizeSmall: {
      height: '2rem', // 32px
      padding: '8px 16px',
      ...typography.B6,
    },
    sizeMedium: {
      height: '2.75rem', // 44px
      padding: '12px 20px',
      ...typography.B5,
    },
    sizeLarge: {
      height: '2.875rem', // 46px
      padding: '12px 24px',
      ...typography.B2,
    }
  },
}

export default MuiButton;

import {useQuery} from "@tanstack/react-query";
import {RecentEventsKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

const useRecentEventsTransactionsQuery = () => {
  const {data, isLoading} = useQuery({
    queryKey: [RecentEventsKeys.EVENTS],
    queryFn: () => ApiClient.socketServer.getRecentEventsTransactions({}),
  })

  return {
    data,
    isLoading
  }
}

export default useRecentEventsTransactionsQuery;

import {DialogStateHandler} from "shared/hooks/useDialogState";
import {useKycRequestDetails} from "apps/KYCRequests/pages/list/useDataQueries";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer
} from "@mui/material";
import CloseIconButton from "shared/CloseButton";
import {AppTableHead} from "shared/components/mui";
import KYCDetailRow from "apps/KYCRequests/pages/list/KYCDetailRow";
import {KYCRequestDetailSchema} from "generatedTypes";

const TABLE_HEAD = [
  {id: 'type', label: 'TYPE'},
  {id: 'first_name', label: 'FIRST NAME'},
  {id: 'last_name', label: 'LAST NAME'},
  {id: 'address', label: 'ADDRESS'},
  {id: 'phone', label: 'PHONE'},
  {id: 'timestamp', label: 'TIMESTAMP'},
  {id: 'actions', label: 'ACTIONS'},
]

interface KYCDetailDialogProps {
  dialogStateHandler: DialogStateHandler,
  email: string,
  kycId: string
}

function formatAddress(data?: KYCRequestDetailSchema) {
  if (data) {
    const {address, city, state, country, zip_code: zipCode} = data;
    return [address, city, state, country, zipCode?.toUpperCase()].filter(Boolean).join(', ');
  }
  return '';
}

const KYCDetailDialog = ({dialogStateHandler, email, kycId}: KYCDetailDialogProps) => {
  const {data, isLoading, isFetching} = useKycRequestDetails(
    {
      email,
      kycId,
      dialogHandler: dialogStateHandler
    }
  )

  return (
    <Dialog open={dialogStateHandler.isOpen} onClose={dialogStateHandler.closeDialog} fullWidth maxWidth='md'>
      <DialogTitle id="modal-dialog-title">
        KYC Request Details
      </DialogTitle>
      <CloseIconButton onClick={dialogStateHandler.closeDialog}/>
      <DialogContent>
        <TableContainer>
          <Table>
            <AppTableHead headLabel={TABLE_HEAD}/>

            <TableBody>
              {isLoading && 'Loading...'}
              <Backdrop open={!isLoading && isFetching} sx={{zIndex: 99, position: 'absolute'}}>
                <CircularProgress color="inherit" sx={{zIndex: 100}}/>
              </Backdrop>

              {data && Object.entries(data).map(([key, value]) => {
                if (Array.isArray(value) && value.length > 0) {
                  return value.map((item) => (
                    <KYCDetailRow
                      key={item.key}
                      type={key}
                      first_name={item.first_name}
                      last_name={item.last_name}
                      address={formatAddress(item)}
                      phone={item.phone}
                      timestamp={item.timestamp}
                      url={item.key}
                    />
                  ));
                }
                return null;
              })}

            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={dialogStateHandler.closeDialog}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default KYCDetailDialog;
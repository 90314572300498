import {createContext, useContext, useState, ReactNode, useMemo} from 'react';

interface Breadcrumb {
  name: string;
  link?: string;
}

interface BreadcrumbContextType {
  breadcrumbs: Breadcrumb[];
  updateBreadcrumbs: (newBreadcrumbs: Breadcrumb[]) => void;
}

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

export const BreadcrumbProvider = ({ children }: { children: ReactNode }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const updateBreadcrumbs = (newBreadcrumbs: Breadcrumb[]) => {
    setBreadcrumbs(newBreadcrumbs);
  };

  const value = useMemo(() => ({ breadcrumbs, updateBreadcrumbs }), [breadcrumbs, updateBreadcrumbs]);


  return (
    <BreadcrumbContext.Provider value={value}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumbs = (): BreadcrumbContextType => {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbProvider');
  }
  return context;
};

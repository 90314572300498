import {useEffect, useRef, useState} from "react";
import {ColDef} from "@ag-grid-community/core";
import {AgGridReact} from "@ag-grid-community/react";
import {CustomerSchema} from "generatedTypes";
import RouteRenderer from "shared/components/AGGridRenderers/RouteRenderer";
import uiPaths from "routes/uiPaths";
import {CellActionsRenderer} from "apps/customers/shared/CustomerActions";
import PhoneRenderer from "shared/components/AGGridRenderers/PhoneRenderer";

const useColumnsConfig = () => {
  const gridRef = useRef<AgGridReact<CustomerSchema>>(null);

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    const baseColumns: ColDef[] = [
      {
        headerName: 'Name',
        field: 'name',
        sortable: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        minWidth: 150,
      },
      {
        headerName: 'Email',
        field: 'email',
        sortable: true,
        cellRenderer: RouteRenderer({uiPath: uiPaths.customerPaths.detail, pathParamName: 'customerEmail'}),
        minWidth: 200,
      },
      {
        headerName: 'Address',
        field: 'address',
        sortable: true,
        minWidth: 300,
      },
      {
        headerName: 'Phone',
        field: 'phone',
        sortable: true,
        minWidth: 140,
        cellRenderer: PhoneRenderer,
      },
      {
        headerName: '',
        cellRenderer: CellActionsRenderer,
        maxWidth: 60,
        cellStyle: {display: 'flex', padding: '13px 8px 13px 8px', alignItems: 'center', justifyContent: 'center'},
      }
    ];

    setColumnDefs([...baseColumns]);
  }, []);

  return {
    columnDefs,
    gridRef,
  }
}

export default useColumnsConfig;

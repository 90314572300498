import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";

interface CloseIconButtonProps {
  onClick: () => void;
}

const CloseIconButton = ({onClick}: CloseIconButtonProps) => (
  <IconButton
    sx={{
      position: 'absolute',
      right: 9,
      top: 15,
      color: (theme) => theme.palette.grey[500],
    }}
    onClick={onClick}
    aria-label="close"
  >
    <Close sx={{width: '16px', height: '16px'}}/>
  </IconButton>
);

export default CloseIconButton;

import {useEffect, useState} from "react";
import {ColDef, ColGroupDef} from "@ag-grid-community/core";
import AmountRenderer from "shared/components/AGGridRenderers/AmountRenderer";
import MethodRenderer from "shared/components/AGGridRenderers/MethodRenderer";
import StatusOfTransactionRenderer from "shared/components/AGGridRenderers/StatusOfTransactionRenderer";

import EmailRenderer from "shared/components/AGGridRenderers/EmailRenderer";
import DateRenderer from "shared/components/AGGridRenderers/DateRenderer";
import TransactionIDRenderer from "shared/components/AGGridRenderers/TransactionIDRenderer";

const useColumnsConfig = () => {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const [columnDefs, setColumnDefs] = useState<(ColDef | ColGroupDef)[]>([]);


  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    const baseColumns: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Method',
        field: 'method',
        cellRenderer: MethodRenderer,
        minWidth: 130,
        sortable: false,
      },
      {
        headerName: 'Status',
        field: 'transaction_status',
        cellRenderer: StatusOfTransactionRenderer,
        minWidth: 130,
        sortable: false,
      },
      {
        headerName: 'Amount',
        field: 'amount',
        cellRenderer: AmountRenderer,
        sortable: false,
      },
      {
        headerName: 'Transaction ID',
        field: 'txn',
        minWidth: 140,
        cellRenderer: TransactionIDRenderer,
        sortable: false,
      },
      {
        headerName: 'Email',
        field: 'email',
        cellRenderer: EmailRenderer,
        sortable: false,
      },
      {
        headerName: 'Site',
        field: 'site_name',
        sortable: false,
      },
      {
        headerName: 'Date',
        field: 'status_timestamp',
        cellRenderer: DateRenderer,
        minWidth: 125,
        sortable: false,
      },
    ];

    setColumnDefs([...baseColumns]);
  }, []);

  return {
    columnDefs,
  }
}

export default useColumnsConfig;
import {Components} from "@mui/material/styles";
import palette from "theme/palette";

const MuiPaginationItem: Components['MuiPaginationItem'] =  {
  styleOverrides: {
    root: {
      margin: 0,

      '&.Mui-selected': {
        backgroundColor: palette.themeColors.grey['30'],
      },
    },
  }
}

export default MuiPaginationItem;

import {Box, Grid, LinearProgress, Tab} from "@mui/material";
import {useParams} from "react-router-dom";
import {useUserDetails} from "apps/users/pages/edit/useDataQueries";
import EditEmail from "apps/users/pages/edit/partials/EditEmail";
import EditPhone from "apps/users/pages/edit/partials/EditPhone";
import EditProfile from "apps/users/pages/edit/partials/EditProfile";
import EditPassword from "apps/users/pages/edit/partials/EditPassword";
import {useState} from "react";
import {TabContext, TabPanel} from "@mui/lab";
import TabList from "@mui/lab/TabList";
import EditPermissions from "apps/users/pages/edit/partials/EditPermissions";
import AccountInfo from "apps/users/pages/edit/partials/AccountInfo";
import usePermissions from "apps/users/pages/edit/permissions/usePermissions";
import PermissionError from "shared/PermissionError";

const EditUser = () => {
  const {userId} = useParams()
  
  const {canEditUsers} = usePermissions()

  const {data, isLoading} = useUserDetails({userId: userId || ''})

  const [tab, setTab] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  if (!canEditUsers){
    return <PermissionError />
  }

  if (isLoading) return <LinearProgress/>

  return (
    <Grid container spacing={3}>
      <Grid item lg={4}>
        <Grid container>
          <Grid item lg={12}>
            <EditProfile
              firstName={data?.first_name}
              lastName={data?.last_name}
              role={data?.role}
              isActive={data?.is_active}
              isTwoFactorEnabled={data?.two_fa_enabled}
            />
          </Grid>
          <Grid item lg={12}>
            <EditEmail email={data?.email_address} verified={data?.email_verified}/>
          </Grid>
          <Grid item lg={12}>
            <EditPhone phone={data?.phone} verified={data?.phone_verified}/>
          </Grid>
          <Grid item lg={12}>
            <EditPassword lastPasswordCreatedAt={data?.last_password_created_at}/>
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={7}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label="Permissions" value="1" />
              <Tab label="Account Info" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <EditPermissions userRole={data?.role || ''} userPermissions={data?.permissions || []}/>
          </TabPanel>
          <TabPanel value="2">
            <AccountInfo data={data || {}} />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  )
}

export default EditUser;
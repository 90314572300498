/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SocketServerRecentEventsPaginatedSchema } from '../models/SocketServerRecentEventsPaginatedSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SocketServerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get users list
     * @returns SocketServerRecentEventsPaginatedSchema Success
     * @throws ApiError
     */
    public getRecentEventsTransactions({
        sids,
        xFields,
    }: {
        /**
         * Filter by sids.
         */
        sids?: Array<string>,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<SocketServerRecentEventsPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/socket-server/recent-events/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'sids': sids,
            },
        });
    }
}

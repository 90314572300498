import {useFormContext, useWatch} from "react-hook-form";
import {isoFormattedDate} from "shared/utils/dateFormats";

export const useFormFilters = () => {
  const {control} = useFormContext();
  const mids = useWatch({control, name: 'mids'});
  const rids = useWatch({control, name: 'rids'});
  const sids = useWatch({control, name: 'sids'});
  const startDate = useWatch({control, name: 'startDate'});
  const endDate = useWatch({control, name: 'endDate'});

  return {mids, rids, sids, startDate: isoFormattedDate(startDate), endDate: isoFormattedDate(endDate)};
}

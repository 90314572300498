import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form"
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box} from "@mui/material";
import TextFormField from "shared/components/formFields/TextFormField";
import { useDebounce } from "@uidotdev/usehooks";

const schema = yup.object().shape({
  search: yup.string(),
})

type FormData = yup.InferType<typeof schema>;

const useFilters = () => {
  const [formData, setFormData] = useState<FormData>();

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const {formState} = methods;

  const searchField = useDebounce(methods.watch('search'), 400);

  const handleSubmit = (data: FormData) => {
    setFormData({search: data.search});
  };

  useEffect(() => {
    handleSubmit({search: searchField});
  }, [searchField]);


  const filters = (
    <FormProvider {...methods}>
      <Box width={500}>
        <TextFormField
          name="search"
          placeholder="Search contact (by name, title, email, phone, messaging or note)"
        />
      </Box>
    </FormProvider>
  )

  const filterData = {
    ...formData,
  }

  return {
    filterData,
    filters,
    submitCount: formState.submitCount,
    isValid: formState.isValid,
  };
}

export default useFilters;

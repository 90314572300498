import React, {useState} from 'react';
import {Box, ClickAwayListener, Divider, IconButton, Paper, Popper, Tab} from '@mui/material';
import {TabContext, TabPanel} from "@mui/lab";
import TabList from "@mui/lab/TabList";
import ColumnsListTab
  from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ColumnsToolBar/ColumnsListTab";
import TemplatesList
  from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ColumnsToolBar/TemplatesList";
import {styled, useTheme} from "@mui/material/styles";
import {ReactComponent as AgGridColumnsIcon} from "assets/iconsDS/aggrid-columns.svg";
import {ReactComponent as AgGridColumnsActiveIcon} from "assets/iconsDS/aggrid-columns-active.svg";
import {ReactComponent as AgGridColumns} from "assets/iconsDS/aggrid-column.svg";
import {ReactComponent as AgGridTemplates} from "assets/iconsDS/aggrid-templates.svg";
import CloseIconButton from "shared/CloseButton";
import {bindPopper, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";


interface ColumnsToolBarProps {
  gridRef: any;
}

const CustomTabPanel = styled(TabPanel)(({theme}) => ({
  padding: theme.spacing(1.5),
}))

function ColumnsToolBar({gridRef}: ColumnsToolBarProps) {
  const theme = useTheme();
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const popupState = usePopupState({variant: 'popover', popupId: 'transactionColumns'})

  return (
    <ClickAwayListener onClickAway={popupState.close}>
      <Box>
        <IconButton
          {...bindTrigger(popupState)}
          sx={{
            padding: '12px',
            border: popupState.isOpen ? `1px solid ${theme.palette.themeColors.purple[20]}` : `1px solid ${theme.palette.themeColors.grey[50]}`,
            backgroundColor: '#fff',

            '&:hover': {
              backgroundColor: '#fff',
              outline: popupState.isOpen ? `3px solid rgba(129, 69, 181, 0.10)` : `3px solid ${theme.palette.themeColors.grey[30]}`
            },

            '&:active': {
              backgroundColor: theme.palette.themeColors.grey[30],
              borderColor: theme.palette.themeColors.grey[60],

              '&:hover': {
                outline: 'none'
              },

              'svg path': {
                stroke: theme.palette.themeColors.grey[120],
                strokeWidth: 1.5
              }
            },

            '& .MuiTouchRipple-root': {
              position: 'unset',
            }
          }}
        >
          {popupState.isOpen ? <AgGridColumnsActiveIcon/> : <AgGridColumnsIcon/>}
        </IconButton>
        <Popper
          {...bindPopper(popupState)}
          placement="bottom-start"
          sx={{
            zIndex: 2
          }}
        >
          <Paper sx={{width: '320px'}}>
            <TabContext value={value}>
              <Box sx={{position: 'relative'}}>
                <Box sx={{padding: '0 24px'}}>
                  <TabList onChange={handleChange} aria-label="Columns Tool Bar">
                    <Tab icon={<AgGridColumns/>} label="Column" value="1"/>
                    <Tab icon={<AgGridTemplates/>} label="Templates" value="2"/>
                  </TabList>
                </Box>
                <Divider
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                  }}
                />
                <CloseIconButton onClick={popupState.close}/>
              </Box>

              <CustomTabPanel value="1">
                <ColumnsListTab gridRef={gridRef}/>
              </CustomTabPanel>
              <CustomTabPanel value="2">
                <TemplatesList gridRef={gridRef}/>
              </CustomTabPanel>
            </TabContext>
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}

export default ColumnsToolBar;

import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Alert, Box, Button, Chip} from "@mui/material";
import PhoneFormField from "shared/components/formFields/PhoneFormField";
import {UserDetailsSchema} from "generatedTypes";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEditPhone} from "apps/users/pages/edit/partials/EditPhone/useDataMutations";
import {useParams} from "react-router-dom";
import {AppCard} from "shared/components/mui";
import useDialogState from "shared/hooks/useDialogState";
import ManuallyVerifyDialog from "apps/users/pages/edit/partials/EditPhone/ManuallyVerifyDialog";
import ResendVerificationDialog from "apps/users/pages/edit/partials/EditPhone/ResendVerificationDialog";
import usePermissions from "apps/users/pages/edit/permissions/usePermissions";
import {phoneValidator} from "shared/schemas";

const schema = yup.object().shape({
  phone: phoneValidator.required('Phone is required'),
})

type FormData = yup.InferType<typeof schema>;

interface PhoneFormProps {
    phone: UserDetailsSchema["phone"]
    verified: UserDetailsSchema["phone_verified"]
}

const EditPhone = ({phone, verified}: PhoneFormProps) => {
  const {userId} = useParams()
  const manuallyVerifyDialogHandler = useDialogState();
  const resendVerificationDialogHandler = useDialogState();

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      phone
    }
  });

  const {canEnableDisableActiveAndVerify} = usePermissions()

  const {mutate, isPending, phoneErrorMessage} = useEditPhone()

  const onSubmit = (data: FormData) => {
    if(data.phone){
      mutate({userId: userId || '', requestBody: {phone: data.phone}})
    }
  }

  return (
    <AppCard
      headStyle={{pb: 0}}
      bodyStyle={{m: 0, p: 1, pt: 2}}
      sx={{pb: 0, mt: 3}}
    >
      <FormProvider {...methods}>
        <Box component='form' mx={3} onSubmit={methods.handleSubmit(onSubmit)}>
          <PhoneFormField name="phone" placeholder="Phone"/>
          {phoneErrorMessage && <Alert severity="error" sx={{mt: 1}}>{phoneErrorMessage}</Alert>}

          <Box sx={{mt: 2}}>
            <Chip label={verified ? 'verified' : 'not verified'} color={verified ? 'success': 'error'} />
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Box>
              {phone && (
                <Button
                  onClick={resendVerificationDialogHandler.openDialog}
                  variant='outlined'
                  sx={{mr: 1}}
                >
                  Verify
                </Button>
              )}

              {(canEnableDisableActiveAndVerify && !verified) && (
                <Button
                  onClick={manuallyVerifyDialogHandler.openDialog}
                  variant='outlined'
                >
                  Manually
                </Button>
              )}
            </Box>
            <LoadingButton
              loading={isPending}
              variant="contained"
              loadingIndicator="Submitting…"
              type="submit"
              sx={{mt: 2, mb: 2, ml: 1, width: 120}}
              disabled={!methods.formState.isDirty || !methods.formState.isValid}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>

      <ManuallyVerifyDialog dialogHandler={manuallyVerifyDialogHandler} phone={phone || ''} />
      <ResendVerificationDialog dialogHandler={resendVerificationDialogHandler} phone={phone || ''} />
    </AppCard>
  )
}

export default EditPhone;

import {styled, useTheme} from "@mui/material/styles";
import {Box, Stack, Typography} from "@mui/material";

interface ProgressBarProps {
  value: number
  label: string
  isSkeleton?: boolean
}

const BackgroundBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.themeColors.purple['30'],
  borderRadius: '4px 4px 4px 4px',
  justifyContent: 'end',
}));

const ForegroundBox = styled(Box)<{ width: string }>(({ theme, width }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  backgroundColor: theme.palette.themeColors.purple['80'],
  width,
  borderRadius: '4px 4px 4px 4px',
}));

const ProgressBar = ({ value, label, isSkeleton = false}: ProgressBarProps) => {
  const theme = useTheme()

  return (
    <Stack direction='row' sx={{marginTop: '12px !important', position: 'relative', height: 20}}>
      <BackgroundBox
        sx={{
          backgroundColor: isSkeleton ? theme.palette.themeColors.grey['20'] : theme.palette.themeColors.purple['30'],
        }}
      />
      <ForegroundBox width={`${Math.floor(value)}%`} />

      <Stack direction='row' sx={{top: 0, left: 0, right: 0, bottom: 0, padding: '2px 4px'}} justifyContent='space-between' position='absolute'>
        <Typography
          variant='B7'
          sx={{
            color: isSkeleton ? theme.palette.themeColors.grey['70'] : '#fff',
            fontWeight: 600
          }}
        >
          {label}
        </Typography>
        <Typography
          variant='B7'
          sx={{
            color: isSkeleton ? theme.palette.themeColors.grey['70']: theme.palette.primary.main,
            fontWeight: 600
          }}
        >
          {Math.floor(value)}%
        </Typography>
      </Stack>
    </Stack>
  )
}

export default ProgressBar;
import {useTheme} from "@mui/material/styles";
import {Avatar, Box, Divider, Skeleton, Stack, Typography} from "@mui/material";
import MarkedBy from "shared/components/DS/MarkedBy";
import UDFS from "shared/components/DS/UDFS";

const SkeletonTransactionDetailsData = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          borderRadius: theme.spacing(1),
          padding: theme.spacing(2),
          paddingTop: '13px',
          border: `1px solid ${theme.palette.themeColors.grey['40']}`,
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Stack direction="row">
          <Box sx={{
            marginRight: '11px',
          }}>
            <Skeleton variant="rounded" width={40} height={40}>
              <Avatar/>
            </Skeleton>
          </Box>

          <Stack gap='7px'>
            <Typography variant='h5' fontWeight={600}>
              <Skeleton variant="rounded" width={120} height={16} />
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                marginTop: '-3px'
              }}
            >
              <Typography variant="B4">
                <Skeleton variant="rounded" width={160} height={20} />
              </Typography>
              <MarkedBy values={[]} />
            </Stack>
          </Stack>
        </Stack>

        <Divider
          sx={{
            margin: '12px 0',
          }}
        />

        <Stack
          direction='row'
          justifyContent='space-between'
        >
          <Stack gap='8px'>
            <Typography variant='B4'>
              <Skeleton variant="rounded" width={60} height={16} />
            </Typography>
            <Typography variant='B4'>
              <Skeleton variant="rounded" width={160} height={20} />
            </Typography>
          </Stack>

          <Stack gap='8px'>
            <Typography variant='B4'>
              <Skeleton variant="rounded" width={160} height={16} />
            </Typography>
            <Typography variant='B4'>
              <Skeleton variant="rounded" width={60} height={20} />
            </Typography>
          </Stack>

          <Stack gap='8px'>
            <Typography variant='B4'>
              <Skeleton variant="rounded" width={60} height={16} />
            </Typography>
            <Typography variant='B4'>
              <Skeleton variant="rounded" width={160} height={20} />
            </Typography>
          </Stack>

          <Stack gap='8px'>
            <Typography variant='B4'>
              <Skeleton variant="rounded" width={60} height={16} />
            </Typography>
            <Typography variant='B4'>
              <Skeleton variant="rounded" width={160} height={20} />
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          marginTop: '20px'
        }}
      >
        <UDFS values={[]} udf1Title="Skeleton" isLoading/>
      </Box>
    </>
  )
}

export default SkeletonTransactionDetailsData;
/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AccountService } from './services/AccountService';
import { ActionCenterService } from './services/ActionCenterService';
import { AuthService } from './services/AuthService';
import { AutocompleteService } from './services/AutocompleteService';
import { BatchUploadsService } from './services/BatchUploadsService';
import { ConfigsService } from './services/ConfigsService';
import { CustomersService } from './services/CustomersService';
import { DashboardService } from './services/DashboardService';
import { DashboardRequestsService } from './services/DashboardRequestsService';
import { ExportService } from './services/ExportService';
import { KycRequestsService } from './services/KycRequestsService';
import { MerchantsService } from './services/MerchantsService';
import { NotificationsService } from './services/NotificationsService';
import { PluginsService } from './services/PluginsService';
import { SocketServerService } from './services/SocketServerService';
import { TransactionService } from './services/TransactionService';
import { TransactionsService } from './services/TransactionsService';
import { UserLimitsService } from './services/UserLimitsService';
import { UsersService } from './services/UsersService';
import { VirtualTerminalService } from './services/VirtualTerminalService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class GeneratedClient {
    public readonly account: AccountService;
    public readonly actionCenter: ActionCenterService;
    public readonly auth: AuthService;
    public readonly autocomplete: AutocompleteService;
    public readonly batchUploads: BatchUploadsService;
    public readonly configs: ConfigsService;
    public readonly customers: CustomersService;
    public readonly dashboard: DashboardService;
    public readonly dashboardRequests: DashboardRequestsService;
    public readonly export: ExportService;
    public readonly kycRequests: KycRequestsService;
    public readonly merchants: MerchantsService;
    public readonly notifications: NotificationsService;
    public readonly plugins: PluginsService;
    public readonly socketServer: SocketServerService;
    public readonly transaction: TransactionService;
    public readonly transactions: TransactionsService;
    public readonly userLimits: UserLimitsService;
    public readonly users: UsersService;
    public readonly virtualTerminal: VirtualTerminalService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '2.5.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.account = new AccountService(this.request);
        this.actionCenter = new ActionCenterService(this.request);
        this.auth = new AuthService(this.request);
        this.autocomplete = new AutocompleteService(this.request);
        this.batchUploads = new BatchUploadsService(this.request);
        this.configs = new ConfigsService(this.request);
        this.customers = new CustomersService(this.request);
        this.dashboard = new DashboardService(this.request);
        this.dashboardRequests = new DashboardRequestsService(this.request);
        this.export = new ExportService(this.request);
        this.kycRequests = new KycRequestsService(this.request);
        this.merchants = new MerchantsService(this.request);
        this.notifications = new NotificationsService(this.request);
        this.plugins = new PluginsService(this.request);
        this.socketServer = new SocketServerService(this.request);
        this.transaction = new TransactionService(this.request);
        this.transactions = new TransactionsService(this.request);
        this.userLimits = new UserLimitsService(this.request);
        this.users = new UsersService(this.request);
        this.virtualTerminal = new VirtualTerminalService(this.request);
    }
}


import {Box, Skeleton, Stack, Typography} from "@mui/material";
import {ReactComponent as LocationIcon} from "assets/iconsDS/location.svg";
import {ReactComponent as HouseIcon} from "assets/iconsDS/house.svg";
import {ReactComponent as PhoneIcon} from "assets/iconsDS/telephone.svg";
import {ReactComponent as IPIcon} from "assets/iconsDS/modern-tv.svg";
import {useTheme} from "@mui/material/styles";
import {WrapperBox} from "shared/styled/WrapperBox";


const SkeletonCustomerInfo = () => {
  const theme = useTheme();

  return(
    <WrapperBox>
      <Stack
        direction="row"
        className="parentBox"
        alignItems='flex-end'
        spacing={1}
        height='16px'
      >
        <Box sx={{width: '33.3333%'}}>
          <Skeleton variant="rounded" height={8} />
        </Box>
        <Box sx={{width: '33.3333%'}}>
          <Skeleton variant="rounded" height={8} />
        </Box>
        <Box sx={{width: '33.3333%'}}>
          <Skeleton variant="rounded" height={8} />
        </Box>
      </Stack>

      <Stack gap='10px'>
        <Box marginTop='24px'>
          <Box
            sx={{
              display: 'flex',
              padding: '16px 12px',
              flexDirection: 'column',
              borderRadius: '8px',
              background: '#F9F9F7',
            }}
            className="contact-info-container"
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', '&:not(:last-child)': { marginBottom: '12px' } }}>
              <LocationIcon
                style={{
                  width: '20px',
                  height: '20px',
                  fill: theme.palette.themeColors.grey['80']
                }}
              />
              <Typography variant="B4" sx={{ color: theme.palette.secondary.main }}>
                <Skeleton variant="rounded" width={220} height={16} />
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', '&:not(:last-child)': { marginBottom: '12px' } }}>
              <HouseIcon
                style={{
                  width: '20px',
                  height: '20px',
                  fill: theme.palette.themeColors.grey['80']
                }}
              />
              <Typography variant="B4" sx={{ color: theme.palette.secondary.main }}>
                <Skeleton variant="rounded" width={220} height={16} />
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', '&:not(:last-child)': { marginBottom: '12px' } }}>
              <PhoneIcon
                style={{
                  width: '20px',
                  height: '20px',
                  fill: theme.palette.themeColors.grey['80']
                }}
              />
              <Typography variant="B4" sx={{ color: theme.palette.secondary.main }}>
                <Skeleton variant="rounded" width={200} height={16} />
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', '&:not(:last-child)': { marginBottom: '12px' } }}>
              <IPIcon
                style={{
                  width: '20px',
                  height: '20px',
                  fill: theme.palette.themeColors.grey['80']
                }}
              />
              <Typography variant="B4" sx={{ color: theme.palette.secondary.main }}>
                <Skeleton variant="rounded" width={160} height={16} />
              </Typography>
            </Box>
          </Box>
        </Box>

        <Stack direction='row' justifyContent='end'>
          <Typography variant="B7" sx={{textTransform: 'uppercase', color: theme.palette.themeColors.grey['70']}}>
            0 days
          </Typography>
        </Stack>
      </Stack>
    </WrapperBox>
  )
}

export default SkeletonCustomerInfo;
import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {PluginQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

export const usePluginSidDetails = (sid: string | number) => {
  const {data, isLoading, isFetching} = useQuery({
    queryKey: [PluginQueryKeys.DETAILS, {sid}],
    queryFn: () => ApiClient.plugins.getPluginSidDetails({sid: String(sid)}),
    placeholderData: keepPreviousData,
    enabled: !!sid,
  })

  return {
    data,
    isLoading,
    isFetching,
  }
}
import Box from '@mui/material/Box';
import {ReactComponent as LocationIcon} from "assets/iconsDS/location.svg";
import {ReactComponent as PhoneIcon} from "assets/iconsDS/telephone.svg";
import {ReactComponent as IPIcon} from "assets/iconsDS/modern-tv.svg";

import type {TransactionDetailModel} from "generatedTypes";
import {InfoItem} from "shared/components/InfoItem";

interface ContactInfoProps {
  address?: TransactionDetailModel['full_address'];
  phoneNumber?: TransactionDetailModel['phone'];
  ipAddress?: TransactionDetailModel['customer_ip_address'];
}

const ContactInfo = ({ address, phoneNumber, ipAddress }: ContactInfoProps) => (
  <Box
    sx={{
      display: 'flex',
      padding: '16px 12px',
      flexDirection: 'column',
      borderRadius: '8px',
      background: '#F9F9F7',
    }}
    className="contact-info-container"
  >
    {address && <InfoItem Icon={LocationIcon} text={address}/>}
    {phoneNumber && <InfoItem Icon={PhoneIcon} text={phoneNumber}/>}
    {ipAddress && <InfoItem Icon={IPIcon} text={ipAddress}/>}
  </Box>
);

export default ContactInfo;

import {styled} from "@mui/material/styles";
import {Button, ButtonProps} from "@mui/material";

interface BreadcrumbItemButtonProps extends ButtonProps {
  active?: boolean;
  to?: string;
}

const BreadcrumbItem = styled(Button)<BreadcrumbItemButtonProps>(({theme, active}) => ({
  ...theme.typography.B4,
  color: theme.palette.themeColors.grey['80'],
  fontWeight: 500,
  textTransform: 'unset',
  ...(active && {
    color: theme.palette.common.black,
  }),
  '&:hover': {
    ...(active ? {
      backgroundColor: 'inherit',
      cursor: 'default',
    } : {
      backgroundColor: theme.palette.themeColors.grey['30'],
      color: theme.palette.themeColors.grey['90'],
    }),
  },
  '&:focus': {
    ...(active ? {
      outline: 'none',
    } : {
      backgroundColor: theme.palette.themeColors.grey['30'],
      color: theme.palette.themeColors.grey['90'],
    }),
  },
  '&:disabled': {
    color: theme.palette.themeColors.grey['90'],
    backgroundColor: theme.palette.themeColors.grey['60'],
    cursor: 'not-allowed',
  },
}));

export default BreadcrumbItem;

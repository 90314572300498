/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Autocomplete_MIDS_Schema } from '../models/Autocomplete_MIDS_Schema';
import type { Autocomplete_RIDS_Schema } from '../models/Autocomplete_RIDS_Schema';
import type { Autocomplete_SIDS_Schema } from '../models/Autocomplete_SIDS_Schema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AutocompleteService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns Autocomplete_MIDS_Schema Success
     * @throws ApiError
     */
    public getMidAutocomplete({
        page,
        perPage,
        search,
        xFields,
    }: {
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * Search by sid or name
         */
        search?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<Autocomplete_MIDS_Schema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/auto-complete/mids/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
                'search': search,
            },
        });
    }
    /**
     * @returns Autocomplete_RIDS_Schema Success
     * @throws ApiError
     */
    public getRidAutocomplete({
        page,
        perPage,
        search,
        xFields,
    }: {
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * Search by sid or name
         */
        search?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<Autocomplete_RIDS_Schema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/auto-complete/rids/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
                'search': search,
            },
        });
    }
    /**
     * @returns Autocomplete_SIDS_Schema Success
     * @throws ApiError
     */
    public getSidAutocomplete({
        page,
        perPage,
        search,
        xFields,
    }: {
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * Search by sid or name
         */
        search?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<Autocomplete_SIDS_Schema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/auto-complete/sids/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
                'search': search,
            },
        });
    }
}

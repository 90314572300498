import React from "react";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface IconTextPairProps {
  Icon: React.ElementType;
  text: string;
}

export const InfoItem = ({ Icon, text }: IconTextPairProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', '&:not(:last-child)': { marginBottom: '12px' } }}>
      <Icon
        style={{
          width: '20px',
          height: '20px',
          fill: theme.palette.themeColors.grey['80']
        }}
      />
      <Typography
        variant="B4"
        sx={{
          color: theme.palette.secondary.main,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

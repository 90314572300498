import {FormControl, FormHelperText, InputAdornment, InputBase, InputLabel} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {NumericFormat, NumericFormatProps} from 'react-number-format';
import React from "react";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const {onChange, ...other} = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        decimalScale={2}
        fixedDecimalScale
      />
    );
  },
);


type SelectFieldsProps = {
  name: string,
  label?: string,
  placeholder?: string,
  type?: "text" | "email" | "number" | "password",
  maxLength?: number,
  multiline?: boolean
  minRows?: number
  isReadOnly?: boolean,
  startIcon?: React.ReactNode,
  endIcon?: React.ReactNode,
  variant?: 'filled' | 'outlined' | 'standard',
  inputRef?: React.Ref<HTMLInputElement>
}

const getInputProps = (type: string) => {
  if (type === 'number') {
    return {
      inputComponent: NumericFormatCustom as any,
      type: 'text',
    };
  }
  return {
    maxLength: 255,
    type,
  };
};

const TextFormField = (
  {
    name,
    label,
    placeholder,
    type = 'text',
    maxLength = 255,
    multiline = false,
    minRows = 3,
    isReadOnly = false,
    startIcon,
    endIcon,
    variant = 'outlined',
    inputRef
  }: SelectFieldsProps
) => {
  const {control} = useFormContext();

  const inputProps = getInputProps(type);

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <FormControl variant={variant} fullWidth>
          {label && (
            <InputLabel shrink htmlFor={`${name}-field`}>
              {label}
            </InputLabel>
          )}
          <InputBase
            {...field}
            id={`${name}-field`}
            inputProps={{maxLength}}
            placeholder={placeholder}
            error={fieldState.invalid}
            multiline={multiline}
            minRows={minRows}
            disabled={isReadOnly}
            startAdornment={startIcon ? <InputAdornment position="start">{startIcon}</InputAdornment> : null}
            endAdornment={endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null}
            inputRef={inputRef}
            {...inputProps}
          />
          {fieldState.error && (
            <FormHelperText error>{fieldState.error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default TextFormField;

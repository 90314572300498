import React from 'react';
import DSTextField from "shared/components/DS/DSTextField";
import {useTheme} from "@mui/material/styles";
import {ReactComponent as LupeIcon} from "assets/iconsDS/text-field-loupe.svg";

interface SearchTextFieldProps {
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchTextField: React.FC<SearchTextFieldProps> = ({placeholder, value, onChange}) => {
  const theme = useTheme();

  return (
    <DSTextField
      value={value}
      onChange={onChange}
      placeholder={placeholder || 'Search'}
      startIcon={<LupeIcon/>}
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor: theme.palette.common.white,
          borderColor: theme.palette.themeColors.grey['40'],
        },
        '& .MuiInputBase-root:not(.Mui-focused):has(.MuiInputBase-input:not([value=""]))': {
          backgroundColor: theme.palette.common.white,
          borderColor: theme.palette.themeColors.grey['40'],
        },
      }}
    />
  );
};

export default SearchTextField;

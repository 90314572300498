import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {TransactionQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";
import {useParams} from "react-router-dom";

export const useTransactionTimeline = () => {
  const {transactionId} = useParams()

  const {data, isLoading} = useQuery({
    queryKey: [TransactionQueryKeys.TIMELINE, {txn: transactionId}],
    queryFn: () => ApiClient.transaction.getTimeline({txn: transactionId || ''}),
    placeholderData: keepPreviousData,
  })

  return {
    data,
    isLoading
  }
}
import {DialogStateHandler} from "shared/hooks/useDialogState";
import UniversalDialog from "shared/components/DS/UniversalDialog";
import {FormProvider, useForm} from "react-hook-form";
import TextFormField from "shared/components/formFields/TextFormField";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Stack, Typography} from "@mui/material";
import {ReactComponent as RefundIcon} from 'assets/iconsDS/refund.svg';
import {useTheme} from "@mui/material/styles";
import {
  useRefundMutation
} from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/partials/ActionRefundDialog/useDataMutations";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {ReactComponent as DollarIcon} from "assets/iconsDS/dollar.svg";


interface ActionRefundProps {
  dialogStateHandler: DialogStateHandler
  action: 'refund' | 'request-refund'
}

const notification = {
  'refund': 'Refund request successful',
  'request-refund': 'Request refund successful',
}

const schema = yup.object().shape({
  amount: yup.number().required('Amount is required'),
})
type FormData = yup.InferType<typeof schema>;

const ActionRefundDialog = ({dialogStateHandler, action}: ActionRefundProps) => {
  const {transactionId} = useParams();

  const theme = useTheme();
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const {mutate, isPending} = useRefundMutation({dialogStateHandler, notification: notification[action]});

  const onSubmit = (data: FormData) => {
    if (transactionId) {
      mutate({
        txn: transactionId,
        requestBody: {
          amount: data.amount,
          action,
        }
      })
    }
  }

  useEffect(() => () => methods.reset(), [])

  return (
    <UniversalDialog
      dialogStateHandler={dialogStateHandler}
      title="Refund Transaction"
      cancelButtonText="Cancel"
      submitButtonText="Confirm"
      onSubmit={methods.handleSubmit(onSubmit)}
      onCancel={dialogStateHandler.closeDialog}
      isPending={isPending}
      dialogSX={{
        '& .MuiDialog-paper': {
          maxWidth: '400px',
        }
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          padding: '24px 0',
        }}
      >
        <Stack
          sx={{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            backgroundColor: 'primary.main',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(3.5),
          }}
        >
          <RefundIcon/>
        </Stack>
        <Typography
          variant="B1"
          component={Box}
          sx={{
            textAlign: 'center',
            marginBottom: theme.spacing(3.5),
          }}
        >
          Are you sure you would like to request a refund for this transaction?
        </Typography>
        <FormProvider {...methods}>
          <TextFormField name="amount" label="Amount" type="number" startIcon={<DollarIcon/>}/>
        </FormProvider>
      </Stack>
    </UniversalDialog>
  );
};

export default ActionRefundDialog;

import {useHasAccess} from "contexts/UserAccessContext";
import {Permissions, Roles} from "contexts/UserAccessContext/const";

const usePermissions = () => {
  const canUseVirtualTerminal = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.USE_VIRTUAL_TERMINAL]
    }
  ])

  return {
    canUseVirtualTerminal
  }
}

export default usePermissions

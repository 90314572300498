import {SocketServerRecentTransactions} from "generatedTypes";
import {Box, ListItem, ListItemText, Link as MuiLink} from "@mui/material";
import {fCurrency} from "shared/utils";
import {getFormattedDistance} from "shared/utils/dateFormats";
import {useMemo} from "react";
import {Link} from "react-router-dom";
import uiPaths from "routes/uiPaths";
import parse from "date-fns/parse";
import truncate from "lodash/truncate";

interface EventNewTransactionProps {
  item: SocketServerRecentTransactions;
}

const EventTransaction = ({item}: EventNewTransactionProps) => {

  const isNew = useMemo(() => item.status !== 'pending' && !item.changes, [item]);
  const isPaymentApproved = useMemo(() => item.status === 'approved' && item.changes, [item]);
  const isPayoutClaimed = useMemo(() => item.type === 'payout' && item.status === 'claimed' && item.changes, [item]);

  if (!isNew && !isPaymentApproved && !isPayoutClaimed) return null;

  const timestampDate = parse(item.timestamp,  "yyyy-MM-dd HH:mm:ss.SSSSSS", new Date());
  const formattedDistance = item.timestamp ? getFormattedDistance({fromDate: timestampDate, toDate: new Date(), convertToDateToUtc: true}): null;

  const name = truncate(item.name, {length: 30, omission: '...'})

  return (
    <ListItem
      key={item.txn}
      disableGutters
      secondaryAction={formattedDistance}
    >
      <MuiLink component={Link} to={uiPaths.transactionPaths.detailNotesTab.reverse({transactionId: item.txn})}>
        <ListItemText
          primary={
            <>
              {isNew && <Box><strong>{fCurrency(item.amount)} {item.currency_code}</strong> {name} created {item.type} for {item.site_name}</Box>}
              {isPaymentApproved && <Box><strong>{fCurrency(item.amount)} {item.currency_code}</strong> paid by {name} to {item.site_name}</Box>}
              {isPayoutClaimed && <Box><strong>{fCurrency(item.amount)} {item.currency_code}</strong> claimed by {name} from {item.site_name}</Box>}
            </>
          }
        />
      </MuiLink>
    </ListItem>
  )
}

export default EventTransaction;

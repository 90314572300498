import axios, {AxiosResponse} from "axios";
import {
  LoginCredentials,
  LoginResponse,
  Select2FAargs,
  SelectTwoFAResponse,
  Validate2FAargs,
  ValidateTwoFAResponse
} from "./types";

export const loginMutation = async ({email, password}: LoginCredentials) => {
  const {data} = await axios.post<LoginResponse>('/api/auth/login/', {email, password})
  return data
}


export const select2FAMutation = async ({selected_method, user_email}: Select2FAargs): Promise<AxiosResponse<SelectTwoFAResponse>> => {
  const response = await axios.post('/api/auth/set-two-fa-method/', {selected_method, email: user_email})
  return response
}

export const validate2FAMutation = async ({code, email}: Validate2FAargs): Promise<AxiosResponse<ValidateTwoFAResponse>> => {
  const response = await axios.post('/api/auth/validate-2fa/', {code, email})
  return response
}

import {CustomCellRendererProps} from "@ag-grid-community/react";
import {Box} from "@mui/material";
import {formatPhoneNumber} from "shared/utils/formats";

const PhoneRenderer = (params: CustomCellRendererProps) => {
  if (!params.value) return null;

  return (
    <Box component='span'>
      {formatPhoneNumber(params.value as string)}
    </Box>
  )
}

export default PhoneRenderer;

import RoutePath from "routes/utils";

export default {
  transactionChart: new RoutePath('/api/customers/:customerEmail/transactions/chart/'),
  details: new RoutePath('/api/customers/:customerEmail/details/'),
  financialDetails: new RoutePath('/api/customers/:customerEmail/financial-details/'),
  notes: new RoutePath('/api/customers/:customerEmail/notes/'),
  cards: new RoutePath('/api/customers/:customerEmail/cards/'),
  blacklist: new RoutePath('/api/customers/:customerEmail/blacklist/'),
  deleteBlacklist: new RoutePath('/api/customers/:customerEmail/blacklist/'),
  recent_orders: new RoutePath('/api/customers/:customerEmail/recent-orders/'),
  information: new RoutePath('/api/customers/:customerEmail/information/'),
  accounts: new RoutePath('/api/customers/:customerEmail/accounts/'),
  merchants: new RoutePath('/api/customers/:customerEmail/merchants/'),
  retailers: new RoutePath('/api/customers/:customerEmail/retailers/'),
  sites: new RoutePath('/api/customers/:customerEmail/sites/'),
  top_merchants: new RoutePath('/api/customers/:customerEmail/top-merchants/'),
}

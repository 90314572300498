/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Configs_ChargeBackReasons } from '../models/Configs_ChargeBackReasons';
import type { Configs_RefundReasons } from '../models/Configs_RefundReasons';
import type { Configs_UserRoles } from '../models/Configs_UserRoles';
import type { ConfigsPermissionsList } from '../models/ConfigsPermissionsList';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ConfigsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all chargeback reasons
     * Roles: ADMIN, CUSTOMER_SERVICE
     * Permissions: CHARGEBACK
     * @returns Configs_ChargeBackReasons Success
     * @throws ApiError
     */
    public getTransactionChargeBackReasons({
        xFields,
    }: {
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<Configs_ChargeBackReasons> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/configs/chargeback-reasons/',
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Get all permissions
     * @returns ConfigsPermissionsList Success
     * @throws ApiError
     */
    public getPermissionsList({
        xFields,
    }: {
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<ConfigsPermissionsList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/configs/permissions/',
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * Get all refund transaction reasons
     * Roles: ADMIN, CUSTOMER_SERVICE
     * Permissions: REFUND_TRANSACTION
     * @returns Configs_RefundReasons Success
     * @throws ApiError
     */
    public getTransactionRefundReasons({
        xFields,
    }: {
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<Configs_RefundReasons> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/configs/refund-transaction-reasons/',
            headers: {
                'X-Fields': xFields,
            },
        });
    }
    /**
     * @returns Configs_UserRoles Success
     * @throws ApiError
     */
    public getUserRoles({
        xFields,
    }: {
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<Configs_UserRoles> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/configs/user-roles/',
            headers: {
                'X-Fields': xFields,
            },
        });
    }
}

import {useMutation, useQueryClient} from "@tanstack/react-query";
import {updateTimezoneMutation} from "apps/account/mutations";
import {AccountQueryKeys} from "shared/queryKeys";
import {CurrentUserSchema} from "generatedTypes";

const useUpdateTimezone = () => {
  const queryClient = useQueryClient()

  const {mutate} = useMutation({
    mutationFn: updateTimezoneMutation,
    onMutate: async (updateTimezone) => {
      await queryClient.cancelQueries({queryKey: [AccountQueryKeys.CURRENT_USER]});
      const currentUser = queryClient.getQueryData([AccountQueryKeys.CURRENT_USER]) as CurrentUserSchema;
      const updatedUser = {
        ...currentUser,
        timezone: updateTimezone.timezone
      };
      queryClient.setQueryData([AccountQueryKeys.CURRENT_USER], updatedUser);
      return {currentUser, updateTimezone};
    },
    onSettled: async () => {
      await queryClient.invalidateQueries();
    },
  });

  return {mutate};
}

export default useUpdateTimezone;
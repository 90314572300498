import {useEffect} from "react";
import useFilters from "apps/KYCRequests/pages/list/useFilters";
import {useKycRequestsList} from "apps/KYCRequests/pages/list/useDataQueries";

type DataFiltersParams = {
  currentPage: number;
  perPage: number;
}

const useDataFilters = ({currentPage, perPage}: DataFiltersParams) => {
  const {filterData, filters, submitCount} = useFilters()

  const {data, refetch, isLoading, isFetching} = useKycRequestsList({
    page: currentPage,
    perPage,
    search: filterData.search,
    status: filterData.status,
    sid: filterData.sid,
  });

  useEffect(() => {
    void refetch();
  }, [submitCount]);

  return {
    data,
    isLoading,
    isFetching,
    filters,
  }
}

export default useDataFilters;
import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {UserQueryKeys} from "shared/queryKeys";
import {useParams, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

type Params = Parameters<typeof ApiClient.users.postUserEmailVerification>[0]

export const useEmailVerification = () => {
  const {userId} = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [isVerified, setIsVerified] = useState(false)

  const {mutate, isPending} = useMutation({
    mutationFn: (params: Params) => ApiClient.users.postUserEmailVerification(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: [UserQueryKeys.DETAILS, {userId}]})
      setIsVerified(true)
    }
  })

  useEffect(() => {
    if (isVerified) {
      navigate(`/users/${userId}/edit/`)
    }
  }, [isVerified, userId]);

  return {
    mutate,
    isPending,
  }
}
import {Box, Button, LinearProgress, Table, TableBody, TableCell, TableContainer, TableFooter} from "@mui/material";
import useDialogState from "shared/hooks/useDialogState";
import {AppCard, AppTableHead} from "shared/components/mui";
import CreateUserLimitOrBlacklistDialog from "apps/userLimits/pages/create";
import usePermissions from "apps/userLimits/pages/permissions/usePermissions";
import usePagination from "shared/hooks/usePagination";
import useSorting from "shared/hooks/useSorting";
import LoaderBackdrop from "shared/components/LoaderBackdrop";
import useDataFilters from "apps/userLimits/pages/list/useDataFilters";
import UserLimitRow from "apps/userLimits/pages/list/UserLimitRow";
import PermissionError from "shared/PermissionError";

const FIRST_TABLE_HEAD = [
  {'label': '', 'colspan': 2},
  {'label': 'Payment Limits', 'colspan': 5},
  {'label': 'Payout Limits', 'colspan': 5},
  {'label': 'Blacklisted', 'colspan': 3},
  {'label': '', 'colspan': 3},
]

const TABLE_HEAD = [
  {id: 'contact', label: 'CONTACT', sortable: true},
  {id: 'sid', label: 'SITE', sortable: true},
  {id: 'payment_limits.24h_limit', label: '24 hours', sortable: true},
  {id: 'payment_limits.7d_limit', label: '7 days', sortable: true},
  {id: 'payment_limits.30d_limit', label: '30 days', sortable: true},
  {id: 'payment_limits.transaction_limit', label: 'Transaction', sortable: true},
  {id: 'payment_limits.verified', label: 'Limit Verified', sortable: true},
  {id: 'payout_limits.24h_limit', label: '24 hours', sortable: true},
  {id: 'payout_limits.7d_limit', label: '7 days', sortable: true},
  {id: 'payout_limits.30d_limit', label: '30 days', sortable: true},
  {id: 'payout_limits.transaction_limit', label: 'Transaction', sortable: true},
  {id: 'payout_limits.verified', label: 'Limit Verified', sortable: true},
  {id: 'blacklisted', label: 'Blacklisted', sortable: true},
  {id: 'expiry', label: 'Expiry', sortable: true},
  {id: 'blacklist_status_updated', label: 'Status Updated At', sortable: true},
  {id: 'limits_created', label: 'Created At', sortable: true},
  {id: 'limits_updated', label: 'Updated At', sortable: true},
  {id: 'actions', label: ''},
]

const UserLimits = () => {
  const {currentPage, perPage, pagination} = usePagination();

  const createUserLimitOrBlacklist = useDialogState();

  const {canCreateUpdateDeleteUserLimit, canViewUserLimitOrBlacklist} = usePermissions();

  const {
    orderDirection,
    orderBy,
    orderByField,
    handleOrderBy,
  } = useSorting({defaultOrderBy: 'limits_created'});

  const {data, isLoading, isFetching, filters} = useDataFilters({currentPage, perPage, orderBy: orderByField});

  if (!canViewUserLimitOrBlacklist) return <PermissionError/>

  if (isLoading) return <LinearProgress/>

  return (
    <>
      <AppCard
        title='User Limits'
        action={
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            {canCreateUpdateDeleteUserLimit && (
              <Button
                variant='contained'
                color='primary'
                onClick={createUserLimitOrBlacklist.openDialog}
                sx={{mr: 1}}
              >
                Create
              </Button>
            )}
            {filters}
          </Box>
        }
      >

        <TableContainer>
          <Table size='small' sx={{bgcolor: '#fff'}}>
            <AppTableHead
              firstHeadLabel={FIRST_TABLE_HEAD}
              headLabel={TABLE_HEAD}
              order={orderDirection}
              orderBy={orderBy}
              onSort={handleOrderBy}
            />

            <TableBody sx={{position: 'relative'}}>
              <LoaderBackdrop open={!isLoading && isFetching}/>
              {data?.items?.map((row) => (
                <UserLimitRow data={row}/>
              ))}
            </TableBody>

            <TableFooter>
              <TableCell colSpan={9} align='right' sx={{py: 1, border: 'none'}}>
                {pagination({total: data?.total, pages: data?.pages})}
              </TableCell>
            </TableFooter>
          </Table>
        </TableContainer>

      </AppCard>

      <CreateUserLimitOrBlacklistDialog dialogStateHandler={createUserLimitOrBlacklist} />
    </>
  )
}

export default UserLimits;
import {Box, Typography} from "@mui/material";
import {fCurrency} from "shared/utils";
import {useTheme} from "@mui/material/styles";

interface StatsItemProps {
  title: string
  value: any
}

const StatsItem = ({title, value}: StatsItemProps) => {
  const theme = useTheme()
  const formattedValue = fCurrency(value);

  return (
    <Box sx={{borderRadius: '8px', width: '100%', padding: '8px 12px', backgroundColor: theme.palette.themeColors.grey['20']}}>
      <Typography variant='B4' sx={{color: theme.palette.themeColors.grey['90'], fontWeight: 400}}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: '20px', fontWeight: 700, color: theme.palette.secondary.main, display: 'flex'}}>
        {formattedValue.split('.')[0]}
        <Typography sx={{ fontSize: '20px', fontWeight: 700, color: theme.palette.themeColors.grey['80'] }}>
          .{formattedValue.split('.')[1]}
        </Typography>
      </Typography>
    </Box>
  )
}

export default StatsItem;
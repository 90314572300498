import {useSiteSharePaymentDataQueries} from "apps/dashboard/pages/Dashboard/partials/SiteSharePayment/useDataQueries";
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import {AppTableHead, AppTableRow} from "shared/components/mui";
import getImageURL from "apps/transactions/pages/TransactionDetails/urls";
import NoRecordsFound from "shared/components/NoRecordsFound";
import {isEmpty, round} from "lodash";
import {fAvatar} from "shared/utils/formats";
import type {DashboardSiteBreakdown} from "generatedTypes";
import {useState} from "react";
import {ChartTypes} from "apps/dashboard/pages/Dashboard/enums";

const TABLE_HEAD = [
  {id: 'name', label: 'NAME'},
  {id: 'amount', label: 'amount', align: 'right'},
]

const calculatePercentage = (amount: number, total: number) => (amount / total) * 100


const SiteSharePayment = () => {
  const [activeChart, setActiveChart] = useState<ChartTypes>(ChartTypes.AMOUNT);
  const {data} = useSiteSharePaymentDataQueries({activeChart})

  if (isEmpty(data?.site_breakdown)) {
    return <Card><NoRecordsFound text='No records found'/></Card>
  }

  const firstAmountTotal = data?.site_breakdown?.[0].amount_total;
  const firstCountTotal = data?.site_breakdown?.[0].count_total;

  const handleChartChange = (event: React.MouseEvent<HTMLElement>, newType: ChartTypes) => {
    setActiveChart(newType)
  }


  const getPercentage = (el: DashboardSiteBreakdown) => {
    if (activeChart === ChartTypes.COUNT) {
      return calculatePercentage(el.count_total || 0, firstCountTotal || 0)
    }

    return calculatePercentage(el.amount_total || 0, firstAmountTotal || 0)
  }

  const getCount = (el: DashboardSiteBreakdown) => {
    if (activeChart === ChartTypes.COUNT && el.count_total) {
      return round(el.count_total, 2)
    }

    if (activeChart === ChartTypes.AMOUNT && el.amount_total) {
      return round(el.amount_total, 2)
    }
    return null
  }

  if (data?.site_breakdown && data?.site_breakdown.length < 2) return null;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
            Site Share Payments
      </Typography>
      <Card>
        <ToggleButtonGroup
          value={activeChart}
          exclusive
          onChange={handleChartChange}
          aria-label="text alignment"
        >
          <ToggleButton value={ChartTypes.AMOUNT} aria-label="left aligned" size='small'>
          $
          </ToggleButton>
          <ToggleButton value={ChartTypes.COUNT} aria-label="centered" size='small'>
          #
          </ToggleButton>
        </ToggleButtonGroup>
        <TableContainer sx={{overflow: 'unset'}}>
          <Table sx={{bgcolor: '#fff'}} size='small'>
            <AppTableHead headLabel={TABLE_HEAD}/>

            <TableBody>
              {data?.site_breakdown?.map((el) => (
                <AppTableRow
                  key={el.sid}
                  progress={getPercentage(el)}
                  data-progress={getPercentage(el)}
                >
                  <TableCell>
                    <Box display='flex' alignItems='center'>
                      <Avatar
                        sx={{width: 20, height: 20, fontSize: 14}}
                        src={getImageURL(el.sid)}
                        variant='rounded'
                      >
                        {fAvatar(el.site_name)}
                      </Avatar>
                      <Typography lineHeight={0} ml={1} variant='subtitle2'>
                        {el.site_name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align='right'>
                    {getCount(el)}
                  </TableCell>
                </AppTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};

export default SiteSharePayment;

import useTerminalPaymentMethods from "apps/virtualTerminal/pages/VirtualTerminal/TerminalPaymentMethods/useDataQuery";
import ToggleButtonsFormField from "shared/components/formFields/ToggleButtonsFormField";
import {LinearProgress, Typography} from "@mui/material";
import {isEmpty} from "lodash";

const TerminalPaymentMethods = () => {
  const {data, isLoading} = useTerminalPaymentMethods();
  const enabledMethodOptions = data?.methods?.map(method => ({value: method, label: method})) || [];

  return isEmpty(enabledMethodOptions)
    ? (
      <>
        <Typography variant="subtitle2">
                Please select a site and payment type to get available payment methods.
        </Typography>
        {isLoading && <LinearProgress />}
      </>
    )
    : <ToggleButtonsFormField name='payment_method' options={enabledMethodOptions} />
};

export default TerminalPaymentMethods;

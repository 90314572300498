import {Alert, Box, Button, Grid, Tab} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {
  emailValidator,
  firstNameValidator,
  lastNameValidator,
  newPasswordValidator,
  phoneValidator
} from "shared/schemas";

import TextFormField from "shared/components/formFields/TextFormField";
import PhoneFormField from "shared/components/formFields/PhoneFormField";
import CheckboxFormField from "shared/components/formFields/CheckboxFormField";
import PasswordFormField from "shared/components/formFields/PasswordFormField";
import RolesSelectFormField from "shared/components/formDataFields/RolesSelectFormField";
import useCreateUser from "apps/users/pages/create/useDataMutations";
import {AppCard} from "shared/components/mui";
import usePermissions from "apps/users/pages/UsersList/permissions/usePermissions";
import PermissionError from "shared/PermissionError";
import LoadingButton from "@mui/lab/LoadingButton";
import AccessTo from "apps/users/pages/create/partials/AccessTo/AccessTo";
import Permissions from "apps/users/pages/create/partials/Permissions/Permissions";
import TabList from "@mui/lab/TabList";
import { TabPanel, TabContext } from "@mui/lab";
import {useEffect, useState} from "react";

const schema = yup.object().shape({
  role: yup.string().required('This field is required.'),
  firstName: firstNameValidator.required('This field is required.'),
  lastName: lastNameValidator.required('This field is required.'),
  email: emailValidator.required('This field is required.'),
  phone: phoneValidator,
  send_password_via_email: yup.boolean().required('This field is required.'),
  password: yup.string().when(
    'send_password_via_email',
    (send_password_via_email, _schema) => send_password_via_email[0] ? _schema : newPasswordValidator.required('This field is required.')
  ),
  confirm_password: yup.string().oneOf([yup.ref('password')], 'Passwords must match').when(
    'send_password_via_email',
    (send_password_via_email, _schema) => send_password_via_email[0] ? _schema : newPasswordValidator.required('This field is required.')
  ),
  mid: yup.string(),
  sid: yup.string(),
  rid: yup.string(),

  permissions: yup.object().json(),
});

type FormData = yup.InferType<typeof schema>;

const defaultValues: FormData = {
  role: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  send_password_via_email: true,
  password: '',
  confirm_password: '',
  mid: '',
  sid: '',
  rid: '',
  permissions: {},
};

const UserCreate = () => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  })

  const [tab, setTab] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const {
    mutate,
    isPending,
    phoneErrorMessage,
    emailErrorMessage,
    errorMessage
  } = useCreateUser(methods.reset)

  const onSubmit = (data: FormData) => {
    // @ts-ignore
    const permissions = Object.keys(data.permissions).filter(key => data.permissions[key] === true)

    mutate({
      formData: {
        role: data.role,
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone: data.phone,
        send_password_via_email: data.send_password_via_email,
        password: data.password,
        confirm_password: data.confirm_password,
        permissions,
        mid: data.mid,
        rid: data.rid,
        sid: data.sid,
      }
    })
  }

  const handleReset = () => {
    methods.reset(methods.formState.defaultValues);
  }

  const clearAccessToSelection = () => {
    methods.setValue('mid', '');
    methods.setValue('rid', '');
    methods.setValue('sid', '');
  };

  const {canViewUserList} = usePermissions();

  const selectedRole = methods.watch('role');

  useEffect(() => {
    clearAccessToSelection();
  }, [selectedRole]);

  if (!canViewUserList){
    return <PermissionError />
  }

  return (
    <FormProvider {...methods}>
      <Grid container spacing={3}>
        <Grid item lg={5}>
          <AppCard
            headStyle={{pb: 0}}
            bodyStyle={{m: 3}}
            sx={{pb: 0}}
          >
            <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12}>
                  <RolesSelectFormField name='role' label='Role'/>
                </Grid>
                <Grid item lg={6} md={6}>
                  <TextFormField name='firstName' label='First Name' />
                </Grid>
                <Grid item lg={6} md={6}>
                  <TextFormField name='lastName' label='Last Name' />
                </Grid>
                <Grid item lg={6} md={6}>
                  <TextFormField name='email' label='Email' />
                </Grid>
                <Grid item lg={6} md={6}>
                  <PhoneFormField name='phone' label='Phone' />
                </Grid>
                <Grid item lg={12} md={12}>
                  <CheckboxFormField name='send_password_via_email' label='Send password via email' />
                </Grid>
                {!methods.watch('send_password_via_email') && (
                  <>
                    <Grid item lg={6} md={6}>
                      <PasswordFormField name='password' placeholder='Password'/>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <PasswordFormField name='confirm_password' placeholder='Confirm Password'/>
                    </Grid>
                  </>
                )}
              </Grid>

              {phoneErrorMessage && <Alert severity="error" sx={{mt: 1}}>{phoneErrorMessage}</Alert>}
              {emailErrorMessage && <Alert severity="error" sx={{mt: 1}}>{emailErrorMessage}</Alert>}
              {errorMessage && <Alert severity="error" sx={{mt: 1}}>{errorMessage}</Alert>}

              <Box mt={3} mb={2} display='flex' justifyContent='space-between'>
                <Button onClick={handleReset} variant='outlined'>Reset</Button>
                <LoadingButton
                  loading={isPending}
                  variant="contained"
                  loadingIndicator="Submitting…"
                  type="submit"
                  disabled={!methods.formState.isDirty || !methods.formState.isValid}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          </AppCard>
        </Grid>

        <Grid item lg={7}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Access to" value="1" />
                <Tab label="Permissions" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <AccessTo clearSelection={clearAccessToSelection} />
            </TabPanel>
            <TabPanel value="2">
              <Permissions />
            </TabPanel>
          </TabContext>
        </Grid>

      </Grid>
    </FormProvider>
  );
}

export default UserCreate;

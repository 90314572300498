import {Alert, Card, Divider, Grid} from "@mui/material";
import SidAutocompleteFormField from "shared/components/formDataFields/SidAutocompleteFormField";
import TextFormField from "shared/components/formFields/TextFormField";
import SwitchFormField from "shared/components/formFields/SwitchFormField";
import DateTimePickerFormField from "shared/components/formFields/DateTimePickerFormField";
import {useFormContext} from "react-hook-form";
import PhoneFormField from "shared/components/formFields/PhoneFormField";
import FileListSelector from "shared/components/FileListSelector";
import DocumentFieldArray from "shared/components/DocumentFieldArray";
import {startCase, toLower} from "lodash";

interface UpdateUserLimitOrBlacklistFormProps {
  isUpdateForm?: boolean;
  fieldsList: string[];
  visibleGroups: string[];
  handleAddVisibleGroup: (field: string) => void;
  handleRemoveVisibleGroup: (field: string) => void;
  errorMessage?: string;
}

const UpdateUserLimitOrBlacklistForm = ({
  isUpdateForm,
  fieldsList,
  visibleGroups,
  handleAddVisibleGroup,
  handleRemoveVisibleGroup,
  errorMessage
}: UpdateUserLimitOrBlacklistFormProps) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  const {formState} = useFormContext();

  const {errors} = formState;

  const emailOrPhoneError = errors.emailOrPhone?.message as string;
  const paymentLimitError = errors.paymentLimit?.root?.message as string;
  const payoutLimitError = errors.payoutLimit?.root?.message as string;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={4}>
          <SidAutocompleteFormField name="sid" isReadonly={isUpdateForm}/>
        </Grid>
        <Grid item lg={4}>
          <TextFormField label="Email" name="email" isReadOnly={isUpdateForm}/>
        </Grid>
        <Grid item lg={4}>
          <PhoneFormField label="Phone" name="phone" isReadOnly={isUpdateForm}/>
        </Grid>
      </Grid>

      {emailOrPhoneError && (
        <Alert severity="error" sx={{mt: 1}}>{emailOrPhoneError}</Alert>
      )}

      <Divider sx={{mt: 2, mb: 2}}>Payment limits</Divider>

      <Grid container spacing={2}>
        <Grid item lg={3}>
          <TextFormField name="paymentLimit.twentyFourHours" type='number' label="24 hours"/>
        </Grid>
        <Grid item lg={3}>
          <TextFormField name="paymentLimit.sevenDays" type='number' label="7 days"/>
        </Grid>
        <Grid item lg={3}>
          <TextFormField name="paymentLimit.thirtyDays" type='number' label="30 days"/>
        </Grid>
        <Grid item lg={3}>
          <TextFormField name="paymentLimit.perTransaction" type='number' label="Per transaction"/>
        </Grid>
      </Grid>

      {paymentLimitError && (
        <Alert severity="error" sx={{mt: 1}}>{paymentLimitError}</Alert>
      )}

      <Divider sx={{mt: 2, mb: 2}}>Payout limits</Divider>

      <Grid container spacing={2}>
        <Grid item lg={3}>
          <TextFormField name="payoutLimit.twentyFourHours" label="24 hours"/>
        </Grid>
        <Grid item lg={3}>
          <TextFormField name="payoutLimit.sevenDays" label="7 days"/>
        </Grid>
        <Grid item lg={3}>
          <TextFormField name="payoutLimit.thirtyDays" label="30 days"/>
        </Grid>
        <Grid item lg={3}>
          <TextFormField name="payoutLimit.perTransaction" label="Per transaction"/>
        </Grid>
      </Grid>

      {payoutLimitError && (
        <Alert severity="error" sx={{mt: 1}}>{payoutLimitError}</Alert>
      )}

      <Divider sx={{mt: 2, mb: 2}}>Blacklist</Divider>

      <Grid container spacing={2}>
        <Grid item lg={6}>
          <SwitchFormField name="isBlacklisted" label='True/False'/>
        </Grid>
        <Grid item lg={6}>
          <DateTimePickerFormField
            name="expiryDate"
            label='Expiry'
            minDate={currentDate}
          />
        </Grid>
      </Grid>

      {isUpdateForm && (
        <>
          <Divider sx={{mt: 2, mb: 2}}>Documents</Divider>
          {visibleGroups.map((fieldName) =>
            <Card sx={{p: 2, mb: 2}} variant='outlined' key={fieldName}>
              <DocumentFieldArray
                arrayName={fieldName}
                fieldName={fieldName}
                sectionName={startCase(toLower(fieldName))}
                groupsCount={visibleGroups.length}
                handleRemoveVisibleGroup={handleRemoveVisibleGroup}
              />
            </Card>
          )}
          <FileListSelector
            fieldsList={fieldsList}
            visibleGroups={visibleGroups}
            handleAddVisibleGroup={handleAddVisibleGroup}
          />
        </>
      )}

      {errorMessage && (
        <Alert severity="error" sx={{mt: 2}}>{errorMessage}</Alert>
      )}
    </>
  )
}

export default UpdateUserLimitOrBlacklistForm;

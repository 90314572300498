import {DialogStateHandler} from "shared/hooks/useDialogState";
import UniversalDialog from "shared/components/DS/UniversalDialog";
import {Box, Stack, Typography} from "@mui/material";
import {ReactComponent as DangerIcon} from 'assets/iconsDS/danger.svg';
import {useTheme} from "@mui/material/styles";
import {
  useRefundMutation
} from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/partials/ActionCancelDialog/useDataMutations";
import {useParams} from "react-router-dom";
import {CancelActionEnum} from "generatedTypes";

interface ActionRefundProps {
  dialogStateHandler: DialogStateHandler
  action: CancelActionEnum
}

const notification = {
  [CancelActionEnum.CANCEL]: 'Cancel request successful',
  [CancelActionEnum.REQUEST_CANCEL]: 'Request cancel successful',
}

const ActionCancelDialog = ({dialogStateHandler, action}: ActionRefundProps) => {
  const {transactionId} = useParams();

  const theme = useTheme();

  const {mutate, isPending} = useRefundMutation({dialogStateHandler, notification: notification[action]});

  const onSubmit = () => {
    if (transactionId) {
      mutate({
        txn: transactionId,
        requestBody: {
          action,
        }
      })
    }
  }


  return (
    <UniversalDialog
      dialogStateHandler={dialogStateHandler}
      title="Cancel Transaction"
      cancelButtonText="Cancel"
      submitButtonText="Confirm"
      onSubmitClick={onSubmit}
      isPending={isPending}
      onCancel={dialogStateHandler.closeDialog}
      submitColor="error"
      dialogSX={{
        '& .MuiDialog-paper': {
          maxWidth: '400px',
        }
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          padding: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
        }}
      >
        <Stack
          sx={{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(3.5),
          }}
        >
          <DangerIcon/>
        </Stack>
        <Typography
          variant="B1"
          component={Box}
          sx={{
            textAlign: 'center',
            marginBottom: theme.spacing(1),
          }}
        >
          Are you sure to request cancellation for this transaction?
        </Typography>
        <Typography
          variant="B4"
          sx={{
            color: theme.palette.themeColors.grey[110],
            marginBottom: theme.spacing(3.5),
          }}
        >
          You won’t be able to revert this action
        </Typography>
      </Stack>
    </UniversalDialog>
  );
};

export default ActionCancelDialog;

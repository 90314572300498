import {useEffect, useRef, useState} from "react";
import {ColDef, ColGroupDef} from "@ag-grid-community/core";
import AmountRenderer from "shared/components/AGGridRenderers/AmountRenderer";
import QuantityRenderer from "shared/components/AGGridRenderers/QuantityRenderer";
import {AgGridReact} from "@ag-grid-community/react";
import {ItemModel} from "generatedTypes";

const useColumnsConfig = () => {
  const gridRef = useRef<AgGridReact<ItemModel>>(null);

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const [columnDefs, setColumnDefs] = useState<(ColDef | ColGroupDef)[]>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    const baseColumns: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Item Name',
        field: 'name',
        minWidth: 130,
        sortable: false,
        colSpan: (params) => {
          if (!params.data) {
            return 1;
          }
          const {name} = params.data;
          const long = new Set(["Subtotal", "Shipping", "Total"]);
          if (long.has(name)) {
            return 5;
          }

          return 1;
        },
      },
      {
        headerName: 'SKU#',
        field: 'sku',
        minWidth: 130,
        sortable: false,
      },
      {
        headerName: 'Description',
        field: 'description',
        sortable: false,
      },
      {
        headerName: 'Unit Price',
        field: 'unit_price',
        minWidth: 130,
        cellRenderer: AmountRenderer,
        cellStyle: {textAlign: 'right'},
        sortable: false,
        type: 'rightAligned'
      },
      {
        headerName: 'Quantity',
        field: 'quantity',
        cellRenderer: QuantityRenderer,
        cellStyle: {textAlign: 'right'},
        sortable: false,
        type: 'rightAligned'
      },
      {
        headerName: 'Total',
        field: 'item_total',
        cellRenderer: AmountRenderer,
        cellStyle: {textAlign: 'right'},
        sortable: false,
        type: 'rightAligned'
      },
    ];

    setColumnDefs([...baseColumns]);
  }, []);

  return {
    columnDefs,
    gridRef,
  }
}

export default useColumnsConfig;

import { Components } from '@mui/material/styles';

import palette from "theme/palette";

const MuiDialog: Components['MuiDialog'] =  {
  styleOverrides: {
    paper: {
      padding: '4px',
      border: '0.5px solid',
      borderColor: palette.themeColors.grey['70'],
      borderRadius: '8px',
      boxShadow: "0px 10px 20px -4px rgba(33, 32, 29, 0.15)"
    }
  }
}

export default MuiDialog;

import {Avatar, Skeleton, Stack, Typography} from "@mui/material";
import CustomerActions from "apps/customers/shared/CustomerActions";

const SkeletonCustomerHead = () => (
  <Stack
    direction='row'
    alignItems='center'
    justifyContent='space-between'
    sx={{
      marginBottom: '16px',
    }}
  >
    <Stack
      direction="row"
      gap='12px'
      sx={{
        width: '100%',
      }}
    >
      <Skeleton width={40} height={40} variant="rounded">
        <Avatar variant="rounded"/>
      </Skeleton>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
      >
        <Stack gap='4px'>
          <Typography variant="B4">
            <Skeleton width={120} height={16} variant="rounded" />
          </Typography>
          <Typography variant="B4">
            <Skeleton width={160} height={20} variant="rounded" />
          </Typography>
        </Stack>
        <CustomerActions customerEmail='' />
      </Stack>
    </Stack>
  </Stack>
)

export default SkeletonCustomerHead;

import {useParams} from "react-router-dom";
import {Box, Button, Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {AgGridReact} from "@ag-grid-community/react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ColDef, ModuleRegistry} from "@ag-grid-community/core";
import {FinancialDetail} from "generatedTypes";
import {
  useCustomerFinancialDetails
} from "apps/customers/pages/CustomerDetails/partials/FinancialDetails/useDataQueries";
import useColumnsConfig from "apps/customers/pages/CustomerDetails/partials/FinancialDetails/useColumnsConfig";
// import {ExcelExportModule, MenuModule} from "@ag-grid-enterprise";
// import {ClientSideRowModelModule, CsvExportModule} from "ag-grid-community";
import AGPagination from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/Pagination";
import {ReactComponent as UploadIcon} from "assets/iconsDS/upload.svg";
import SearchTextField from "shared/components/DS/SearchTextField";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {MenuModule} from "@ag-grid-enterprise/menu";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ExcelExportModule,
  MenuModule,
]);

const FinancialDetails = () => {
  const {customerEmail} = useParams();
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const paginationRef = useRef(null);
  const {gridRef, columnDefs} = useColumnsConfig()
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    minWidth: 100,
    resizable: false
  }), []);

  const {data} = useCustomerFinancialDetails({email: customerEmail || ''});

  const onButtonExport = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    if (selectedRows.length > 0) {
      gridRef.current!.api.exportDataAsExcel({onlySelected: true});
    } else {
      gridRef.current!.api.exportDataAsExcel();
    }
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }

  const onFilterTextBoxChanged = useCallback((val: string) => {
    gridRef.current!.api.setGridOption("quickFilterText", val);
  }, []);

  useEffect(() => {
    if (gridRef?.current?.api) {
      onFilterTextBoxChanged(searchValue);
    }
  }, [gridRef?.current?.api, searchValue])

  return (
    <Box
      sx={{width: "100%"}}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{
          marginBottom: '22px',
        }}
      >
        <Stack>
          <Box sx={{display: 'flex', gap: '8px', alignItems: 'center', height: '44px'}}>
            <Typography variant="h3">
              Financial Details
            </Typography>
            <Typography variant="h3" sx={{display: 'flex', color: theme.palette.themeColors.grey['80']}}>
              {data?.results.length}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" spacing={2}>
          <SearchTextField
            value={searchValue}
            onChange={handleSearchChange}
          />
          <Box>
            <Button
              variant='contained'
              color='primary'
              onClick={onButtonExport}
              startIcon={<UploadIcon/>}
              size='medium'
            >
              Export
            </Button>
          </Box>
        </Stack>
      </Stack>

      <Box
        sx={{width: "100%"}}
        className="ag-theme-paybilt"
      >
        <Box height='603px' className="transactions-table-container-ag-theme-paybilt">
          <AgGridReact<FinancialDetail>
            ref={gridRef}
            rowData={data?.results || []}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
            pagination
            paginationPageSize={10}
            paginationPageSizeSelector={[10, 20, 30]}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: 'white',
            border: `1px solid ${theme.palette.themeColors.grey['40']}`,
            borderTop: 'none',
            borderRadius: '8px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            padding: '12px 16px',
          }}
        >
          <AGPagination
            gridRef={gridRef}
            ref={paginationRef}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default FinancialDetails;

import {Box, Typography} from "@mui/material";
import HelpToolTip from "shared/components/DS/HelpToolTip";
import {useTheme} from "@mui/material/styles";

interface DatetimeTypographyProps {
  datetime: string;
}

const DatetimeTypography = ({datetime}: DatetimeTypographyProps) => {
  const theme = useTheme();

  return (
    <Typography
      variant='B4'
      sx={{
        color: theme.palette.themeColors.grey['90'],
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {datetime}
      <Box
        component="span"
        sx={{
          marginLeft: '4px',
        }}
      >
        <HelpToolTip title={datetime}/>
      </Box>
    </Typography>
  )
}

export default DatetimeTypography;
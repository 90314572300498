import FilterComponent from "apps/transactions/pages/TransactionsList/useFilters/FilterComponent";
import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form"
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box} from "@mui/material";
import useQueryString from "shared/hooks/useQueryString";
import {defaultValues} from "apps/transactions/pages/TransactionsList/useFilters/consts";


const schema = yup.object().shape({
  search: yup.string(),
  email: yup.string().email(),
  method: yup.array().of(yup.string().required()),
  transaction_status: yup.array().of(yup.string().required()),
  type: yup.array().of(yup.string().required()),
  ip_address: yup.string().matches(
    /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})$/,
    {message: 'Invalid IP address', excludeEmptyString: true}
  ).defined(),
  name: yup.string(),
  phone: yup.string().matches(
    /^\+?(\d{1,3})?[ .-]?(\()?(\d{1,3})\)?[ .-]?(\d{1,4})[ .-]?(\d{1,9})$/,
    {message: 'Invalid phone number', excludeEmptyString: true}
  ),
  amount: yup.number().nullable(),
  rid_sid: yup.number().nullable(),
  date_start: yup.date().max(yup.ref('date_end'), '`From Date` should be less than `Date To`').nullable(),
  date_end: yup.date().min(yup.ref('date_start'), '`Date To` should be greater than `Date From`').nullable(),
  place_id: yup.string(),
})

export type FormData = yup.InferType<typeof schema>;

const useFilters = () => {
  const {getDefaultValues} = useQueryString()
  const [formData, setFormData] = useState<FormData>(defaultValues);

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: getDefaultValues(defaultValues)
  });

  const {formState} = methods;

  const onSubmit = (callbackFn?: (data: FormData) => void) => (data: FormData) => {
    setFormData(data);
    if (callbackFn) {
      callbackFn(data);
    }
    // setQueryString({
    //   page: 1,
    //   ...data,
    //   date_start: data.date_start?.toISOString(),
    //   date_end: data.date_end?.toISOString()
    // });
  }

  const triggerSubmit = (callbackFn?: (data: FormData) => void) => async () => {
    await methods.handleSubmit(onSubmit(callbackFn))();
  };

  const filters = (
    <FormProvider {...methods}>
      <Box
        component='form'
        // onSubmit={methods.handleSubmit(onSubmit)}
      >
        <FilterComponent/>
        {/* <FilterButtonsComponent onSubmit={onSubmit}/> */}
      </Box>
    </FormProvider>
  )

  const filterData = {
    ...formData,
    date_start: formData.date_start?.toISOString(),
    date_end: formData.date_end?.toISOString()
  }

  return {
    filterData,
    filters,
    submitCount: formState.submitCount,
    isValid: formState.isValid,
    triggerSubmit,
  };
}

export default useFilters;

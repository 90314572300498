export const defaultValues = {
  search: '',
  method: [],
  transaction_status: [],
  type: [],
  ip_address: '',
  name: '',
  phone: '',
  rid_sid: null,
  amount: null,
  email: "",
  date_start: null,
  date_end: null,
  place_id: '',
}

import {useEffect} from "react";
import {useUserList} from "apps/users/pages/UsersList/useDataQueries";
import useFilters from "apps/users/pages/UsersList/useFilters";

type DataFiltersParams = {
  currentPage: number;
  perPage: number;
}

const useDataFilters = ({currentPage, perPage}: DataFiltersParams) => {
  const {filterData, filters, submitCount} = useFilters()

  const {data, refetch, isLoading, isFetching} = useUserList({
    page: currentPage,
    perPage,
    search: filterData.search,
  });

  useEffect(() => {
    void refetch();
  }, [submitCount]);

  return {
    data,
    isLoading,
    isFetching,
    filters,
  }
}

export default useDataFilters;

import {Box} from "@mui/material";
import SelectFormField from "shared/components/formFields/SelectFormField";
import {useSetPreferredMethod, useTwoFaMethods} from "apps/account/pages/settings/TwoFa/useDataQueries";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useEffect} from "react";
import {UserSettingsSchema} from "generatedTypes";

const schema = yup.object().shape({
  preferred_method: yup.string().required('Preferred method is required'),
})

type FormData = yup.InferType<typeof schema>;

interface SelectPreferredMethodProps {
  userPreferredMethod: UserSettingsSchema["preferred_2fa"],
}

const SelectPreferredMethod = ({userPreferredMethod}: SelectPreferredMethodProps) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      preferred_method: userPreferredMethod,
    }
  })

  const {mutate} = useSetPreferredMethod()

  const {data, isLoading} = useTwoFaMethods()

  const preferredMethod = methods.watch('preferred_method');

  const handleSubmit = (formData: FormData) => {
    mutate({preferredMethod: formData.preferred_method})
  };

  useEffect(() => {
    if (preferredMethod !== userPreferredMethod) {
      handleSubmit({ preferred_method: preferredMethod });
    }
  }, [preferredMethod]);

  return(
    <Box width={220}>
      <FormProvider {...methods}>
        <SelectFormField name='preferred_method' options={data?.two_fa_methods || []} label='Preferred Method' isLoading={isLoading} />
      </FormProvider>
    </Box>
  )
}

export default SelectPreferredMethod;
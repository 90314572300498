import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {useSnackbar} from "notistack";
import {TransactionQueryKeys} from "shared/queryKeys";
import {useParams} from "react-router-dom";

type createParams = Parameters<typeof ApiClient.transactions.createNoteTransaction>[0]

interface CreateNoteArgs {
  reset: () => void
}

export const useCreateNote = ({reset}: CreateNoteArgs) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient();

  const {transactionId} = useParams()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: createParams) => ApiClient.transactions.createNoteTransaction(params),
    onSuccess: async () => {
      enqueueSnackbar('Note created successfully', {variant: 'success'})
      await queryClient.invalidateQueries({queryKey: [TransactionQueryKeys.NOTES, {txn: transactionId || ''}]});
      reset()
    },
  })

  return {
    mutate,
    isPending,
  }
}

type updateParams = Parameters<typeof ApiClient.transactions.updateNoteTransaction>[0]

interface UpdateNoteArgs {
  onSuccessCallback: () => void
}

export const useUpdateNote = ({onSuccessCallback}: UpdateNoteArgs) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient();

  const {transactionId} = useParams()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: updateParams) => ApiClient.transactions.updateNoteTransaction(params),
    onSuccess: async () => {
      enqueueSnackbar('Note updated successfully', {variant: 'success'})
      onSuccessCallback()
      await queryClient.invalidateQueries({queryKey: [TransactionQueryKeys.NOTES, {txn: transactionId || ''}]});
    },
  })

  return {
    mutate,
    isPending,
  }
}

type deleteParams = Parameters<typeof ApiClient.transactions.deleteNoteTransaction>[0]

export const useDeleteNote = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient();

  const {transactionId} = useParams()

  const {mutate, isPending} = useMutation({
    mutationFn: (params: deleteParams) => ApiClient.transactions.deleteNoteTransaction(params),
    onSuccess: async () => {
      enqueueSnackbar('Note deleted successfully', {variant: 'success'})

      await queryClient.invalidateQueries({queryKey: [TransactionQueryKeys.NOTES, {txn: transactionId || ''}]});
    },
  })

  return {
    mutate,
    isPending,
  }
}

import {Grid} from "@mui/material";
import TextFormField from "shared/components/formFields/TextFormField";

interface Field {
  name: string;
  label: string;
}

type DefaultFields = {
  firstName: Field;
  lastName: Field;
  email: Field;
  address: Field;
  city: Field;
  state: Field;
  country: Field;
  postalCode: Field;
};

interface CustomerDetailsFormProps {
  arrayName?: string;
  fields?: Partial<DefaultFields>;
}

const defaultFields = {
  firstName: {name: 'first_name', label: 'First name'},
  lastName: {name: 'last_name', label: 'Last name'},
  email: {name: 'email_address', label: 'Email'},
  address: {name: 'address', label: 'Address'},
  city: {name: 'city', label: 'City'},
  state: {name: 'state', label: 'State'},
  country: {name: 'country', label: 'Country'},
  postalCode: {name: 'postal_code', label: 'Postal code'},
};

const CustomerDetailsForm = ({arrayName = '', fields = {}}: CustomerDetailsFormProps) => {
  const mergedFields = {...defaultFields, ...fields};

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TextFormField name={`${arrayName}.${mergedFields.firstName.name}`} label={mergedFields.firstName.label} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFormField name={`${arrayName}.${mergedFields.lastName.name}`} label={mergedFields.lastName.label} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFormField name={`${arrayName}.${mergedFields.email.name}`} label={mergedFields.email.label} />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextFormField name={`${arrayName}.${mergedFields.address.name}`} label={mergedFields.address.label} />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextFormField name={`${arrayName}.${mergedFields.city.name}`} label={mergedFields.city.label} />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextFormField name={`${arrayName}.${mergedFields.state.name}`} label={mergedFields.state.label} />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextFormField name={`${arrayName}.${mergedFields.country.name}`} label={mergedFields.country.label} />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextFormField name={`${arrayName}.${mergedFields.postalCode.name}`} label={mergedFields.postalCode.label} />
      </Grid>
    </Grid>
  )
}

export default CustomerDetailsForm;

type Color = "success" | "primary" | "secondary" | "warning" | "default" | "error" | "info" | undefined;

const roleColors: Record<string, Color> = {
  'super_admin': 'success',
  'admin': 'success',
  'manager': 'primary',
  'retailer': 'secondary',
  'merchant': 'secondary',
  'customer_service': 'secondary',
  'reseller': 'error',
  'accounting': 'error',
  'site_admin': 'warning',
};

const getRoleColor = (role: string): Color => {
  if (!role) return 'warning';
  return roleColors[role] || 'warning';
}

export default getRoleColor;
import AuthFormsContainer from "apps/auth/components/AuthFormsWrapper";
import {Alert, Box} from "@mui/material";

const EmailAddressLink = () => (
  <AuthFormsContainer>
    <Box my={4}>
      <Alert severity="success">A link to login has been sent to your email address.</Alert>
    </Box>
  </AuthFormsContainer>
);

export default EmailAddressLink;

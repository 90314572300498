import {Chip} from "@mui/material";
import { ReactComponent as ArrowPaymentSuccess } from "assets/iconsDS/ChipTransactionStatus/ArrowPaymentSuccess.svg";
import { ReactComponent as ArrowPaymentWarning } from "assets/iconsDS/ChipTransactionStatus/ArrowPaymentWarning.svg";
import { ReactComponent as ArrowPaymentError } from "assets/iconsDS/ChipTransactionStatus/ArrowPaymentError.svg";

import { ReactComponent as ArrowPayoutSuccess } from "assets/iconsDS/ChipTransactionStatus/ArrowPayoutSuccess.svg";
import { ReactComponent as ArrowPayoutWarning } from "assets/iconsDS/ChipTransactionStatus/ArrowPayoutWarning.svg";
import { ReactComponent as ArrowPayoutError } from "assets/iconsDS/ChipTransactionStatus/ArrowPayoutError.svg";

import { ReactComponent as ArrowRefundSuccess } from "assets/iconsDS/ChipTransactionStatus/ArrowRefundSuccess.svg";
import { ReactComponent as ArrowRefundWarning } from "assets/iconsDS/ChipTransactionStatus/ArrowRefundWarning.svg";
import { ReactComponent as ArrowRefundError } from "assets/iconsDS/ChipTransactionStatus/ArrowRefundError.svg";
import { ReactComponent as ArrowRefundReturned } from "assets/iconsDS/ChipTransactionStatus/ArrowRefundReturned.svg";

import { ReactComponent as ArrowChargebackSuccess } from "assets/iconsDS/ChipTransactionStatus/ArrowChargebackSuccess.svg";
import { ReactComponent as ArrowChargebackWarning } from "assets/iconsDS/ChipTransactionStatus/ArrowChargebackWarning.svg";
import { ReactComponent as ArrowChargebackError } from "assets/iconsDS/ChipTransactionStatus/ArrowChargebackError.svg";

import { ReactComponent as CloseSuccess } from "assets/iconsDS/ChipTransactionStatus/CloseSuccees.svg";
import { ReactComponent as CloseWarning } from "assets/iconsDS/ChipTransactionStatus/CloseWarning.svg";
import { ReactComponent as CloseError } from "assets/iconsDS/ChipTransactionStatus/CloseError.svg";

interface TransactionStatusProps {
  status: string,
  type: string
}

type Color = "success" | "error" | "warning" | "default" | "primary" | "secondary" | "info";

interface Mapping {
  [key: string]: {
    [key: string]: {
      icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
      color: Color
    }
  }
}

const getStatusIconAndColor = (type: string, status: string) => {
  const mappings: Mapping = {
    payment: {
      approved: { icon: ArrowPaymentSuccess, color: 'success' },
      claimed: { icon: ArrowPaymentSuccess, color: 'success' },

      batched: { icon: ArrowPaymentWarning, color: 'warning' },
      pending: { icon: ArrowPaymentWarning, color: 'warning' },
      confirming: { icon: ArrowPaymentWarning, color: 'warning' },
      sent: { icon: ArrowPaymentWarning, color: 'warning' },
      waiting_authorization: { icon: ArrowPaymentWarning, color: 'warning' },

      declined: { icon: ArrowPaymentError, color: 'error' },
      cancelled: { icon: ArrowPaymentError, color: 'error' },
      expired: { icon: ArrowPaymentError, color: 'error' },
      failed: { icon: ArrowPaymentError, color: 'error' },
      refused: { icon: ArrowPaymentError, color: 'error' },
      pg_error: { icon: ArrowPaymentError, color: 'error' },
      pl_error: { icon: ArrowPaymentError, color: 'error' },
      process_expired: { icon: ArrowPaymentError, color: 'error' },
    },
    payout: {
      approved: { icon: ArrowPayoutSuccess, color: 'success' },
      claimed: { icon: ArrowPayoutSuccess, color: 'success' },

      batched: { icon: ArrowPayoutWarning, color: 'warning' },
      pending: { icon: ArrowPayoutWarning, color: 'warning' },
      confirming: { icon: ArrowPayoutWarning, color: 'warning' },
      sent: { icon: ArrowPayoutWarning, color: 'warning' },
      waiting_authorization: { icon: ArrowPayoutWarning, color: 'warning' },

      declined: { icon: ArrowPayoutError, color: 'error' },
      cancelled: { icon: ArrowPayoutError, color: 'error' },
      expired: { icon: ArrowPayoutError, color: 'error' },
      failed: { icon: ArrowPayoutError, color: 'error' },
      refused: { icon: ArrowPayoutError, color: 'error' },
      pg_error: { icon: ArrowPayoutError, color: 'error' },
      pl_error: { icon: ArrowPayoutError, color: 'error' },
      process_expired: { icon: ArrowPayoutError, color: 'error' },
    },
    chargeback: {
      approved: { icon: ArrowChargebackSuccess, color: 'success' },
      claimed: { icon: ArrowChargebackSuccess, color: 'success' },

      batched: { icon: ArrowChargebackWarning, color: 'warning' },
      pending: { icon: ArrowChargebackWarning, color: 'warning' },
      confirming: { icon: ArrowChargebackWarning, color: 'warning' },
      sent: { icon: ArrowChargebackWarning, color: 'warning' },
      waiting_authorization: { icon: ArrowChargebackWarning, color: 'warning' },

      declined: { icon: ArrowChargebackError, color: 'error' },
      cancelled: { icon: ArrowChargebackError, color: 'error' },
      expired: { icon: ArrowChargebackError, color: 'error' },
      failed: { icon: ArrowChargebackError, color: 'error' },
      refused: { icon: ArrowChargebackError, color: 'error' },
      pg_error: { icon: ArrowChargebackError, color: 'error' },
      pl_error: { icon: ArrowChargebackError, color: 'error' },
      process_expired: { icon: ArrowChargebackError, color: 'error' },
    },
    refund: {
      approved: { icon: ArrowRefundSuccess, color: 'success' },
      claimed: { icon: ArrowRefundSuccess, color: 'success' },

      batched: { icon: ArrowRefundWarning, color: 'warning' },
      pending: { icon: ArrowRefundWarning, color: 'warning' },
      confirming: { icon: ArrowRefundWarning, color: 'warning' },
      sent: { icon: ArrowRefundWarning, color: 'warning' },
      waiting_authorization: { icon: ArrowRefundWarning, color: 'warning' },

      declined: { icon: ArrowRefundError, color: 'error' },
      cancelled: { icon: ArrowRefundError, color: 'error' },
      expired: { icon: ArrowRefundError, color: 'error' },
      failed: { icon: ArrowRefundError, color: 'error' },
      refused: { icon: ArrowRefundError, color: 'error' },
      pg_error: { icon: ArrowRefundError, color: 'error' },
      pl_error: { icon: ArrowRefundError, color: 'error' },
      process_expired: { icon: ArrowRefundError, color: 'error' },

      returned: { icon: ArrowRefundReturned, color: 'primary' },
    },
    capture: {
      approved: { icon: CloseSuccess, color: 'success' },
      claimed: { icon: CloseSuccess, color: 'success' },

      batched: { icon: CloseWarning, color: 'warning' },
      pending: { icon: CloseWarning, color: 'warning' },
      confirming: { icon: CloseWarning, color: 'warning' },
      sent: { icon: CloseWarning, color: 'warning' },
      waiting_authorization: { icon: CloseWarning, color: 'warning' },

      declined: { icon: CloseError, color: 'error' },
      cancelled: { icon: CloseError, color: 'error' },
      expired: { icon: CloseError, color: 'error' },
      failed: { icon: CloseError, color: 'error' },
      refused: { icon: CloseError, color: 'error' },
      pg_error: { icon: CloseError, color: 'error' },
      pl_error: { icon: CloseError, color: 'error' },
      process_expired: { icon: CloseError, color: 'error' },
    },
    preauth: {
      approved: { icon: CloseSuccess, color: 'success' },
      claimed: { icon: CloseSuccess, color: 'success' },

      batched: { icon: CloseWarning, color: 'warning' },
      pending: { icon: CloseWarning, color: 'warning' },
      confirming: { icon: CloseWarning, color: 'warning' },
      sent: { icon: CloseWarning, color: 'warning' },
      waiting_authorization: { icon: CloseWarning, color: 'warning' },

      declined: { icon: CloseError, color: 'error' },
      cancelled: { icon: CloseError, color: 'error' },
      expired: { icon: CloseError, color: 'error' },
      failed: { icon: CloseError, color: 'error' },
      refused: { icon: CloseError, color: 'error' },
      pg_error: { icon: CloseError, color: 'error' },
      pl_error: { icon: CloseError, color: 'error' },
      process_expired: { icon: CloseError, color: 'error' },
    },
    void: {
      approved: { icon: CloseSuccess, color: 'success' },
      claimed: { icon: CloseSuccess, color: 'success' },

      batched: { icon: CloseWarning, color: 'warning' },
      pending: { icon: CloseWarning, color: 'warning' },
      confirming: { icon: CloseWarning, color: 'warning' },
      sent: { icon: CloseWarning, color: 'warning' },
      waiting_authorization: { icon: CloseWarning, color: 'warning' },

      declined: { icon: CloseError, color: 'error' },
      cancelled: { icon: CloseError, color: 'error' },
      expired: { icon: CloseError, color: 'error' },
      failed: { icon: CloseError, color: 'error' },
      refused: { icon: CloseError, color: 'error' },
      pg_error: { icon: CloseError, color: 'error' },
      pl_error: { icon: CloseError, color: 'error' },
      process_expired: { icon: CloseError, color: 'error' },
    },
  };

  const typeMapping = mappings[type] || {};
  return typeMapping[status] || { icon: null, color: 'success' };
};

function formatStatus(value: string): string {
  switch (value) {
  case 'pg_error': {
    return 'PG_Error'
  }
  case 'pl_error': {
    return 'PL_Error'
  }
  default: {
    if (['waiting_authorization', 'process_expired'].includes(value)) {
      return value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
    return value
  }
  }
}

const StatusOfTransaction = ({ status, type }: TransactionStatusProps) => {
  const { icon: Icon, color } = getStatusIconAndColor(type, status);

  return (
    <Chip
      icon={Icon ? <Icon /> : undefined}
      color={color}
      label={formatStatus(status)}
    />
  );
};

export default StatusOfTransaction;
import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {UserQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

type Params = Parameters<typeof ApiClient.users.getUsersList>[0]

export const useUserList = ({
  page,
  perPage,
  ...filters
}: Params) => {
  const {data, refetch, isLoading, isFetching} = useQuery({
    queryKey: [UserQueryKeys.LIST, {page, perPage, filters: {filters}}],
    queryFn: () => ApiClient.users.getUsersList({page, perPage, ...filters}),
    placeholderData: keepPreviousData,
    staleTime: 0,
    gcTime: 0,
  })

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  }
}

import {useSnackbar} from "notistack";
import {IconButton} from "@mui/material";
import {ReactComponent as IconClose} from "assets/iconsDS/Close.svg";

function SnackbarCloseButton({ snackbarKey }: any) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} sx={{padding: '6px 8px', marginRight: '7px'}}>
      <IconClose />
    </IconButton>
  );
}

export default SnackbarCloseButton;
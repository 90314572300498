import {useCallback} from "react";
import {SelectChangeEvent} from "@mui/material";
import PaginationComponent from "shared/hooks/usePagination/PaginationComponent";
import useQueryString from "shared/hooks/useQueryString";

interface PaginationData {
  total?: number;
  pages?: number;
}

function calculateRange(currentPage: number, itemsPerPage: number, totalItems: number): string {
  if (totalItems === 0) return '0 Items';

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);
  return `${startItem}-${endItem} of ${totalItems} Items`;
}

const usePagination = () => {
  const {urlParams, setQueryString} = useQueryString()

  const page = Number(urlParams.page) || 1
  const perPage = Number(urlParams.perPage) || 10

  const handlePageChange = (event: unknown, value: number) => {
    setQueryString({
      page: String(value),
    })
  }

  const handlePerPageChange = (event: SelectChangeEvent<unknown>) => {
    setQueryString({
      page: '1',
      perPage: String(event.target.value),
    })
  }

  const pagination = useCallback(({total = 0, pages = 1}: PaginationData) => {
    const itemsRange = calculateRange(Number(page), Number(perPage), total)
    return (
      <PaginationComponent
        perPage={perPage}
        currentPage={page}
        itemsRange={itemsRange}
        pages={pages}
        handlePageChange={handlePageChange}
        handlePerPageChange={handlePerPageChange}
      />
    )
  }, [page, perPage])

  return {
    currentPage: page,
    perPage,
    pagination,
  }
}

export default usePagination;

import {useMutation, useQuery} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {useEffect, useState} from "react";
import {defaultValues} from "apps/transactions/pages/TransactionsList/useFilters/consts";
import useQueryString from "shared/hooks/useQueryString";
import {useErrorMessage} from "shared/hooks/useErrorMessage";
import {IApiError} from "shared/types";

interface MutationArgs {
  fileFormat: 'csv' | 'xlsx',
  fields: string[],
  selectedTxns: string[],
  email?: string,
}

export const useTransactionExport = () => {
  const {errorMessage, handleSetErrorMessage} = useErrorMessage()
  const {
    errorMessage: exportStatusError,
    handleSetErrorMessage: handleSetExportStatusError,
  } = useErrorMessage()
  const {getDefaultValues} = useQueryString()

  const [fileId, setFileId] = useState<string | null>(null)

  const {mutate, isPending, isSuccess, reset} = useMutation({
    mutationFn: ({fileFormat, fields, selectedTxns, email}: MutationArgs) => {
      const requestBody = getDefaultValues(defaultValues)
      return ApiClient.export.transactionsExport({
        fileFormat,
        requestBody: {
          ...requestBody,
          fields,
          txns: selectedTxns,
          email_address: email
        },
      })},
    onError: (error: IApiError) => {
      handleSetErrorMessage(error)
    },
    onSuccess: (data) => {
      setFileId(data.file_id)
    }
  })

  const {data, error: exportError} = useQuery({
    queryKey: ['export_file'],
    queryFn: () => ApiClient.export.getFile({
      fileId: String(fileId),
    }),
    enabled: fileId !== null,
    refetchInterval: 1000,
  })

  useEffect(() => {
    if (data?.download_link) {
      setFileId(null)
    }
  }, [data]);

  useEffect(() => {
    if (exportError) {
      setFileId(null)
      handleSetExportStatusError(exportError as unknown as IApiError)
    }
    return () => {
      handleSetExportStatusError()
      handleSetErrorMessage()
    }
  }, [exportError])

  return {
    exportTransactions: mutate,
    mutationReset: reset,
    downloadLink: data?.download_link,
    isFileLoading: Boolean(fileId),
    errorMessage,
    exportStatusError,
    isPending,
    isSuccess,
  }
};

import {LoginReducerActions, LoginReducerState} from "apps/auth/pages/login/types";
import {LoginReducerActionType} from "apps/auth/pages/login/enums";

const loginReducer = (state: LoginReducerState, action: LoginReducerActions): LoginReducerState => {
  switch (action.type) {
  case LoginReducerActionType.SET_2FA: {
    const payload = action.payload as boolean
    return {
      ...state,
      initial: false,
      two_fa_enabled: payload,
    };
  }
  case LoginReducerActionType.SET_METHOD_2FA: {
    const payload = action.payload as string
    return {
      ...state,
      initial: false,
      preferred_2fa: payload
    };
  }
  case LoginReducerActionType.SET_EMAIL: {
    const payload = action.payload as string
    return {
      ...state,
      user_email: payload
    }
  }
  case LoginReducerActionType.SET_ACCESS_TOKEN: {
    const payload = action.payload as string
    return {
      ...state,
      access_token: payload
    }
  }
  default: {
    return state;
  }
  }
};

export default loginReducer

import AuthFormsContainer from 'apps/auth/components/AuthFormsWrapper';
import {Alert, Box, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {select2FAMutation} from "apps/auth/pages/login/mutations";
import {useMutation} from "@tanstack/react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import {useState} from "react";
import {AxiosError} from "axios/index";

const schema = yup.object().shape({
  faMethod: yup.string().required('This field is required.'),
});

type FormData = yup.InferType<typeof schema>;

interface Select2FAMethodProps {
  user_email?: string
  handleMethod2FAChange: (preferred_2fa: string) => void
}

function Select2FAMethod({user_email, handleMethod2FAChange}: Select2FAMethodProps) {
  const [errorMessage, setErrorMessage] = useState('');
  const { handleSubmit, control } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      faMethod: 'email_address_link'
    }
  });

  const { mutate, isPending } = useMutation({
    mutationFn: select2FAMutation,
    onSuccess: (data, variables) => {
      handleMethod2FAChange(variables.selected_method)
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      const message = error.response?.data?.message
      if (typeof message === 'string') {
        setErrorMessage(message)
      }

      if (typeof message === 'object' && message !== null) {
        const messages = Object.keys(message).map(key => message[key])
        setErrorMessage(messages.join('\n'))
      }
    },
  })

  const onSubmit = (data: FormData) => {
    mutate({selected_method: data.faMethod, user_email})
  }

  return (
    <AuthFormsContainer title="Two FA Method" subtitle="select two fa method.">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, width: '100%' }}>
        <Controller
          control={control}
          name="faMethod"
          render={({ field }) => (
            <RadioGroup
              aria-labelledby="select-2fa-method"
              {...field}
            >
              <FormControlLabel value="email_address_link" control={<Radio />} label="Send a link to my email" />
              <FormControlLabel value="email_address" control={<Radio />} label="Send a code to my email" />
            </RadioGroup>
          )}
        />

        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

        <LoadingButton
          fullWidth
          loading={isPending}
          variant="contained"
          loadingIndicator="Submiting…"
          type="submit"
          sx={{ mt: 3, mb: 2 }}
        >
            Submit
        </LoadingButton>
      </Box>
    </AuthFormsContainer>
  );
}

export default Select2FAMethod;

import LiveStreaming from "apps/dashboard/pages/Dashboard/partials/LiveStreaming";
import {Box, Grid} from "@mui/material";
import StatCards from "apps/dashboard/pages/Dashboard/partials/StatCards";
import {useForm, FormProvider} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import Filters from "apps/dashboard/pages/Dashboard/partials/Filters";
import { subDays } from 'date-fns';
import SiteSharePayment from "apps/dashboard/pages/Dashboard/partials/SiteSharePayment";
import ChartPayments from "apps/dashboard/pages/Dashboard/partials/ChartPayments";

const schema = yup.object().shape({
  sids: yup.array(),
  mids: yup.array(),
  rids: yup.array(),
  startDate: yup.date(),
  endDate: yup.date(),
});

const today = new Date();

const defaultValues = {
  sids: [],
  mids: [],
  rids: [],
  startDate: subDays(today, 30),
  endDate: today,
}

type FormData = yup.InferType<typeof schema>;

const Dashboard = () => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <h1>Dashboard</h1>
      <Box mb={2}>
        <Filters />
      </Box>

      <StatCards />
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Box mt={2}>
            <ChartPayments />
          </Box>
        </Grid>
        <Grid item md={6}>
          <LiveStreaming />
          <SiteSharePayment />
        </Grid>
      </Grid>
    </FormProvider>
  );
}

export default Dashboard;

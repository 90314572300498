import {Link, TableCell, TableRow, Typography} from "@mui/material";
import {KYCRequestDetailSchema} from "generatedTypes";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";

interface KYCDetailRowProps {
  type: string,
  first_name: KYCRequestDetailSchema['first_name'],
  last_name: KYCRequestDetailSchema['last_name'],
  address: KYCRequestDetailSchema['address'],
  phone: KYCRequestDetailSchema['phone'],
  timestamp: KYCRequestDetailSchema['timestamp'],
  url: KYCRequestDetailSchema['key'],
}

function KYCDetailRow({
  type,
  first_name,
  last_name,
  address,
  phone,
  timestamp,
  url
}: KYCDetailRowProps) {

  return (
    <TableRow>
      <TableCell>
        <Typography variant='body2' textTransform='uppercase'>{type}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' textTransform='capitalize'>
          {first_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' textTransform='capitalize'>
          {last_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' textTransform='capitalize'>
          {address}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {phone}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {timestamp && convertIsoToCustomFormat(timestamp)}
        </Typography>
      </TableCell>
      <TableCell>
        <Link href={url || ''}>View file</Link>
      </TableCell>
    </TableRow>
  )
}

export default KYCDetailRow;

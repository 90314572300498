import {useTheme} from "@mui/material/styles";
import {useSnackbar} from "notistack";
import {useCopyToClipboard} from "@uidotdev/usehooks";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, IconButton, Stack, Tooltip} from "@mui/material";
import {ReactComponent as CopyIcon} from "assets/iconsDS/copy.svg";

interface IconTextPairProps {
  Icon: React.ElementType;
  text: string;
  openDialog?: () => void;
}

export const InfoItemWithCopy = ({ Icon, text, openDialog }: IconTextPairProps) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar()
  const [isCopied, copyToClipboard] = useCopyToClipboard();

  const handleCopyToClipboard = () => {
    void copyToClipboard(text);
    if (isCopied) {
      enqueueSnackbar("Copied to clipboard!", { variant: 'success' });
    } else {
      enqueueSnackbar("Copied to clipboard!", { variant: 'success' });
    }
  };

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', '&:not(:last-child)': { mb: 1 }, position: 'relative', '&:hover .copyButton': { visibility: 'visible' } }}>
        <Icon
          style={{
            width: '20px',
            height: '20px',
            fill: theme.palette.themeColors.grey['80']
          }}
        />
        <Typography variant="B4" sx={{ color: theme.palette.secondary.main }}>
          {text}
        </Typography>
        <Box className="copyButton" sx={{visibility: 'hidden'}}>
          <Tooltip title='Copy IP Address' placement='top' arrow>
            <IconButton
              sx={{
                width: '20px',
                height: '20px',
              }}
              onClick={handleCopyToClipboard}
            >
              <CopyIcon/>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box>
        <Button variant='text' color='primary' size='small' onClick={openDialog}>
          See All IPs
        </Button>
      </Box>
    </Stack>
  );
}
import {AppCard} from "shared/components/mui";
import {FormProvider, useForm} from "react-hook-form";
import {Alert, Box, Typography} from "@mui/material";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {UserDetailsSchema} from "generatedTypes";
import PasswordFormField from "shared/components/formFields/PasswordFormField";
import LoadingButton from "@mui/lab/LoadingButton";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";
import {useEditPassword} from "apps/users/pages/edit/partials/EditPassword/useDataMutations";
import {useParams} from "react-router-dom";

export const passwordUpdateSchema = yup.object().shape({
  password: yup.string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters long')
    .max(20, 'Password must be at most 20 characters long')
    .matches(/^\S*$/, 'Password cannot contain whitespace characters')
    .matches(/(?=.*[a-z])/, 'Password must contain at least 1 lowercase letter')
    .matches(/(?=.*[A-Z])/, 'Password must contain at least 1 uppercase letter')
    .matches(/(?=.*\d)/, 'Password must contain at least 1 digit')
    .matches(/(?=.*[!#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, 'Password must contain at least 1 special character'),
  confirmPassword: yup.string()
    .required('Confirm password is required')
    .min(8, 'Password must be at least 8 characters long')
    .max(20, 'Password must be at most 20 characters long')
    .oneOf([yup.ref('password')], 'Passwords must match'),
})

type FormData = yup.InferType<typeof passwordUpdateSchema>;

interface ChangePasswordProps {
  lastPasswordCreatedAt: UserDetailsSchema["last_password_created_at"];
}

const EditPassword = ({lastPasswordCreatedAt}: ChangePasswordProps) => {
  const {userId} = useParams()

  const methods = useForm<FormData>({
    resolver: yupResolver(passwordUpdateSchema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });

  const {isDirty, isValid} = methods.formState

  const {mutate, isPending, errorMessage} = useEditPassword(methods.reset)

  const onSubmit = (data: FormData) => {
    mutate({userId: userId || '', requestBody: {password: data.password, confirm_password: data.confirmPassword}})
  };

  return (
    <AppCard
      headStyle={{pb: 0}}
      bodyStyle={{m: 0, p: 1, pt: 2}}
      sx={{pb: 0, mt: 3}}
    >
      <FormProvider {...methods}>
        <Box component='form' mx={3} mt={1} onSubmit={methods.handleSubmit(onSubmit)}>
          <Box mb={2}>
            <PasswordFormField name='password' placeholder='New Password' />
          </Box>
          <Box mb={2}>
            <PasswordFormField name='confirmPassword' placeholder='Confirm Password' />
          </Box>

          <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 1}}>
            <Box>
              <Typography
                variant='body1'
                color='text.secondary'
              >
                Last updated
              </Typography>
              <Typography variant='body2'>
                {convertIsoToCustomFormat(lastPasswordCreatedAt)}
              </Typography>
            </Box>
          </Box>

          {errorMessage && <Alert sx={{mt: 1}} severity="error">{errorMessage}</Alert>}

          <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 2}}>
            <LoadingButton
              loading={isPending}
              variant="contained"
              loadingIndicator="Submitting…"
              type="submit"
              sx={{mt: 1, width: 120}}
              disabled={!isValid || !isDirty}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>
    </AppCard>
  );
}

export default EditPassword;

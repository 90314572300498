import {useRef, useEffect} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import loadScript from "shared/utils/loadScript";
import useOptions from "shared/components/formFields/AddressAutocompleteFormField/useOptions";
import {PlaceType} from "shared/components/formFields/AddressAutocompleteFormField/types";
import {Controller, useFormContext} from "react-hook-form";

const GOOGLE_MAPS_API_KEY = 'AIzaSyC-WYk-nqKFDaylFpZzFmRlTOfyahuEiuI';

interface AddressAutocompleteFormFieldProps {
  name: string;
}

const AddressAutocompleteFormField = ({name}: AddressAutocompleteFormFieldProps) => {
  const {control, setValue} = useFormContext();
  const {value, options, handleValueChange, handleInputValueChange, handleOptionsChange} = useOptions();
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  useEffect(() => {
    if (value) {
      // @ts-ignore
      setValue(name, value.place_id);
    }
  }, [value]);

  return (
    <Controller
      name={name}
      control={control}
      render={({fieldState}) => (
        <Autocomplete
          id={`google-map-${name}`}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.description
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          noOptionsText="No locations"
          onChange={(event: any, newValue: PlaceType | null) => {
            handleOptionsChange(newValue ? [newValue, ...options] : options);
            handleValueChange(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            handleInputValueChange(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Add a location"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              fullWidth
            />
          )}
          renderOption={(props, option) => {
            const matches = option.structured_formatting.main_text_matched_substrings || [];

            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length]),
            );

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    {parts.map((part, index) => (
                      <Box
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                      >
                        {part.text}
                      </Box>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    />);
}

export default AddressAutocompleteFormField;

import Select, {components, MenuListProps, OptionProps, StylesConfig} from 'react-select';
import {Box, FormHelperText, InputLabel, LinearProgress, Stack, Typography} from "@mui/material";
import {isEmpty} from "lodash";
import {Controller, useFormContext} from "react-hook-form";
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";

const MenuList = (props: MenuListProps) => {
  const {children, options, selectProps: {isLoading}} = props;
  return (
    <Box>
      <components.MenuList {...props}>
        {children}
        {!isEmpty(options) && isLoading && <LinearProgress/>}
      </components.MenuList>
    </Box>
  );
};

const Option = (props: OptionProps) => (
  <components.Option {...props}>
    <Box>
      <Typography
        variant="B4"
      >
        {props.label}
      </Typography>
    </Box>
  </components.Option>
);

interface RSSelectFormFieldProps {
  name: string,
  loadMoreOptions?: () => void,
  options: any[],
  isLoading?: boolean,
  handleInputChange?: (value: string) => void,
  loadingMessage?: () => string,
  isMulti?: boolean,
  preselectFirst?: boolean,
  placeholder?: string,
  isReadonly?: boolean,
  label?: string,
  isCustom?: boolean,
  onChange?: (value: any) => void,
}

interface CustomStylesArgs {
  menuIsOpen: boolean,
  theme: any,
  isCustom?: boolean,
  isError?: boolean,
}

const customStyles = ({menuIsOpen, theme, isError, isCustom = false}: CustomStylesArgs): StylesConfig => ({
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    borderRadius: '8px',
    backgroundColor: state.menuIsOpen
      ? theme.palette.themeColors.grey['30']
      : theme.palette.themeColors.grey['20'],
    borderColor: isError
      ? theme.palette.error.main
      : state.menuIsOpen
        ? theme.palette.primary.main
        : 'transparent',
    outline: state.menuIsOpen
      ? '3px solid rgba(129, 69, 181, 0.10)'
      : 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.themeColors.grey['30'],
      '& > div > div:first-child': {
        color: theme.palette.themeColors.grey['110'],
      }
    },
    '&:active': {
      borderColor: theme.palette.primary.main,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: theme.typography.B4?.fontFamily,
    fontSize: theme.typography.B4?.fontSize,
    lineHeight: theme.typography.B4?.lineHeight,
    letterSpacing: theme.typography.B4?.letterSpacing,
    fontWeight: 400,
    color: menuIsOpen
      ? theme.palette.themeColors.grey['120']
      : theme.palette.themeColors.grey['120'],
  }),
  valueContainer: (provided) => ({
    ...provided,

    padding: isCustom ? '8px 12px' : '6px 2px 6px 5px',
  }),
  input: (provided) => ({
    ...provided,
    caretColor: theme.palette.primary.main,
    margin: isCustom ? '0': '2px',
    padding: isCustom ? '0': '4px 0',
    height: isCustom ? '20px': 'auto',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
    width: 'max-content',
    boxShadow: '0px 10px 20px -4px rgba(33, 32, 29, 0.15)',
  }),
  option: (provided) => ({
    ...provided,
    background: 'transparent !important',
    padding: '0 6px',
    "&>div": {
      padding: '8px 12px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderRadius: '6px',
      color: theme.palette.themeColors.grey['110'],
      "&:hover": {
        backgroundColor: theme.palette.themeColors.grey['30'],
        color: theme.palette.themeColors.grey['120'],
        cursor: 'pointer',
      },
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    '& [class$="indicatorContainer"]': {
      padding: 0,
      paddingRight: isCustom ? '12px' : '0'
    },
  })
})

const RSSelectFormField = ({
  name,
  loadMoreOptions,
  options,
  isLoading,
  isMulti,
  handleInputChange,
  loadingMessage = () => 'Loading...',
  preselectFirst = false,
  placeholder,
  isReadonly = false,
  label,
  isCustom = false,
  onChange
}: RSSelectFormFieldProps) => {
  const theme = useTheme();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const {control, setValue, getValues} = useFormContext();

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  useEffect(() => {
    if (!preselectFirst) return;

    if (options.length > 0 && !getValues(name)) {
      if (isMulti) {
        setValue(name, [options[0].value]);
      } else {
        setValue(name, options[0].value);
      }
    }
  }, [options, preselectFirst, isMulti, getValues, name, setValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => {
        let fieldValue = field.value;
        if (isMulti) {
          if (!Array.isArray(field.value)) {
            fieldValue = [fieldValue];
          }
          fieldValue = fieldValue.map((el: any) => el?.toString());
        }

        return (
          <Stack>
            {label && (
              <InputLabel
                sx={{
                  ...theme.typography.B4,
                  marginBottom: '8px',
                }}
              >
                {label}
              </InputLabel>
            )}
            <Select
              {...field}
              value={isMulti
                ? options.filter((option) => fieldValue.includes(option.value) || fieldValue.includes(option.value?.toString()))
                : options.find((option) => fieldValue && option.value.toString() === fieldValue.toString()) || null
              }
              onChange={(newValue) => {
                const val = isMulti ? newValue.map((option: any) => option.value) : newValue?.value;
                field.onChange(val);
                if (onChange) onChange(newValue)
              }}
              options={options}
              styles={customStyles({menuIsOpen, theme, isError: fieldState.invalid, isCustom})}
              components={{
                MenuList,
                Option
              }}
              onMenuScrollToBottom={loadMoreOptions}
              isLoading={isLoading}
              loadingMessage={loadingMessage}
              onInputChange={handleInputChange}
              placeholder={placeholder}
              isMulti={isMulti}
              isDisabled={isReadonly}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
            />
            {fieldState.invalid &&
              <FormHelperText sx={{color: theme.palette.error.main}}>{fieldState.error?.message}</FormHelperText>}
          </Stack>
        );
      }}
    />
  );
}

export default RSSelectFormField;

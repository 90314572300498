import {Box, Button,} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import useFilters, {FormData} from "apps/transactions/pages/TransactionsList/useFilters";

interface ColumnsToolBarProps {
  gridRef: any;
}

function transformFilterData(filterData: any) {
  const transformedData: any = {};

  for (const key in filterData) {
    if (filterData[key] !== null) {
      if (Array.isArray(filterData[key])) {
        transformedData[key] = {
          type: "set",
          values: filterData[key],
        };
      } else if (typeof filterData[key] === "string" && filterData[key] !== "") {
        transformedData[key] = {
          filterType: 'text',
          type: "contains",
          filter: filterData[key],
        };
      } else if (typeof filterData[key] === "number") {
        transformedData[key] = {
          filterType: 'number',
          type: "equals",
          filter: filterData[key],
        };
      } else if (typeof filterData[key] === "string" && !Number.isNaN(Date.parse(filterData[key]))) {
        transformedData[key] = {
          type: "lessThan",
          dateFrom: filterData[key],
        };
      }
    }
  }

  return transformedData;
}

const FiltersListTab = ({gridRef}: ColumnsToolBarProps) => {
  const theme = useTheme();
  const {filters, triggerSubmit} = useFilters()

  const handleApply = (data: FormData) => {
    const transformedData = transformFilterData(data);
    gridRef.current.api.setFilterModel(transformedData);
  }

  return (
    <>
      <Box
        sx={{
          maxHeight: '400px',
          overflow: 'auto',
          marginTop: 1.5,
          marginBottom: 3,
          padding: 2,
          borderRadius: 1,
          border: `1px solid ${theme.palette.themeColors.grey[40]}`
        }}
      >
        {filters}
      </Box>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={triggerSubmit(handleApply)}
      >
        Apply
      </Button>
    </>
  );
}

export default FiltersListTab;

import {usePermissionsDataQuery} from "apps/users/pages/create/partials/Permissions/useDataQueries";
import {Box, Chip, LinearProgress, TextField, Typography} from "@mui/material";
import {useMemo, useState} from "react";
import {AutoSizer, List} from "react-virtualized";
import CheckboxFormField from "shared/components/formFields/CheckboxFormField";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEditPermissions} from "apps/users/pages/edit/partials/EditPermissions/useDataMutations";
import {useParams} from "react-router-dom";

interface PermissionsProps {
  userRole: string,
  userPermissions: string[]
}

const schema = yup.object().shape({
  permissions: yup.object().json()
})

type FormData = yup.InferType<typeof schema>;

const formatPermissions = (permissions: string[]) => permissions.reduce((acc, permission) => {
  acc[permission] = true;
  return acc;
}, {} as Record<string, boolean>);

const EditPermissions = ({userRole, userPermissions}: PermissionsProps) => {
  const {userId} = useParams()

  const formattedPermissions = formatPermissions(userPermissions);

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      permissions: formattedPermissions
    },
  })

  const selectedRole = userRole;

  const [search, setSearch] = useState('')

  const {data, isLoading} = usePermissionsDataQuery()

  const isDisabled = selectedRole === '' || selectedRole === 'super_admin';

  const {mutate, isPending} = useEditPermissions()

  const onSubmit = (formData: FormData) => {
    // @ts-ignore
    const permissions = Object.keys(formData.permissions).filter(key => formData.permissions[key] === true)

    mutate({
      userId: userId || '',
      formData: {
        permissions,
        role: userRole
      }
    })
  }

  function filterData(searchPhrase: string) {
    if (!searchPhrase && !selectedRole) {
      return data?.permissions || [];
    }

    if (selectedRole === 'super_admin') {
      return data?.permissions || [];
    }

    return data?.permissions?.filter(
      (item) => {
        if (searchPhrase && selectedRole) {
          return item?.name?.toLowerCase().includes(searchPhrase.toLowerCase()) && item?.roles?.includes(selectedRole)
        }

        if (searchPhrase){
          return item?.name?.toLowerCase().includes(searchPhrase.toLowerCase())
        }

        if (selectedRole){
          return item?.roles?.includes(selectedRole)
        }

        return false
      }
    ) || [];
  }

  const flatData = useMemo(() => filterData(search), [data, search, selectedRole]);

  // @ts-ignore
  const rowRenderer = ({index, key, style}) => {
    const item = flatData[index];

    return (
      <Box key={key} style={style} display='flex' alignItems='flex-start'>
        <CheckboxFormField
          name={`permissions.${item.name}`}
          label=''
          disabled={isDisabled}
        />
        <Box display='flex' flexDirection='column'>
          <Typography>
            {item.name}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            {item.description}
          </Typography>
          <Chip label={`[${item?.roles?.map(role => `'${role}'`).join(', ')}]`} color='success' sx={{width: 'max-content'}}/>
        </Box>
      </Box>
    )
  }

  if (isLoading) {
    return <LinearProgress/>;
  }

  return (
    <FormProvider {...methods}>
      <Box sx={{height: '70vh', overflow: 'auto'}} component='form' onSubmit={methods.handleSubmit(onSubmit)}>
        <Box mb={2}>
          <TextField
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            size='small'
            sx={{width: 350}}
          />
        </Box>
        <Box sx={{height: '50vh'}}>
          <AutoSizer>
            {({height, width}) => (
              <List
                width={width}
                height={height}
                rowCount={flatData.length || 0}
                rowHeight={92}
                rowRenderer={rowRenderer}/>
            )}
          </AutoSizer>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <LoadingButton
            loading={isPending}
            variant="contained"
            loadingIndicator="Submitting…"
            type="submit"
            sx={{mt: 2, mb: 2, width: 120}}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>
  )
}

export default EditPermissions;

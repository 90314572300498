/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportGetFileResponse } from '../models/ExportGetFileResponse';
import type { ExportTemplate } from '../models/ExportTemplate';
import type { FieldsModel } from '../models/FieldsModel';
import type { TemplateCreationRequest } from '../models/TemplateCreationRequest';
import type { TransactionModelResponse } from '../models/TransactionModelResponse';
import type { TransactionsExportModel } from '../models/TransactionsExportModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ExportService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Transactions Export
     * @returns TransactionModelResponse Successful Response
     * @throws ApiError
     */
    public transactionsExport({
        fileFormat,
        requestBody,
    }: {
        fileFormat: 'csv' | 'xlsx',
        requestBody: TransactionsExportModel,
    }): CancelablePromise<TransactionModelResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/export/transactions/{file_format}/',
            path: {
                'file_format': fileFormat,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Transactions Fields
     * Get the list of fields that can be exported.
     * @returns FieldsModel Successful Response
     * @throws ApiError
     */
    public getTransactionsFields(): CancelablePromise<Array<FieldsModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/export/fields/transactions/',
        });
    }
    /**
     * Get File
     * Get file by ID.
     * @returns ExportGetFileResponse Successful Response
     * @throws ApiError
     */
    public getFile({
        fileId,
    }: {
        fileId: string,
    }): CancelablePromise<ExportGetFileResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/export/get-file-link/{file_id}/',
            path: {
                'file_id': fileId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Template
     * @returns ExportTemplate Successful Response
     * @throws ApiError
     */
    public getTemplates(): CancelablePromise<Array<ExportTemplate>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/export/template/',
        });
    }
    /**
     * Create Template
     * @returns ExportTemplate Successful Response
     * @throws ApiError
     */
    public createTemplate({
        requestBody,
    }: {
        requestBody: TemplateCreationRequest,
    }): CancelablePromise<ExportTemplate> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/export/template/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Template
     * @returns number Successful Response
     * @throws ApiError
     */
    public deleteTemplate({
        templateId,
    }: {
        templateId: string,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/export/template/{template_id}/',
            path: {
                'template_id': templateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}

import { Components } from '@mui/material/styles';

const MuiAccordionSummary: Components['MuiAccordionSummary'] = {
  styleOverrides: {
    root: {
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
      },

      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '16px 0',
      }
    },
    content: {
      margin: '16px 0'
    },
  }
}

export default MuiAccordionSummary;
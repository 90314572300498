import {Grid} from '@mui/material';
import {useStatCardsDataQuery} from "apps/dashboard/pages/Dashboard/partials/StatCards/useDataQueries";
import StatCard from "apps/dashboard/pages/Dashboard/partials/StatCards/StatCard";

const StatCards = () => {
  const {data} = useStatCardsDataQuery();
  return (
    <Grid container spacing={2}>
      <Grid item md={3}>
        <StatCard
          amountTotal={data?.payment_amount_total || 0}
          countTotal={data?.payment_count_total || 0}
          statName='Payments'/>
      </Grid>
      <Grid item md={3}>
        <StatCard
          amountTotal={data?.payout_amount_total || 0}
          countTotal={data?.payout_count_total || 0}
          statName='Payouts'/>
      </Grid>
      <Grid item md={3}>
        <StatCard
          amountTotal={data?.refund_amount_total || 0}
          countTotal={data?.refund_count_total || 0}
          statName='Refund'/>
      </Grid>
      <Grid item md={3}>
        <StatCard
          amountTotal={data?.chargeback_amount_total || 0}
          countTotal={data?.chargeback_count_total || 0}
          statName='Chargebacks'/>
      </Grid>
    </Grid>
  );
};

export default StatCards;

import {FormProvider, useForm} from "react-hook-form";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {UserLimitsModel} from "generatedTypes";
import useUpdateUserLimitOrBlacklist from "apps/userLimits/pages/update/useDataMutations";
import {LimitValidator, phoneValidator} from "shared/schemas";
import DialogGeneral from "shared/components/DialogGeneral";
import UpdateUserLimitOrBlacklistForm from "apps/userLimits/components/UpdateUserLimitOrBlacklistForm";
import validator from 'validator';
import {fieldsList, documentsDefaultValues} from "apps/userLimits/const";
import {useState} from "react";
import {documentsSchema} from "apps/userLimits/schemas";

interface ValidationResult {
  email?: string;
  phone?: string;
}

function getEmailOrPhone(input: string): ValidationResult | never {
  const result: ValidationResult = {};

  if (validator.isEmail(input)) {
    result.email = input;
  } else if (validator.isMobilePhone(input)) {
    result.phone = input;
  } else {
    throw new Error('Input is neither a valid email nor a valid phone number');
  }

  return result;
}

interface UpdateUserLimitOrBlacklistProps {
  dialogStateHandler: DialogStateHandler,
  data: UserLimitsModel
}

const schema = yup.object().shape({
  sid: yup.number().required('This field is required.'),
  email: yup.string().email().nullable(),
  phone: phoneValidator.nullable(),
  paymentLimit: LimitValidator('paymentLimit'),
  payoutLimit: LimitValidator('payoutLimit'),
  isBlacklisted: yup.boolean().required(),
  expiryDate: yup.date().nullable(),
  fields: yup.object().shape(documentsSchema),
})

type FormData = yup.InferType<typeof schema>;

function convertStructure(data: FormData['fields']) {
  const result: { [key: string]: string[] } = {};

  Object.entries(data).forEach(([key, items]) => {
    const files = items?.filter(item => Boolean(item[key])).map(item => item[key]);
    if (files?.length) {
      result[key] = files;
    }
  });

  return result;
}

const UpdateUserLimitOrBlacklistDialog = ({dialogStateHandler, data}: UpdateUserLimitOrBlacklistProps) => {
  const [visibleGroups, setVisibleGroups] = useState<string[]>([fieldsList[0], fieldsList[1]]);

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  const contactData = getEmailOrPhone(data.contact);
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      sid: data.sid,
      email: contactData.email || null,
      phone: contactData.phone || null,
      paymentLimit: {
        twentyFourHours: data.payment_limits?.["24h_limit"],
        sevenDays: data.payment_limits?.["7d_limit"],
        thirtyDays: data.payment_limits?.["30d_limit"],
        perTransaction: data.payment_limits?.transaction_limit,
      },
      payoutLimit: {
        twentyFourHours: data.payout_limits?.["24h_limit"],
        sevenDays: data.payout_limits?.["7d_limit"],
        thirtyDays: data.payout_limits?.["30d_limit"],
        perTransaction: data.payout_limits?.transaction_limit,
      },
      isBlacklisted: data.blacklisted || false,
      expiryDate: data.expiry ? new Date(data.expiry) : null,
      fields: documentsDefaultValues,
    },
  });

  const {mutate, isPending, errorMessage} = useUpdateUserLimitOrBlacklist({
    dialogHandler: dialogStateHandler,
    reset: methods.reset
  });

  const onSubmit = (formData: FormData) => {
    const fields = convertStructure(formData.fields);
    mutate({
      id: data._id,
      formData: {
        payload: {
          payment_limit: {
            twenty_four_hours: formData.paymentLimit.twentyFourHours,
            seven_days: formData.paymentLimit.sevenDays,
            thirty_days: formData.paymentLimit.thirtyDays,
            per_transaction: formData.paymentLimit.perTransaction,
          },
          payout_limit: {
            twenty_four_hours: formData.payoutLimit.twentyFourHours,
            seven_days: formData.payoutLimit.sevenDays,
            thirty_days: formData.payoutLimit.thirtyDays,
            per_transaction: formData.payoutLimit.perTransaction,
          },
          is_blacklisted: formData.isBlacklisted,
          expiry: formData.expiryDate?.toISOString(),
        },
        ...fields
      },
    })
  }

  const handleReset = () => {
    methods.reset(methods.formState.defaultValues);
  }

  const handleOnClose = () => {
    dialogStateHandler.closeDialog();
    methods.reset(methods.formState.defaultValues);
  }


  const handleAddVisibleGroup = (field: string) => {
    setVisibleGroups((prev) => [...prev, field]);
  }

  const handleRemoveVisibleGroup = (field: string) => {
    setVisibleGroups((prev) => prev.filter((item) => item !== field));
  }

  return (
    <FormProvider {...methods}>
      <DialogGeneral
        dialogStateHandler={dialogStateHandler}
        dialogTitle="Update user limit/blacklist"
        onSubmit={methods.handleSubmit(onSubmit)}
        submitBtnText="Update"
        onClose={handleOnClose}
        isPending={isPending}
        reset={handleReset}
      >
        <UpdateUserLimitOrBlacklistForm
          errorMessage={errorMessage}
          isUpdateForm
          fieldsList={fieldsList}
          visibleGroups={visibleGroups}
          handleAddVisibleGroup={handleAddVisibleGroup}
          handleRemoveVisibleGroup={handleRemoveVisibleGroup}
        />
      </DialogGeneral>
    </FormProvider>
  )
}

export default UpdateUserLimitOrBlacklistDialog;

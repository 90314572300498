import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import {Controller, useFormContext} from "react-hook-form";
import {HTMLAttributes, ReactNode} from "react";

interface Option {value: string | number, label: string};

interface AutocompleteFormFieldProps {
  name: string,
  options: Option[],
  label: string,
  multiple?: boolean,
  isLoading?: boolean,
  renderOption?: (props: HTMLAttributes<HTMLLIElement>, option: Option) => ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AutocompleteFormField = ({name, options, label, multiple, isLoading, renderOption}: AutocompleteFormFieldProps) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <Autocomplete
          id={`${name}-field`}
          options={options}
          loading={isLoading}
          multiple={multiple}
          value={multiple
            ? options.filter((option) => field.value.includes(option.value))
            : options.find((option) => option.value === field.value) || null
          }
          onChange={(event, value) => {
            if (multiple) {
              return field.onChange((value as Option[])?.map((v: Option) => v.value));
            }
            return field.onChange((value as Option)?.value);
          }}

          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={label}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          {...(renderOption ? {renderOption} : {})}
        />)}
    />
  );
}

export default AutocompleteFormField;

import React from 'react'
import { styled, alpha } from '@mui/material/styles'
import {
  ButtonProps,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  MenuProps,
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

// Components
import { AppButton } from 'shared/components/mui';

interface AppDropdownProps extends MenuProps {
  children?: React.ReactNode
  name: string
  width?: number
  titleComp?: string | React.ReactNode
  titleProps?: ButtonProps
  dropdown: null | HTMLElement
  setDropdown: React.Dispatch<React.SetStateAction<null | HTMLElement>>
  menu?: {
    icon?: React.ReactNode
    label: string
    onClick?: () => void
    divider?: boolean
    disabled?: boolean
  }[]
}

interface MuiMenuInterface extends MenuProps {
  width?: number
}

const MuiMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))<MuiMenuInterface>(({ theme, width }) => ({
  '& .MuiPaper-root': {
    fontSize: 12,
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: width || 100,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey[600]
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '& .MuiMenu-list': {
      padding: '4px 0',
    },

    '& .MuiSvgIcon-root, & .MuiListItemIcon-root': {
      fontSize: 18,
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[600]
          : theme.palette.grey[300],
      marginRight: theme.spacing(1),
      minWidth: 'auto',
    },
    '&:active': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      ),
    },
  },
}))

const AppDropdown: React.FC<AppDropdownProps> = ({
  name,
  menu,
  children,
  dropdown,
  setDropdown,
  titleComp,
  titleProps,
  ...props
}) => (
  <>
    <AppButton
      name={name}
      dropdown
      endIcon={<KeyboardArrowDown />}
      onClick={(e) => setDropdown(e.currentTarget)}
      {...titleProps}
    >
      {titleComp || name}
    </AppButton>
    <MuiMenu
      id={`${name}-menu`}
      MenuListProps={{
        'aria-labelledby': `${name  }-button`,
      }}
      anchorEl={dropdown}
      onClose={() => setDropdown(null)}
      {...props}
    >
      {children || (
        <MenuList>
          {menu?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              {item.divider && <Divider />}
              <MenuItem onClick={item.onClick} disabled={item.disabled}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText>{item.label}</ListItemText>
              </MenuItem>
            </div>
          ))}
        </MenuList>
      )}
    </MuiMenu>
  </>
)

export default AppDropdown

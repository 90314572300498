import BreadcrumbItem from "layouts/MainLayout/partials/TopNavigation/BreadcrumbItem";
import {Divider, Stack} from "@mui/material";
import NavigationButtons from "layouts/MainLayout/partials/TopNavigation/NavArrows";
import {useBreadcrumbs} from "contexts/BreadcrumbContext";
import {Link} from "react-router-dom";

const TopBreadcrumbs = () => {
  const {breadcrumbs} = useBreadcrumbs();

  return (
    <>
      <NavigationButtons/>

      <Stack
        aria-label="breadcrumb"
        direction="row"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem sx={{height: 12, margin: 'auto 0',}}/>}
      >
        {breadcrumbs.map((crumb, index) => {
          const isLastElement = index === breadcrumbs.length - 1;
          return isLastElement ? (
            <BreadcrumbItem active key={crumb.name}>
              {crumb.name}
            </BreadcrumbItem>
          ) : (
            crumb.link && (
              <BreadcrumbItem component={Link} to={crumb.link} key={crumb.name}>
                {crumb.name}
              </BreadcrumbItem>
            )
          );
        })}
      </Stack>
    </>
  );
};

export default TopBreadcrumbs;

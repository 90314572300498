import usePagination from "shared/hooks/usePagination";
import {
  Alert, AlertTitle, Box, Button,
  Container,
  LinearProgress, Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter
} from "@mui/material";
import useDataFilters from "apps/users/pages/UsersList/useDataFilters";
import {AppCard, AppTableHead} from "shared/components/mui";
import LoaderBackdrop from "shared/components/LoaderBackdrop";
import UserRow from "apps/users/pages/UsersList/UserRow";
import usePermissions from "apps/users/pages/UsersList/permissions/usePermissions";
import {Link as RouterLink} from "react-router-dom";
import uiPaths from "apps/users/uiPaths";

const TABLE_HEAD = [
  {id: 'first_name', label: 'First NAME'},
  {id: 'last_name', label: 'LAST NAME'},
  {id: 'email', label: 'EMAIL'},
  {id: 'phone', label: 'PHONE'},
  {id: 'role', label: 'ROLE'},
  {id: 'status', label: 'STATUS'},
  {id: 'created_at', label: 'CREATED AT'},
  {id: 'actions', label: ''},
]

const UsersList = () => {
  const {currentPage, perPage, pagination} = usePagination();

  const {canViewUserList} = usePermissions();

  const {data, isLoading, isFetching, filters} = useDataFilters({currentPage, perPage});

  if (!canViewUserList){
    return (
      <Container maxWidth="xs">
        <Alert severity="error" variant="filled">
          <AlertTitle>403 Forbidden</AlertTitle>
          You are not allowed to perform this action!
        </Alert>
      </Container>
    )
  }

  if (isLoading) return <LinearProgress/>

  return (
    <AppCard
      title='Users'
      action={
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Box mr={2}>
            <Link component={RouterLink} to={uiPaths.create.reverse({})}>
              <Button variant="outlined" color="primary">
                Create User
              </Button>
            </Link>
          </Box>
          {filters}
        </Box>
      }
    >
      <TableContainer sx={{overflow: 'unset'}}>
        <Table size='small' sx={{minWidth: 800, bgcolor: '#fff', position: 'relative'}}>
          <AppTableHead headLabel={TABLE_HEAD}/>

          <TableBody sx={{position: 'relative'}}>
            <LoaderBackdrop open={!isLoading && isFetching}/>
            {data && data.items?.map((el => (
              <UserRow user={el}/>
            )))}
          </TableBody>

          <TableFooter>
            <TableCell colSpan={9} align='right' sx={{py: 1, border: 'none'}}>
              {pagination({total: data?.total, pages: data?.pages})}
            </TableCell>
          </TableFooter>
        </Table>
      </TableContainer>
    </AppCard>
  )
}

export default UsersList;
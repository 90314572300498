import {styled, Theme, useTheme} from "@mui/material/styles";
import {Box, CSSObject, Divider, Drawer as MuiDrawer, List, ListItem, Stack, Typography,} from "@mui/material";
import {useMemo, useState} from "react";
import menuItems, {ISidebarMenuItem} from "layouts/MainLayout/partials/Sidebar/menuItems";
import SidebarMenuItem from "layouts/MainLayout/partials/Sidebar/SidebarMenuItem";
import {ReactComponent as SettingsIcon} from 'assets/iconsDS/settings.svg';
import ProfileMenu from "layouts/MainLayout/partials/Sidebar/ProfileMenu";
import uiPaths from "routes/uiPaths";
import SidebarSubMenuItem from "layouts/MainLayout/partials/Sidebar/SidebarSubMenuItem";

interface SidebarProps {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  drawerWidth: number;
  menuWidth: number;
}

const Sidebar = ({isDrawerOpen, toggleDrawer, drawerWidth, menuWidth}: SidebarProps) => {
  const dsTheme = useTheme();
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  });

  const Drawer = useMemo(
    () =>
      styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== "open"})(
        ({theme, open}) => ({
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: "nowrap",
          boxSizing: "border-box",
          "& > .MuiDrawer-paper": {
            marginLeft: '57px',
            marginTop: '52px',
            background: dsTheme.palette.themeColors.grey["20"],
            border: 'none',
          },
          ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
          }),
          ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
          }),
        })
      ),
    [drawerWidth]
  );

  const handleMenuClick = (item: ISidebarMenuItem) => {
    if (item.subMenu) {
      setActiveSubmenu(item.name);
      toggleDrawer();
    }
  };

  return (
    <Box
      sx={{
        ...openedMixin(dsTheme),
        background: dsTheme.palette.themeColors.grey["20"],
        marginTop: '52px',
        ...(isDrawerOpen
          ? {width: drawerWidth + menuWidth}
          : {width: menuWidth}),
      }}>
      <Stack
        sx={{
          position: 'fixed',
          top: '52px',
          bottom: 0,
          borderRight: `1px solid ${dsTheme.palette.themeColors.grey.border}`,
        }}
      >
        <List
          sx={{
            width: menuWidth,
            height: 'calc(100vh - 190px)',
            overflow: 'auto',
            padding: '2px 8px',
            backgroundColor: 'transparent',
            borderRadius: 0,
            boxShadow: 'none',
          }}
        >
          {menuItems.map((item) => (
            <ListItem
              key={item.name}
              disablePadding
              sx={{
                display: "block",
              }}
            >
              {item.subMenu ? (
                <SidebarMenuItem
                  icon={item.icon}
                  name={item.name}
                  link={item.link}
                  onClick={() => handleMenuClick(item)}
                />
              ) : (
                <SidebarMenuItem
                  icon={item.icon}
                  name={item.name}
                  link={item.link}
                />
              )}
            </ListItem>
          ))}
        </List>
        <Stack>
          <List
            sx={{
              width: menuWidth,
              overflow: 'auto',
              padding: '2px 8px',
              backgroundColor: 'transparent',
              borderRadius: 0,
              boxShadow: 'none',
            }}
          >
            <ListItem
              disablePadding
              sx={{
                display: "block",
              }}
            >
              <SidebarMenuItem
                name="Settings"
                icon={
                  <Box
                    component={SettingsIcon}
                    sx={{
                      '& path': {
                        stroke: dsTheme.palette.themeColors.grey['100'],
                      },
                    }}
                  />
                }
                link={uiPaths.accountPaths.settings.reverse()}
              />
            </ListItem>
          </List>
          <Box
            sx={{}}>
            <Divider
              sx={{
                width: '12px',
                margin: '16px auto 0',
              }}
            />
            <ProfileMenu/>
          </Box>
        </Stack>
      </Stack>

      <Drawer variant="permanent" open={isDrawerOpen}>
        <Box
          sx={{
            margin: '10px 25px 0',
            padding: '10px 12px 10px 12px',
          }}
        >
          <Typography
            variant="B3"
            sx={{
              color: dsTheme.palette.themeColors.grey["100"],
            }}
          >
            {activeSubmenu}
          </Typography>
        </Box>

        <List
          sx={{
            padding: '2px 16px',
            backgroundColor: 'transparent',
            borderRadius: 0,
            boxShadow: 'none',
          }}
        >
          {menuItems
            .find((item) => item.name === activeSubmenu)
            ?.subMenu?.map((submenu) => (
              <ListItem
                key={submenu.name}
                disablePadding
                sx={{
                  display: "block"
                }}
              >
                <SidebarSubMenuItem
                  name={submenu.name}
                  link={submenu.link}
                />
              </ListItem>
            ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;

import { useQuery } from "@tanstack/react-query";
import ApiClient from "apiClient";
import {TransactionQueryKeys} from "shared/queryKeys";

type Params = Parameters<typeof ApiClient.transactions.getTransactionDetails>[0]

export const useTransactionDetailsQuery = ({txn}: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: [TransactionQueryKeys.DETAILS, {txn}],
    queryFn: () => ApiClient.transactions.getTransactionDetails({txn}),
    enabled: !!txn,
  });

  return {
    data,
    isLoading,
  };
};

import {useActionCenterDetails} from "apps/actionCenter/pages/list/useDataQueries";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import DialogGeneral from "shared/components/DialogGeneral";
import {Box, Link, Typography} from "@mui/material";

interface ActionCenterDialogProps {
  email: string,
  sid: string,
  dialogHandler: DialogStateHandler;
}

const ActionCenterDialog = ({email, sid, dialogHandler}: ActionCenterDialogProps) => {
  const {data} = useActionCenterDetails({email, sid, dialogHandler})

  if (!data) return null

  return (
    <DialogGeneral dialogStateHandler={dialogHandler} dialogTitle={`KYC documents for ${email}`}>
      {data && Object.entries(data)
        .filter(([, value]) => Array.isArray(value) && value.length > 0)
        .map(([key, value]) => (
          <Box mb={2}>
            <Typography key={key} sx={{textTransform: 'uppercase'}}>{key.replaceAll('_', ' ')}</Typography>
            {value.map((item) => {
              if (item.file_type && ['.png', '.jpeg', '.jpg'].includes(item.file_type)) {
                return <img key={key} src={item.key} alt={item.key} height={300} />;
              }
              if (item.file_type === '.mp4') {
                return (
                  <video key={key} controls height={300}>
                    <source src={item.key} type='video/mp4' />
                    <track kind="captions" />
                    Your browser does not support the video tag.
                  </video>
                );
              }
              if (item.file_type && ['.pdf', '.doc', '.docx'].includes(item.file_type)){
                return <Link key={key} href={item.key}>{key}</Link>
              }
              return null;
            })}
          </Box>
        ))}
    </DialogGeneral>
  )
}

export default ActionCenterDialog;

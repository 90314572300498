import {useEffect, useRef, useState} from 'react';
import { Box, Button, IconButton, Typography } from "@mui/material";
import TextFormField from "shared/components/formFields/TextFormField";
import { ReactComponent as EditIcon } from 'assets/iconsDS/Edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/iconsDS/Delete.svg';
import { convertIsoToCustomFormat } from "shared/utils/dateFormats";
import {NoteModel} from "generatedTypes";
import * as yup from "yup";
import {useForm, FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
  useDeleteNote,
  useUpdateNote
} from "apps/transactions/pages/TransactionDetails/partials/tabs/Notes/useDataMutations";
import {useTheme} from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

interface NoteDetailsProps {
  item: NoteModel
}

const schema = yup.object().shape({
  note: yup.string()
    .required('Field is required')
    .min(1, 'Note cannot be empty')
    .max(500, 'Note cannot be longer than 500 characters')
    .matches(/^[\w !#$&'()*+,.:@-]*$/, 'Character(s) invalid or not allowed.'),
});

type FormData = yup.InferType<typeof schema>;

const NoteDetails = ({ item }: NoteDetailsProps) => {
  const theme = useTheme();

  const defaultValues: FormData = {
    note: item.note || ''
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  })

  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    if (editMode && inputRef.current) {
      const { current } = inputRef;
      const { value } = current;
      const { length } = value; // Destructure length from value
      current.focus();
      current.setSelectionRange(length, length);
    }
  }, [editMode]);

  const {mutate, isPending} = useUpdateNote({onSuccessCallback: () => setEditMode(false)})
  const {mutate: deleteNote, isPending: deleteNotePending} = useDeleteNote()

  const onSubmit = (formData: FormData) => {
    mutate(
      {
        txn: item.txn || '',
        noteId: item._id,
        requestBody: {note: formData.note}}
    )
  }

  const handleDeleteNote = () => {
    deleteNote({
      txn: item.txn || '',
      noteId: item._id
    })
  }

  const handleOnCancel = () => {
    methods.reset(methods.formState.defaultValues);
    setEditMode(false)
  }

  return editMode ? (
    <Box>
      <FormProvider {...methods}>
        <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
          <TextFormField
            minRows={4.7}
            variant='filled'
            name='note'
            placeholder='Note'
            multiline
            inputRef={inputRef}
          />
          <Box sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '12px' }}>
            <Button variant='outlined' color='secondary' size='large' onClick={handleOnCancel}>Cancel</Button>
            <LoadingButton
              loading={isPending}
              loadingIndicator="Saving…"
              variant="contained"
              color='primary'
              size='large'
              type="submit"
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  ) : (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='B5' color={theme.palette.themeColors.grey['110']}>
          {convertIsoToCustomFormat(item.timestamp || '', 'MMM dd yyyy, hh:mm a')}
        </Typography>
        <Typography variant='B5' color={theme.palette.themeColors.grey['100']} fontWeight={400}>
          {item.note}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px'}}>
        <IconButton onClick={handleEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDeleteNote} disabled={deleteNotePending}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default NoteDetails;
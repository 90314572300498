import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form"
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box} from "@mui/material";
import TextFormField from "shared/components/formFields/TextFormField";
import { useDebounce } from "@uidotdev/usehooks";
import useQueryString from "shared/hooks/useQueryString";

const schema = yup.object().shape({
  search: yup.string(),
})

type FormData = yup.InferType<typeof schema>;

const useFilters = () => {
  const {urlParams, setQueryString} = useQueryString()
  const [formData, setFormData] = useState<FormData>();

  const defaultSearch = urlParams.search ? String(urlParams.search) : '';

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      search: defaultSearch,
    }
  });

  const {formState} = methods;

  const currentSearch = useDebounce(methods.watch('search'), 400);

  const handleSubmit = (data: FormData) => {
    setFormData({search: data.search});
    if (defaultSearch !== currentSearch) {
      setQueryString({
        page: 1,
        search: data.search || ''
      });
    }
  };

  useEffect(() => {
    handleSubmit({search: currentSearch});
  }, [currentSearch]);


  const filters = (
    <FormProvider {...methods}>
      <Box display='flex' alignItems='center'>
        <Box width={300}>
          <TextFormField name="search" placeholder="Search by title, body"/>
        </Box>
      </Box>
    </FormProvider>
  )

  const filterData = {
    ...formData,
  }

  return {
    filterData,
    filters,
    submitCount: formState.submitCount,
    isValid: formState.isValid,
  };
}

export default useFilters;

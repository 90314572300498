import {useParams} from "react-router-dom";
import {Stack, Typography} from "@mui/material";
import {
  useCustomerFinancialDetails
} from "apps/customers/pages/CustomerDetails/partials/FinancialInstitutionSummary/useDataQueries";
import SummaryBar from "apps/customers/pages/CustomerDetails/partials/FinancialInstitutionSummary/SummaryBar";
import {FinancialDetailsModel} from "generatedTypes";
import {useTheme} from "@mui/material/styles";
import {WrapperBox} from "shared/styled/WrapperBox";

interface DataItem {
  [key: string]: string | number;

  count: number;
}

interface NormalizedItem {
  label: string;
  count: number;
}

interface NormalizedData {
  institute: NormalizedItem[];
  name: NormalizedItem[];
  method: NormalizedItem[];
  email: NormalizedItem[];
}

const normalizeData = (data?: FinancialDetailsModel['summary']): NormalizedData => {
  const normalizedData: NormalizedData = {
    institute: [],
    name: [],
    method: [],
    email: []
  };

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      normalizedData[key as keyof FinancialDetailsModel['summary']] = (data as Record<string, DataItem[]>)[key].map((item: DataItem) => {
        const newItem: NormalizedItem = {
          label: item[Object.keys(item)[0]] as string,
          count: item.count
        };
        return newItem;
      });
    }
  }

  return normalizedData;
}

const FinancialInstitutionSummary = () => {
  const theme = useTheme();
  const {customerEmail} = useParams();

  const {data} = useCustomerFinancialDetails({email: customerEmail || ''});

  const summary = normalizeData(data?.summary);

  return (
    <WrapperBox
      sx={{
        padding: '16px 24px 24px 24px',
      }}
    >
      <Typography variant="h4">
        Financial Institution Summary
      </Typography>

      <Stack spacing="12px" sx={{marginTop: '24px'}}>
        <Stack>
          <Typography variant="B4" sx={{color: theme.palette.themeColors.grey['80']}}>Institute</Typography>
          <SummaryBar data={summary.institute}/>
        </Stack>
        <Stack>
          <Typography variant="B4" sx={{color: theme.palette.themeColors.grey['80']}}>Name</Typography>
          <SummaryBar data={summary.name}/>
        </Stack>
        <Stack>
          <Typography variant="B4" sx={{color: theme.palette.themeColors.grey['80']}}>Method</Typography>
          <SummaryBar data={summary.method}/>
        </Stack>
        <Stack>
          <Typography variant="B4" sx={{color: theme.palette.themeColors.grey['80']}}>Email</Typography>
          <SummaryBar data={summary.email}/>
        </Stack>
      </Stack>
    </WrapperBox>
  )
}

export default FinancialInstitutionSummary;

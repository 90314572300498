import Select, {components, MenuListProps} from 'react-select';
import {Box, FormHelperText, LinearProgress} from "@mui/material";
import {isEmpty} from "lodash";
import {Controller, useFormContext} from "react-hook-form";
import {useEffect} from "react";

const MenuList = (props: MenuListProps) => {
  const {children, options, selectProps: {isLoading}} = props;
  return (
    <Box>
      <components.MenuList {...props}>
        {children}
        {!isEmpty(options) && isLoading && <LinearProgress/>}
      </components.MenuList>
    </Box>
  );
};

interface RSAutocompleteFormFieldProps {
  name: string,
  loadMoreOptions?: () => void,
  options: any[],
  isLoading?: boolean,
  handleInputChange?: (value: string) => void,
  loadingMessage?: () => string,
  isMulti?: boolean,
  preselectFirst?: boolean,
  placeholder?: string,
  isReadonly?: boolean,
}

const RSAutocompleteFormField = ({
  name,
  loadMoreOptions,
  options,
  isLoading,
  isMulti,
  handleInputChange,
  loadingMessage = () => 'Loading...',
  preselectFirst = false,
  placeholder,
  isReadonly = false,
}: RSAutocompleteFormFieldProps) => {
  const {control, setValue, getValues} = useFormContext();

  useEffect(() => {
    if (!preselectFirst) return

    if (options.length > 0 && !getValues(name)) {

      if (isMulti) {
        setValue(name, [options[0].value])
      } else {
        setValue(name, options[0].value)
      }
    }
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => {
        let fieldValue = field.value;
        if (isMulti) {
          if (!Array.isArray(field.value)) {
            fieldValue = [fieldValue];
          }
          fieldValue = fieldValue.map((el: any) => el.toString());
        }

        return (
          <>
            <Select
              {...field}
              value={isMulti
                ? options.filter((option) => fieldValue.includes(option.value) || fieldValue.includes(option.value.toString()))
                : options.find((option) => fieldValue && option.value.toString() === fieldValue.toString()) || null
              }
              onChange={(newValue) => {
                const val = isMulti ? newValue.map((option: any) => option.value) : newValue?.value;
                return field.onChange(val)
              }}
              options={options}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 2,
                }),
              }}
              components={{MenuList}}
              onMenuScrollToBottom={loadMoreOptions}
              isLoading={isLoading}
              loadingMessage={loadingMessage}
              onInputChange={handleInputChange}
              placeholder={placeholder}
              isMulti={isMulti}
              isDisabled={isReadonly}
            />
            {fieldState.invalid &&
              <FormHelperText style={{color: '#EC6764'}}>{fieldState.error?.message}</FormHelperText>}
          </>
        )
      }}
    />
  )
}

export default RSAutocompleteFormField;

import {useState} from "react";
import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {IApiError} from "shared/types";
import {UseFormSetError} from "react-hook-form";
import {FormData} from "shared/features/ActionUpdateUserLimits/index";
import {UserLimitsQueryKeys} from "shared/queryKeys";

type CreateUserLimitsParams = Parameters<typeof ApiClient.userLimits.createUserLimitOrBlacklist>[0]

function setFormErrors(error: IApiError, setError: UseFormSetError<FormData>) {
  error.body.errors.forEach((err: any) => {
    Object.keys(err).forEach((key: string) => {
      // @ts-ignore
      setError(key, {type: 'api_response', message: err[key].message});
    });
  });
}

interface ProcessBatchUploadArgs {
  setError: UseFormSetError<FormData>
}


export const useCreateUserLimits = ({setError}: ProcessBatchUploadArgs) => {
  const [apiErrors, setApiErrors] = useState<any>()
  const [apiSuccess, setApiSuccess] = useState<any>()
  const {enqueueSnackbar} = useSnackbar()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (params: CreateUserLimitsParams) => ApiClient.userLimits.createUserLimitOrBlacklist(params),
    onMutate: () => {
      setApiErrors(undefined)
    },
    onError: (error: IApiError) => {
      if (error.body.errors) {
        setFormErrors(error, setError)
      }
      if (error.body.detail.api_errors) {
        setApiErrors(error.body.detail.api_errors)
      }
      if (error.body.detail.permissions) {
        enqueueSnackbar(error.body.detail.permissions, {variant: 'error'})
      }
    },
    onSuccess: (data) => {
      setApiSuccess(data)
      queryClient.removeQueries({queryKey: [UserLimitsQueryKeys.DETAILS, {contact: data.email, sid: data.sid}]})

      enqueueSnackbar('User limit entry updated successfully', {variant: 'success'})
    },
  })

  return {
    mutation,
    apiErrors,
    apiSuccess,
  };
}

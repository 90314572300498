import React from 'react';
import {keepPreviousData, useQuery} from "@tanstack/react-query";
import ApiClient from "apiClient";
import {Box, LinearProgress, Link as MuiLink, ListItemText, MenuItem, MenuList,} from "@mui/material";
import Paper from "@mui/material/Paper";
import {Link} from "react-router-dom";
import {capitalize} from "lodash";
import Highlighter from "react-highlight-words";
import {useTheme} from "@mui/material/styles";
import SearchTextField from "shared/components/DS/SearchTextField";


const AutoSuggestSearch = () => {
  const theme = useTheme();
  const [search, setSearch] = React.useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {data, isLoading, isFetching} = useQuery({
    queryKey: ['autosuggest-search', {search}],
    queryFn: () => ApiClient.transactions.searchDocumentsGrouped({requestBody: {search_phrase: search}}),
    enabled: !!search,
    placeholderData: keepPreviousData,
  });

  return (
    <Box>
      <SearchTextField
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />

      <Box sx={{position: 'relative'}}>
        <Box width='200px' sx={{position: 'absolute', zIndex: 4, marginTop: '-3px'}}>
          {(isLoading || isFetching) && <LinearProgress/>}
        </Box>
        {search && data && (
          <Paper sx={{position: 'absolute', zIndex: 3}}>
            <MenuList dense>
              {data?.group_sets && Object.entries(data?.group_sets).map((item) => {
                if (item[1].length === 0) {
                  return null;
                }

                return (
                  <>
                    <MenuItem>
                      <ListItemText>
                        <strong>{capitalize(item[0])}</strong>
                      </ListItemText>
                    </MenuItem>
                    {item[1].map((group) => group?.documents?.map((doc) => (
                      <MenuItem>
                        <ListItemText>
                          <MuiLink
                            component={Link}
                            to={`/transactions/${doc.txn}`}
                            sx={{
                              '& .highlight': {
                                backgroundColor: theme.palette.themeColors.orange['20'],
                              },
                            }}
                          >
                            <Highlighter
                              highlightClassName='highlight'
                              searchWords={[search]}
                              autoEscape
                              // @ts-ignore
                              textToHighlight={String(doc[item[0]])}
                            />
                            <br/>
                            Txn: {doc.txn}
                          </MuiLink>
                        </ListItemText>
                      </MenuItem>
                    )))}
                  </>
                )
              })}
            </MenuList>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default AutoSuggestSearch;

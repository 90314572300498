import palette from "theme/palette";

export const colorDot: { [key: string]: string } = {
  credit_card: palette.themeColors.iris['20'],
  reward_card: palette.themeColors.blush['20'],
  online_banking: palette.themeColors.turquoise['20'],
  direct_deposit: palette.themeColors.violet['30'],
  digital_cheque: palette.themeColors.pink['20'],
  rtp_payout: palette.themeColors.teal['20'],

  credit_send: palette.themeColors.alge['20'],
  CreditSendVisa: palette.themeColors.alge['20'],
  CreditSendMasterCard: palette.themeColors.alge['20'],

  etransfer_standard: palette.themeColors.orange['20'],
  etransfer_send_money: palette.themeColors.orange['20'],
  etransfer_request_money: palette.themeColors.orange['20'],

  ach_payment: palette.themeColors.blue['20'],
  ach_payout: palette.themeColors.blue['20'],

  interac_online: palette.themeColors.brown['20'],
}

export const colorText: { [key: string]: string } = {
  credit_card: palette.themeColors.iris['30'],
  reward_card: palette.themeColors.blush['30'],
  online_banking: palette.themeColors.turquoise['30'],
  direct_deposit: palette.themeColors.violet['30'],
  digital_cheque: palette.themeColors.pink['30'],
  rtp_payout: palette.themeColors.teal['30'],

  credit_send: palette.themeColors.alge['20'],
  CreditSendVisa: palette.themeColors.alge['20'],
  CreditSendMasterCard: palette.themeColors.alge['20'],

  etransfer_standard: palette.themeColors.orange['30'],
  etransfer_send_money: palette.themeColors.orange['30'],
  etransfer_request_money: palette.themeColors.orange['30'],

  ach_payment: palette.themeColors.blue['30'],
  ach_payout: palette.themeColors.blue['30'],

  interac_online: palette.themeColors.brown['30'],
}

export const methodText: { [key: string]: string } = {
  credit_card: 'Credit Card',
  reward_card: 'Reward Card',
  online_banking: 'Online Banking',
  direct_deposit: 'Direct Deposit',
  digital_cheque: 'Digital Cheque',
  rtp_payout: 'RTP Payout',

  credit_send: 'Credit Send',
  CreditSendVisa: 'Credit Send',
  CreditSendMasterCard: 'Credit Send',

  etransfer_standard: 'e-Transfer',
  etransfer_send_money: 'e-Transfer',
  etransfer_request_money: 'e-Transfer',

  ach_payment: 'ACH',
  ach_payout: 'ACH',

  interac_online: 'Interac Online',
}

import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIconButton from "shared/CloseButton";
import LoadingButton from "@mui/lab/LoadingButton";
import useDialogState, {DialogStateHandler} from "shared/hooks/useDialogState";
import {FormProvider, useForm} from "react-hook-form";
import TextFormField from "shared/components/formFields/TextFormField";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useVerifyAuthApp} from "apps/account/pages/settings/AuthApp/useDataMutations";
import RecoveryCodeDialog from "apps/account/pages/settings/AuthApp/RecoveryCodeDialog";
import {useMemo} from "react";

interface VerifyAuthAppDialogProps {
  dialogStateHandler: DialogStateHandler,
  totpUri: string
}

const schema = yup.object().shape({
  securityCode: yup.string().max(6).required('Security code is required'),
})

type FormData = yup.InferType<typeof schema>;

const VerifyAuthAppDialog = ({dialogStateHandler, totpUri}: VerifyAuthAppDialogProps) => {
  const codesDialogHandler = useDialogState();

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const qrCodeImage = useMemo(() => `https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${totpUri}`, [totpUri]);

  const {
    mutate,
    isPending,
    errorMessage,
    oneTimeUseCodes
  } = useVerifyAuthApp(
    {dialogHandler: dialogStateHandler, codesDialogHandler}
  )

  const onSubmit = (data: FormData) => {
    if (data.securityCode){
      mutate({securityCode: data.securityCode})
    }
  }

  return (
    <>
      <Dialog open={dialogStateHandler.isOpen} onClose={dialogStateHandler.closeDialog} fullWidth maxWidth='xs'>
        <DialogTitle id="modal-dialog-title">
          Scan and Enter Security Code
        </DialogTitle>
        <CloseIconButton onClick={dialogStateHandler.closeDialog}/>
        <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} sx={{width: '100%'}}>
          <FormProvider {...methods}>
            <DialogContent>
              <Box sx={{display: 'flex', justifyContent: 'center', mb: 1}}>
                <img width={300} height={300} alt={qrCodeImage} src={qrCodeImage} />
              </Box>
              <TextFormField name="securityCode" placeholder="Security code"/>
              {errorMessage && <Alert severity="error" sx={{mt: 1}}>{ errorMessage }</Alert>}
            </DialogContent>
            <DialogActions>
              <LoadingButton
                loading={isPending}
                variant="contained"
                loadingIndicator="Submitting…"
                type="submit"
                disabled={!methods.formState.isDirty || !methods.formState.isValid}
                sx={{width: 120}}
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </FormProvider>
        </Box>
      </Dialog>

      <RecoveryCodeDialog dialogStateHandler={codesDialogHandler} oneTimeUseCodes={oneTimeUseCodes || []} />
    </>
  )
}

export default VerifyAuthAppDialog;
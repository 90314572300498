import {useHasAccess} from "contexts/UserAccessContext";
import {Permissions, Roles} from "contexts/UserAccessContext/const";

const usePermissions = () => {
  const canCreateNote = useHasAccess([
    {roles: [Roles.ADMIN]},
    {roles: [Roles.CUSTOMER_SERVICE], permissions: [Permissions.ADD_NOTE]},
  ])

  const canViewAPILookup = useHasAccess([
    {'roles': [Roles.ADMIN]},
    {
      'roles': [Roles.CUSTOMER_SERVICE, Roles.MANAGER, Roles.MERCHANT, Roles.RETAILER, Roles.SITE],
      'permissions': [Permissions.API_LOOKUP]
    }
  ])

  const canViewText = useHasAccess([
    {'roles': [Roles.ADMIN, Roles.CUSTOMER_SERVICE]},
  ])

  const canAddToBlacklist = useHasAccess([
    {
      'roles': [Roles.ADMIN, Roles.CUSTOMER_SERVICE],
      'permissions': [Permissions.ADD_TO_BLACKLIST]
    }
  ])

  const canRecoverTransaction = useHasAccess([
    {
      'roles': [Roles.ADMIN, Roles.CUSTOMER_SERVICE],
      'permissions': [Permissions.RECOVER_WORKER]
    }
  ])

  const canCreateChargeback = useHasAccess([
    {
      'roles': [Roles.ADMIN, Roles.CUSTOMER_SERVICE],
      'permissions': [Permissions.CHARGEBACK]
    }
  ])

  const canCreateRefund = useHasAccess([
    {
      'roles': [Roles.ADMIN, Roles.CUSTOMER_SERVICE],
      'permissions': [Permissions.REFUND_TRANSACTION]
    }
  ])

  const canCancelTransaction = useHasAccess([
    {
      'roles': [Roles.ADMIN],
    },
    {
      'roles': [Roles.CUSTOMER_SERVICE, Roles.MANAGER, Roles.MERCHANT, Roles.RETAILER, Roles.SITE],
      'permissions': [Permissions.CANCEL_TRANSACTION]
    }
  ])

  const canDeclineTransaction = useHasAccess([
    {
      'roles': [Roles.ADMIN],
    },
    {
      'roles': [Roles.CUSTOMER_SERVICE, Roles.MANAGER, Roles.MERCHANT, Roles.RETAILER, Roles.SITE],
      'permissions': [Permissions.DECLINE_TRANSACTION]
    }
  ])

  const canPatchTransaction = useHasAccess([])

  return {
    canCreateNote,
    canViewAPILookup,
    canViewText,
    canAddToBlacklist,
    canRecoverTransaction,
    canCreateChargeback,
    canCreateRefund,
    canPatchTransaction,
    canCancelTransaction,
    canDeclineTransaction,
  }
}

export default usePermissions

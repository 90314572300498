import {DialogStateHandler} from "shared/hooks/useDialogState";
import UniversalDialog from "shared/components/DS/UniversalDialog";
import {useCopyToClipboard} from "@uidotdev/usehooks";
import {useEffect} from "react";
import {useSnackbar} from "notistack";
import List from "@mui/material/List";
import {CustomerOverview} from "generatedTypes";
import IPAddressItem from "apps/customers/pages/CustomerDetails/partials/CustomerInfo/SeeIPsDialog/IPAddressItem";
import {styled} from "@mui/material/styles";

const StyledList = styled(List)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.themeColors.grey['20'],
  padding: '10px 12px',
  width: '100%',

  '& .MuiListItemSecondaryAction-root': {
    right: 0
  }
}));

interface SeeIPsDialogProps {
  dialogStateHandler: DialogStateHandler
  ipAddresses: CustomerOverview['ip_addresses']
}

const SeeIPsDialog = ({dialogStateHandler, ipAddresses}: SeeIPsDialogProps) => {
  const [isCopied, copyToClipboard] = useCopyToClipboard();
  const {enqueueSnackbar} = useSnackbar()

  const handleSubmitClick = () => {
    const ipAddressList: string[] = ipAddresses.map(item => item.ip_address);
    void copyToClipboard(ipAddressList.join(', '));
  }

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar('Copied to clipboard', {variant: 'success'});
    }
  }, [isCopied]);

  return(
    <UniversalDialog
      dialogStateHandler={dialogStateHandler}
      title='The list with all IP Addresses'
      cancelButtonText='Close'
      submitButtonText='Copy'
      onSubmitClick={handleSubmitClick}
      onCancel={dialogStateHandler.closeDialog}
    >
      <StyledList>
        {ipAddresses.map((item) => (
          item.ip_address ? <IPAddressItem item={item} key={`${item.ip_address}-${item.count}`} /> : null
        ))}
      </StyledList>
    </UniversalDialog>
  )
}

export default SeeIPsDialog;
/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardRequestsListPaginatedModel } from '../models/DashboardRequestsListPaginatedModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DashboardRequestsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Dashboard Requests
     * Get dashboard requests list.
     * @returns DashboardRequestsListPaginatedModel Successful Response
     * @throws ApiError
     */
    public getDashboardRequests({
        page,
        perPage,
        search,
        action,
        status,
        sid,
        txn,
        orderBy,
    }: {
        page?: (number | null),
        perPage?: (number | null),
        search?: (string | null),
        action?: (string | null),
        status?: (string | null),
        sid?: (Array<number> | null),
        txn?: (string | null),
        orderBy?: (string | null),
    }): CancelablePromise<DashboardRequestsListPaginatedModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/dashboard-requests/',
            query: {
                'page': page,
                'per_page': perPage,
                'search': search,
                'action': action,
                'status': status,
                'sid': sid,
                'txn': txn,
                'order_by': orderBy,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}

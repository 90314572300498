import {Components} from "@mui/material/styles";
import palette from "theme/palette";

const MuiToggleButtonGroup: Components['MuiToggleButtonGroup'] = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    grouped: {
      margin: '0',
      border: '1px solid',
      borderRadius: '4px',
      borderColor: palette.themeColors.grey['70'],
      marginLeft: '12px',
      backgroundColor: palette.themeColors.grey['20'],

      'svg path': {
        stroke: palette.themeColors.grey['100'],
      },

      'svg rect': {
        stroke: palette.themeColors.grey['100'],
      },

      '&:hover': {
        color: palette.themeColors.grey['110'],
        borderColor: palette.themeColors.grey['80'],
        backgroundColor: palette.themeColors.grey['30'],

        'svg path': {
          stroke: palette.themeColors.grey['110'],
        },

        'svg rect': {
          stroke: palette.themeColors.grey['110'],
        },
      },

      '&.Mui-focusVisible': {
        color: palette.themeColors.purple['20'],
        backgroundColor: palette.themeColors.grey['30'],
        outline: '3px solid rgba(129, 69, 181, 0.10)'
      },

      '&.Mui-selected': {
        borderColor: palette.themeColors.purple['20'],
        backgroundColor: palette.themeColors.purple['30'],
        color: palette.themeColors.purple['20'],

        'svg path': {
          stroke: palette.themeColors.purple['20'],
        },

        'svg rect': {
          stroke: palette.themeColors.purple['20'],
        },
      },

      '&.Mui-selected:hover': {
        color: palette.themeColors.purple['20'],
        backgroundColor: palette.themeColors.purple['50'],

        'svg path': {
          stroke: palette.themeColors.purple['20'],
        },

        'svg rect': {
          stroke: palette.themeColors.purple['20'],
        }
      },

      '&.Mui-selected:active': {
        backgroundColor: palette.themeColors.purple['50'],
        borderColor: palette.themeColors.purple['20'],
        color: palette.themeColors.purple['20'],

        'svg path': {
          stroke: palette.themeColors.purple['20'],
        },

        'svg rect': {
          stroke: palette.themeColors.purple['20'],
        },
      },

      '&.Mui-selected + .MuiToggleButtonGroup-grouped.Mui-selected': {
        borderLeft: '1px solid',
        borderLeftColor: palette.themeColors.purple['20'],
        marginLeft: '12px',
      },

    },
    firstButton: {
      marginLeft: 0,
    },
    lastButton: {
      marginRight: 0,
    },
  },
}

export default MuiToggleButtonGroup;

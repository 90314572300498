import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";

interface Item {
  message: string[];
  status: boolean;
  udf1: string;
}

export interface Report {
  comment?: string;
  items?: Item[];
}

const ErrorsList = (error: Report) => (
  <Paper sx={{p: 2}}>
    <Typography variant="h6" color="error">Error: {error.comment}</Typography>
    <Typography variant="subtitle1">Report:</Typography>
    <TableContainer>
      <Table className="table table-bordered table-sm">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>UDF1</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {error.items?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{item.udf1}</TableCell>
              <TableCell>
                {item.status ? 'Accepted' : 'Declined'}
              </TableCell>
              <TableCell>
                <ol className="m-0">
                  {item.message.map((msg, msgIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={msgIndex} className="text-capitalize">{msg}</li>
                  ))}
                </ol>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
);

export default ErrorsList;

import {useQuery} from "@tanstack/react-query";
import {ConfigsQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";

export const usePermissionsDataQuery = () => {
  const {data, isLoading} = useQuery({
    queryKey: [ConfigsQueryKeys.USER_PERMISSIONS],
    queryFn: () => ApiClient.configs.getPermissionsList({}),
  })

  return {
    data,
    isLoading
  };
};

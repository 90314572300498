import React from 'react'
// @mui
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
  TableHeadProps,
} from '@mui/material'

// ----------------------------------------------------------------------

interface TransTableHeadProps extends TableHeadProps {
  order?: 'asc' | 'desc'
  orderBy?: string
  firstHeadLabel?: {
    label: string,
    colspan: number,
  }[]
  headLabel: {
    id: string
    label: string
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    align?: 'center' | 'left' | 'right' | 'inherit' | 'justify' | any
    width?: string
    minWidth?: string
    sortable?: boolean
  }[]
  rowCount?: number
  numSelected?: number
  onSort?: (id: string) => void
  onSelectAllRows?: any
  title?: string
  actionButton?: React.ReactNode;
}

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
}

// ----------------------------------------------------------------------

const TransTableHead: React.FC<TransTableHeadProps> = ({
  order,
  orderBy,
  rowCount = 0,
  firstHeadLabel,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  title,
  actionButton,
  ...props
}) => (
  <TableHead {...props}>
    {title && (
      <TableRow>
        <TableCell align='center' colSpan={headLabel.length} style={{ borderBottom: 'none' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Typography>{title}</Typography>
            {actionButton}
          </Box>
        </TableCell>
      </TableRow>
    )}

    {firstHeadLabel && (
      <TableRow>
        {firstHeadLabel.map((headCell) => (
          <TableCell
            key={headCell.label}
            colSpan={headCell.colspan}
            align='center'
            sx={{ p: 2, border: '1px solid rgba(224, 224, 224, 1)' }}
          >
            <Typography
              variant='body2'
              fontWeight='medium'
              textTransform='uppercase'
              fontSize={12}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    )}

    <TableRow>
      {onSelectAllRows && (
        <TableCell padding='checkbox'>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllRows}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
      )}

      {headLabel.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.align || 'left'}
          sortDirection={orderBy === headCell.id ? order : false}
          sx={{ width: headCell.width, minWidth: headCell.minWidth, p: 2 }}
        >
          {(onSort && headCell.sortable) ? (
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => onSort(headCell.id)}
              sx={{ textTransform: 'capitalize' }}
            >
              <Typography
                variant='body2'
                fontWeight='medium'
                textTransform='uppercase'
                fontSize={12}
              >
                {headCell.label}
              </Typography>

              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            <Typography
              variant='body2'
              fontWeight='medium'
              textTransform='uppercase'
              fontSize={12}
            >
              {headCell.label}
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
)

export default TransTableHead

import {Components} from "@mui/material/styles";
import palette from "theme/palette";

const MuiDialogActions: Components['MuiDialogActions'] =  {
  styleOverrides: {
    root: {
      padding: '12px',
      borderTop: '1px solid',
      borderColor: palette.themeColors.grey['50'],
    }
  }
}

export default MuiDialogActions;

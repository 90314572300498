import Select2FAMethod from "apps/auth/pages/login/partials/Select2FAMethod";
import loginReducer from "apps/auth/pages/login/reducer";
import {useReducer} from "react";
import {LoginReducerState} from "apps/auth/pages/login/types";
import {LoginReducerActionType} from "apps/auth/pages/login/enums";
import LoginForm from "apps/auth/pages/login/partials/LoginForm";
import EmailAddressLink from "apps/auth/pages/login/partials/EmailAddressLink";
import EmailAddressCode from "apps/auth/pages/login/partials/EmailAddressCode";

const initialState: LoginReducerState = {
  initial: true,
  two_fa_enabled: undefined,
  preferred_2fa: undefined,
  access_token: undefined,
  user_email: undefined,
}


function LoginView() {
  const [state, dispatch] = useReducer(loginReducer, initialState);

  const handle2FAChange = (two_fa_enabled: boolean) => {
    dispatch({type: LoginReducerActionType.SET_2FA, payload: two_fa_enabled})
  }
  const handleMethod2FAChange = (preferred_2fa: string) => {
    dispatch({type: LoginReducerActionType.SET_METHOD_2FA, payload: preferred_2fa})
  }

  const handleEmailChange = (email: string) => {
    dispatch({type: LoginReducerActionType.SET_EMAIL, payload: email})
  }

  return (
    <>
      {state.initial
        && <LoginForm
          handle2FAChange={handle2FAChange}
          handleMethod2FAChange={handleMethod2FAChange}
          handleEmailChange={handleEmailChange}
        />
      }

      {(state.two_fa_enabled && !state.preferred_2fa)
        && <Select2FAMethod
          user_email={state.user_email}
          handleMethod2FAChange={handleMethod2FAChange}
        />
      }

      {(state.two_fa_enabled && state.preferred_2fa === 'email_address_link')
      && <EmailAddressLink />
      }

      {(state.two_fa_enabled && state.preferred_2fa === 'email_address')
      && <EmailAddressCode
        user_email={state.user_email}
      />
      }

    </>
  );
}

export default LoginView;

import Timeline from "@mui/lab/Timeline";
import TimelineItem, {timelineItemClasses} from "@mui/lab/TimelineItem";
import {isEmpty} from "lodash";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import {Box, Button, Typography} from "@mui/material";
import {TimelineSchema} from "generatedTypes";
import {useTheme} from "@mui/material/styles";
import DatetimeTypography from "apps/transactions/pages/TransactionDetails/partials/tabs/Timeline/partials/DatetimeTypography";

interface ShortTimelineProps {
  data: TimelineSchema
  handleButtonClick: () => void
}

const ShortTimeline = ({data, handleButtonClick}: ShortTimelineProps) => {
  const theme = useTheme();

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },

        [`& .${timelineItemClasses.root}:first-of-type .MuiTimelineDot-root`]: {
          marginBottom: '0',
        },

        [`& .${timelineItemClasses.root}:first-of-type .MuiTimelineConnector-root`]: {
          position: 'absolute',
          height: '80%',
          top: '14px',
        },

        [`& .${timelineItemClasses.root} .MuiTimelineConnector-root`]: {
          borderRadius: '4px'
        }
      }}
    >
      {!isEmpty(data.api_started) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                Transaction created
              </Typography>
              <DatetimeTypography datetime={data.api_started.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot/>
          <TimelineConnector/>
        </TimelineSeparator>
        <TimelineContent>
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Button variant='outlined' color='secondary' size='small' onClick={handleButtonClick}>
              See All Timeline
            </Button>
          </Box>
        </TimelineContent>
      </TimelineItem>

      {!isEmpty(data.process_ended) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot/>
          </TimelineSeparator>
          <TimelineContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography variant='B4' color={theme.palette.secondary.main}>
                Transaction process ended
              </Typography>
              <DatetimeTypography datetime={data.process_ended.datetime} />
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}
    </Timeline>
  )
}

export default ShortTimeline;
import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {ActionCenterQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";
import {DialogStateHandler} from "shared/hooks/useDialogState";

type Params = Parameters<typeof ApiClient.actionCenter.getActionCenterList>[0]

export const useActionCenter = ({
  page,
  perPage,
  orderBy,
  ...filters
}: Params) => {
  const {data, isLoading, isFetching, refetch} = useQuery({
    queryKey: [ActionCenterQueryKeys.LIST, {page, perPage, orderBy, filters: {filters}}],
    queryFn: () => ApiClient.actionCenter.getActionCenterList({page, perPage, orderBy, ...filters}),
    placeholderData: keepPreviousData,
  })

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  }
}

interface ActionCenterDetailsParams {
  email: string;
  sid: string;
  dialogHandler: DialogStateHandler;
}

export const useActionCenterDetails = ({email, sid, dialogHandler}: ActionCenterDetailsParams) => {
  const {data, isLoading, isFetching} = useQuery({
    queryKey: [ActionCenterQueryKeys.DETAILS, {email, sid}],
    queryFn: () => ApiClient.actionCenter.getActionCenterDetails({email, sid}),
    placeholderData: keepPreviousData,
    enabled: dialogHandler.isOpen,
  })

  return {
    data,
    isLoading,
    isFetching,
  }
}
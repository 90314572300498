import { useQuery } from "@tanstack/react-query";
import ApiClient from "apiClient";
import {TransactionQueryKeys} from "shared/queryKeys";
import {IApiError} from "shared/types";

type ApiLookupResponse = ReturnType<typeof ApiClient.transactions.getApiLookup>;
type Params = Parameters<typeof ApiClient.transactions.getApiLookup>[0]

interface ApiLookupQueryArgs {
  txn?: Params['txn'];
  isEnabled: boolean;
}

export const useApiLookupQuery = ({txn = '', isEnabled}: ApiLookupQueryArgs) => {
  const { data, isLoading, error } = useQuery<ApiLookupResponse, IApiError>({
    queryKey: [TransactionQueryKeys.API_LOOKUP, {txn}],
    queryFn: () => ApiClient.transactions.getApiLookup({txn}),
    enabled: !!txn && isEnabled,
  });

  return {
    data,
    isLoading,
    error,
  };
};

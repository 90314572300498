// @ts-nocheck
import { ReactComponent as CreditSendVisa } from 'assets/methods/CreditSendVisa.svg';
import { ReactComponent as CreditSendMasterCard } from 'assets/methods/CreditSendMasterCard.svg';
import { ReactComponent as CreditCard} from "assets/methods/CreditCard.svg";
import { ReactComponent as RewardCard} from "assets/methods/RewardCard.svg";
import { ReactComponent as OnlineBanking} from "assets/methods/OnlineBanking.svg";
import { ReactComponent as InteracOnline} from "assets/methods/InteracOnline.svg";
import { ReactComponent as EtransferStandard} from "assets/methods/EtransferStandard.svg";
import { ReactComponent as EtransferSendMoney} from "assets/methods/EtransferSendMoney.svg";
import { ReactComponent as EtransferRequestMoney} from "assets/methods/EtransferRequestMoney.svg";
import { ReactComponent as DirectDeposit} from "assets/methods/DirectDeposit.svg";
import { ReactComponent as DigitalCheque} from "assets/methods/DigitalCheque.svg";
import {TransactionsModel} from "generatedTypes";

type IconComponent = {
  [key: string]: JSX.Element;
};

interface MethodIconProps {
  method: TransactionsModel['method']
  descriptor: TransactionsModel['descriptor'];
  width?: number;
}

const MethodIcon = ({method, descriptor, width = 20}: MethodIconProps) => {
  if (!method) {
    return null;
  }

  const Icons: IconComponent = {
    CreditSendVisa: <CreditSendVisa width={width}/>,
    CreditSendMasterCard: <CreditSendMasterCard width={width}/>,
    credit_card: <CreditCard width={width}/>,
    reward_card: <RewardCard width={width}/>,
    online_banking: <OnlineBanking width={width}/>,
    interac_online: <InteracOnline width={width}/>,
    etransfer_standard: <EtransferStandard width={width}/>,
    etransfer_send_money: <EtransferSendMoney width={width}/>,
    etransfer_request_money: <EtransferRequestMoney width={width}/>,
    direct_deposit: <DirectDeposit width={width}/>,
    digital_cheque: <DigitalCheque width={width}/>,
  };

  if (method === 'credit_send') {
    return descriptor ? Icons[descriptor] : null;
  }
  return Icons[method];
};

export default MethodIcon;

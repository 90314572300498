import {List} from "@mui/material";
import EventTransaction from "apps/dashboard/pages/Dashboard/partials/LiveStreaming/EventTransaction";
import useRecentEventsTransactionsQuery from "apps/dashboard/pages/Dashboard/partials/LiveStreaming/useDataQueries";
import useRecentTransactionsSocketData from "apps/dashboard/pages/Dashboard/partials/LiveStreaming/useSocketData";
import {StreamType} from "apps/dashboard/pages/Dashboard/partials/LiveStreaming/types";
import {SocketServerRecentTransactions} from "generatedTypes";


interface EventsListProps {
  streamType: StreamType
}

const EventsList = ({streamType}: EventsListProps) => {
  const {data: socketData} = useRecentTransactionsSocketData()
  const {data} = useRecentEventsTransactionsQuery()

  const filterFn = (element: SocketServerRecentTransactions) => {
    if (streamType === 'everything') return true;

    return element.type === streamType;
  }

  const socketTransactions = socketData?.filter((element) => filterFn(element)) || [];
  const apiTransactions = data?.items?.filter((element) => filterFn(element)) || [];

  return (
    <List sx={{width: '100%', bgcolor: 'background.paper', px: 2}}>
      {socketTransactions.map((item) => (
        <EventTransaction item={item} key={item.timestamp}/>
      ))}
      {apiTransactions.map((item) => (
        <EventTransaction item={item} key={item.timestamp}/>
      ))}
    </List>
  );
};

export default EventsList;

import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

export const AnimatedBox = styled(Box)(() => ({
  width: '33.3333%',
  height: '8px',
  borderRadius: '4px',
  transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out',
  '&:hover': {
    width: '70%',
    height: '20px',
    '& .text': {
      opacity: 1,
      transition: 'opacity 0.3s ease-in-out',
    },
  },
  '& .text': {
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    padding: '2px 4px',
  },
}));
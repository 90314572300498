import {Badge, Box, Divider, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, styled} from '@mui/material'
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import AppIcon from "shared/components/AppIcon";
import ICONS from "shared/components/icons";
import {useNotificationsList} from "apps/notifications/list/useDataQueries";
import {Link as RouterLink} from "react-router-dom";
import uiPaths from "apps/notifications/uiPaths";
import {ReactComponent as BellIcon} from "assets/iconsDS/bell.svg";
import {useTheme} from "@mui/material/styles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MuiIconButton = styled(IconButton)(({theme}) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: 12,

  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}))

const Notifications = () => {
  const theme = useTheme();
  const popupState = usePopupState({variant: 'popover', popupId: 'demoMenu'})

  const {data} = useNotificationsList({
    page: 1,
    perPage: 10,
    orderBy: 'read',
  });

  return (
    <Box>
      <IconButton
        aria-label='notifications'
        {...bindTrigger(popupState)}
        sx={{padding: '8px 8px'}}
      >
        <Badge
          badgeContent={data?.total}
          sx={{
            '& .MuiBadge-badge': {
              display: 'flex',
              padding: '0px 5px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderRadius: '4px',
              background: theme.palette.themeColors.primaryShades.purple30,
              color: theme.palette.primary.main,
            },
          }}
        >
          <BellIcon/>
        </Badge>
      </IconButton>

      <Menu
        {...bindMenu(popupState)}
        slotProps={{
          paper: {
            sx: {width: '450px'},
          },
        }}
      >
        {data?.items?.map((item) => (
          <Link key={item._id} component={RouterLink} underline='none' color='inherit'
            to={uiPaths.details.reverse({notificationId: item._id})}>
            <MenuItem>
              <ListItemIcon>
                <AppIcon src={ICONS.notification}/>
              </ListItemIcon>
              <ListItemText>
                {item.title}
              </ListItemText>
            </MenuItem>
          </Link>
        ))}

        <Divider/>

        <Link align='center' underline="none" component={RouterLink} to={uiPaths.list.reverse({})}>
          <MenuItem>
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
              Read all notifications
            </Box>
          </MenuItem>
        </Link>
      </Menu>
    </Box>
  )
}

export default Notifications

import {Alert, Box, LinearProgress,} from "@mui/material";
import {useParams} from "react-router-dom";
import 'highlight.js/styles/atom-one-dark.css';

import {DialogStateHandler} from "shared/hooks/useDialogState";
import HLJson from "shared/components/highlighted/HLJson";
import {useEffect} from "react";
import {
  useApiLookupQuery
} from "apps/transactions/pages/TransactionDetails/partials/TransactionActions/partials/ActionApiLookup/useDataQueries";
import UniversalDialog from "shared/components/DS/UniversalDialog";
import {useCopyToClipboard} from "@uidotdev/usehooks";
import {useSnackbar} from "notistack";

interface APILookupActionProps {
  dialogStateHandler: DialogStateHandler
}

const ActionApiLookupDialog = ({dialogStateHandler}: APILookupActionProps) => {
  const {transactionId} = useParams();
  const [isCopied, copyToClipboard] = useCopyToClipboard();
  const {enqueueSnackbar} = useSnackbar()

  const {data, isLoading, error} = useApiLookupQuery({txn: transactionId, isEnabled: dialogStateHandler.isOpen});

  const handleSubmitClick = () => {
    void copyToClipboard(JSON.stringify(data, null, 2));
  }

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar('Copied to clipboard', {variant: 'success'});
    }
  }, [isCopied]);

  return (
    <UniversalDialog
      dialogStateHandler={dialogStateHandler}
      title={`API Lookup - (${transactionId})`}
      cancelButtonText="Close"
      submitButtonText="Copy"
      onSubmitClick={handleSubmitClick}
      onCancel={dialogStateHandler.closeDialog}
      isSubmitDisabled={isLoading}
    >
      {isLoading && (
        <Box>
          <LinearProgress/>
        </Box>
      )}
      {
        error?.body.detail
          ? (
            <Alert severity="error">
            Something went wrong
            </Alert>
          )
          : <HLJson data={data}/>
      }
    </UniversalDialog>
  );
}

export default ActionApiLookupDialog;

import {useHasAccess} from "contexts/UserAccessContext";
import {Permissions, Roles} from "contexts/UserAccessContext/const";

const usePermissions = () => {
  const canViewKYCRequests = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.VIEW_KYC_REQUESTS]
    }
  ])

  const canSubmitKYCRequest = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.UPLOAD_KYC_REQUESTS]
    }
  ])

  return {
    canViewKYCRequests,
    canSubmitKYCRequest
  }
}

export default usePermissions

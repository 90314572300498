import React from 'react'
import { Button, ButtonProps, styled } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import {PaymentStatus} from "shared/types";

interface AppButtonProps extends ButtonProps {
  children: React.ReactNode
  dropdown?: boolean
  active?: boolean
  status?: PaymentStatus
  bgcolor?: string
  name?: string
  width?: number
  bold?: boolean
  uppercase?: boolean,
  loading?: boolean
}

const MuiButton = styled((props: AppButtonProps) => (
  <Button
    disableRipple
    disableElevation
    color='inherit'
    variant='outlined'
    {...props}
  />
))(
  ({
    theme,
    variant = 'outlined',
    color = 'inherit',
    bold = false,
    status,
    bgcolor,
    uppercase,
    active = false,
    width,
  }) => ({
    borderRadius: 8,
    minWidth: width || 50,
    textTransform: uppercase ? 'uppercase' : 'capitalize',
    fontWeight: bold ? 700 : 400,
    fontSize: 12,
    color: theme.palette.grey[600],

    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },

    '& .MuiButton-startIcon': {
      marginRight: 4,
    },

    ...(variant === 'outlined' && {
      borderColor: theme.palette.grey[300],
      backgroundColor: 'transparent',

      '&:hover': {
        backgroundColor: 'transparent',
      },

      ...(active && {
        borderColor: theme.palette.grey[600],
      }),
    }),

    ...(variant === 'contained' && {
      fontWeight: 500,
      ...(color === 'inherit' && {
        backgroundColor: theme.palette.grey[200],

        '&:hover': {
          backgroundColor: theme.palette.grey[300],
          color: theme.palette.primary.main,
        },
      }),

      ...(color === 'primary' && {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.dark,

        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
      }),

      ...(color === 'secondary' && {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,

        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      }),

      ...(color === 'info' && {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.dark,

        '&:hover': {
          backgroundColor: theme.palette.info.light,
        },
      }),

      ...(color === 'success' && {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.dark,

        '&:hover': {
          backgroundColor: theme.palette.success.light,
        },
      }),

      ...(color === 'warning' && {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.warning.dark,

        '&:hover': {
          backgroundColor: theme.palette.warning.light,
        },
      }),

      ...(color === 'error' && {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.dark,

        '&:hover': {
          backgroundColor: theme.palette.error.light,
        },
      }),

      ...(bgcolor && {
        backgroundColor: bgcolor,

        '&:hover': {
          backgroundColor: bgcolor,
        },
      }),
    }),
    ...(variant === 'text' && {
      fontSize: 13,
      fontWeight: 500,
      color: theme.palette.grey[500],
      '&:hover': {
        backgroundColor: 'transparent',
      },
    }),

    ...(status && {
      cursor: 'default',

      ...((status.toLowerCase() === 'approved' ||
        status.toLowerCase() === 'accepted' ||
        status.toLowerCase() === 'delivered') && {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.dark,

        '&:hover': {
          backgroundColor: theme.palette.success.light,
        },
      }),

      ...(status.toLowerCase() === 'pending' && {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.warning.dark,

        '&:hover': {
          backgroundColor: theme.palette.warning.light,
        },
      }),

      ...((status.toLowerCase() === 'rejected' ||
        status.toLowerCase() === 'declined') && {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.dark,

        '&:hover': {
          backgroundColor: theme.palette.error.light,
        },
      }),
    }),
  })
)

const AppButton: React.FC<AppButtonProps> = ({
  name,
  children,
  dropdown,
  active = false,
  status,
  bgcolor,
  uppercase,
  bold = false,
  ...props
}) => (
  <MuiButton
    active={active}
    bold={!!bold}
    aria-controls={name ? `${name}-menu` : undefined}
    aria-haspopup='true'
    aria-expanded={name ? 'true' : undefined}
    endIcon={dropdown && <ArrowDropDown fontSize='small' />}
    variant={status ? 'contained' : 'outlined'}
    status={status}
    uppercase={uppercase}
    bgcolor={bgcolor}
    {...props}
  >
    {children}
  </MuiButton>
)

export default AppButton

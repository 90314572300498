import {TableCell, TableRow, Typography, Chip, Link, Button} from "@mui/material";
import {UsersListSchema} from "generatedTypes";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";
import getRoleColor from "shared/components/roleColor";
import {truncate} from "lodash";
import {Link as RouterLink} from "react-router-dom";
import uiPaths from "apps/users/uiPaths";
import usePermissions from "apps/users/pages/edit/permissions/usePermissions";

interface UserRowProps {
  user: UsersListSchema
}

const UserRow = ({ user }: UserRowProps) => {
  const {canEditUsers} = usePermissions()
  
  return (
    <TableRow>
      <TableCell>
        <Typography variant='body2'>
          {truncate(`${user.first_name}`, {length: 15, omission: '...'})}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {truncate(`${user.last_name}`, {length: 15, omission: '...'})}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>{user.email}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>{user.phone}</Typography>
      </TableCell>
      <TableCell>
        <Chip label={user.role} color={getRoleColor(user.role || '')}/>
      </TableCell>
      <TableCell>
        <Chip label={user.is_active ? 'Active' : 'Inactive'} color={user.is_active ? 'success' : 'error'}/>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>{convertIsoToCustomFormat(user.created_at)}</Typography>
      </TableCell>
      <TableCell>
        {canEditUsers && (
          <Link underline="none" component={RouterLink} to={uiPaths.details.reverse({userId: user._id})}>
            <Button variant='outlined'>
              Details
            </Button>
          </Link>
        )}
      </TableCell>
    </TableRow>
  )
}

export default UserRow;

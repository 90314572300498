import {useCallback, useState} from "react";
import useCreateTemplate
  from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ColumnsToolBar/useDataMutations";
import type {ColumnStateModel} from "generatedTypes";
import {useSnackbar} from "notistack";

interface ColumnsState {
  gridRef: any;
}

const validateInput = (input: string) => {
  const unsafeCharacters = /[#$%&*<>]/;
  return !unsafeCharacters.test(input);
}

const useColumnsState = ({gridRef}: ColumnsState) => {
  const [templateName, setTemplateName] = useState('');

  const {mutate, isPending} = useCreateTemplate();
  const {enqueueSnackbar} = useSnackbar();


  const saveState = () => {
    if (gridRef.current) {
      mutate({
        requestBody: {
          name: templateName,
          template: gridRef.current.api.getColumnState()
        }
      });
      setTemplateName('');
    }
  };

  const restoreState = useCallback((state: Array<ColumnStateModel> | undefined) => {
    if (!state) {
      enqueueSnackbar('Failed to apply template', {variant: 'error'});
    }

    if (gridRef.current) {
      gridRef.current.api.applyColumnState({
        state,
        applyOrder: true,
      });
    }
  }, []);

  const resetState = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.resetColumnState();
    }
  }, []);

  const handleTemplateNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validateInput(event.target.value)) {
      setTemplateName(event.target.value);
    }
  }

  return {
    saveState,
    restoreState,
    resetState,
    isSavePending: isPending,
    templateName,
    setTemplateName: handleTemplateNameChange,
  }
}

export default useColumnsState;

import {useHasAccess} from "contexts/UserAccessContext";
import {Permissions, Roles} from "contexts/UserAccessContext/const";

const usePermissions = () => {
  const canViewActionCenter = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.VIEW_ACTION_CENTER]
    }
  ])

  const canUpdateActionCenter = useHasAccess([
    {
      'roles': [Roles.MERCHANT],
      'permissions': [Permissions.UPDATE_ACTION_CENTER]
    }
  ])

  const canUploadDocuments = useHasAccess([
    {
      'roles': [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.CUSTOMER_SERVICE,
        Roles.MERCHANT, Roles.RETAILER, Roles.SITE, Roles.MANAGER],
    }
  ])

  return {
    canViewActionCenter,
    canUpdateActionCenter,
    canUploadDocuments,
  }
}

export default usePermissions

import {useSnackbar} from "notistack";
import {useMutation} from "@tanstack/react-query";
import ApiClient from "apiClient";
import authService from "routes/authService";

export const useUserLogout = () => {
  const { enqueueSnackbar } = useSnackbar()

  const {mutate} = useMutation({
    mutationFn: () => ApiClient.auth.getLogout(),
    onMutate: () => {
      enqueueSnackbar('You are logged out successfully', {variant: 'success'})
    },
    onSuccess: () => authService.logout()
  })

  return {
    mutate
  }
}

export default useUserLogout;

import {CustomCellRendererProps} from "@ag-grid-community/react";
import {CustomerSchema} from "generatedTypes";
import {Box, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {bindMenu, usePopupState} from "material-ui-popup-state/hooks";
import {ReactComponent as UserLimitsIcon} from "assets/iconsDS/transaction-actions/user-limits.svg";
import {ReactComponent as NotesIcon} from "assets/iconsDS/Notes.svg";

import useDialogState, {DialogStateHandler} from "shared/hooks/useDialogState";
import ActionUpdateUserLimitsDialog from "shared/features/ActionUpdateUserLimits";
import {useTheme} from "@mui/material/styles";
import AddNoteDialog from "apps/customers/shared/CustomerActions/ActionAddNote";
import TableIconButton from "apps/customers/shared/CustomerActions/partials/TableIconButton";
import DetailsIconButton from "apps/customers/shared/CustomerActions/partials/DetailsIconButton";

interface CustomerActionsProps {
  customerEmail: CustomerSchema['email'],
  sid?: number,
  isTable?: boolean,
}

const CustomerActions = ({customerEmail, sid, isTable = false}: CustomerActionsProps) => {
  const theme = useTheme();
  const popupState = usePopupState({variant: 'popover', popupId: `customerActions-${customerEmail}`})

  const updateUserLimitsDialog = useDialogState();
  const addNoteDialog = useDialogState();

  const handleDialogMenuClick = (dialogStateHandler: DialogStateHandler) => () => {
    dialogStateHandler.openDialog();
    popupState.close();
  }

  return (
    <>
      {isTable ? (<TableIconButton popupState={popupState}/>) : (<DetailsIconButton popupState={popupState}/>)}
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...bindMenu(popupState)}
      >
        <MenuItem onClick={handleDialogMenuClick(addNoteDialog)}>
          <ListItemIcon>
            <Box
              component={NotesIcon}
              sx={{
                '& path, & rect': {
                  stroke: theme.palette.themeColors.grey['120'],
                }
              }}
            />
          </ListItemIcon>
          <ListItemText>Add Note</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDialogMenuClick(updateUserLimitsDialog)}>
          <ListItemIcon>
            <UserLimitsIcon/>
          </ListItemIcon>
          <ListItemText>Update User Limits</ListItemText>
        </MenuItem>
      </Menu>

      <ActionUpdateUserLimitsDialog
        dialogStateHandler={updateUserLimitsDialog}
        email={customerEmail}
        sid={sid}
      />
      <AddNoteDialog
        dialogStateHandler={addNoteDialog}
        customerEmail={customerEmail}
      />
    </>
  );
};

export const CellActionsRenderer = ({data}: CustomCellRendererProps) => (
  <CustomerActions
    customerEmail={data.email}
    isTable
  />
)

export default CustomerActions;

import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {DialogStateHandler} from "shared/hooks/useDialogState";
import useCreateNote from "apps/customers/shared/CustomerActions/ActionAddNote/useDataMutations";
import TextFormField from "shared/components/formFields/TextFormField";
import UniversalDialog from "shared/components/DS/UniversalDialog";

const schema = yup.object().shape({
  note: yup.string()
    .required('Field is required')
    .max(500, 'Note cannot be longer than 500 characters')
    .matches(/^[\w !#$&'()*+,.:@-]*$/, 'Character(s) invalid or not allowed.'),
});

export type FormData = yup.InferType<typeof schema>;

export interface AddNoteProps {
  dialogStateHandler: DialogStateHandler
  customerEmail?: string
}

const AddNoteDialog = ({dialogStateHandler, customerEmail}: AddNoteProps) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });


  const {mutate, isPending} = useCreateNote({
    setError: methods.setError,
    reset: () => methods.reset(methods.formState.defaultValues),
    onSuccess: dialogStateHandler.closeDialog,
  });


  const onSubmit = (data: FormData) => {
    if (customerEmail) {
      mutate({email: customerEmail, requestBody: {note: data.note}})
    }
  }

  return (
    <FormProvider {...methods}>
      <UniversalDialog
        dialogStateHandler={dialogStateHandler}
        title="Add note to customer"
        submitButtonText="Create"
        onSubmit={methods.handleSubmit(onSubmit)}
        isPending={isPending}
      >
        <TextFormField name='note' placeholder='What’s on the agenda...' multiline/>
      </UniversalDialog>
    </FormProvider>
  );
};

export default AddNoteDialog;

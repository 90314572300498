import React from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Timeline, { TimelineProps } from '@mui/lab/Timeline/Timeline'
import { styled } from '@mui/material/styles'
import { timelineOppositeContentClasses } from '@mui/lab'

interface AppTimelineProps extends TimelineProps {
  ref?: never,
  children: React.ReactNode
}

const MuiTimeline = styled((props: AppTimelineProps) => <Timeline {...props} />)(
  () => ({
    [`& .${timelineOppositeContentClasses.root}`]: {
      flex: 0.1,
    },

    '& .MuiTimelineDot-root': {
      margin: 0,
      padding: 8,

      '&.hasIcon': {
        padding: 0,
      },

      '& > .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },

    '& .MuiTimelineSeparator-root': {
      transform: 'translateY(8px)',
    },

    '& .MuiTimelineItem-root': {
      minHeight: 100,
    },
  })
)

const AppTimeline: React.FC<AppTimelineProps> = ({ children, ...props }) => <MuiTimeline {...props}>{children}</MuiTimeline>

export default AppTimeline

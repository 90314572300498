import {ColDef} from "@ag-grid-community/core";
import ColumnItem from "apps/transactions/pages/TransactionsList/partials/TransactionsTable/ColumnsToolBar/ColumnItem";

interface ColumnsListProps {
  columns?: ColDef[];
  gridRef: any;
}

const ColumnsList = ({ columns, gridRef }: ColumnsListProps) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {
      columns?.map((item, index: number) => (
        <ColumnItem
          column={item}
          index={index}
          key={item.field}
          gridRef={gridRef}
        />
      ))
    }
  </>
)

export default ColumnsList;

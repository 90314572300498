export enum TransactionStatus {
  approved = 'approved',
  claimed = 'claimed',
  batched = 'batched',
  pending = 'pending',
  confirming = 'confirming',
  sent = 'sent',
  waiting_authorization = 'waiting_authorization',
  declined = 'declined',
  cancelled = 'cancelled',
  expired = 'expired',
  failed = 'failed',
  refused = 'refused',
  pg_error = 'pg_error',
  pl_error = 'pl_error',
  process_expired = 'process_expired',
}

export enum TransactionType {
  capture = 'capture',
  chargeback = 'chargeback',
  payment = 'payment',
  payout = 'payout',
  preauth = 'preauth',
  refund = 'refund',
  void = 'void',
}

import {Controller, useFormContext} from "react-hook-form";
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {isValid, parseISO} from "date-fns";
import {useCallback} from "react";

type SelectFieldsProps = {
  name: string,
  placeholder?: string,
  label?: string,
  maxDate?: Date,
  minDate?: Date,
}

const DateTimePickerFormField = ({name, placeholder, label, maxDate, minDate}: SelectFieldsProps) => {
  const {control} = useFormContext();

  const validateDate = useCallback((value: string | Date) => {
    if (typeof value === 'string') {
      const date = parseISO(value);
      if (isValid(date)) {
        return date;
      }
    }
    return value as Date;
  }, [name])

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => {
        const value = validateDate(field.value);
        return (
          <DateTimePicker
            {...field}
            value={value}
            label={label}
            maxDate={maxDate}
            minDate={minDate}
            slotProps={{
              textField: {
                fullWidth: true,
                size: 'small',
                error: fieldState.invalid,
                id: `${name}-field`,
                helperText: fieldState.error?.message,
                placeholder,
              }
            }}
          />
        )}}
    />
  );
};

export default DateTimePickerFormField;

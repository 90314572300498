import {Grid} from '@mui/material';
import {useParams} from "react-router-dom";
import {useTransactionDetailsQuery} from "apps/transactions/pages/TransactionDetails/useDataQueries";
import TransactionHead from "apps/transactions/pages/TransactionDetails/partials/TransactionHead";
import TransactionDetailsData from "apps/transactions/pages/TransactionDetails/partials/TransactionDetailsData";
import RelatedTransactions from "apps/transactions/pages/TransactionDetails/partials/tabs/RelatedTransactions";
import TransactionCustomer from "apps/transactions/pages/TransactionDetails/partials/TransactionCustomer";
import Items from "apps/transactions/pages/TransactionDetails/partials/tabs/Items";
import Notes from "apps/transactions/pages/TransactionDetails/partials/tabs/Notes";
import TransactionTimeline from "apps/transactions/pages/TransactionDetails/partials/tabs/Timeline";
import useBreadcrumb from "shared/hooks/useBreadcrumb";
import {commonBreadcrumbs} from "shared/breadcrumbConfig";
import {useRef, useState} from "react";
import Navigation from "apps/transactions/pages/TransactionDetails/partials/Navigation";


const TransDetails = () => {
  const {transactionId} = useParams()

  const {data, isLoading} = useTransactionDetailsQuery({txn: transactionId || ''})
  useBreadcrumb([commonBreadcrumbs.transactionsList, { name: data?.txn || ''}]);

  const itemsRef = useRef<HTMLDivElement | null>(null);
  const relatedRef = useRef<HTMLDivElement | null>(null);
  const notesRef = useRef<HTMLDivElement | null>(null);

  const [itemsCount, setItemsCount] = useState<number | undefined>()
  const [notesCount, setNotesCount] = useState<number | undefined>()
  const [relatedCount, setRelatedCount] = useState<number | undefined>()

  const handleScroll = (value: string) => {
    if (value === "notes" && notesRef.current) {
      notesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (value === "items" && itemsRef.current) {
      itemsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (value === "related" && relatedRef.current) {
      relatedRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      <TransactionHead
        status_timestamp={data?.status_timestamp || ''}
        amount={data?.amount || 0}
        refundableBalance={data?.refundable_balance || 0}
        status={data?.transaction_status || ''}
        type={data?.type || ''}
        email={data?.email || ''}
        sid={data?.sid || 0}
        isLoading={isLoading}
        action={data?.action || ''}
      >
        <Grid container spacing={3}>
          <Grid item md={8}>
            <TransactionDetailsData
              txn={data?.txn || ''}
              markedBy={data?.marked_by}
              sid={data?.sid}
              siteName={data?.site_name}
              refundableBalance={data?.refundable_balance}
              firstName={data?.first_name}
              lastName={data?.last_name}
              method={data?.method}
              descriptor={data?.descriptor}
              udfs={data?.udfs}
              bankName={data?.institution_name?.fi_name}
              status={data?.transaction_status}
              type={data?.type}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item md={4}>
            <TransactionCustomer
              email={data?.email || ''}
              firstName={data?.first_name || ''}
              lastName={data?.last_name || ''}
              address={data?.full_address || ''}
              phoneNumber={data?.phone || ''}
              ipAddress={data?.customer_ip_address || ''}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </TransactionHead>

      <Grid container spacing={3}>
        <Grid item md={9.5}>
          <Navigation
            handleScroll={handleScroll}
            itemsCount={itemsCount}
            relatedCount={relatedCount}
            notesCount={notesCount}
            isLoading={isLoading}
          />

          <Items ref={itemsRef} setCount={setItemsCount} />
          <RelatedTransactions ref={relatedRef} setCount={setRelatedCount}/>
          <Notes ref={notesRef} setCount={setNotesCount} />
        </Grid>
        <Grid item md={2.5}>
          <TransactionTimeline isLoading={isLoading} />
        </Grid>
      </Grid>
    </>
  )
}

export default TransDetails

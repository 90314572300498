import {Components} from "@mui/material/styles";
import palette from "theme/palette";

const MuiMenu: Components['MuiMenu'] =  {
  styleOverrides: {
    paper: {
      borderRadius: '8px',
      border: `1px solid ${palette.themeColors.grey["20"]}`,
      boxShadow: '0px 10px 20px -4px rgba(33, 32, 29, 0.15)',
    },
    list: {
      padding: '4px 6px'
    }
  }
}

export default MuiMenu;

import {Avatar, Chip, Link, Stack, Typography} from "@mui/material";
import {WrapperBox} from "shared/styled/WrapperBox";
import {Link as RouterLink, useParams} from "react-router-dom";
import UiPaths from "routes/uiPaths";
import {useTheme} from "@mui/material/styles";
import palette from "theme/palette";
import {
  useCustomerRelatedCustomers
} from "apps/customers/pages/CustomerDetails/partials/RelatedCustomers/useDataQueries";

const RelatedCustomers = () => {
  const {customerEmail} = useParams();
  const theme = useTheme();

  const {data} = useCustomerRelatedCustomers({email: customerEmail || ''});

  const relatedCustomersCount = data?.related_customers?.length || 0;

  return (
    <WrapperBox
      sx={{
        padding: '16px 24px'
      }}
    >
      <Stack spacing={1}>
        <Stack  sx={{paddingBottom: '14px'}}>
          <Stack direction='row' alignItems='center' gap='8px'>
            <Typography variant='h4'>
              Related Customers
            </Typography>
            <Typography variant='h4' color={theme.palette.themeColors.grey['80']}>
              {data?.related_customers.length}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          {data?.related_customers.map((item, index) => {
            const isLastItem = index === relatedCustomersCount - 1;
            return (
              <Stack
                key={item.email}
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                padding='14px 0px'
                sx={{
                  borderBottom: isLastItem ? 'none' : `1px solid ${theme.palette.themeColors.grey['40']}`,
                }}
              >
                <Stack direction='row' alignItems='center'>
                  <Avatar
                    variant="rounded"
                    sx={{
                      marginRight: '12px',
                      width: '28px',
                      height: '28px',
                    }}
                  >
                    {item.email[0].toUpperCase()}
                  </Avatar>
                  <Link
                    component={RouterLink}
                    to={UiPaths.customerPaths.detail.reverse({customerEmail: item.email})}
                    sx={{
                      color: palette.primary.main,
                    }}
                  >
                    {item.email}
                  </Link>
                </Stack>
                <Chip label={item.type} variant={item.type === 'customer' ? 'tealBadge': 'pinkBadge'}/>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </WrapperBox>
  )
}

export default RelatedCustomers;
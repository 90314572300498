import type {LoaderFunctionArgs} from "react-router-dom";
import {redirect} from "react-router-dom";
import authService from "routes/authService";

export const protectedLoader = ({request}: LoaderFunctionArgs) => {
  const {isAuthenticated} = authService;

  if (!isAuthenticated) {
    const params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect(`/login?${params.toString()}`);
  }
  return null;
}

export const loginLoader = () => {
  const {isAuthenticated} = authService;

  if (isAuthenticated) {
    return redirect('/');
  }
  return null;
}

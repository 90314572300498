import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {KycRequestQueryKeys} from "shared/queryKeys";
import ApiClient from "apiClient";
import {DialogStateHandler} from "shared/hooks/useDialogState";

type Params = Parameters<typeof ApiClient.kycRequests.getKycRequestsList>[0]

export const useKycRequestsList = ({
  page,
  perPage,
  ...filters
}: Params) => {
  const {data, refetch, isLoading, isFetching} = useQuery({
    queryKey: [KycRequestQueryKeys.LIST, {page, perPage, filters: {filters}}],
    queryFn: () => ApiClient.kycRequests.getKycRequestsList({page, perPage, ...filters}),
    placeholderData: keepPreviousData,
    staleTime: 0,
    gcTime: 0,
  })

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  }
}

interface KycRequestDetailsArgs {
  email: string,
  kycId: string,
  dialogHandler: DialogStateHandler
}

export const useKycRequestDetails = ({email, kycId, dialogHandler}: KycRequestDetailsArgs) => {
  const {data, isLoading, isFetching} = useQuery({
    queryKey: [KycRequestQueryKeys.DETAILS, {email, kycId}],
    queryFn: () => ApiClient.kycRequests.getKycRequestDetail({email, kycId}),
    placeholderData: keepPreviousData,
    enabled: dialogHandler.isOpen,
  })

  return {
    data,
    isLoading,
    isFetching,
  }
}
import {AppCard} from "shared/components/mui";
import {UserSettingsSchema} from "generatedTypes";
import EmailForm from "apps/account/pages/settings/ContacInfo/EmailForm";
import PhoneForm from "apps/account/pages/settings/ContacInfo/PhoneForm";
import { Box } from "@mui/material";

interface ContactInfoProps {
  email: UserSettingsSchema["email_address"]
  phone: UserSettingsSchema["phone"]
  emailVerified: UserSettingsSchema["email_verified"]
  phoneVerified: UserSettingsSchema["phone_verified"]
}

const ContactInfo = ({email, phone, emailVerified, phoneVerified}: ContactInfoProps) => (
  <AppCard
    title='Contact Info'
    headStyle={{pb: 0}}
    bodyStyle={{m: 0}}
    sx={{pb: 0}}
  >
    <Box sx={{display: 'flex', mt: 1}}>
      <EmailForm email={email} verified={emailVerified}/>
      <PhoneForm phone={phone} verified={phoneVerified} />
    </Box>
  </AppCard>
)

export default ContactInfo;
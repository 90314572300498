import {Avatar, Box, Typography} from "@mui/material";
import getImageURL from "apps/transactions/pages/TransactionDetails/urls";
import {fAvatar} from "shared/utils/formats";

interface SitePreviewProps {
  site_name: string;
  sid: number;
}

const SitePreview = ({site_name, sid}: SitePreviewProps) => (
  <Box sx={{display: 'flex', alignItems: 'center', gap: '20px'}}>
    <Avatar
      sx={{width: 16, height: 16, fontSize: 13}}
      src={getImageURL(sid)}
      variant='rounded'
    >
      {fAvatar(site_name)}
    </Avatar>
    <Typography variant='body2'>{site_name} (#{sid})</Typography>
  </Box>
)

export default SitePreview;
import EventsList from "apps/dashboard/pages/Dashboard/partials/LiveStreaming/EventsList";
import {Box, MenuItem, Select} from "@mui/material";
import {useState} from "react";
import {StreamType} from "apps/dashboard/pages/Dashboard/partials/LiveStreaming/types";

const LiveStreaming = () => {
  const [streamType, setStreamType] = useState<StreamType>('everything');
  return (
    <Box>
      <Box sx={{
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'baseline',
      }}>
        <h3>Live Streaming</h3>
        <Box>
          <Select
            value={streamType}
            size='small'
            onChange={(e) => setStreamType(e.target.value as StreamType)}
          >
            <MenuItem value='everything'>Everything</MenuItem>
            <MenuItem value='payment'>Payment</MenuItem>
            <MenuItem value='payout'>Payout</MenuItem>
          </Select>
        </Box>
      </Box>

      <EventsList streamType={streamType}/>
    </Box>
  );
}

export default LiveStreaming;

import {Typography} from "@mui/material";
import {fCurrency} from "shared/utils";

interface AmountProps {
  value: number;
  currency_code: 'CAD'| 'USD';
}

const Amount = ({value, currency_code}: AmountProps) => (
  <Typography variant='B4'>
    {fCurrency(value)} {currency_code}
  </Typography>
)

export default Amount;
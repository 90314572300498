import React from 'react'
import {
  Box,
  Card,
  CardHeader,
  CardProps,
  SxProps,
  styled,
} from '@mui/material'

interface AppCardProps extends CardProps {
  title?: string
  subheader?: string
  transparent?: boolean
  action?: React.ReactNode
  children: React.ReactNode
  bodyStyle?: SxProps
  headStyle?: SxProps
}

const MuiCard = styled((props: AppCardProps) => <Card elevation={0} {...props} />)(
  ({ theme, transparent = false }) => ({
    borderRadius: 6,
    boxShadow: '0px 1px 1px #DCDFE1',
    paddingBottom: 32,

    '& .MuiCardHeader-title': {
      color: theme.palette.text.primary,
    },

    ...(transparent && {
      backgroundColor: 'transparent',
      paddingBottom: 0,
      boxShadow: 'none',
    }),
  })
)

const AppCard: React.FC<AppCardProps> = ({
  title,
  subheader,
  action,
  children,
  transparent = false,
  bodyStyle,
  headStyle,
  ...props
}) => (
  <MuiCard transparent={!!transparent} {...props}>
    {(title || subheader) && (
      <CardHeader
        titleTypographyProps={{ variant: 'body1', fontWeight: 'medium' }}
        title={title}
        subheader={subheader}
        action={action}
        sx={{ p: 3, ...headStyle }}
      />
    )}

    <Box sx={{ mx: 3, ...bodyStyle, }}>{children}</Box>
  </MuiCard>
)

export default AppCard

import {Chip, Link, TableCell, TableRow, Typography} from "@mui/material";
import {DashboardRequestsListModel} from "generatedTypes";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";
import truncate from "lodash/truncate";
import {fCurrency} from "shared/utils";
import {Link as RouterLink} from "react-router-dom";
import SitePreview from "shared/components/SitePreview";
import uiPaths from "apps/users/uiPaths";
import TransactionLink from "shared/components/TransactionLink";

function DashboardRequestsRow({
  sid_name,
  sid,
  txn,
  action,
  amount,
  currency_code,
  reason,
  status,
  comment,
  created_by,
  created_at,
  updated_by,
  updated_at
}: DashboardRequestsListModel) {
  return (
    <TableRow>
      <TableCell>
        <SitePreview site_name={sid_name || ''} sid={sid || 0} />
      </TableCell>
      <TableCell>
        <TransactionLink txn={txn || ''} />
      </TableCell>
      <TableCell>
        <Chip label={action} color='secondary' />
      </TableCell>
      <TableCell>
        {amount && (
          <Typography variant='body2'>
            {fCurrency(amount)} {currency_code}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {truncate(reason || '', {length: 30})}
        </Typography>
      </TableCell>
      <TableCell>
        <Chip label={status} color={status === 'completed' ? 'success' : status === 'processing' ? 'error' : 'warning'} />
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {truncate(comment || '', {length: 30})}
        </Typography>
      </TableCell>
      <TableCell>
        {created_by && (
          <Link underline="none" component={RouterLink} to={uiPaths.details.reverse({userId: created_by})}>
            {created_by}
          </Link>
        )}
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {created_at && convertIsoToCustomFormat(created_at)}
        </Typography>
      </TableCell>
      <TableCell>
        {updated_by && (
          <Link underline="none" component={RouterLink} to={uiPaths.details.reverse({userId: updated_by})}>
            {updated_by}
          </Link>
        )}
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {updated_at && convertIsoToCustomFormat(updated_at)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default DashboardRequestsRow;

import {useMemo} from 'react';
import {useQuery} from "@tanstack/react-query";
import ApiClient from "apiClient";
import AutocompleteFormField from "shared/components/formFields/AutocompleteFormField";
import {VirtualTerminalQueryKeys} from "shared/queryKeys";

interface CustomerSitesAutocompleteFormFieldProps {
  name: string;
  label: string;
}

const CustomerSitesAutocompleteFormField = ({name, label}: CustomerSitesAutocompleteFormFieldProps) => {
  const {data, isLoading} = useQuery({
    queryKey: [VirtualTerminalQueryKeys.SITES],
    queryFn: () => ApiClient.virtualTerminal.getVirtualTerminalSites({perPage: -1}),
  })

  const optionsList = useMemo(() => data?.items
    ?.filter(site => site.name && site.sid)
    .map((site) => ({value: site.sid as number, label: `${site.name} - (#${site.sid})` })), [data])

  return (
    <AutocompleteFormField
      name={name}
      label={label}
      options={optionsList || []}
      isLoading={isLoading}
    />
  );
};

export default CustomerSitesAutocompleteFormField;

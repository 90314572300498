import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import type {TransactionDetailModel} from "generatedTypes";
import StatusOfTransaction from "shared/components/DS/StatusOfTransaction";
import PaymentType from "shared/components/DS/PaymentType";
import greenBg from "assets/images/transaction-bg/green.svg";
import orangeBg from "assets/images/transaction-bg/orange.svg";
import redBg from "assets/images/transaction-bg/red.svg";
import {useTheme} from "@mui/material/styles";
import {fCurrency} from "shared/utils";
import {convertIsoToCustomFormat} from "shared/utils/dateFormats";
import {TransactionStatus} from "shared/enum_const";
import HelpToolTip from "shared/components/DS/HelpToolTip";
import TransactionActions from "apps/transactions/pages/TransactionDetails/partials/TransactionActions";
import SkeletonTransactionHead
  from "apps/transactions/pages/TransactionDetails/partials/TransactionHead/SkeletonTransactionHead";

export const TransactionStatusBackground: { [key: string]: string } = {
  [TransactionStatus.approved]: greenBg,
  [TransactionStatus.claimed]: greenBg,
  [TransactionStatus.batched]: orangeBg,
  [TransactionStatus.pending]: orangeBg,
  [TransactionStatus.confirming]: orangeBg,
  [TransactionStatus.sent]: orangeBg,
  [TransactionStatus.waiting_authorization]: orangeBg,
  [TransactionStatus.declined]: redBg,
  [TransactionStatus.cancelled]: redBg,
  [TransactionStatus.expired]: redBg,
  [TransactionStatus.failed]: redBg,
  [TransactionStatus.refused]: redBg,
  [TransactionStatus.pg_error]: redBg,
  [TransactionStatus.pl_error]: redBg,
  [TransactionStatus.process_expired]: redBg,

  default: greenBg,
};

const getHeadBg = (status: TransactionDetailModel['transaction_status']) => {
  if (!status) return TransactionStatusBackground.default;

  return TransactionStatusBackground[status] || TransactionStatusBackground.default
}

interface TransactionHeadProps {
  status_timestamp?: TransactionDetailModel['status_timestamp'];
  amount?: TransactionDetailModel['amount'];
  refundableBalance: TransactionDetailModel['refundable_balance'];
  status: TransactionDetailModel['transaction_status'];
  type?: TransactionDetailModel['type'];
  email?: TransactionDetailModel['email'];
  sid?: TransactionDetailModel['sid'];
  // currencyCode?: TransactionDetailModel['currency_code'];
  children: React.ReactNode;
  isLoading: boolean;
  action: TransactionDetailModel['action'];
}

const TransactionHead = ({
  status_timestamp, amount, refundableBalance, status, type, email, sid, children, isLoading, action
}: TransactionHeadProps) => {
  const theme = useTheme();

  if (isLoading) return <SkeletonTransactionHead>{children}</SkeletonTransactionHead>;

  return (
    <Box
      sx={{
        backgroundImage: `url(${getHeadBg(status)})`,
        backgroundColor: theme.palette.common.white,
        backgroundRepeat: 'no-repeat',
        border: `1px solid ${theme.palette.themeColors.grey['40']}`,
        padding: theme.spacing(3),
        borderRadius: theme.spacing(1.5),
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Typography variant="h1">
            {fCurrency(amount)}
          </Typography>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              marginTop: '2px',
            }}
          >
            <Stack direction="row" alignItems='center' spacing={1.25}>
              {status && type && (
                <StatusOfTransaction
                  status={status}
                  type={type}
                />
              )}

              <PaymentType value={type} />
            </Stack>
            <Stack
              direction="row"
              sx={{
                marginTop: '9px !important',
                alignItems: 'center',
              }}>
              <Typography
                variant="B4"
                color={theme.palette.themeColors.grey['90']}
                sx={{
                  fontWeight: 400,
                }}
              >
                {status_timestamp && convertIsoToCustomFormat(status_timestamp)}
              </Typography>
              <Box sx={{
                marginLeft: '4px',
              }}>
                <HelpToolTip title="Status timestamp" />
              </Box>
            </Stack>
          </Stack>
        </Stack>

        <Stack>
          <TransactionActions
            transactionAction={action}
            transactionStatus={status}
            transactionAmount={amount}
            refundableBalance={refundableBalance}
            transactionType={type}
            isTransactionProcessed={false}
            email={email}
            sid={sid}
          />
        </Stack>
      </Stack>
      <Box sx={{marginTop: '23px'}}>
        {children}
      </Box>
    </Box>
  );
};

export default TransactionHead;

import { Components, Theme } from '@mui/material/styles';

const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.B4,
      color: theme.palette.themeColors.grey['90'],
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      padding: '4px 0 8px',
      minHeight: '32px',
      minWidth: 'auto',
      textTransform: 'none',
      '& path': {
        stroke: theme.palette.themeColors.grey['90'],
      },
      '&.Mui-selected': {
        color: theme.palette.themeColors.grey['120'],
        '& path': {
          stroke: theme.palette.themeColors.grey['120'],
        },
      },
      '& .MuiTab-iconWrapper': {
        margin: 0,
      },
    }),
  },
};

export default MuiTab;

import {useEffect} from 'react';
import {useBreadcrumbs} from "contexts/BreadcrumbContext";
import {isEmpty, isEqual, xorWith} from "lodash";

interface Breadcrumb {
  name: string;
  link?: string;
}

const useBreadcrumb = (breadcrumbs: Breadcrumb[]) => {
  const {updateBreadcrumbs, breadcrumbs: currentBreadcrumbs} = useBreadcrumbs();

  const newBreadcrumbs = isEmpty(xorWith(breadcrumbs, currentBreadcrumbs, isEqual))

  useEffect(() => {
    updateBreadcrumbs(breadcrumbs);
  }, [newBreadcrumbs]);
};

export default useBreadcrumb;
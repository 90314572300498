/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MerchantDirectoryPaginatedSchema } from '../models/MerchantDirectoryPaginatedSchema';
import type { MerchantDirectorySchema } from '../models/MerchantDirectorySchema';
import type { MerchantPaginatedSchema } from '../models/MerchantPaginatedSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MerchantsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get virtual terminal sites for current user
     * @returns MerchantPaginatedSchema Success
     * @throws ApiError
     */
    public getMerchant({
        page,
        perPage,
        xFields,
    }: {
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<MerchantPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/merchants/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }
    /**
     * Get merchant directory contacts
     * Roles: MERCHANT
     * Permissions: ACCESS_MERCHANT_DIRECTORY
     * @returns MerchantDirectoryPaginatedSchema Success
     * @throws ApiError
     */
    public getMerchantDirectoryContacts({
        page,
        perPage,
        search,
        orderBy,
        xFields,
    }: {
        /**
         * Page to display
         */
        page?: number,
        /**
         * Items per page
         */
        perPage?: number,
        /**
         * Search by name, title, email, phone, messaging_appname, messaging_username, additional_notes
         */
        search?: string,
        /**
         * Order by field
         */
        orderBy?: string,
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<MerchantDirectoryPaginatedSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/merchants/directory/',
            headers: {
                'X-Fields': xFields,
            },
            query: {
                'page': page,
                'per_page': perPage,
                'search': search,
                'order_by': orderBy,
            },
        });
    }
    /**
     * Create merchant directory contacts
     * Roles: MERCHANT
     * Permissions: MANAGE_MERCHANT_DIRECTORY
     * @returns MerchantDirectorySchema Success
     * @throws ApiError
     */
    public postMerchantDirectoryContacts({
        requestBody,
        xFields,
    }: {
        requestBody: {
            mid?: string;
            contact_type?: string;
            name?: string;
            title?: string;
            email?: string;
            phone?: string;
            username?: string;
            app_name?: string;
            additional_notes?: string;
        },
        /**
         * An optional fields mask
         */
        xFields?: string,
    }): CancelablePromise<MerchantDirectorySchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/merchants/directory/',
            headers: {
                'X-Fields': xFields,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete merchant directory contacts
     * Roles: MERCHANT
     * Permissions: MANAGE_MERCHANT_DIRECTORY
     * @returns any Success
     * @throws ApiError
     */
    public deleteMerchantDirectoryContacts({
        ids,
    }: {
        /**
         * List of merchant directory ids
         */
        ids: Array<string>,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/merchants/directory/',
            query: {
                '_ids': ids,
            },
        });
    }
}
